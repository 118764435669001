import { ApplicationStatus, User } from 'graphql/globalTypes';
import Closed from 'components/ApplicationStatuses/Closed';

interface IApplications {
  applicants?: User[];
}
const ClosedApplications: React.FC<IApplications> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) => app.application?.currentStatus === ApplicationStatus.Closed
  ).length;

  return (
    <div>
      <div className="application-statistics" data-testid="closedcounter">
        {applicationCount}
      </div>
      <Closed />
    </div>
  );
};

export default ClosedApplications;
