import DatePicker from 'react-datepicker';
//The react datepicker css needs to be added like this due to jest not transforming it from the node modules. Snapshots were throwing errors because jest was ignoring it within the node modules.
import './react-datepicker.css';

interface iFilterDatepickerComponentProps {
  startDate?: Date | null;
  onSetStartDate: (date?: unknown) => void;
  endDate?: Date | null;
  onSetEndDate: (date?: unknown) => void;
}

export const DateChangedFilterComponent: React.FC<iFilterDatepickerComponentProps> = (props) => {
  return (
    <>
      <div className="filter-date-title">
        <label className="date-css"> UPDATED DATE </label>
      </div>
      <DatePicker
        selected={props.startDate}
        placeholderText="Filter start date"
        onChange={(date) => props.onSetStartDate(date)}
        selectsStart
        startDate={props.startDate}
        endDate={props.endDate}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isClearable={true}
      />
      <DatePicker
        selected={props.endDate}
        placeholderText="Filter end date"
        onChange={(date) => props.onSetEndDate(date)}
        selectsEnd
        startDate={props.startDate}
        endDate={props.endDate}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={props.startDate}
        isClearable={true}
      />
    </>
  );
};
