import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface IDashboardWidgetFooter {
  linkText: string;
  linkPath: string;
}

export const DashboardWidgetFooter: React.FC<IDashboardWidgetFooter> = (props) => {
  return (
    <div className="dashboard-widget-footer">
      <Link to={props.linkPath}>
        {props.linkText} <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    </div>
  );
};
