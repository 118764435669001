import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import IndustryTrainingForm from './Forms/IndustryTrainingForm';

import { REMOVE_INDUSTRY_TRAINING, REPLACE_ALL_INDUSTRY_TRAINING } from 'graphql/queries/industrytraining';
import { GET_USER } from 'graphql/queries/users';
import {
  IndustryTraining,
  GetUserQuery,
  IndustryTrainingInput,
  MutationRemoveIndustryTrainingArgs,
  MutationReplaceAllIndustryTrainingArgs,
} from 'graphql/globalTypes';

import { constants } from 'services/constants';
import { AnalyticsSendEvent } from 'services/googleAnalytics';
import Tooltip from 'components/Tooltip';

let saveTimeoutId: number | undefined;

const ITComponent: React.FC = () => {
  const {
    loading: GetITloading,
    error: GetITError,
    data: ITData,
    refetch: RefetchITData,
  } = useQuery<GetUserQuery>(GET_USER);
  const [ReplaceAllIndustryTraining, { error: PutITError }] =
    useMutation<MutationReplaceAllIndustryTrainingArgs>(REPLACE_ALL_INDUSTRY_TRAINING);

  const [removeIT, { error: RemoveError }] = useMutation<MutationRemoveIndustryTrainingArgs>(REMOVE_INDUSTRY_TRAINING);

  const [industryTraining, setIndustryTrainings] = useState<IndustryTraining[]>([]);

  useEffect(() => {
    if (ITData?.getUser?.industryTraining) {
      console.log('useEffect:GetITData', ITData);

      setIndustryTrainings([...ITData.getUser.industryTraining]);
    }
  }, [ITData]);

  const onITChange = (index: number, sectionDetail: IndustryTraining, addNew = false): void => {
    console.log('sectionDetail', sectionDetail);
    if (saveTimeoutId) {
      window.clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    let newExperiences: IndustryTraining[];

    if (addNew) {
      newExperiences = [...industryTraining, sectionDetail];
    } else {
      newExperiences = [...industryTraining];
      newExperiences[index] = Object.assign({}, sectionDetail);
    }

    setIndustryTrainings(newExperiences);

    saveTimeoutId = window.setTimeout(async () => {
      const saveVar: IndustryTrainingInput[] = newExperiences.map((weItem) => {
        const { __typename, id, ...rest } = weItem;

        return rest;
      });

      try {
        await ReplaceAllIndustryTraining({ variables: { industryTraining: saveVar } });
      } catch (error) {
        console.log('Error Saving Industry Training: ', error);
      } finally {
        await RefetchITData();
      }
    }, constants.TIME_TO_SAVE);
  };

  const onPlusBtnClick = (e: React.FormEvent): void => {
    e.preventDefault();

    onITChange(-1, { id: 'newId' }, true);

    AnalyticsSendEvent({
      category: 'User - Applicant',
      action: 'Add more details',
      label: 'Industry training',
      transport: 'beacon',
    });
  };

  if (GetITloading) {
    console.log('Waiting to load Industry Training');
    return <div className="response success-response">Loading Data</div>;
  }

  const onDelete = async (index: number, sectionDetail: IndustryTraining): Promise<void> => {
    // delete clicked.
    console.log('delete button clicked.');
    console.log('following is going to be deleted.');
    console.log(industryTraining[index]);
    try {
      await removeIT({
        variables: {
          industryTrainingId: sectionDetail.id,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      await RefetchITData();
    }
  };

  return (
    <div className="education-form-container">
      <h3>
        Industry Training
        <Tooltip tooltip="Provide details of all relevant training that you undertook within previous employments." />
      </h3>
      {industryTraining.map((itItem, index) => {
        return (
          <div>
            <div className="break mt-1 mb-1"></div>
            <div className="review-section-number">{index + 1}</div>
            <IndustryTrainingForm
              key={`itItem_${itItem.id}_${index}`}
              index={index}
              job={itItem}
              onChange={onITChange}
              onDelete={onDelete}
            />
          </div>
        );
      })}
      <div className="add-button-container">
        <button type="submit" onClick={onPlusBtnClick}>
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
      </div>
      {RemoveError && <div className="response error-response">Error delete Data: {RemoveError}</div>}
      {GetITError && <div className="response error-response">Error getting Data: {GetITError}</div>}
      {PutITError && <div className="response error-response">Error putting Data: {PutITError}</div>}
    </div>
  );
};

export default ITComponent;
