import { DashboardWidget } from 'components/DashboardWidget';
import { decodeToken } from 'services/validation';
import { DashboardWidgetFooter } from 'components/DashboardWidgetFooter';

interface IPersonalDetailsWidget {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  personalInfo: any;
}

export const PersonalDetailsWidget: React.FC<IPersonalDetailsWidget> = (props) => {
  return (
    <DashboardWidget>
      <div className="personal-details-widget-row">
        <>
          {props.personalInfo.personalDetails.profilePicture?.filepath && (
            <img
              className="avatar-img-sm"
              src={
                props.personalInfo.personalDetails.profilePicture?.filepath + '?token=' + decodeToken().token ??
                undefined
              }
              alt={`${props.personalInfo.personalDetails.firstName} ${props.personalInfo.personalDetails.lastName} avatar`}
            />
          )}
        </>
        <div className="ml-1">
          <p className="mb-0 text-bold">
            {props.personalInfo.personalDetails.firstName} {props.personalInfo.personalDetails.lastName}
          </p>
          <p>{props.personalInfo.email}</p>
        </div>
      </div>
      <DashboardWidgetFooter linkText={'Personal details'} linkPath={'/personalinfo/personal-info'} />
    </DashboardWidget>
  );
};
