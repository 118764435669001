import { gql } from '@apollo/client';

export const SOLLE_ATTACHMENT_FIELDS = gql`
  fragment SolleAttachmentFields on SolleAttachment {
    id
    filepath
    mimetype
    encoding
    filename
  }
`;
