import React, { Fragment, useEffect, useState } from 'react';
import { PCLform } from './PCLform';
import { OPCLform } from './OPCLform';
import { PELform } from './PELform';
import { ExemptionReason, Module, User } from 'graphql/globalTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ReasonModal } from './ReasonModal';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';

export interface IExemptionReason {
  onReasonsChange: (moduleId: string, exemptionReasons: ExemptionReason[], saveNow: boolean) => void;
  module: Module;
  index: number;
  exemptionReasons?: ExemptionReason[];
  onAttachmentSelect: funcAttachmentsSelect;
  onDeleteExemptionReason: (moduleId: string, reasonId: string) => void;
  user: User;
}

const ExemptionReasons: React.FC<IExemptionReason> = (props) => {
  const [exemptionReasons, setExemptionReasons] = useState<ExemptionReason[]>(props.exemptionReasons ?? []);

  const onExemptionReasonsChange = (index: number, sectionDetail: ExemptionReason, saveNow = false): void => {
    const newExemptionReasons: ExemptionReason[] = [...exemptionReasons];
    newExemptionReasons[index] = Object.assign({}, sectionDetail);

    setExemptionReasons(newExemptionReasons);

    console.log('current state of exemption reasons', newExemptionReasons);
    props.onReasonsChange(props.module._id, newExemptionReasons, saveNow);
  };

  useEffect(() => {
    if (props.exemptionReasons) {
      setExemptionReasons([...props.exemptionReasons]);
    }
  }, [props.exemptionReasons]);

  const onReasonTypeSelected = (type: string): void => {
    onExemptionReasonsChange(exemptionReasons.length, { id: 'id', type: type }, true);
  };

  const showReasonTypeModal = (): void => {
    const confirmSubmission = document.getElementById('reason_modal' + props.index);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const plustButtonLabel = exemptionReasons.length == 0 ? 'Select basis for exemption' : 'Add more information';

  return (
    <Fragment>
      <ReasonModal
        index={props.index}
        key={`key-er-m-${props.index}`}
        onSelected={onReasonTypeSelected}
        moduleId={props.module._id}
      />

      {exemptionReasons.map((item, index) => {
        if (item.type == 'PCL') {
          return (
            <PCLform
              key={`er-${props.index}${index}`}
              index={index}
              parentIndex={props.index}
              job={item}
              onChange={onExemptionReasonsChange}
              moduleId={props.module._id}
              onAttachmentSelect={props.onAttachmentSelect}
              filename={item.attachments?.filename}
              onDeleteExemptionReason={props.onDeleteExemptionReason}
            />
          );
        } else if (item.type == 'PL') {
          return (
            <OPCLform
              key={`er-${props.index}${index}`}
              index={index}
              parentIndex={props.index}
              job={item}
              onChange={onExemptionReasonsChange}
              moduleId={props.module._id}
              onAttachmentSelect={props.onAttachmentSelect}
              filename={item.attachments?.filename}
              onDeleteExemptionReason={props.onDeleteExemptionReason}
              user={props.user}
            />
          );
        }

        return (
          <PELform
            key={`er-${props.index}${index}`}
            index={index}
            parentIndex={props.index}
            job={item}
            onChange={onExemptionReasonsChange}
            moduleId={props.module._id}
            onAttachmentSelect={props.onAttachmentSelect}
            filename={item.attachments?.filename}
            onDeleteExemptionReason={props.onDeleteExemptionReason}
            user={props.user}
          />
        );
      })}

      <div className="form-group">
        <div className="add-list-item-container">
          <button id={`btn-er-${props.index}`} type="submit" onClick={showReasonTypeModal}>
            <FontAwesomeIcon icon={faPlusCircle} />
            <div>
              <label htmlFor="exemption_basis">{plustButtonLabel}</label>
            </div>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export { ExemptionReasons };
