import React, { Fragment } from 'react';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { User } from 'graphql/globalTypes';
import { EducationTypes } from 'pages/Applicant/PersonalDetails/Education';

export interface IExemptionEduSelection {
  onEduSelect: (edu: string, id: string, type: EducationTypes) => void;
  index: number;
  user: User;
}

const ExemptionEduSelection: React.FC<IExemptionEduSelection> = (props) => {
  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { value } = event.currentTarget;

    let type = EducationTypes.ThirdLevel;
    let found = props.user.education?.thirdLevel?.filter((we) => we.id === value)[0];

    if (!found) {
      found = props.user.education?.furtherEducation?.filter((we) => we.id === value)[0];
      type = EducationTypes.FurtherEducation;
    }

    if (found) {
      const data = `Qualification: ${found.qualification} \nInstitute: ${found.institute}`;
      props.onEduSelect(data, value, type);
    }
  };

  const values: string[] = [];
  const valuesDescription = new Map();

  props.user.education?.thirdLevel?.map((item) => {
    if (item.qualification) {
      values.push(item.id);
      valuesDescription.set(item.id, item.qualification);
    }
  });

  props.user.education?.furtherEducation?.map((item) => {
    if (item.qualification) {
      values.push(item.id);
      valuesDescription.set(item.id, item.qualification);
    }
  });

  console.log('education data.');
  console.log(props.user.education);

  const labelText = 'Select education for exemption';

  return (
    <Fragment>
      <FormSelect
        name="eduData"
        uniqueName={`ex-edudata-${props.index}`}
        label={labelText}
        values={values}
        valuesDescription={valuesDescription}
        fullwidth={true}
        onDataChange={onSelectChange}
      />
    </Fragment>
  );
};

export { ExemptionEduSelection };
