import { useState } from 'react';

import { GridComponent } from 'components/GridComponent';
import { ModalDialog } from 'components/ModalDialog';
import { SelectCourse } from 'pages/Course/SelectCourse';
import { CourseSelectedCB } from 'pages/Course/ShowCourses';
import { Application, ApplicationStatus, Course } from 'graphql/globalTypes';
import { ActiveApplicationCard } from 'components/ActiveApplicationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

export type ApplicationWithCourse = Application & { course: Course };

interface IManageApplicationsProps {
  currentCourse?: string;
  applications?: ApplicationWithCourse[];
  courseSelected: () => void;
  removeApplication: (applicationId: string) => void;
  activateCourse: (targetCourse?: string) => void;
}

export interface IManageApplications {
  CourseName?: string | undefined;
  Status?: ApplicationStatus | undefined;
  Length?: number | undefined;
  YearFrom?: string | undefined;
  YearTo?: string | undefined;
  Credits?: number | undefined;
  ImagePath?: string | undefined;
}

export const ManageApplications: React.FC<IManageApplicationsProps> = (props) => {
  const [currentApplicationIndex] = useState(
    props.applications?.findIndex((application) => application.courseId === props.currentCourse) ?? 0
  );
  const [, setCurrentSelectedIndex] = useState(0);
  const [showCoursesSelection, setShowCourseSelection] = useState(false);
  const [deleteApplicationId, setDeleteApplicationId] = useState<string>();

  const data = props.applications?.map((item) => {
    return {
      CourseName: item.course.title,
      Status: item.currentStatus,
      Length: item.course.Length,
      YearFrom: item.course.activeFrom,
      YearTo: item.course.activeTo,
      Credits: item.course.credits,
      ImagePath: item.course.imageUrl,
      ApplicationId: item._id,
      CourseId: item.courseId,
    };
  });

  const onSelected = (index: number) => {
    setCurrentSelectedIndex(index);
  };

  const onCancelSelectCourse = () => setShowCourseSelection(false);

  const onCourseSelected: CourseSelectedCB = () => {
    setShowCourseSelection(false);
    props.courseSelected();
  };

  const switchHandler = () => setShowCourseSelection(true);
  const deleteHandler = (courseId: string) => setDeleteApplicationId(courseId);
  const onCancelDelete = () => setDeleteApplicationId(undefined);

  return (
    <>
      <ModalDialog isOpen={showCoursesSelection} title="Select Course" onCancel={onCancelSelectCourse}>
        <SelectCourse isAdmin={false} isLoggedIn={true} hideReadMore={true} onCourseSelected={onCourseSelected} />
      </ModalDialog>
      <ModalDialog isOpen={!!deleteApplicationId} title="Application deletion" onCancel={onCancelDelete}>
        <div className="modal-panel">
          <h3>Confirm that you wish to delete this application</h3>
          <p>This action can not be undone.</p>
          <button
            className="btn btn-danger mt-1 pl-1 pr-1"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              props.removeApplication(deleteApplicationId!);
              onCancelDelete();
            }}
          >
            Delete
          </button>
          <button className="btn mt-1 ml-1 pl-1 pr-1" onClick={() => onCancelDelete()}>
            Cancel
          </button>
        </div>
      </ModalDialog>
      <div className="dashboard-applicant-toolbar">
        <div>
          <span className="text-bold">Manage your applications</span>
        </div>
        <div>
          <button className="btn btn-sm pl-1 pr-1" onClick={switchHandler}>
            Browse applications
          </button>
        </div>
      </div>
      <div className="grid-collapse h-100">
        <div className="col-4">
          <div className="panel p-1">
            {props.applications ? (
              <>
                <h3 className="mb-1">Active Application</h3>
                <h5 className="mb-1">This application is active for editing.</h5>
                <ActiveApplicationCard
                  application={props.applications?.find((item) => item.courseId === props.currentCourse)}
                />
              </>
            ) : (
              <p className="text-bold mb-0">You have not currently activated an application.</p>
            )}
          </div>
        </div>
        <div className="col-8">
          <div className="applicant-saved-applications">
            {data && (
              <GridComponent
                Title={'Your saved applications'}
                Data={data?.filter((item) => item.CourseId !== props.currentCourse) as unknown[]}
                ColumnFields={['CourseName', 'YearFrom', 'Status']}
                ReturnFields={['ApplicationId', 'CourseId']}
                ColumnNames={['Award Name', 'Start', 'Status']}
                CurrentIndex={currentApplicationIndex}
                onSelected={onSelected}
                deleteIcon={{
                  icon: <FontAwesomeIcon icon={faTrash} />,
                  btnClass: 'action-icon',
                  handler: deleteHandler,
                }}
                switchIcon={{
                  icon: <FontAwesomeIcon icon={faChevronCircleRight} />,
                  btnClass: 'action-icon-blue',
                  handler: props.activateCourse,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
