import React, { ReactPropTypes } from 'react';
import MarketingFooter from './MarketingFooter';
import Hero from 'components/hero/Hero';
import { ContentAbout } from 'components/content/ContentAbout';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

export const About: React.FC<IUserStateDetail> = (props) => {
  return (
    <div>
      <Hero {...props} {...{ target: 'about' }} />
      <ContentAbout />
      <MarketingFooter />
    </div>
  );
};
