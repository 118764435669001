import React from 'react';
import { Course } from 'graphql/globalTypes';

interface IActiveCourseCard {
  course?: Course;
}

export const ActiveCourseCard: React.FC<IActiveCourseCard> = (props) => {
  if (!props.course) {
    return null;
  }
  return (
    <React.Fragment>
      <img src={props?.course?.imageUrl ?? ''} alt={props?.course?.imageUrl ?? ''} className="card-img-top" />
      <div className="m-1" data-testid="coursedetail">
        <p className="text-bold">{props?.course?.title}</p>
        <p>
          This {props?.course?.credits} credit award runs for {props?.course?.Length} months, starting from{' '}
          {props?.course?.activeFrom}.
        </p>
      </div>
    </React.Fragment>
  );
};
