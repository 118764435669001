import React from 'react';
import { Link } from 'react-router-dom';

interface ISolleLink {
  link: string;
}

export const SolleLink: React.FC<ISolleLink> = (props) => {
  return (
    <Link className={'link'} to={props.link}>
      {props.children}
    </Link>
  );
};
