import React from 'react';

interface IHelperProps {
  date?: Date | string;
  monthYearFormat?: boolean;
}

export const DateFormatter: React.FC<IHelperProps> = (props) => {
  return (
    <React.Fragment>
      <span>{props.monthYearFormat ? monthYearDateFormatter(props.date) : dateFormatter(props.date)}</span>
    </React.Fragment>
  );
};

const dateFormatter = (date?: Date | string): string | undefined => {
  const dateUnformatted = date?.toString();
  const n = dateUnformatted?.indexOf('T');

  let dateFormatted = dateUnformatted?.substring(0, n !== -1 ? n : dateUnformatted.length);
  dateFormatted = dateFormatted?.split('-').reverse().join('-');

  return dateFormatted;
};

const monthYearDateFormatter = (date?: Date | string): string | undefined => {
  let dateAsserted: Date;
  let dateFormatted = '';
  if (date) {
    dateAsserted = new Date(date);
    dateFormatted = `${dateAsserted.getMonth() + 1 < 10 ? '0' : ''}${dateAsserted.getMonth() + 1}-${dateAsserted
      .getFullYear()
      .toString()
      .substring(2)}`;
  }

  return dateFormatted;
};
