import React, { Fragment } from 'react';

import { SecondaryEducation } from 'graphql/globalTypes';
import { ShowFieldString, ShowFieldAttachment } from './ReviewShowPrimitives';

interface IReviewSegmentSecondLevel {
  secondLevel: SecondaryEducation;
  count: number;
}

export const ReviewSegmentSecondLevel: React.FC<IReviewSegmentSecondLevel> = (props) => {
  return (
    <Fragment>
      <div className="review-section-block">
        <div className="grid-collapse">
          <div className="col-1">
            <div className="review-section-number">{props.count}</div>
          </div>
          <div className="col-11">
            {ShowFieldString('Institute', props.secondLevel.institute)}
            {ShowFieldString('Country of institute', props.secondLevel.country)}
            <div className="review-group">
              <h4>Subjects</h4>
              {props?.secondLevel.subjects?.map((subject) => {
                return (
                  <div className="mt-1" key={subject.id}>
                    {ShowFieldString('Subject', subject.subject)}
                    {ShowFieldString('Grade', subject.grade?.toString())}
                    {ShowFieldString('Subject level', subject.level)}
                    {ShowFieldString('Year of exam', subject.yearExam)}
                    {subject?.attachments ? (
                      ShowFieldAttachment('Transcript', subject?.attachments)
                    ) : (
                      <div>No transcript uploaded</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
