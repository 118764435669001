import { useQuery } from '@apollo/client';
import { GET_COURSE } from 'graphql/queries/courses';
import { QueryGetCourseArgs, GetCourseQuery } from 'graphql/globalTypes';
import CourseHero from 'components/hero/CourseHero';
import MarketingFooter from 'pages/Marketing/MarketingFooter';
import AuxCourseData from 'data/course-html.json';
import { useParams } from 'react-router-dom';

const openTab = (e: React.MouseEvent, tabName = 'Overview'): void | undefined => {
  const tablinks = document.getElementsByClassName('tablinks');
  let i;
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(' active', '');
    const tabContent = document.getElementsByClassName('tab-content'); //HTMLCollectionOf<Element> TS seems to force type the returned array items so using 'any' as a workaround

    console.log('tabContent is ', tabContent[i] ?? '');

    if (tabContent[i]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (tabContent[i] as any).style.display = 'none';
    }
  }

  const getTab: HTMLElement | null = document.getElementById(tabName);
  console.log('getting ' + tabName);
  if (getTab !== null) {
    getTab.style.display = 'block';
  }
  e.currentTarget.className += ' active';
};

export const CourseMoreDetail: React.FC = () => {
  const { courseId } = useParams();
  const {
    data: getCourseData,
    loading,
    error,
  } = useQuery<GetCourseQuery, QueryGetCourseArgs>(GET_COURSE, {
    variables: { courseId: courseId as string },
  });

  if (loading) return <p>loading....</p>;
  if (error) {
    return <p>{error.message}</p>;
  }
  if (!getCourseData) {
    return <p>Something went wrong on the server.</p>;
  }

  const courseData = getCourseData.getCourse;
  const listCourseLeaders = (): JSX.Element[] | undefined => {
    if (courseData.courseLeaders) {
      return courseData.courseLeaders.map((item) => {
        return (
          <>
            {item} <br />
          </>
        );
      });
    } else {
      return;
    }
  };

  const dataObj = tempEntryInfo(AuxCourseData, courseData.witId);

  return (
    <div>
      <CourseHero course={courseData} />
      <main>
        <section id="content-area">
          <div className="content-area">
            <div className="content-header-subnav">
              <nav className="content-subnav">
                <ul>
                  <li>
                    <button
                      className="tablinks active"
                      onClick={(event: React.MouseEvent): void => openTab(event, 'Overview')}
                    >
                      Overview
                    </button>
                  </li>
                  <li>
                    <button className="tablinks" onClick={(event: React.MouseEvent): void => openTab(event, 'Modules')}>
                      Modules
                    </button>
                  </li>
                  <li>
                    <button className="tablinks" onClick={(event: React.MouseEvent): void => openTab(event, 'Entry')}>
                      Entry
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            {dataObj[0] && (
              <div className="content-body">
                <div
                  id="Overview"
                  className="tab-content show-content"
                  dangerouslySetInnerHTML={{ __html: dataObj[0].overview }}
                ></div>
                <div
                  id="Modules"
                  className="tab-content"
                  dangerouslySetInnerHTML={{ __html: dataObj[0].modules }}
                ></div>
                <div id="Entry" className="tab-content" dangerouslySetInnerHTML={{ __html: dataObj[0].entry }}></div>
              </div>
            )}
          </div>
        </section>
      </main>
      <MarketingFooter />
    </div>
  );
};

type tempInfoType = { id: string; overview: string; entry: string; modules: string }[];

const tempEntryInfo = (payload: tempInfoType, witCourseId: string): tempInfoType =>
  payload.filter((obj: { id: string }) => witCourseId === obj.id);
