import React from 'react';

import { constants } from './constants';

import { User, Course, AdminUser } from 'graphql/globalTypes';
import { CreateCtx } from './CreateCtx';
import { LoggedInStatus } from 'pages/Login/IsLoggedIn';

const _UserCtx = new CreateCtx<User>('User');
export const UserCtx = _UserCtx.Ctx;

const _AdminUserCtx = new CreateCtx<AdminUser>('AdminUser');
export const AdminUserCtx = _AdminUserCtx.Ctx;

const _CourseCtx = new CreateCtx<Course>('SelectedCourse');
export const CourseCtx = _CourseCtx.Ctx;

const _JwtCtx = new CreateCtx<string>(constants.JWT_STORAGE_KEY);
export const JwtCtx = _JwtCtx.Ctx;

const _LoggedInStatusCtx = new CreateCtx<LoggedInStatus>('LoggedInStatus');
export const LoggedInStatusCtx = _LoggedInStatusCtx.Ctx;

const _ShowExemptionsCtx = new CreateCtx<boolean>('ShowExemptions');
export const ShowExemptionsCtx = _ShowExemptionsCtx.Ctx;

interface ISetupContentProviders {
  defaultCourseValue?: Course;
  defaultAdminUserValue?: AdminUser;
  defaultUserValue?: User;
  defaultShowExemptionsValue?: boolean;
}

export const SetupContextProviders: React.FC<ISetupContentProviders> = (props) => {
  return (
    <_LoggedInStatusCtx.Provider defaultValue={LoggedInStatus.LoggedOut}>
      <_JwtCtx.Provider>
        {/** * If we set the default show exemption value to undefined, it converted to object when access */}
        <_ShowExemptionsCtx.Provider defaultValue={props.defaultShowExemptionsValue ?? undefined}>
          <_AdminUserCtx.Provider defaultValue={props.defaultAdminUserValue}>
            <_UserCtx.Provider defaultValue={props.defaultUserValue}>
              <_CourseCtx.Provider defaultValue={props.defaultCourseValue}>{props.children}</_CourseCtx.Provider>
            </_UserCtx.Provider>
          </_AdminUserCtx.Provider>
        </_ShowExemptionsCtx.Provider>
      </_JwtCtx.Provider>
    </_LoggedInStatusCtx.Provider>
  );
};
