import { Route } from 'react-router-dom';

import { Logout } from 'pages/Login/Logout';
import { About } from 'pages/Marketing/About';
import ContactUs from 'pages/Marketing/ContactUs';
import Courses from 'pages/Marketing/Courses';
import Privacy from 'pages/Marketing/Privacy';
import Cookies from 'pages/Marketing/Cookies';
import { CourseMoreDetail } from 'pages/Course/CourseMoreDetail';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

export const CommonRoutes = (props: IUserStateDetail) => {
  return (
    <>
      <Route path="/logout" element={<Logout />} />
      <Route path="/about" element={<About {...props} />} />
      <Route path="/courses" element={<Courses {...props} />} />
      <Route path="/courses/:courseId" element={<CourseMoreDetail />} />
      <Route path="/contact" element={<ContactUs {...props} />} />
      <Route path="/privacy" element={<Privacy {...props} />} />
      <Route path="/cookies" element={<Cookies {...props} />} />
    </>
  );
};
