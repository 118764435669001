import React, { useState, useEffect } from 'react';
import Avatar from 'images/samples/avatar.png';
import { UserMenu } from 'pages/Navigation/UserMenu';
import { useLocation } from 'react-router-dom';
import { decodeToken } from 'services/validation';
import { useQuery } from '@apollo/client';
import { GetUserQuery, GetUserQueryVariables } from 'graphql/globalTypes';
import { GET_USER } from 'graphql/queries/users';

interface IAvatarBtnProps {
  isAdmin?: boolean;
}

const UserAvatarButton: React.FC<IAvatarBtnProps> = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [profileAvatar, setProfileAvatar] = useState<string>(Avatar);
  const { data } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER);

  useEffect(() => {
    if (data?.getUser.personalDetails.profilePicture?.filepath) {
      setProfileAvatar(data?.getUser.personalDetails.profilePicture?.filepath + '?token=' + decodeToken().token);
    }
  }, [data]);

  const handleClick = (): void => {
    return setShowDropdown(!showDropdown);
  };

  return (
    <>
      <img
        className="topbar-avatar-img"
        src={profileAvatar}
        alt="Current user's avatar"
        data-testid="control_btn"
        onClick={handleClick}
      />
      <UserMenu dropdownVisible={showDropdown} isAdmin={props.isAdmin} />
    </>
  );
};

export { UserAvatarButton };
