import React, { Fragment } from 'react';

export const ExemptionAltForm: React.FC = () => {
  return (
    <Fragment>
      <div className="Exemptions-form">
        Application for exemption from modules on this award is currently unavailable through this portal. Please
        contact the course leader if you wish to seek exemption from any modules.
      </div>
    </Fragment>
  );
};
