import React, { Fragment } from 'react';

import { PersonalDetails, Language } from 'graphql/globalTypes';
import { decodeToken } from 'services/validation';
import { ShowFieldString, ShowFieldDate, ShowFieldAttachment } from './ReviewShowPrimitives';

interface IReviewSegmentPersonal {
  personalData: PersonalDetails;
}

export const ReviewSegmentPersonal: React.FC<IReviewSegmentPersonal> = (props) => {
  return (
    <Fragment>
      <div className="review-section">
        <div>
          <h3>Personal details</h3>
          {props.personalData.profilePicture && (
            <div className="review-profile-image">
              <img
                src={props.personalData.profilePicture.filepath + '?token=' + decodeToken().token ?? undefined}
                alt="Applicant profile avatar"
              />
            </div>
          )}
          {ShowFieldString('Name', `${props.personalData.firstName} ${props.personalData.lastName}`)}
          {ShowFieldString('(PPS No.) Personal Public Service No.', props.personalData.ppsNo)}
          {ShowFieldDate('Date of birth', props.personalData.dateOfBirth)}
          {ShowFieldString('Gender', props.personalData.gender)}
          {ShowFieldString('Phone number', props.personalData.phone)}
          {ShowFieldString('First Language', props.personalData.firstLanguage)}
          {!!props.personalData.firstLanguage &&
            props.personalData.firstLanguage !== Language.English &&
            ShowFieldAttachment('English language certificate', props.personalData.englishLanguageCert)}
          {props.personalData.cv && ShowFieldAttachment('Recent CV', props.personalData.cv)}
        </div>
      </div>
    </Fragment>
  );
};
