import React, { Fragment, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import Crest from 'images/logo/wit-crest.png';
import Logo from 'images/logo/setu-white.png';
import ResetPasswordForm from './ResetPasswordForm';
import FlashMessageSuccess from 'components/FlashMessageSuccess';

export interface IReset {
  url: string;
  closeModal: () => void;
  backToMain: () => void;
}

const ResetPassword: React.FC<IReset> = (props) => {
  const [rpSubmittedMessage, setRPSubmittedMessage] = useState('');

  const onResetSubmitted = (message: string): void => {
    setRPSubmittedMessage(message);
  };

  const onFadeout = () => {
    setRPSubmittedMessage('');
    props.closeModal();
  };

  return (
    <Fragment>
      <div className="modal-login-header">
        {/* <img src={Crest} alt="Waterford IT crest" /> */}
        <img src={Logo} alt="SETU logo" />

        <h2>Recognition of Prior Learning</h2>
      </div>
      <div className="modal-login-form-area">
        <div className="close-modal" onClick={(): void => props.closeModal()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="modal-content">
          {rpSubmittedMessage ? (
            <FlashMessageSuccess message={rpSubmittedMessage} timeoutSecond={5000} onFadeout={onFadeout} />
          ) : (
            <Fragment>
              <p className="modal-login-welcome">Forgot Your Password?</p>
              <ResetPasswordForm url={props.url} backToMain={props.backToMain} onResetSubmitted={onResetSubmitted} />
              <p className="link-text-subtle" onClick={(): void => props.backToMain()}>
                Back
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
