import React, { useEffect, useState } from 'react';

import FormInput, { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import FormSelect from 'components/FormPrimitives/FormSelect';
import {
  Maybe,
  Gender,
  AdminPersonalDetailsInput,
  Roles,
  PersonalDetails,
  AdminPersonalDetails,
} from 'graphql/globalTypes';

const GENDER_VALUES = ['male', 'female', 'notspecified'];
export const GenderDescription = new Map<string, string>([
  [Gender.Male, 'Male'],
  [Gender.Female, 'Female'],
  [Gender.Notspecified, 'Not specified'],
]);

const ROLE_VALUES = ['ADMIN', 'DEPARTMENTHEAD', 'COURSELEADER', 'ASSISTANT'];
export const RoleDescription = new Map<string, string>([
  [Roles.Admin, 'ADMIN'],
  [Roles.Departmenthead, 'DEPARTMENTHEAD'],
  [Roles.Courseleader, 'COURSELEADER'],
  [Roles.Assistant, 'ASSISTANT'],
]);

const TITLE_VALUES = ['Mrs', 'Miss', 'Ms', 'Mr', 'Dr', 'Prof', 'assit'];
export const TitleDescription = new Map<string, string>([
  [TITLE_VALUES[0], TITLE_VALUES[0]],
  [TITLE_VALUES[1], TITLE_VALUES[1]],
  [TITLE_VALUES[2], TITLE_VALUES[2]],
  [TITLE_VALUES[3], TITLE_VALUES[3]],
  [TITLE_VALUES[4], TITLE_VALUES[4]],
  [TITLE_VALUES[5], TITLE_VALUES[5]],
  [TITLE_VALUES[6], TITLE_VALUES[6]],
]);

interface ICreateAdminUser {
  personalDetails?: Maybe<AdminPersonalDetailsInput>;
  email?: string;
  role?: string;
  isEditing?: boolean;
  onChangeAdmin: (event: FormInputChangeParam) => void;
  onChangePD: (event: FormInputChangeParam) => void;
}

const CreateAdminUserForm: React.FC<ICreateAdminUser> = (props) => {
  return (
    <div>
      <form>
        <FormSelect
          name="title"
          label="Title"
          values={TITLE_VALUES}
          valuesDescription={TitleDescription}
          fullwidth={true}
          onDataChange={props.onChangePD}
          selectedValue={props.personalDetails?.title ?? '-- select an option --'}
        />
        <FormInput
          name="firstName"
          label="First Name"
          type="text"
          fullwidth={true}
          onDataChange={props.onChangePD}
          value={props.personalDetails?.firstName}
        />
        <FormInput
          name="lastName"
          label="Surname"
          type="text"
          fullwidth={true}
          onDataChange={props.onChangePD}
          value={props.personalDetails?.lastName}
        />
        <FormInput
          name="email"
          label="Email address"
          type="email"
          fullwidth={true}
          disabled={props.isEditing ?? false}
          onDataChange={props.onChangeAdmin}
          value={props.email}
        />
        <FormInput
          name="department"
          label="Department"
          type="text"
          fullwidth={true}
          onDataChange={props.onChangePD}
          value={props.personalDetails?.department}
        />
        <FormInput
          name="staffId"
          label="Staff ID"
          type="text"
          fullwidth={true}
          onDataChange={props.onChangePD}
          value={props.personalDetails?.staffId}
        />
        <FormSelect
          name="gender"
          label="Gender"
          values={GENDER_VALUES}
          valuesDescription={GenderDescription}
          fullwidth={true}
          onDataChange={props.onChangePD}
          selectedValue={props.personalDetails?.gender ?? '-- select an option --'}
        />
        <FormSelect
          name="role"
          label="Set Role"
          values={ROLE_VALUES}
          valuesDescription={RoleDescription}
          fullwidth={true}
          onDataChange={props.onChangeAdmin}
          selectedValue={props?.role ?? '-- select an option --'}
        />
        <div className="break"></div>
      </form>
    </div>
  );
};

export default CreateAdminUserForm;
