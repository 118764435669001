import { ApplicationStatus } from 'graphql/globalTypes';

interface IApplicationStatusLabel {
  statusNow?: string;
}

export const ApplicationStatusLabel: React.FC<IApplicationStatusLabel> = (props) => {
  return <>{labelStates(props.statusNow)}</>;
};

const labelStates = (inpData: unknown | undefined) => {
  switch (inpData) {
    case ApplicationStatus.Presubmission:
      return <div className="status-label neutral-response">Pre-submission</div>;
    case ApplicationStatus.Submitted:
      return <div className="status-label notice-response">Submitted for review</div>;
    case ApplicationStatus.Reviewing:
      return <div className="status-label neutral-response">In review</div>;
    case ApplicationStatus.Moreinfo:
      return <div className="status-label alert-response">Information requested</div>;
    case ApplicationStatus.Accepted:
      return <div className="status-label success-response">Approved</div>;
    case ApplicationStatus.Refused:
      return <div className="status-label error-response">Refused</div>;
    case ApplicationStatus.Closed:
      return <div className="status-label notice-response">Closed</div>;
    case ApplicationStatus.Recommendaccept:
      return <div className="status-label neutral-response">In review</div>;
    case ApplicationStatus.Recommendrefuse:
      return <div className="status-label neutral-response">In review</div>;
    case ApplicationStatus.Registrarapproval:
      return <div className="status-label neutral-response">In review</div>;
    default:
      return <div className="status-label neutral-response">Status unavailable</div>;
  }
};
