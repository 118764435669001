/* istanbul ignore file */
import React, { Fragment, useContext } from 'react';

import { DashboardTopToolbar } from 'components/DashboardTopToolbar';
import { PDFDocument } from './PDFDocument';
import { PDFViewer } from '@react-pdf/renderer';
import { CourseCtx, UserCtx } from 'services/getCtx';

export const SavePDF: React.FC = () => {
  const [user] = useContext(UserCtx);
  const [course] = useContext(CourseCtx);
  return (
    <Fragment>
      <section className="pdf-section">
        <DashboardTopToolbar label="Back" destination="/admin/application-review/personal-info" />
        <div className="pdfViewer-container">
          <PDFViewer>
            <PDFDocument user={user} course={course} />
          </PDFViewer>
        </div>
      </section>
    </Fragment>
  );
};
