import React, { createContext, Dispatch, SetStateAction, useState, useEffect } from 'react';

interface ICtxProviderProps<T> {
  defaultValue?: T;
}

/**
 * @class MakeCtx<T>
 *
 * @description This will make return a context item ready for use -
 * @Note The class is NOT a singleton, so only call it once per data type
 *
 * @example
 * // Create:
 * const myCtxObj = new CreateCtx<MyObject>("MyStorageObject")
 *
 * // Provider:
 * <myCtxObj.Provider>
 *   <MyComponents>
 * </myCtxObj.Provider>
 *
 * // Use:
 * const [data, setData] = useContext(myCtxObj.Ctx)
 *
 * @constructor Provide localstorage key, no key provided will disable local storage
 * @member Ctx - context item to use with useContext
 * @member Provider - React Initialiser
 */
export class CreateCtx<T> {
  private readonly _storageKey?: string;

  constructor(storageKey?: string) {
    this._storageKey = storageKey;
  }

  private readonly _ctx = createContextItem<T>();

  get Ctx(): React.Context<CtxT<T>> {
    return this._ctx;
  }

  public Provider: React.FC<ICtxProviderProps<T>> = (props) => {
    const [value, setValue] = useLocalStorage<T>(this._storageKey, props.defaultValue);

    return <this._ctx.Provider value={[value, setValue]}>{props.children}</this._ctx.Provider>;
  };
}

//-----------------------------------------------------

type CtxT<T> = [T, (value: T) => void];

const createContextItem = <T,>(): React.Context<CtxT<T>> => createContext([] as unknown as CtxT<T>);

const useLocalStorage = <T,>(key?: string, defaultValue?: T): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(_getLocalStorage<T>(key, defaultValue) ?? ({} as T));

  const setValue = (value: T) => {
    try {
      console.log(`Saving ${value}: into ${key}`);
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save react state
      setStoredValue(valueToStore);
      // Save to local storage
      if (key) {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

const _getLocalStorage = <T,>(key?: string, defaultValue?: T): T | undefined => {
  if (key) {
    const ls = localStorage.getItem(key);

    if (ls) {
      return JSON.parse(ls) as T;
    }
  }

  return defaultValue;
};
