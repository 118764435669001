import { gql } from '@apollo/client';

import { APPLICATION_FIELDS } from './applications';

export const SET_APPLICATION_STATUS = gql`
  mutation SetApplicationStatus($status: String!, $applicationId: String!) {
    setApplicationStatus(status: $status, applicationId: $applicationId) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;
