import React, { Fragment } from 'react';

import { SkillsCompetencies } from 'graphql/globalTypes';
import { ShowFieldAttachment } from './ReviewShowPrimitives';

interface IReviewSegmentSkillsProps {
  skills: SkillsCompetencies;
  count: number;
}

export const ReviewSegmentSkills: React.FC<IReviewSegmentSkillsProps> = (props) => {
  const descriptionText = props.skills.description ? props.skills.description : 'No details provided';
  return (
    <Fragment>
      <div>
        <div className="grid-collapse">
          <div className="col-1">
            <div className="review-section-number">{props.count}</div>
          </div>
          <div className="col-11">
            <p className="text-bold">{props.skills.title}</p>
            <div className="review-group">
              <p>{descriptionText}</p>
            </div>
            {props?.skills.attachments?.filename && ShowFieldAttachment('Certification', props.skills.attachments)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
