import { AdminUser } from 'graphql/globalTypes';
import { AdminEditActionButton } from './AdminEditActionButton';
import { AdminDeleteActionButton } from './AdminDeleteActionButton';

interface IAdminUsers {
  Title?: string;
  admins: AdminUser[];
  onSetAdminUser: (targetAdmin: AdminUser) => void;
  onDeleteAction: (targetAdmin: AdminUser) => void;
}

const AdminUserListing: React.FC<IAdminUsers> = (props) => {
  return (
    <>
      {props.Title?.length ? (
        <p className="text-bold mb-0" data-testid={'gridtitle'}>
          {props.Title}
        </p>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Staff Id</th>
            <th>Department</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.admins?.map((user) => (
            <tr key={`adm.${user._id}`}>
              <td>
                <span className="text-bold">{`${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`}</span>
              </td>
              <td>{user.personalDetails?.staffId}</td>
              <td>{user.personalDetails?.department}</td>
              <td>{user.role}</td>
              <td>
                <AdminEditActionButton targetAdmin={user} setAdmin={props.onSetAdminUser} />
                <AdminDeleteActionButton targetAdmin={user} setAdmin={props.onDeleteAction} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AdminUserListing;
