import React, { useState, useEffect, Fragment } from 'react';
import FormInput from 'components/FormPrimitives/FormInput';
import { Notification } from 'graphql/globalTypes';

interface IFormProps {
  job: Notification;
  onChange: (sectionDetail: Notification) => void;
  onCancel: () => void;
}

const AESettingsForm: React.FC<IFormProps> = (props) => {
  const [sectionDetail, setSectionDetail] = useState<Notification>(props.job);

  useEffect(() => {
    console.log('EmailSettingsForm: useEffect: props.job', props.job);
    setSectionDetail(props.job);
  }, [props.job]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = checked;
    setSectionDetail(newSD);
  };

  const saveChanges = async () => {
    props.onChange(sectionDetail);
    props.onCancel();
  };

  return (
    <Fragment>
      <form className="-container">
        <FormInput
          name="newMessage"
          uniqueName="newMessage-notify"
          label="Receive email notifications for new messages on your application"
          type="checkbox"
          fullwidth={true}
          onDataChange={onCheckboxChange}
          checked={sectionDetail.newMessage}
        />
        <div className="mb-1"></div>
        <FormInput
          name="statusChange"
          uniqueName="statusChange-notify"
          label="Receive an email notification when your application status changes"
          type="checkbox"
          fullwidth={true}
          onDataChange={onCheckboxChange}
          checked={sectionDetail.statusChange}
        />
      </form>
      <div className="mt-2">
        <button onClick={saveChanges} className="btn pl-1 pr-1">
          Save
        </button>
        <button onClick={() => props.onCancel()} className="btn btn-danger ml-1 pl-1 pr-1">
          Cancel
        </button>
      </div>
    </Fragment>
  );
};

export default AESettingsForm;
