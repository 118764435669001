import React, { useState, useEffect } from 'react';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import { GET_NOTIFICATIONS_ADMIN, GET_NOTIFICATIONS_APPLICANT } from 'graphql/queries/messages';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const Notifications: React.FC<IUserStateDetail> = (props) => {
  const query = props.isAdmin ? GET_NOTIFICATIONS_ADMIN : GET_NOTIFICATIONS_APPLICANT;
  const { data, error, loading } = useQuery(query);
  const [notifications, setNotifications] = useState<string>();

  useEffect(() => {
    if (!error && !loading && data && !notifications) {
      let notifs = '';

      if (data.getNotifications) {
        notifs = `${data.getNotifications.totalMessages}`;
      } else if (data.getNotificationsForApplicant) {
        notifs = `${data.getNotificationsForApplicant.totalMessages}`;
      }
      setNotifications(notifs);
    }
  }, [data, error, loading, notifications]);

  const showNotifications = (): JSX.Element | undefined => {
    if (notifications && notifications !== '0') {
      return <span className="fa-layers-counter">{notifications}</span>;
    } else {
      return;
    }
  };

  return (
    <div className="topbar-notifications mr-1">
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faBell} />
        {showNotifications()}
      </span>
    </div>
  );
};

export default Notifications;
