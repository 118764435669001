import React, { useState, Fragment, useEffect, useContext } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// import Crest from 'images/logo/wit-crest.png';
import Logo from 'images/logo/setu-white.png';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

import { GET_USER_COURSE_APPLICATION, ADD_UPDATE_CURRENT_APPLICATION } from 'graphql/queries/users';
import { constants } from 'services/constants';
import {
  AddOrUpdateCurrentApplicationMutation,
  AddOrUpdateCurrentApplicationMutationVariables,
  GetUserCourseApplicationQuery,
  User,
} from 'graphql/globalTypes';
import { CourseCtx, LoggedInStatusCtx, JwtCtx } from 'services/getCtx';
import ResetPassword from './ResetPassword';
import { LoggedInStatus } from './IsLoggedIn';

type OnSuccessFN = (jwtToken: string) => void;

export interface ILogin {
  url: string;
  onSuccess: OnSuccessFN;
}

interface ILoginModelProps {
  courseId?: string;
}

const LoginModal: React.FC<ILoginModelProps> = (props) => {
  const [, setLoggedInStatus] = useContext(LoggedInStatusCtx);
  const [, setCourse] = useContext(CourseCtx);
  const [, setJwt] = useContext(JwtCtx);
  const [getUser, { error, data: userData }] = useLazyQuery<GetUserCourseApplicationQuery, User>(
    GET_USER_COURSE_APPLICATION
  );
  const [AddOrUpdateCurrentApplication, { error: UpdateApplicationError, data: updateApplication }] = useMutation<
    AddOrUpdateCurrentApplicationMutation,
    AddOrUpdateCurrentApplicationMutationVariables
  >(ADD_UPDATE_CURRENT_APPLICATION);
  const [showReg, setShowReg] = useState(false);
  const [showAdminLogin, setShowAdminLogin] = useState(false);
  const [showResetForm, setShowForgotForm] = useState(false);
  const [showSelectCourseMsg, setShowSelectCourseMsg] = useState<boolean>(false);

  useEffect(() => {
    if (userData) {
      console.log('here we are in called', userData);
      if (userData.getUser.course) {
        // User data has a course login only
        setCourse(userData.getUser.course);
        setLoggedInStatus(LoggedInStatus.LoggedIn);
        closeModal();
      } else {
        if (props.courseId) {
          // user doesn't have a course but selected course before login or signup
          AddOrUpdateCurrentApplication({ variables: { courseId: props.courseId } });
        } else {
          // user doesn't have a course and didn't select a course before signup or login
          setShowSelectCourseMsg(true);
          setLoggedInStatus(LoggedInStatus.LoggedIn);
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (updateApplication) {
      setLoggedInStatus(LoggedInStatus.LoggedIn);
      closeModal();
    }
  }, [updateApplication]);

  if (UpdateApplicationError || error) {
    return <div>Error updating current course</div>;
  }

  const closeModal = (): void => {
    const login = document.getElementById('login_modal');

    if (login !== null) {
      if (login.classList.contains('show-modal')) {
        login.classList.remove('show-modal');

        setShowForgotForm(false);
      }
    }
  };

  const onSuccess: OnSuccessFN = async (jwtToken) => {
    console.log('Login/Register Success:', jwtToken);
    setJwt(jwtToken);

    try {
      // only get the applicant to test for current course
      !showAdminLogin && getUser();
    } catch (err) {
      console.log('-=-=-=-=-=-=-====>', err);
    }

    if (showAdminLogin) {
      setLoggedInStatus(LoggedInStatus.LoggedIn);
      closeModal();
    }
  };

  const showRPClicked = (): void => {
    console.log(`Show Admin Login: ${showAdminLogin}`);
    console.log('Show Message Login: ');
    console.log(showMessage(showAdminLogin));
    setShowForgotForm(true);
  };

  const backToMain = (): void => {
    setShowForgotForm(false);
  };

  return (
    <div id="login_modal" className="modal">
      <div className="modal-popover">
        {showResetForm ? (
          <ResetPassword
            url={showAdminLogin ? constants.ADMIN_FORGOT_PASSWORD_URL : constants.APPLICANT_FORGOT_PASSWORD_URL}
            closeModal={closeModal}
            backToMain={backToMain}
          />
        ) : (
          <Fragment>
            <div className="modal-login-header">
              {/* <img src={Crest} alt="Waterford IT crest" /> */}
              <img src={Logo} alt="SETU logo" />
              <h2>Recognition of Prior Learning</h2>
              {!showSelectCourseMsg && showFormTop(showReg, showAdminLogin, setShowReg)}
            </div>
            <div className="modal-login-form-area">
              <div className="close-modal" onClick={(): void => closeModal()}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              {!showSelectCourseMsg ? (
                <div className="modal-content">
                  <p className="modal-login-welcome">Welcome</p>
                  {showMessage(showAdminLogin)}
                  {showForm(showReg, showAdminLogin, onSuccess)}
                  <p className="link-text-subtle" onClick={showRPClicked}>
                    Forgot password?
                  </p>
                  {showSwitch(showAdminLogin, setShowAdminLogin)}
                </div>
              ) : (
                <div className="modal-content">
                  <p className="response success-response">
                    You have been successfully registered. Please select an award before switching to the RPL Dashboard.
                  </p>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default LoginModal;

const showFormTop = (
  showReg: boolean,
  showAdmin: boolean,
  setStatus: React.Dispatch<React.SetStateAction<boolean>>
): JSX.Element => {
  if (showAdmin) {
    return <p className="modal-login-welcome-manager">Management Login</p>;
  } else {
    return (
      <nav>
        <ul>
          <li className={showReg ? 'not-active' : 'active'} data-tabindex="1">
            <div className="modal-login-option" onClick={(): void => setStatus(false)}>
              Login
            </div>
          </li>
          <li className={showReg ? 'active' : 'not-active'} data-tabindex="2">
            <div className="modal-login-option" onClick={(): void => setStatus(true)}>
              Sign up
            </div>
          </li>
        </ul>
      </nav>
    );
  }
};

const showMessage = (showAdmin: boolean): JSX.Element => {
  if (showAdmin) {
    return <p>Sign in to your staff account on the portal.</p>;
  } else {
    return <p>Login in or sign up to build and submit your RPL portfolio to apply for an SETU award.</p>;
  }
};

const showForm = (showReg: boolean, showAdmin: boolean, onSuccess: OnSuccessFN): JSX.Element => {
  const url = showReg
    ? constants.APPLICANT_REGISTER_URL
    : showAdmin
    ? constants.ADMIN_LOGIN_URL
    : constants.APPLICANT_LOGIN_URL;

  if (showReg && !showAdmin) {
    return <RegisterForm onSuccess={onSuccess} url={url} />;
  } else {
    return (
      <Fragment>
        <LoginForm onSuccess={onSuccess} url={url} />
      </Fragment>
    );
  }
};

const showSwitch = (showAdmin: boolean, setStatus: React.Dispatch<React.SetStateAction<boolean>>): JSX.Element => {
  if (showAdmin) {
    return (
      <p className="link-text text-centre" onClick={(): void => setStatus(false)}>
        Go to applicant login
      </p>
    );
  } else {
    return (
      <p className="link-text text-centre" onClick={(): void => setStatus(true)}>
        Go to manager login
      </p>
    );
  }
};
