import { Language } from 'graphql/globalTypes';

export const LanguageDescription = new Map<string, string>([
  [Language.English, 'English'],
  [Language.Abkhazian, 'Abkhazian'],
  [Language.Afar, 'Afar'],
  [Language.Afrikaans, 'Afrikaans'],
  [Language.Akan, 'Akan'],
  [Language.Albanian, 'Albanian'],
  [Language.Amharic, 'Amharic'],
  [Language.Arabic, 'Arabic'],
  [Language.Aragonese, 'Aragonese'],
  [Language.Armenian, 'Armenian'],
  [Language.Assamese, 'Assamese'],
  [Language.Avaric, 'Avaric'],
  [Language.Avestan, 'Avestan'],
  [Language.Aymara, 'Aymara'],
  [Language.Azerbaijani, 'Azerbaijani'],
  [Language.Bambara, 'Bambara'],
  [Language.Bashkir, 'Bashkir'],
  [Language.Basque, 'Basque'],
  [Language.Belarusian, 'Belarusian'],
  [Language.Bengali, 'Bengali'],
  [Language.Biharilanguages, 'Bihari languages'],
  [Language.Bislama, 'Bislama'],
  [Language.Bosnian, 'Bosnian'],
  [Language.Breton, 'Breton'],
  [Language.Bulgarian, 'Bulgarian'],
  [Language.Burmese, 'Burmese'],
  [Language.Catalan, 'Catalan'],
  [Language.CatalanValencian, 'Catalan Valencian'],
  [Language.CentralKhmer, 'Central Khmer'],
  [Language.Chamorro, 'Chamorro'],
  [Language.Chechen, 'Chechen'],
  [Language.ChichewaChewaNyanja, 'Chichewa Chewa Nyanja'],
  [Language.Chinese, 'Chinese'],
  [Language.ChurchSlavonicOldBulgarianOldChurchSlavonic, 'Church Slavonic Old Bulgarian Old Church Slavonic'],
  [Language.Chuvash, 'Chuvash'],
  [Language.Cornish, 'Cornish'],
  [Language.Corsican, 'Corsican'],
  [Language.Cree, 'Cree'],
  [Language.Croatian, 'Croatian'],
  [Language.Czech, 'Czech'],
  [Language.Danish, 'Danish'],
  [Language.DivehiDhivehiMaldivian, 'Divehi Dhivehi Maldivian'],
  [Language.DutchFlemish, 'Dutch Flemish'],
  [Language.Dzongkha, 'Dzongkha'],
  [Language.English, 'English'],
  [Language.Esperanto, 'Esperanto'],
  [Language.Estonian, 'Estonian'],
  [Language.Ewe, 'Ewe'],
  [Language.Faroese, 'Faroese'],
  [Language.Fijian, 'Fijian'],
  [Language.Finnish, 'Finnish'],
  [Language.French, 'French'],
  [Language.Fulah, 'Fulah'],
  [Language.GaelicScottishGaelic, 'Gaelic Scottish Gaelic'],
  [Language.Galician, 'Galician'],
  [Language.Ganda, 'Ganda'],
  [Language.Georgian, 'Georgian'],
  [Language.German, 'German'],
  [Language.GikuyuKikuyu, 'Gikuyu Kikuyu'],
  [Language.Greek, 'Greek (Modern)'],
  [Language.GreenlandicKalaallisut, 'Greenlandic Kalaallisut'],
  [Language.Guarani, 'Guarani'],
  [Language.Gujarati, 'Gujarati'],
  [Language.HaitianHaitianCreole, 'Haitian Haitian Creole'],
  [Language.Hausa, 'Hausa'],
  [Language.Hebrew, 'Hebrew'],
  [Language.Herero, 'Herero'],
  [Language.Hindi, 'Hindi'],
  [Language.HiriMotu, 'Hiri Motu'],
  [Language.Hungarian, 'Hungarian'],
  [Language.Icelandic, 'Icelandic'],
  [Language.Ido, 'Ido'],
  [Language.Igbo, 'Igbo'],
  [Language.Indonesian, 'Indonesian'],
  [Language.Interlingua, 'Interlingua'],
  [Language.Interlingue, 'Interlingue'],
  [Language.Inuktitut, 'Inuktitut'],
  [Language.Inupiaq, 'Inupiaq'],
  [Language.Irish, 'Irish'],
  [Language.Italian, 'Italian'],
  [Language.Japanese, 'Japanese'],
  [Language.Javanese, 'Javanese'],
  [Language.Kannada, 'Kannada'],
  [Language.Kanuri, 'Kanuri'],
  [Language.Kashmiri, 'Kashmiri'],
  [Language.Kazakh, 'Kazakh'],
  [Language.Kinyarwanda, 'Kinyarwanda'],
  [Language.Komi, 'Komi'],
  [Language.Kongo, 'Kongo'],
  [Language.Korean, 'Korean'],
  [Language.KwanyamaKuanyama, 'Kwanyama Kuanyama'],
  [Language.Kurdish, 'Kurdish'],
  [Language.Kyrgyz, 'Kyrgyz'],
  [Language.Lao, 'Lao'],
  [Language.Latin, 'Latin'],
  [Language.Latvian, 'Latvian'],
  [Language.LetzeburgeschLuxembourgish, 'Letzeburgesch Luxembourgish'],
  [Language.LimburgishLimburganLimburger, 'Limburgish Limburgan Limburger'],
  [Language.Lingala, 'Lingala'],
  [Language.Lithuanian, 'Lithuanian'],
  [Language.LubaKatanga, 'Luba-Katanga'],
  [Language.Macedonian, 'Macedonian'],
  [Language.Malagasy, 'Malagasy'],
  [Language.Malay, 'Malay'],
  [Language.Malayalam, 'Malayalam'],
  [Language.Maltese, 'Maltese'],
  [Language.Manx, 'Manx'],
  [Language.Maori, 'Maori'],
  [Language.Marathi, 'Marathi'],
  [Language.Marshallese, 'Marshallese'],
  [Language.MoldovanMoldavianRomanian, 'Moldovan Moldavian Romanian'],
  [Language.Mongolian, 'Mongolian'],
  [Language.Nauru, 'Nauru'],
  [Language.NavajoNavaho, 'Navajo Navaho'],
  [Language.NorthernNdebele, 'Northern Ndebele'],
  [Language.Ndonga, 'Ndonga'],
  [Language.Nepali, 'Nepali'],
  [Language.NorthernSami, 'Northern Sami'],
  [Language.Norwegian, 'Norwegian'],
  [Language.NorwegianBokmal, 'Norwegian Bokmål'],
  [Language.NorwegianNynorsk, 'Norwegian Nynorsk'],
  [Language.NuosuSichuanYi, 'Nuosu Sichuan Yi'],
  [Language.Occitan, 'Occitan'],
  [Language.Ojibwa, 'Ojibwa'],
  [Language.Oriya, 'Oriya'],
  [Language.Oromo, 'Oromo'],
  [Language.OssetianOssetic, 'Ossetian Ossetic'],
  [Language.Pali, 'Pali'],
  [Language.PanjabiPunjabi, 'Panjabi Punjabi'],
  [Language.PashtoPushto, 'Pashto Pushto'],
  [Language.Persian, 'Persian'],
  [Language.Polish, 'Polish'],
  [Language.Portuguese, 'Portuguese'],
  [Language.Quechua, 'Quechua'],
  [Language.Romansh, 'Romansh'],
  [Language.Rundi, 'Rundi'],
  [Language.Russian, 'Russian'],
  [Language.Samoan, 'Samoan'],
  [Language.Sango, 'Sango'],
  [Language.Sanskrit, 'Sanskrit'],
  [Language.Sardinian, 'Sardinian'],
  [Language.Serbian, 'Serbian'],
  [Language.Shona, 'Shona'],
  [Language.Sindhi, 'Sindhi'],
  [Language.SinhalaSinhalese, 'Sinhala Sinhalese'],
  [Language.Slovak, 'Slovak'],
  [Language.Slovenian, 'Slovenian'],
  [Language.Somali, 'Somali'],
  [Language.SothoSouthern, 'Sotho Southern'],
  [Language.SouthNdebele, 'South Ndebele'],
  [Language.Spanish, 'Spanish'],
  [Language.SpanishCastilian, 'Spanish Castilian'],
  [Language.Sundanese, 'Sundanese'],
  [Language.Swahili, 'Swahili'],
  [Language.Swati, 'Swati'],
  [Language.Swedish, 'Swedish'],
  [Language.Tagalog, 'Tagalog'],
  [Language.Tahitian, 'Tahitian'],
  [Language.Tajik, 'Tajik'],
  [Language.Tamil, 'Tamil'],
  [Language.Tatar, 'Tatar'],
  [Language.Telugu, 'Telugu'],
  [Language.Thai, 'Thai'],
  [Language.Tibetan, 'Tibetan'],
  [Language.Tigrinya, 'Tigrinya'],
  [Language.Tonga, 'Tonga (Tonga Islands)'],
  [Language.Tsonga, 'Tsonga'],
  [Language.Tswana, 'Tswana'],
  [Language.Turkish, 'Turkish'],
  [Language.Turkmen, 'Turkmen'],
  [Language.Twi, 'Twi'],
  [Language.UighurUyghur, 'Uighur Uyghur'],
  [Language.Ukrainian, 'Ukrainian'],
  [Language.Urdu, 'Urdu'],
  [Language.Uzbek, 'Uzbek'],
  [Language.Venda, 'Venda'],
  [Language.Vietnamese, 'Vietnamese'],
  [Language.VolapK, 'Volap_k'],
  [Language.Walloon, 'Walloon'],
  [Language.Welsh, 'Welsh'],
  [Language.WesternFrisian, 'Western Frisian'],
  [Language.Wolof, 'Wolof'],
  [Language.Xhosa, 'Xhosa'],
  [Language.Yiddish, 'Yiddish'],
  [Language.Yoruba, 'Yoruba'],
  [Language.ZhuangChuang, 'Zhuang Chuang'],
  [Language.Zulu, 'Zulu'],
]);

export const educationCountries = [
  'Ireland',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'North Korea',
  'South Korea',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];
