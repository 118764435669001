import { gql } from '@apollo/client';
import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const EXEMPTIONS_FIELDS = gql`
  fragment ExemptionFields on Exemption {
    moduleId
    exemptionType
    experience
    exemptionStatus
    exemptionReasons {
      id
      type
      experience
      attachments {
        ...SolleAttachmentFields
      }
    }
  }
  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const APPLICATION_FIELDS = gql`
  fragment ApplicationFields on Application {
    _id
    courseId
    applicantId
    currentStatus
    statusHistory {
      status
      date
      userId {
        email
        role
      }
      exemptionApplication
      exemptionStatus
      moduleId
    }
    exemptionApplication
    exemptions {
      ...ExemptionFields
    }
    createdAt
    updatedAt
  }
  ${EXEMPTIONS_FIELDS}
`;

export const REMOVE_APPLICATION = gql`
  mutation RemoveApplication($applicationId: String!) {
    removeApplication(applicationId: $applicationId)
  }
`;
