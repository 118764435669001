import React from 'react';

import { Link } from 'react-router-dom';

import { MenuItem } from './menuItems';

interface PanelProps {
  active: boolean;
  onMenuItemClick: (menuitem: string) => void;
  menuitem: MenuItem;
}

export const PanelItem: React.FC<PanelProps> = (props) => {
  const linkOnClick = (): void => {
    props.onMenuItemClick(props.menuitem.title);
  };

  return (
    <div className="panel-item" data-testid="test-panel-item">
      <div className="panel-item-head">
        <div className="panel-item-title">
          <img src={props.menuitem.icon} alt={props.menuitem.title} />
          <h2>{props.menuitem.title}</h2>
        </div>
        <Link className={'link'} to={props.menuitem.link} onClick={linkOnClick} role="link">
          <button className={props.active ? 'btn-secondary btn-sm active' : 'btn-secondary btn-sm'} type="button">
            {props.active ? 'Active' : 'View'}
          </button>
        </Link>
      </div>
      {/* {props.menuitem.progressValue && (
        <div className="progress-sm">
          <div
            className="progress-bar-sm"
            role="progressbar"
            data-aria-valuenow={props.menuitem.progressValue}
            data-aria-valuemin="0"
            data-aria-valuemax="100"
            style={{ width: '70%' }}
          >
            <span className="sr-only">{props.menuitem.progressValue}% Complete</span>
          </div>
        </div>
      )} */}
      {props.active && <div className="panel-item-blurb">{props.menuitem.blurb}</div>}
    </div>
  );
};
