import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import strucFund from 'images/funders/eu-structural-fund.png';
import euFlag from 'images/funders/eu-emblem.jpg';
import desHarp from 'images/funders/logo-des-2020.jpg';
import eiLogo from 'images/funders/ent-irl-logo-black.jpg';
// import Logo from 'images/logo/wit-white.png';
import Logo from 'images/logo/setu-white.png';

const MarketingFooter: React.FC = () => {
  return (
    <footer>
      <div className="footer-primary">
        <div className="grid">
          <div className="col-3">
            <div className="footer-logo">
              <a className="" href="/">
                {/* <img src={Logo} alt="Waterford Institute of Technology logo" /> */}
                <img src={Logo} alt="SETU logo" />
              </a>
            </div>
            <div className="footer-postal-address">
              <h4>Address</h4>
              <div className="text-small">
                <address>
                  Main Campus Cork Road, Waterford City <br />
                  Co. Waterford, Ireland <br />
                  Eircode: X91 K0EK
                </address>
              </div>
              <div className="social-bar">{socialMediaBar()}</div>
            </div>
          </div>
          <div className="col-3">
            <h4>Contact</h4>
            <div className="text-small">
              <address>
                T: +353 51 30 2000 <br />
                E: <a href="mailto:rpl4lifelonglearning@wit.ie">rpl4lifelonglearning@wit.ie</a> <br />
              </address>
            </div>
          </div>
          <div className="col-3">
            <h4>Legal</h4>
            <ul className="footer-list">
              <li>
                <Link to="/privacy">Privacy Statement</Link>
              </li>
              <li>
                <a href="https://www.setu.ie/terms-conditions">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="https://www.setu.ie/freedom-of-information">Freedom of Information</a>
              </li>
              <li>
                <Link to="/cookies">Cookies</Link>
              </li>
              <li>
                <a href="https://www.wit.ie/about_wit/for_staff/web_information">Website Information</a>
              </li>
            </ul>
          </div>
          <div className="col-3">
            <h4>Resources</h4>
            <nav>
              <ul className="footer-list">
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/place/Waterford+Institute+of+Technology/@52.2457377,-7.1422529,16.36z/data=!4m5!3m4!1s0x0:0x4042f0dcf13f7cfe!8m2!3d52.2460592!4d-7.1387045"
                    target="new"
                  >
                    How to find us
                  </a>
                </li>
                <li>
                  <a href="https://www.wit.ie/about_wit/contact_us/staff_directory/">SETU Staff Search</a>
                </li>
                <li>
                  <a href="https://www.wit.ie/about_wit/for_staff/staff_vacancies">Work at SETU</a>
                </li>
                <li>
                  <a href="https://www.wit.ie/current_students/student_affairs/academic_calendar">Academic Calendar</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="footer-funders-row">
        <div className="grid">
          <div className="col-3">
            <img src={strucFund} alt="EU structual fund logo" />
          </div>
          <div className="col-3">
            <img src={euFlag} alt="European Union, European structural and investment funds logo" />
          </div>
          <div className="col-3">
            <img src={desHarp} alt="Irish Government Department of Education and Skills logo" />
          </div>
          <div className="col-3">
            <img src={eiLogo} alt="Enterprise Ireland logo" />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default MarketingFooter;

const socialMediaBar = () => {
  return (
    <div className="footer-social-nav">
      <ul>
        <li>
          <a href="https://www.facebook.com/setuireland" target="blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/setuireland" target="blank">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UC9JFTXV68P0DrcUZJz_TILA" target="blank">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/school/south-east-technological-university" target="blank">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/setuireland" target="blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        {/* <li>
          <a href="https://photos.wit.ie/" target="blank">
            <FontAwesomeIcon icon={faImage} />
          </a>
        </li> */}
      </ul>
    </div>
  );
};
