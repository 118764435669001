import React from 'react';
import { SolleLink } from 'components/Links/SolleLink';
import { GreenButton } from 'components/Buttons/GreenButton';
interface ISolleLink {
  link: string;
  keyValue: string;
  dataTestId?: string;
  onClick: () => void;
  buttonTitle: string;
}

export const GreenStyleButtonWithLink: React.FC<ISolleLink> = (props) => {
  return (
    <SolleLink link={props.link}>
      <GreenButton keyValue={props.keyValue} dataTestId={props.dataTestId ?? props.keyValue} onClick={props.onClick}>
        {props.buttonTitle}
      </GreenButton>
    </SolleLink>
  );
};
