import React from 'react';
import Data from 'data/site-data.json';

interface IContentProps {
  target: string;
}

type marketingItem = { subhead: string | null; content: string };

type DataMarketingProps = {
  heroHead: string;
  heroSub: string;
  title: string;
  information: { subhead: null | string; content: string }[];
};

export type DataMarketing = {
  [key: string]: DataMarketingProps;
};

const Content: React.FC<IContentProps> = (props) => {
  const payload: DataMarketing = Data.Marketing;
  const pageName = props.target;
  const title = payload[pageName].title;
  const information = payload[pageName].information;

  return (
    <div>
      <section id="content-area">
        <div className="content-area">
          <div className="content-header">
            <h2>{title}</h2>
          </div>
          <div className="content-body">{contentItem(information)}</div>
        </div>
      </section>
    </div>
  );
};

export default Content;

const contentItem = (contentData: marketingItem[]): JSX.Element[] => {
  return contentData.map((item) => {
    return (
      <div>
        {item.subhead && <h3>{item.subhead}</h3>}
        <p>{item.content}</p>
      </div>
    );
  });
};
