import React from 'react';
import hea from 'images/funders/hea-logo.png';

const showCurrentYear = (): number => {
  return new Date().getFullYear();
};

const DashboardFooter: React.FC = () => {
  return (
    <footer className="footer-dashboard">
      <div className="flex-grid-row">
        <div className="flex-grid-col">
          <div className="flex-grid-item-wrapper">
            <div className="flex-grid-item-container">
              <a href="https://hea.ie/" target="blank">
                <img src={hea} alt="Higher Education Authority logo" />
              </a>
            </div>
          </div>
        </div>

        <div className="flex-grid-col">
          <div className="flex-grid-item-wrapper">
            <div className="flex-grid-item-container">
              <p className="text-right">
                Recognition of prior learning &copy; <span className="current-year">{showCurrentYear()}</span> | South
                East Technological University
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default DashboardFooter;
