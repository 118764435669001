import React from 'react';

import Tooltip from '../Tooltip';
import { Maybe } from 'graphql/globalTypes';

export type FormTextAreaChange = (value: string, index: number | string) => void;

interface IFormTextAreaProps {
  index?: number;
  label?: string;
  name: string;
  uniqueName?: string;
  value?: Maybe<string>;
  tooltip?: string;
  placeholder?: string;
  onDataChange: FormTextAreaChange;
}

const FormTextArea: React.FC<IFormTextAreaProps> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (props.index !== undefined) {
      props.onDataChange(event.target.value, props.index);
    } else {
      props.onDataChange(event.target.value, props.name ?? '');
    }
  };

  return (
    <div className="form-group">
      <label className="mb-1" htmlFor={props.uniqueName ?? props.name}>
        {props.label}
        {props.tooltip ? <Tooltip tooltip={props.tooltip ?? props.placeholder} /> : ''}
      </label>
      <textarea
        name={props.name}
        id={props.uniqueName ?? props.name}
        defaultValue={props.value ?? undefined}
        onChange={onChange}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default FormTextArea;
