import React, { useContext } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import { AnalyticsSendEvent } from 'services/googleAnalytics';

import { ReviewSegmentPersonal } from 'pages/Review/ReviewSegmentPersonal';
import { ReviewSegmentIndustryTraining } from 'pages/Review/ReviewSegmentIndustryTraining';
import { ReviewSegmentWorkExperience } from 'pages/Review/ReviewSegmentWork';
import { ReviewSegmentSkills } from 'pages/Review/ReviewSegmentSkills';
import { ReviewSegmentFurtherEducation } from 'pages/Review/ReviewSegmentFurtherEducation';
import { ReviewSegmentSecondLevel } from 'pages/Review/ReviewSegmentSecondLevel';
import { ReviewConfirmModal } from 'pages/Review/ReviewConfirmModal';
import { ReviewSubmitButton } from 'pages/Review/ReviewSubmitButton';

import { GET_USER, REVIEW_AND_SUBMIT } from 'graphql/queries/users';
import { ApplicationStatus, GetUserQuery, User } from 'graphql/globalTypes';
import { CourseCtx } from 'services/getCtx';
import { CCIsVisible } from 'services/utils';
import { ReviewSegmentExemptions } from 'pages/Review/ReviewSegmentExemptions';

const Review: React.FC = () => {
  const [course] = useContext(CourseCtx);
  const { data: userData, error: UDError, refetch: RefetchData } = useQuery<GetUserQuery>(GET_USER);

  const [ReviewAndSubmit, { data: PutRSData, error: PutRSError }] = useMutation(REVIEW_AND_SUBMIT);

  const handleClick = (): void => {
    const confirmSubmission = document.getElementById('confirm_modal');

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const onReview = async (): Promise<void> => {
    await ReviewAndSubmit();
    await RefetchData();

    AnalyticsSendEvent({
      category: 'User - Applicant',
      action: 'Application review',
      label: 'Submit application',
      transport: 'beacon',
    });
  };

  const submissionStatus = (): boolean => {
    if (userData?.getUser?.application) {
      const hasBeenSubmitted = userData.getUser.application.currentStatus;
      if (hasBeenSubmitted !== null) {
        const submissionStatus = hasBeenSubmitted === ApplicationStatus.Submitted;
        console.log('submissionStatus: ', submissionStatus);
        return submissionStatus;
      }
    }

    return false;
  };

  return (
    <div>
      <ReviewConfirmModal onReview={onReview} />
      <div>
        <div className="panel panel-form">
          <div className="application-form-area">
            <h2>Review</h2>
            <p className="content-body">
              Below, you can check the detail of the information you have added. Once you are happy you can submit your
              application for assessment.
            </p>

            {userData?.getUser?.personalDetails && (
              <ReviewSegmentPersonal personalData={userData.getUser.personalDetails} />
            )}

            {userData?.getUser?.workExperience && CCIsVisible(course.criteria?.workExperienceState) && (
              <div className="review-section">
                <h3>Work experience</h3>
                <div>
                  {userData?.getUser.workExperience?.map((weItem, index) => (
                    <ReviewSegmentWorkExperience key={`weexpforms${index}`} workExperience={weItem} count={index + 1} />
                  ))}
                </div>
              </div>
            )}

            {userData?.getUser.education?.secondary &&
              CCIsVisible(course.criteria?.educationState) &&
              CCIsVisible(course.criteria?.educationCriteria?.secondary) && (
                <div className="review-section">
                  <div>
                    <h3>Education and Training: Second level</h3>
                    <div>
                      {userData?.getUser.education?.secondary?.map((educationEntry, index) => (
                        <ReviewSegmentSecondLevel
                          key={`secedu${index}`}
                          secondLevel={educationEntry}
                          count={index + 1}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {userData?.getUser.education?.furtherEducation &&
              CCIsVisible(course.criteria?.educationState) &&
              CCIsVisible(course.criteria?.educationCriteria?.further) && (
                <div className="review-section">
                  <div>
                    <h3>Education and Training: Further Education</h3>
                    <div>
                      {userData?.getUser.education?.furtherEducation?.map((tlItem, index) => (
                        <ReviewSegmentFurtherEducation
                          key={`fedu${index}`}
                          furtherEducation={tlItem}
                          count={index + 1}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {userData?.getUser.education?.thirdLevel &&
              CCIsVisible(course.criteria?.educationState) &&
              CCIsVisible(course.criteria?.educationCriteria?.thirdLevel) && (
                <div className="review-section">
                  <div>
                    <h3>Education and Training: Third level</h3>
                    <div>
                      {userData?.getUser.education?.thirdLevel?.map((tlItem, index) => (
                        <ReviewSegmentFurtherEducation
                          key={`thrdedu${index}`}
                          furtherEducation={tlItem}
                          count={index + 1}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {userData?.getUser?.industryTraining && CCIsVisible(course.criteria?.industryTrainingState) && (
              <div className="review-section">
                <div>
                  <h3>Education and Training: Industry training</h3>
                  <div>
                    {userData?.getUser.industryTraining?.map((weItem, index) => (
                      <ReviewSegmentIndustryTraining
                        key={`itexpforms${index}`}
                        industryTraining={weItem}
                        count={index + 1}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}

            {userData?.getUser.skillsCompetencies &&
              CCIsVisible(course.criteria?.scState) &&
              course.criteria?.scCriteria && (
                <div className="review-section">
                  <div>
                    <h3>Skills &amp; Competencies</h3>
                    <div>
                      {userData?.getUser.skillsCompetencies
                        ?.filter((skillEntry) =>
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          course.criteria!.scCriteria!.find(
                            (item) => item.title?.toLowerCase() === skillEntry.title.toLowerCase()
                          )
                        )
                        .map((skillEntry, index) => (
                          <ReviewSegmentSkills key={`scies${index}`} skills={skillEntry} count={index + 1} />
                        ))}
                    </div>
                  </div>
                </div>
              )}

            {userData?.getUser.application?.exemptions && (
              <ReviewSegmentExemptions
                data={userData?.getUser.application?.exemptions}
                moduleData={course.modules}
                applicationId={userData?.getUser.application._id.toString()}
                isAdmin={false}
              />
            )}

            {UDError && <div className="response error-response">Error fetching Application: {UDError}</div>}
            {PutRSData && <div className="response success-response">Application successfully submitted.</div>}
            {PutRSError && <div className="response error-response">Error submitting Application: {PutRSError}</div>}
            <div className="modal-login-submit text-right">
              <ReviewSubmitButton
                handleClick={handleClick}
                // disabled = {submissionStatus}
                statusMessage={submissionStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
