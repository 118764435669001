import React from 'react';
import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Analytics from 'react-ga';
import { constants } from 'services/constants';

let getConsent = getCookieConsentValue() === 'true';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleAcceptCookie = () => {
  getConsent = true;
  Analytics.initialize(constants.GOOGLE_ANALYTCS_KEY);
  AnalyticsSendPageview('/');
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleDeclineCookie = () => {
  getConsent = false;
  Cookies.remove('_ga');
  Cookies.remove('_gat');
  Cookies.remove('_gid');
};

export const AnalyticsInit = (): void => {
  if (getConsent) {
    Analytics.initialize(constants.GOOGLE_ANALYTCS_KEY);
  }
};

export const AnalyticsSendEvent = (payload: Analytics.EventArgs): void => {
  if (getConsent) {
    Analytics.event(payload);
  }
};

export const AnalyticsSendPageview = (path: string): void => {
  if (getConsent) {
    Analytics.set({ page: path });
    Analytics.pageview(path);
  }
};

export const AnalyticsTiming = (
  categoryName: string,
  variableName: string,
  valueNum: number,
  valueLabel?: string
): void => {
  if (getConsent) {
    console.log('analytics timing fired for ' + categoryName + ' ' + variableName + ' ' + valueNum + ' ' + valueLabel);
    Analytics.timing({
      category: categoryName,
      variable: variableName,
      value: valueNum,
      label: valueLabel,
    });
  }
};

export function useGoogleAnalytics(): void {
  if (getConsent) {
    const location = window.location;

    React.useEffect(() => {
      const currentPath = location.pathname + location.search;
      AnalyticsSendPageview(currentPath);
    }, [location]);
  }
}
