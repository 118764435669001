import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface IDeleteConfirmModal {
  onDeleteConfirm: () => void;
  record: string;
  index: number;
  modalId: string;
}

export const DeleteConfirmModal: React.FC<IDeleteConfirmModal> = (props) => {
  const CloseModal = (): void => {
    const confirmSubmission = document.getElementById(props.modalId);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      }
    }
  };

  const onConfirmBtnClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    //ReviewAndSubmit();
    CloseModal();
    props.onDeleteConfirm();
  };

  return (
    <div id={props.modalId} className="modal">
      <div className="modal-popover-confirm">
        <div className="modal-login-form-area">
          <div className="close-modal" onClick={CloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="modal-content-exemptions">
            <p className="text-bold text-danger">Confirm delete</p>
            <p className="mb-1 mt-2">Are you sure you want to delete the following entry?</p>
            <p className="text-bold">{props.record}</p>
          </div>
        </div>

        <div className="modal-confirm-button text-right">
          <button type="submit" className="btn btn-sm btn-secondary btn-auto-width mr-1" onClick={CloseModal}>
            Back
          </button>
          <button
            data-testid="Delete"
            type="submit"
            className="btn btn-sm btn-auto-width mr-5"
            onClick={onConfirmBtnClick}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
