import axios, { AxiosResponse } from 'axios';

interface IEnvValues {
  serverUrl: string;
  applicantLoginUri: string;
  adminLoginUri: string;
  applicantRegisterUri: string;
  graphqlUri: string;
  timeToSave: number;
  googleAnalyticsKey: string;
  uploadMaxFileSize: number;
  FF_ExemptionsEnabled: boolean;
  FF_AllowVerifiedOnly: boolean;
  FF_ShowApplicantHomePage: boolean;
  FF_ShowAdminHomePage: boolean;
  FF_ShowExemptionStatus: boolean;
  FF_ShowSuperUserPage: boolean;
  FF_HideSubmitToRegistrar: boolean;
  JwtStorageKey: string;
  applicantForgotPasswordUri: string;
  adminForgotPasswordUri: string;
  applicantVerifyFPasswordUri: string;
}

class CONSTANTS {
  private static environment: IEnvValues;
  private static _constants: CONSTANTS;

  static async Init(): Promise<boolean> {
    try {
      const { data } = await axios.get<unknown, AxiosResponse<IEnvValues>>(`${getClientUrl()}/env.json`);

      CONSTANTS.environment = data;

      console.log('Contents of env file:');
      console.table(CONSTANTS.environment);

      if (CONSTANTS._constants) {
        CONSTANTS._constants.setEnv();
      }

      return true;
    } catch (err) {
      console.error('Error Loading Environment:', err);

      return false;
    }
  }

  static get Instance(): CONSTANTS {
    if (!CONSTANTS._constants) {
      CONSTANTS._constants = new CONSTANTS();
    }

    if (process.env.NODE_ENV !== 'test') {
      // console.trace();
      console.table(CONSTANTS._constants);
    }
    return CONSTANTS._constants;
  }

  private _serverUrl = 'http://localhost:3000';
  private _applicantLoginUrl = 'http://localhost:3000//api/login';
  private _adminLoginUrl = 'http://localhost:3000//api/adminlogin';
  private _applicantRegisterUrl = 'http://localhost:3000//api/register';
  private _applicantForgotPasswordUrl = 'http://localhost:3000//api/forgot';
  private _adminForgotPasswordUrl = 'http://localhost:3000//api/adminforgot';
  private _applicantVerifyFPasswordUrl = 'http://localhost:3000//api/verifyresetpassword/:token';
  private _graphqlUrl = 'http://localhost:3000/api/graphql';
  private _timeToSave = 1000;
  private _googleAnalyticsKey = 'UA-176106395';
  private _uploadMaxFileSize = 10 * 1024 * 1024;
  private _FF_ExemptionsEnabled = true;
  private _FF_AllowVerifiedOnly = true;
  private _FF_ShowApplicantHomePage = true;
  private _FF_ShowAdminHomePage = true;
  private _FF_ShowExemptionStatus = true;
  private _FF_ShowSuperUserPage = true;
  private _FF_HideSubmitToRegistrar = true;
  private _JwtStorageKey = 'SolleJwt';

  private constructor() {
    this.setEnv();
  }

  setEnv(): void {
    this._serverUrl =
      process?.env?.REACT_APP_SS_SERVER_URL ?? (CONSTANTS.environment?.serverUrl as string) ?? getClientUrl();

    this._applicantLoginUrl = `${this._serverUrl}${CONSTANTS.environment?.applicantLoginUri ?? '/api/login'}`;
    this._adminLoginUrl = `${this._serverUrl}${CONSTANTS.environment?.adminLoginUri ?? '/api/adminlogin'}`;
    this._applicantRegisterUrl = `${this._serverUrl}${CONSTANTS.environment?.applicantRegisterUri ?? '/api/register'}`;
    this._applicantForgotPasswordUrl = `${this._serverUrl}${
      CONSTANTS.environment?.applicantForgotPasswordUri ?? '/api/forgot'
    }`;
    this._adminForgotPasswordUrl = `${this._serverUrl}${
      CONSTANTS.environment?.adminForgotPasswordUri ?? '/api/adminforgot'
    }`;

    this._applicantVerifyFPasswordUrl = `${this._serverUrl}${
      CONSTANTS.environment?.applicantVerifyFPasswordUri ?? '/api/verifyresetpassword'
    }`;

    this._graphqlUrl = `${this._serverUrl}${CONSTANTS.environment?.graphqlUri ?? '/api/graphql'}`;
    this._timeToSave = CONSTANTS.environment?.timeToSave ?? this._timeToSave;
    this._googleAnalyticsKey = CONSTANTS.environment?.googleAnalyticsKey ?? this._googleAnalyticsKey;
    this._uploadMaxFileSize = CONSTANTS.environment?.uploadMaxFileSize ?? this._uploadMaxFileSize;
    this._FF_ExemptionsEnabled = CONSTANTS.environment?.FF_ExemptionsEnabled ?? this.FF_EXEMPTIONS_ENABLED;
    this._FF_AllowVerifiedOnly = CONSTANTS.environment?.FF_AllowVerifiedOnly ?? this._FF_AllowVerifiedOnly;
    this._FF_ShowApplicantHomePage = CONSTANTS.environment?.FF_ShowApplicantHomePage ?? this._FF_ShowApplicantHomePage;
    this._FF_ShowAdminHomePage = CONSTANTS.environment?.FF_ShowAdminHomePage ?? this._FF_ShowAdminHomePage;
    this._FF_ShowExemptionStatus = CONSTANTS.environment?.FF_ShowExemptionStatus ?? this._FF_ShowExemptionStatus;
    this._FF_ShowSuperUserPage = CONSTANTS.environment?.FF_ShowSuperUserPage ?? this._FF_ShowSuperUserPage;
    this._FF_HideSubmitToRegistrar = CONSTANTS.environment?.FF_HideSubmitToRegistrar ?? this._FF_HideSubmitToRegistrar;
    this._JwtStorageKey = CONSTANTS.environment?.JwtStorageKey ?? this._JwtStorageKey;

    // workaround as jest.useFakeTimer doesn't appear to work properly wihen async code is involved.
    // couldn't get any other workarounds to work
    // see https://stackoverflow.com/questions/52177631/jest-timer-and-promise-dont-work-well-settimeout-and-async-function
    if (process.env.NODE_ENV === 'test') {
      this._timeToSave = 0;
    }
  }

  get APPLICANT_VERIFY_FORGOT_PASSWORD_URL(): string {
    return this._applicantVerifyFPasswordUrl;
  }

  get APPLICANT_FORGOT_PASSWORD_URL(): string {
    return this._applicantForgotPasswordUrl;
  }

  get ADMIN_FORGOT_PASSWORD_URL(): string {
    return this._adminForgotPasswordUrl;
  }

  get APPLICANT_LOGIN_URL(): string {
    return this._applicantLoginUrl;
  }

  get APPLICANT_REGISTER_URL(): string {
    return this._applicantRegisterUrl;
  }

  get ADMIN_LOGIN_URL(): string {
    return this._adminLoginUrl;
  }

  get GRAPHQL_URL(): string {
    return this._graphqlUrl;
  }

  get TIME_TO_SAVE(): number {
    return this._timeToSave;
  }

  get GOOGLE_ANALYTCS_KEY(): string {
    return this._googleAnalyticsKey;
  }

  get UPLOAD_MAX_FILE_SIZE(): number {
    return this._uploadMaxFileSize;
  }

  get FF_EXEMPTIONS_ENABLED(): boolean {
    return this._FF_ExemptionsEnabled;
  }

  get FF_ALLOW_VERIFIED_ONLY(): boolean {
    return this._FF_AllowVerifiedOnly;
  }

  get FF_SHOW_HIDE_HOME(): boolean {
    return this._FF_ShowApplicantHomePage;
  }

  get FF_SHOW_EXEMPTION_STATUS(): boolean {
    return this._FF_ShowExemptionStatus;
  }

  get FF_SHOW_HIDE_ADMINHOME(): boolean {
    return this._FF_ShowAdminHomePage;
  }

  get FF_SHOW_SUPER_USER(): boolean {
    return this._FF_ShowSuperUserPage;
  }

  get FF_HideSubmitToRegistrar(): boolean {
    return this._FF_HideSubmitToRegistrar;
  }

  get JWT_STORAGE_KEY(): string {
    return this._JwtStorageKey;
  }
}

export const InitConstants = async (): Promise<boolean> => {
  return await CONSTANTS.Init();
};

const getClientUrl = (): string => {
  let hostname = 'localhost:3000';
  let protocol = 'http:';

  if (
    typeof window !== 'undefined' &&
    window &&
    'location' in window &&
    'protocol' in window.location &&
    'host' in window.location
  ) {
    protocol = window.location.protocol;
    hostname = window.location.host;
  }

  return `${protocol}//${hostname}`;
};

export const promptToolTip =
  'If you need to upload more than one file, add all files that you need to upload to a zip file and select that zip file for uploading.';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const constants = CONSTANTS.Instance;
