import { ApplicationStatus, User } from 'graphql/globalTypes';
import Accepted from 'components/ApplicationStatuses/Accepted';

interface IApplications {
  applicants?: User[];
}
const AcceptedApplications: React.FC<IApplications> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) => app.application?.currentStatus === ApplicationStatus.Accepted
  ).length;

  return (
    <div>
      <div className="application-statistics" data-testid="acceptedcounter">
        {applicationCount}
      </div>
      <Accepted />
    </div>
  );
};

export default AcceptedApplications;
