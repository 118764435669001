import React, { useContext } from 'react';

import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import { CourseCtx } from 'services/getCtx';

interface IShowCourseInfoProps {
  isAdmin: boolean;
  isLoggedIn: boolean;
  collapsed: boolean;
}

const ShowCourseInfo: React.FC<IShowCourseInfoProps> = (props) => {
  const [course] = useContext(CourseCtx);

  return (
    <h1>
      {props.isAdmin && <>Admin - </>} {course.title} - {props.collapsed ? 'RPL' : 'Recognition of Prior Learning'}
    </h1>
  );
};

export default ShowCourseInfo;
