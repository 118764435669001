interface IGreenButton {
  keyValue: string;
  dataTestId?: string;
  onClick: () => void;
}

export const GreenButton: React.FC<IGreenButton> = (props) => {
  return (
    <button
      key={props.keyValue}
      data-testid={props.dataTestId ?? props.keyValue}
      className="btn btn-sm btn-secondary"
      type="button"
      onClick={props.onClick}
    >
      <span>{props.children}</span>
    </button>
  );
};
