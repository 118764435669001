import React from 'react';

import { Maybe, SolleAttachment } from 'graphql/globalTypes';
import { DisplayAttachment } from 'components/DisplayAttachment';
import { DateFormatter } from 'components/helper_date';

export const ShowFieldString = (label: string, value?: Maybe<string>, classN = 'review-group'): JSX.Element => {
  return (
    <div className={classN}>
      <label>{label}</label>
      <p>{value}</p>
    </div>
  );
};

export const ShowFieldDate = (label: string, value?: Maybe<string>, classN = 'review-group'): JSX.Element => {
  return (
    <div className={classN}>
      <label>{label}</label>
      <p>
        <DateFormatter date={value ?? ''} />
      </p>
    </div>
  );
};

export const ShowFieldAttachment = (
  label: string,
  attachment?: Maybe<SolleAttachment>,
  classN = 'review-group'
): JSX.Element => {
  return (
    <div className={classN}>
      <DisplayAttachment label={label} attachment={attachment} />
    </div>
  );
};
