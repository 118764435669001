import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AttachmentInput } from 'graphql/globalTypes';
import Tooltip from 'components/Tooltip';

export type funcAttachmentsSelect = (attachmentsData: AttachmentInput, attachments: File[]) => void;

interface IFormAttachmentsSelectProps {
  label: string;
  name: string;
  uniqueName?: string;
  attachmentsData: AttachmentInput;
  tooltip?: string;
  placeholder?: string;
  onAttachmentsSelect: funcAttachmentsSelect;
}

export const FormAttachmentsSelect: React.FC<IFormAttachmentsSelectProps> = (props) => {
  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement & { files: File[] }>): void => {
    props.onAttachmentsSelect(props.attachmentsData, event.target.files);
  };

  return (
    <React.Fragment>
      <div className="file-upload">
        <div
          className="form-group"
          onClick={(): void => document.getElementById(props.uniqueName ?? props.name)?.click()}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          <span>
            {props.label}
            {props.tooltip ? <Tooltip tooltip={props.tooltip ?? props.placeholder} /> : ''}
          </span>
        </div>
        <input name={props.name} type="file" id={props.uniqueName ?? props.name} onChange={onFileSelect} />
      </div>
    </React.Fragment>
  );
};
