import React from 'react';

import FormInput, { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { AttachmentTypes, Maybe, PersonalDetailsInput, Language, Gender } from 'graphql/globalTypes';
import { funcAttachmentsSelect, FormAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { LanguageDescription } from 'data/selector_lists';
import { parseDate } from 'services/utils';
import { promptToolTip } from 'services/constants';

const GENDER_VALUES = ['male', 'female', 'notspecified'];
export const GenderDescription = new Map<string, string>([
  [Gender.Male, 'Male'],
  [Gender.Female, 'Female'],
  [Gender.Notspecified, 'Not specified'],
]);

interface IPersonalInfo {
  personalDetails?: Maybe<PersonalDetailsInput>;
  pName?: Maybe<string>;
  cName?: Maybe<string>;
  eName?: Maybe<string>;
  email?: string;
  onAttachmentSelect: funcAttachmentsSelect;
  onPDChange: (event: FormInputChangeParam) => void;
}

const PersonalInfoForm: React.FC<IPersonalInfo> = (props) => {
  const firstLanguage = props.personalDetails?.firstLanguage ?? undefined;
  return (
    <div>
      <form>
        <FormInput
          name="firstName"
          label="First Name"
          type="text"
          fullwidth={true}
          onDataChange={props.onPDChange}
          value={props.personalDetails?.firstName}
        />
        <FormInput
          name="lastName"
          label="Surname"
          type="text"
          fullwidth={true}
          onDataChange={props.onPDChange}
          value={props.personalDetails?.lastName}
        />
        <FormInput
          name="ppsNo"
          label="(PPS No.) Personal Public Service No."
          type="text"
          fullwidth={true}
          onDataChange={props.onPDChange}
          value={props.personalDetails?.ppsNo}
        />
        <FormInput
          name="dateOfBirth"
          label="Date of birth"
          type="date"
          placeholder="dd/mm/yyyy"
          fullwidth={true}
          onDataChange={props.onPDChange}
          value={parseDate(props.personalDetails?.dateOfBirth)}
        />
        <FormInput
          name="phone"
          label="Phone number"
          type="number"
          fullwidth={true}
          onDataChange={props.onPDChange}
          value={props.personalDetails?.phone}
        />
        <FormInput
          name="email"
          label="Email address"
          type="email"
          fullwidth={true}
          disabled={true}
          onDataChange={props.onPDChange}
          value={props.email}
        />
        <FormSelect
          name="gender"
          label="Gender"
          values={GENDER_VALUES}
          valuesDescription={GenderDescription}
          fullwidth={true}
          onDataChange={props.onPDChange}
          selectedValue={props.personalDetails?.gender}
        />
        <div className="break"></div>
        <FormAttachmentsSelect
          name="profilePicture"
          label="Upload profile picture"
          attachmentsData={{ attachmentType: AttachmentTypes.ProfilePicture }}
          onAttachmentsSelect={props.onAttachmentSelect}
          tooltip={promptToolTip}
        />
        {props.pName && <div className="filename">{props.pName}</div>}
        <div className="break"></div>
        <FormAttachmentsSelect
          name="cv"
          label="Upload CV"
          attachmentsData={{ attachmentType: AttachmentTypes.Cv }}
          onAttachmentsSelect={props.onAttachmentSelect}
          tooltip={promptToolTip}
        />
        {props.cName ? <div className="filename">{props.cName}</div> : ''}
        <div className="break"></div>
        <FormSelect
          name="firstLanguage"
          label="First Language"
          values={['English', ...Object.values(Language)]}
          valuesDescription={LanguageDescription}
          fullwidth={false}
          onDataChange={props.onPDChange}
          selectedValue={firstLanguage}
        />
        <div className="form-group mt-0">
          {firstLanguage !== undefined && firstLanguage !== Language.English && (
            <div>
              <div>
                <p className="first-language">English language certificate</p>
                <label>
                  Applicants whose first language is not English must submit evidence of competency in English, please
                  see{' '}
                  <a
                    href="http://www.wit.ie/about_wit/documents_and_policies/english_language_requirements"
                    target="new"
                  >
                    SETU's English Language Requirements
                  </a>{' '}
                  for details.
                </label>
                <FormAttachmentsSelect
                  name="englishLanguageCert"
                  label="Upload English Language Certificate"
                  attachmentsData={{ attachmentType: AttachmentTypes.EnglishLanguage }}
                  onAttachmentsSelect={props.onAttachmentSelect}
                  tooltip={promptToolTip}
                />
              </div>
              {props.eName ? <div className="filename">{props.eName}</div> : ''}
            </div>
          )}
          {props.personalDetails?.firstLanguage === Language.English && <p className="first-language">&nbsp;</p>}
        </div>
        <div className="break"></div>
      </form>
    </div>
  );
};

export default PersonalInfoForm;
