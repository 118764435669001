import React from 'react';
import MarketingFooter from './MarketingFooter';
import Hero from 'components/hero/Hero';
import Content from 'components/content/Content';
import { ShowCourses } from 'pages/Course/ShowCourses';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

const Courses: React.FC<IUserStateDetail> = (props) => {
  return (
    <div>
      <Hero {...props} {...{ target: 'courses' }} />
      <Content target="courses" />
      <section id="courses-area">
        <div className="content-area">
          <div className="content-header">
            <h2>Awards for RPL Applicants</h2>
          </div>
          <div className="content-body-cards">
            <div className="flex-grid-row-marketing">
              <ShowCourses />
            </div>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </div>
  );
};

export default Courses;
