import gql from 'graphql-tag';
import { APPLICATION_FIELDS } from './applications';

export const ADD_OR_UPDATE_ALL_EXEMPTIONS = gql`
  mutation AddOrUpdateAllExemptions($applicationId: String!, $exemptions: [ExemptionInput!]!) {
    addOrUpdateAllExemptions(applicationId: $applicationId, exemptions: $exemptions) {
      ...ApplicationFields
    }
  }

  ${APPLICATION_FIELDS}
`;

// export const ADD_UPDATE_EXEMPTIONS = gql`
//   mutation AddOrUpdateExemptions($exemption: ExemptionInput!, $applicationId: String!) {
//     addOrUpdateExemption(exemption: $exemption, applicationId: $applicationId) {
//       ...ApplicationFields
//     }
//   }
//   ${APPLICATION_FIELDS}
// `;

export const REMOVE_EXEMPTION_REASON = gql`
  mutation RemoveExemptionReason($moduleId: String!, $reasonId: String!) {
    removeExemptionReason(moduleId: $moduleId, reasonId: $reasonId) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;

export const REMOVE_EXEMPTION = gql`
  mutation RemoveExemption($moduleId: String!) {
    removeExemption(moduleId: $moduleId) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;

export const SET_EXEMPTION_SUPPORT = gql`
  mutation SetExemptionSupport($applicationId: String!, $shouldSupportExemption: Boolean!) {
    setExemptionSupport(applicationId: $applicationId, shouldSupportExemption: $shouldSupportExemption) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;
