import gql from 'graphql-tag';

export const PCL_FIELDS = gql`
  fragment PCLFields on PCL {
    _id
    pclId
    courseId
    moduleId
    title
    institute
    semester
    country
    qualification
    minGrade
    description
    createdAt
    updatedAt
  }
`;
