import { useContext, useRef } from 'react';

import { ApplicantDataNavigate } from 'pages/Navigation/ApplicantDataNavigate';
import { ApplicantReviewMenuItems } from 'pages/Navigation/ApplicationReviewMenuItems';
import DashboardReviewBar from 'pages/Messaging/DashboardReviewBar';
import { DashboardTopToolbar } from 'components/DashboardTopToolbar';
import { UserCtx } from 'services/getCtx';

interface ApplicationReview {
  isLoggedIn: boolean;
  isAdmin: boolean;
}

export const ApplicationReview: React.FC<ApplicationReview> = (props) => {
  const [user] = useContext(UserCtx);
  const reviewerArea = useRef<HTMLDivElement>(null);

  const setDestination = (): string => (user ? '/admin/applications-view/' + user.currentCourse : '/admin');

  return (
    <>
      <section>
        <div id="reviewer_area" className="dashboard-review-area" ref={reviewerArea}>
          <DashboardTopToolbar label="Select application" destination={setDestination()} />
          <div className="grid-collapse">
            <ApplicantDataNavigate menuItems={ApplicantReviewMenuItems} {...props} />
          </div>
        </div>
      </section>
      <DashboardReviewBar expanded={true} {...props} parentRef={reviewerArea} />
    </>
  );
};
