import { ApplicationStatus, User } from 'graphql/globalTypes';
import Refused from 'components/ApplicationStatuses/Refused';

interface IApplications {
  applicants?: User[];
}
const RefusedApplications: React.FC<IApplications> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) => app.application?.currentStatus === ApplicationStatus.Refused
  ).length;

  return (
    <div>
      <div className="application-statistics" data-testid="refusedcounter">
        {applicationCount}
      </div>
      <Refused />
    </div>
  );
};

export default RefusedApplications;
