import React, { useState, useEffect } from 'react';
import { AnalyticsSendEvent } from 'services/googleAnalytics';

import axios, { AxiosResponse } from 'axios';
import { validatePassword, IValidatePassword, confirmMatch } from 'services/validatePassword';

export interface IVerifyRPForm {
  url: string;
  onSuccess: (message: string) => void;
  token: string;
}
const VerifyRPForm: React.FC<IVerifyRPForm> = (props) => {
  const defaultValidPassword: IValidatePassword = {
    validUppercase: true,
    validLowercase: true,
    validNumber: true,
    validLength: true,
    validMatch: true,
  };

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const [error, setError] = useState(false);
  const [passwordValid, setPasswordValid] = useState<IValidatePassword>(defaultValidPassword);
  const [passwordValConfirmed, setPasswordValConfirmed] = useState(false);
  const [showPasswordMatchPrompt, setShowPasswordMatchPrompt] = useState(false);

  useEffect(() => {
    if (resetPassword) {
      console.log('feedback response is');
      console.log(feedback);
      props.onSuccess(feedback);
    }
  }, [resetPassword]);

  const disableSubmitBtn = (): boolean => !passwordValConfirmed && !loading;

  const handlePasswordInput = (): void => {
    const passwordValue: string | undefined | null = (document.getElementById('user_password') as HTMLInputElement)
      .value;
    const repeatPasswordValue: string | undefined | null = (
      document.getElementById('repeat_password') as HTMLInputElement
    ).value;

    setPassword(passwordValue);
    setPasswordValConfirmed(confirmMatch(passwordValue, repeatPasswordValue, defaultValidPassword));
    setPasswordValid(validatePassword(passwordValue, defaultValidPassword));
    passwordValue !== repeatPasswordValue ? setShowPasswordMatchPrompt(true) : setShowPasswordMatchPrompt(false);
  };

  const handleInputChange = (): void => {
    const passwordValue: string | undefined | null = (document.getElementById('user_password') as HTMLInputElement)
      .value;
    const repeatPasswordValue: string | undefined | null = (
      document.getElementById('repeat_password') as HTMLInputElement
    ).value;

    setPasswordValConfirmed(confirmMatch(passwordValue, repeatPasswordValue, defaultValidPassword));
    passwordValue !== repeatPasswordValue ? setShowPasswordMatchPrompt(true) : setShowPasswordMatchPrompt(false);
  };

  const onResetPassword = async (event: React.MouseEvent): Promise<void> => {
    setLoading(true);
    event.preventDefault();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await axios.post<unknown, AxiosResponse<any, any>>(
        props.url,
        {
          token: props.token,
          password: password,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      // console.log('response is here.');

      // console.log(JSON.stringify(response));

      setFeedback(data.message);
      setLoading(false);
      setError(false);
      setResetPassword(true);

      AnalyticsSendEvent({
        category: 'User - Applicant',
        action: 'Resetting Password',
        transport: 'beacon',
      });

      console.log(feedback);
    } catch (error) {
      setError(true);
      setFeedback(error.status);
      setResetPassword(false);
      setLoading(false);
      console.log(error.body);
    }
  };

  return (
    <form className="registration-form">
      <label id="register_password" htmlFor="user_password">
        Password
      </label>
      <input
        id="user_password"
        type="password"
        name="password"
        className="full-width-form-field"
        aria-describedby="register_email"
        aria-labelledby="register_password"
        onChange={handlePasswordInput}
      />
      <label htmlFor="repeat_password">Repeat password</label>
      <input
        id="repeat_password"
        type="password"
        className="full-width-form-field"
        aria-describedby="register_email"
        aria-labelledby="repeat_password"
        onChange={handleInputChange}
      />
      {showPasswordMatchPrompt && (
        <div className="password-status">
          <p>Passwords do not match</p>
        </div>
      )}
      {!passwordValid.validLength && (
        <div className="password-status">
          <p>Should be between 8 and 64 characters long</p>
        </div>
      )}
      {!passwordValid.validUppercase && (
        <div className="password-status">
          <p>Must contain at least 1 uppercase character</p>
        </div>
      )}
      {!passwordValid.validLowercase && (
        <div className="password-status">
          <p>Must contain at least 1 lowercase character</p>
        </div>
      )}
      {!passwordValid.validNumber && (
        <div className="password-status">
          <p>Must contain at least 1 number</p>
        </div>
      )}
      {error && (
        <div className="response error-response">
          <p>There was an error with your submission, please try again.</p>
        </div>
      )}
      <div className="modal-login-submit">
        <button
          id="verifyFP_submit_btn"
          type="submit"
          className="btn btn-modal btn-secondary btn-full-width"
          onClick={onResetPassword}
          disabled={disableSubmitBtn()}
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default VerifyRPForm;
