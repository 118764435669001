import React, { useState, useEffect } from 'react';

import { AnalyticsSendEvent } from 'services/googleAnalytics';

import axios, { AxiosResponse } from 'axios';

import { ILogin } from './LoginModal';

const LoginForm: React.FC<ILogin> = (props) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loggedin, setLoggedin] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    if (loggedin) {
      props.onSuccess(jwtToken);
    }
  }, [loggedin]);

  const onLogin = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await axios.post<unknown, AxiosResponse<any, any>>(
        props.url,
        {
          email: emailAddress,
          password: password,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      setJwtToken(data.jwtToken.token);
      setLoggedin(true);
      setError(false);

      AnalyticsSendEvent({
        category: 'User - Generic',
        action: 'Signed in',
        transport: 'beacon',
      });
    } catch (error) {
      setLoggedin(false);
      setError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  const updateEmailAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailAddress(event.target.value);
  };

  const updatePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  return (
    <form>
      <div className="form-group">
        <label id="user_email" htmlFor="email">
          Your email address
        </label>
        <input
          type="email"
          className="full-width-form-field"
          aria-describedby="user_email"
          onChange={updateEmailAddress}
        />
      </div>
      <div className="form-group">
        <label htmlFor="userPassword">Password</label>
        <input value={password} type="password" className="full-width-form-field" onChange={updatePassword} />
      </div>
      {/* <div className="form-group form-check">
        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        <label htmlFor="exampleCheck1">I agree to T&Cs</label>
      </div> */}
      {error && (
        <div className="response error-response">
          <p>{errorMessage}</p>
        </div>
      )}
      <div className="modal-login-submit">
        <button
          type="submit"
          className="btn btn-modal btn-secondary btn-full-width"
          disabled={emailAddress === '"' && password === ''}
          onClick={onLogin}
        >
          Login
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
