import { NavLink } from 'react-router-dom';

import HomeIcon from 'images/icons/home.svg';

import DashboardHeader, { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import DashboardFooter from 'pages/Navigation/DashboardFooter';

import { SuperAdminRoutes } from 'pages/SuperAdmin/SuperAdminRoute';

export const SuperAdminView: React.FC<IUserStateDetail> = (props) => {
  return (
    <div className="dashboard-view">
      <DashboardHeader {...props} />
      <div className="dashboard-sidebar-left">
        <ul className="Private">
          <li>
            <NavLink to="/superadmin" className={({ isActive }) => (isActive ? 'active' : undefined) as string}>
              <img src={HomeIcon} alt="click for home" />
            </NavLink>
          </li>
        </ul>
      </div>
      <SuperAdminRoutes {...props} />
      <DashboardFooter />
    </div>
  );
};
