import React, { useState, useEffect } from 'react';
import { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import { useMutation, useQuery } from '@apollo/client';
import VideoImage from 'images/video-image.jpg';
import { GET_USER } from 'graphql/queries/users';
import { UPDATE_PERSONAL_DETAIL } from 'graphql/queries/personaldetail';
import PersonalInfoForm from './Forms/PersonalInfoForm';
import { UpdateAttachments } from 'services/attachments';
import { constants } from 'services/constants';
import {
  PersonalDetails,
  PersonalDetailsInput,
  Maybe,
  GetUserQuery,
  UpdatePersonalDetailMutation,
  MutationUpdatePersonalDetailsArgs,
} from 'graphql/globalTypes';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import FlashMessageError from 'components/FlashMessageError';
import { fileSizeExceeded } from 'services/utils';

let saveTimeoutId: number | undefined;
type PDType = PersonalDetails & { __typename: string };

const SERVER_ERROR_MSG =
  'There is an error occured while fetching the Personal Information. Please refresh the page to load them again.';
const SERVER_LOADING_MSG = "Please wait while we're fetching Personal Information from server.";
const PROFILE_UPDATED_MSG = 'Personal Information has saved successfully.';
const PROFILE_UDATEERROR_MSG = 'There is an error while updating the Personal Information. ';
const PROFILE_LOADING_MSG = 'Please wait while updating the Personal Information.';

const PersonalInfo: React.FC = () => {
  const {
    data: userData,
    refetch: userRefetch,
    error: serverError,
    loading: serverLoading,
  } = useQuery<GetUserQuery>(GET_USER);

  // we need these seperate set of string to display the
  // filename as it comes from the server
  const [pName, setPName] = useState<Maybe<string>>();
  const [eName, setEName] = useState<Maybe<string>>();
  const [cName, setCName] = useState<Maybe<string>>();

  const [email, setEmail] = useState<string>();
  const [fileuploadExceedLimitMessage, setFileuploadExceedLimitMessage] = useState<Error>();

  const [userInfo, setUserInfo] = useState<Maybe<PersonalDetailsInput>>();

  const [savePD, { error: PDError }] = useMutation<UpdatePersonalDetailMutation, MutationUpdatePersonalDetailsArgs>(
    UPDATE_PERSONAL_DETAIL
  );

  // Update when data has loaded from db
  useEffect(() => {
    if (userData?.getUser?.personalDetails) {
      console.log('useEffect:userData,personalDetails: ', userData.getUser.personalDetails);

      const ui = Object.assign({}, userData.getUser.personalDetails);
      const { profilePicture, cv, englishLanguageCert, __typename, ...rest } = ui as PDType;
      setUserInfo(rest);

      setEmail(userData.getUser.email);
      setPName(profilePicture?.filename);
      setEName(englishLanguageCert?.filename);
      setCName(cv?.filename);
    }
  }, [userData]);

  const onChange = (event: FormInputChangeParam): void => {
    let newData = { ...userInfo, [event.target.name]: event.target.value };

    if (
      (event.target.name === 'gender' || event.target.name === 'firstLanguage') &&
      event.target.value === '-- select an option --'
    ) {
      newData = { ...userInfo, [event.target.name]: null };
    }

    console.log(newData);
    setUserInfo(newData);

    if (saveTimeoutId) {
      window.clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    saveTimeoutId = window.setTimeout(async () => {
      console.log('In timeout', newData);
      try {
        await savePD({ variables: { personalDetails: newData } });
      } catch (err) {
        console.log('Personal.tsx:onSave:Error: ', err);
      } finally {
        console.log('refetching...');

        // some tests are failing as the test data isn't setup properly
        // this causes the test to throw an error with calling the following
        // function. The try catch protects against this
        try {
          await userRefetch();
        } catch (err) {
          console.log(err);
        }
      }
    }, constants.TIME_TO_SAVE);
  };

  const onResetFlashMessage = (): void => {
    setFileuploadExceedLimitMessage(undefined);
  };

  const onAttachmentSelect: funcAttachmentsSelect = async (attachmentsData, attachments) => {
    const validateError = fileSizeExceeded(attachments);

    if (validateError) {
      setFileuploadExceedLimitMessage(validateError);
    } else {
      try {
        await UpdateAttachments(attachmentsData, attachments);
        await userRefetch();
      } catch (e) {
        setFileuploadExceedLimitMessage(e);
      }
    }
  };

  return (
    <div>
      <div className="panel panel-form">
        <div className="panel-jumbo">
          <img src={VideoImage} alt="placeholder for jumbo intro video" />
        </div>

        <div className="application-form-area">
          <h2>Personal Information</h2>

          {fileuploadExceedLimitMessage && (
            <FlashMessageError error={fileuploadExceedLimitMessage} onFinish={onResetFlashMessage} />
          )}
          {!serverLoading && (
            <PersonalInfoForm
              key={email}
              personalDetails={userInfo}
              pName={pName}
              cName={cName}
              eName={eName}
              email={email}
              onAttachmentSelect={onAttachmentSelect}
              onPDChange={onChange}
            />
          )}
          <div className="break"></div>
          {serverError && formatMsg(SERVER_ERROR_MSG)}
          {serverLoading && formatMsg(SERVER_LOADING_MSG)}
          {/* {PDUpdate && formatMsg(PROFILE_UPDATED_MSG)} */}
          {PDError && formatMsg(PROFILE_UDATEERROR_MSG, PDError.message)}
          {/* {PDLoading && formatMsg(PROFILE_LOADING_MSG)} */}
        </div>
      </div>
    </div>
  );
};

function formatMsg(msg: string, param?: string): JSX.Element {
  if (msg === PROFILE_UPDATED_MSG || msg === PROFILE_LOADING_MSG) {
    return (
      <div className="response success-response">
        <p>
          {msg}
          {param !== undefined ? param : ''}
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p className="response error-response">
          {msg}
          {param !== undefined ? param : ''}
        </p>
      </div>
    );
  }
}

export default PersonalInfo;
