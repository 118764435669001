/* istanbul ignore file */
import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import {
  Maybe,
  PersonalDetails,
  WorkExperience,
  Education,
  SecondaryEducation,
  FurtherEducation,
  SkillsCompetencies,
  IndustryTraining,
  Course,
  User,
} from 'graphql/globalTypes';
import { decodeToken } from 'services/validation';
import { EducationTypes } from 'pages/Applicant/PersonalDetails/Education';
import { CCIsVisible } from 'services/utils';
import { exemptionMapping, exemptionBasis, exemptionLookup } from 'components/Helper_ExemptionMapper';
import { DateFormatter } from 'components/helper_date';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    fontWeight: 'light',
    padding: 32,
  },
  section: {
    padding: 8,
    flexGrow: 1,
  },
  minorSection: {
    marginBottom: 8,
    padding: 0,
    flexGrow: 1,
  },
  heading: {
    marginBottom: 8,
    fontSize: 13,
    color: '#20325b',
    fontWeight: 'bold',
  },
  subHeading: {
    marginBottom: 8,
    fontSize: 12,
    color: '#9d9d9d',
  },
  itemHeading: {
    marginBottom: 4,
    fontSize: 11,
    color: '#20325b',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    textDecoration: 'underline',
    fontWeight: 'bold',
    color: '#9d9d9d',
  },
  showLineItem: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    fontSize: 11,
    backgroundColor: '#f3f3f3',
  },
  showLineItemLabel: {
    marginRight: 16,
    fontWeight: 'bold',
    color: '#666666',
    width: '30%',
    textAlign: 'right',
  },
  showLineItemValue: {
    width: '60%',
  },
  header: {
    fontSize: 18,
  },
  footer: {
    textAlign: 'right',
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 10,
  },
  image: {
    margin: 'auto',
    width: 100,
    height: 'auto',
  },
  email: {
    margin: 'auto',
    marginTop: 8,
    fontSize: 12,
  },
  courseTitle: {
    margin: 'auto',
    marginBottom: 18,
    fontSize: 14,
  },
});

interface IPDFDocument {
  user: User;
  course: Course;
}

export const PDFDocument: React.FC<IPDFDocument> = (props) => {
  const user = props.user;
  const course = props.course;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.courseTitle}>Application to {course.title}</Text>
          {user.personalDetails.profilePicture?.filepath && (
            <Image
              style={styles.image}
              src={(user.personalDetails.profilePicture?.filepath ?? '') + '?token=' + decodeToken().token}
            />
          )}
        </View>
        <View>
          <Text style={styles.email}>{user.email}</Text>
        </View>
        {ShowPersonalInformation(user.personalDetails)}
        {CCIsVisible(course.criteria?.workExperienceState) && ShowWorkExperience(user.workExperience)}
        {CCIsVisible(course.criteria?.educationState) && ShowEducation(user.education, user.industryTraining, course)}
        {CCIsVisible(course.criteria?.scState) && ShowSkillsAndCompetencies(user.skillsCompetencies, course)}
        {ShowExemptions(user, course)}
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }): string => `Page ${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const ShowPersonalInformation = (personalDetails: PersonalDetails): JSX.Element => {
  return (
    <View style={styles.section}>
      {ShowTitle('Personal Information')}
      {ShowLineItem('First Name', personalDetails.firstName)}
      {ShowLineItem('Last Name', personalDetails.lastName)}
      {ShowDateItem('Date of Birth', personalDetails.dateOfBirth)}
      {ShowLineItem('Gender', personalDetails.gender)}
      {ShowLineItem('Phone Number', personalDetails.phone)}
      {ShowLineItem('PPS Number', personalDetails.ppsNo)}
      {ShowLineItem('First Language', personalDetails.firstLanguage)}
      {ShowLineItem('Language Cert', personalDetails.englishLanguageCert?.filename)}
      {ShowLineItem('CV', personalDetails.cv?.filename)}
    </View>
  );
};

const ShowWorkExperience = (workExperience?: Maybe<WorkExperience[]>): JSX.Element => {
  return (
    <View style={styles.section}>
      {ShowTitle('Work Experience')}
      {workExperience?.map((weItem, index) => {
        return (
          <View>
            <View style={styles.heading}>
              <Text>
                {index + 1}. {weItem.currentlyWorking ? 'Current role' : 'Previous work experience'}
              </Text>
            </View>
            {ShowLineItem('Employer', weItem.companyName)}
            {ShowLineItem('Job title', weItem.jobTitle)}
            {ShowLineItem('Address', weItem.companyAddress)}
            {ShowLineItem('Nature Of Business', weItem.NatureOfBusiness)}
            {ShowDateItem('Start Date', weItem.startDate, true)}
            {!weItem.currentlyWorking && ShowDateItem('End Date', weItem.endDate, true)}
            {ShowLineItem('Key Responsibilities', weItem.keyResponsibilities)}
            {ShowLineItem('Skills and Competencies', weItem.skillsAndCompetencies)}
            {ShowLineItem('Promoted', weItem.promoted ? 'Yes' : 'No')}
            {weItem.promoted && ShowLineItem('Promotion Details', weItem.promotionDetails)}
            {weItem.attachments && ShowLineItem('Certfication', weItem.attachments?.filename)}
          </View>
        );
      })}
    </View>
  );
};

const ShowEducation = (
  education?: Maybe<Education>,
  industryTraining?: Maybe<IndustryTraining[]>,
  course?: Course
): JSX.Element => {
  return (
    <View style={styles.section}>
      {ShowTitle('Education')}
      {CCIsVisible(course?.criteria?.educationCriteria?.secondary) && ShowSecondaryEducation(education?.secondary)}
      {CCIsVisible(course?.criteria?.educationCriteria?.further) &&
        ShowFurtherEducation(EducationTypes.FurtherEducation, education?.furtherEducation)}
      {CCIsVisible(course?.criteria?.educationCriteria?.thirdLevel) &&
        ShowFurtherEducation(EducationTypes.ThirdLevel, education?.thirdLevel)}
      {CCIsVisible(course?.criteria?.industryTrainingState) && ShowIndustryTraining(industryTraining)}
    </View>
  );
};

const ShowSecondaryEducation = (SL?: Maybe<SecondaryEducation[]>): JSX.Element => {
  return (
    <View>
      {SL?.length && ShowHeading('Secondary Education')}
      {SL?.map((SL) => {
        return (
          <View>
            {ShowLineItem('Institute', SL.institute ?? '')}
            {ShowLineItem('Country', SL.country)}
            {SL.subjects?.length && ShowSubHeading('Subjects')}
            {SL.subjects?.map((subject) => {
              return (
                <View>
                  {ShowItemHeading(subject.subject ?? '')}
                  {ShowLineItem('Level', subject.level)}
                  {ShowLineItem('grade', subject.grade?.toString())}
                  {ShowLineItem('Year', subject.yearExam)}
                  {subject.attachments && ShowLineItem('Certfication', subject.attachments?.filename)}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

const ShowFurtherEducation = (educationType: EducationTypes, FE?: Maybe<FurtherEducation[]>): JSX.Element => {
  const title = educationType === EducationTypes.FurtherEducation ? 'Further Education' : 'Third Level';

  return (
    <View>
      {(FE?.length ?? 0) > 0 && ShowHeading(title)}
      {FE?.map((feItem, index) => {
        return (
          <View style={{ marginBottom: 16 }}>
            {ShowSubHeading(`${index + 1}.`)}
            {ShowLineItem('Institute', feItem.institute ?? '')}
            {ShowLineItem('Country', feItem.country)}
            {ShowDateItem('Start Date', feItem.startDate, true)}
            {ShowDateItem('End Date', feItem.endDate, true)}
            {ShowLineItem('Qualification', feItem.qualification)}
            {ShowLineItem('Grade', feItem.grade?.toString())}
            {ShowLineItem('Full Time', feItem.fullTime ? 'Yes' : 'No')}
            {ShowLineItem('Course Info', feItem.courseInfo)}
            {feItem.attachments && ShowLineItem('Certfication', feItem.attachments?.filename)}
          </View>
        );
      })}
    </View>
  );
};

const ShowIndustryTraining = (IT?: Maybe<IndustryTraining[]>): JSX.Element => {
  return (
    <View>
      {(IT?.length ?? 0) > 0 && ShowHeading('Industry Training')}
      {IT?.map((itItem, index) => {
        return (
          <View style={{ marginBottom: 16 }}>
            {ShowSubHeading(`${index + 1}.`)}
            {ShowLineItem('Job Title', itItem.jobTitle)}
            {ShowLineItem('Industry Sector', itItem.NatureOfBusiness)}
            {ShowLineItem('Company Name', itItem.companyName)}
            {ShowLineItem('Location', itItem.companyAddress)}
            {ShowDateItem('Start Date', itItem.startDate, true)}
            {ShowDateItem('End Date', itItem.endDate, true)}
            {ShowLineItem('Work Releated Training', itItem.workRelatedTraining)}
            {ShowLineItem('Training Events', itItem.trainingEvents)}
          </View>
        );
      })}
    </View>
  );
};

const ShowSkillsAndCompetencies = (SC?: Maybe<SkillsCompetencies[]>, course?: Course): JSX.Element => {
  return (
    <View style={styles.section}>
      {ShowTitle('Skills and Competencies')}
      {SC?.filter((skillEntry) =>
        course?.criteria?.scCriteria?.find((item) => item.title?.toLowerCase() === skillEntry.title.toLowerCase())
      ).map((scItem) => {
        return (
          <View>
            {ShowLineItem(scItem.title, scItem.description)}
            {scItem.attachments && ShowLineItem('Certfication', scItem.attachments?.filename)}
          </View>
        );
      })}
    </View>
  );
};

const ShowExemptions = (user?: User, course?: Course): JSX.Element | undefined => {
  if (user?.application?.courseId === course?._id && user?.application?.exemptionApplication) {
    return (
      <View style={styles.section}>
        {ShowTitle('Exemptions')}
        {user?.application?.exemptions?.map((exemption) => {
          return (
            <Fragment>
              {ShowHeading(getModuleTitle(exemption.moduleId, user, course))}
              {ShowLineItem('Exemption sought', exemptionLookup(exemption.exemptionType, exemptionMapping))}
              {ShowSubHeading('Justification for exemption')}
              {exemption.exemptionReasons?.map((item) => {
                return (
                  <Fragment>
                    <View style={{ marginBottom: 16 }}>
                      {ShowLineItem('Basis for exemption', exemptionLookup(item.type, exemptionBasis))}
                      {item.type !== 'PCL' && ShowLineItem('Experience', item.experience)}
                      {ShowLineItem('Certification', item.attachments?.filename)}
                    </View>
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </View>
    );
  } else {
    return;
  }
};

const getModuleTitle = (moduleId: string, user?: User, course?: Course): string => {
  if (user?.application?.exemptions && user?.application?.exemptions?.length > 0) {
    const targetModule = course?.modules?.filter((item) => moduleId === item._id);
    return `Module: ${targetModule?.map((item) => item.name?.toString()) as unknown as string}`;
  } else {
    return 'Selected module';
  }
};

const ShowHeading = (heading: string): JSX.Element => {
  return (
    <View style={styles.heading}>
      <Text>{heading}</Text>
    </View>
  );
};

const ShowSubHeading = (subHeading: string): JSX.Element => {
  return (
    <View style={styles.subHeading}>
      <Text>{subHeading}</Text>
    </View>
  );
};

const ShowItemHeading = (itemHeading: string): JSX.Element => {
  return (
    <View style={styles.itemHeading}>
      <Text>{itemHeading}</Text>
    </View>
  );
};

const ShowTitle = (title: string): JSX.Element => {
  return (
    <View style={styles.title}>
      <Text>{title}</Text>
    </View>
  );
};

const ShowLineItem = (label: string, value?: Maybe<string>): JSX.Element => {
  return (
    <View style={styles.showLineItem}>
      <Text style={styles.showLineItemLabel}>{label}</Text>
      <Text style={styles.showLineItemValue}>{value}</Text>
    </View>
  );
};

const ShowDateItem = (label: string, value?: Maybe<string>, monthYearFormat?: boolean): JSX.Element => {
  const dateFormatter = (date?: Date | string | null): string | undefined => {
    const dateUnformatted = date?.toString();
    const n = dateUnformatted?.indexOf('T');

    let dateFormatted = dateUnformatted?.substring(0, n !== -1 ? n : dateUnformatted.length);
    dateFormatted = dateFormatted?.split('-').reverse().join('-');

    return dateFormatted;
  };

  const formattedDate = dateFormatter(value);
  console.log(monthYearFormat);
  return (
    <View style={styles.showLineItem}>
      <Text style={styles.showLineItemLabel}>{label}</Text>
      <Text>{value && <DateFormatter date={value} monthYearFormat={monthYearFormat} />}</Text>
      {/* <Text style={styles.showLineItemValue}>{formattedDate}</Text> */}
    </View>
  );
};
