import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// import Crest from 'images/logo/wit-crest.png';
import Logo from 'images/logo/setu-white.png';

import { constants } from 'services/constants';
import VerifyRPForm from './VerifyRPForm';
import { useParams } from 'react-router-dom';

const VerifyResetPassword: React.FC = () => {
  const { token } = useParams();
  console.log('I am working fine.');
  const [message, setMessage] = useState('');

  const closeModal = (): void => {
    const fp = document.getElementById('fp_modal');

    if (fp !== null) {
      if (fp.classList.contains('show-modal')) {
        fp.classList.remove('show-modal');
      }
    }
  };

  const onSuccess = (resultMessage: string): void => {
    console.log('on success working.');
    console.log(resultMessage);
    setMessage(resultMessage);
  };

  return (
    <div id="fp_modal" className="modal">
      <div className="modal-popover">
        <div className="modal-login-header">
          {/* <img src={Crest} alt="Waterford IT crest" /> */}
          <img src={Logo} alt="SETU logo" />

          <h2>Recognition of Prior Learning</h2>
        </div>
        <div className="modal-login-form-area">
          <div className="close-modal" onClick={(): void => closeModal()}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {message ? (
            <div className="modal-content">
              <p className="response success-response">{message}</p>
            </div>
          ) : (
            <div className="modal-content">
              <p className="modal-login-welcome">Reset Password</p>
              <p>Please reset your password</p>
              <VerifyRPForm
                url={constants.APPLICANT_VERIFY_FORGOT_PASSWORD_URL}
                onSuccess={onSuccess}
                token={token ?? ''}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyResetPassword;
