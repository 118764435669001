import { Route, Navigate, Routes } from 'react-router-dom';

import { IUserStateDetail } from '../Navigation/DashboardHeader';

import SuperAdminHome from './Sidemenu/SuperAdminHome';
import { CommonRoutes } from 'pages/Navigation/CommonRoutes';
import { IsAuthorized } from 'services/PrivateRoute';

export const SuperAdminRoutes: React.FC<IUserStateDetail> = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/superadmin" />} />
        <Route
          path="/superadmin/*"
          element={
            <IsAuthorized {...props}>
              <SuperAdminHome {...props} />
            </IsAuthorized>
          }
        />
        {CommonRoutes(props)}
      </Routes>
    </>
  );
};
