import React, { Fragment, useContext } from 'react';

import { ReviewSegmentPersonal } from 'pages/Review/ReviewSegmentPersonal';
import { UserCtx } from 'services/getCtx';

export const PersonalInfo: React.FC = () => {
  const [user] = useContext(UserCtx);

  return (
    <Fragment>
      <div className="panel panel-form">
        {user?.personalDetails && <ReviewSegmentPersonal personalData={user.personalDetails} />}
      </div>
    </Fragment>
  );
};
