import { User } from 'graphql/globalTypes';
import { Link } from 'react-router-dom';

interface IViewApplications {
  setApplicant: (targetApplicant: User) => void;
  targetApplicant: User;
}

export const ApplicationLink: React.FC<IViewApplications> = (props) => {
  return (
    <div>
      <Link className={'link'} to={`/admin/application-review`}>
        <button
          key={props.targetApplicant._id}
          data-testid={`view${props.targetApplicant._id}`}
          className="btn btn-sm btn-secondary"
          type="button"
          onClick={(): void => props.setApplicant(props.targetApplicant)}
        >
          <span>View</span>
        </button>
      </Link>
    </div>
  );
};
