import React, { Fragment } from 'react';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { Exemption } from 'graphql/globalTypes';

export interface IExemptionTypeSelection {
  onSelectChange: (type: string) => void;
  exemptionDetail?: Exemption;
  index: number;
}

const ExemptionTypeSelection: React.FC<IExemptionTypeSelection> = (props) => {
  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { value } = event.currentTarget;

    console.log('ExemptionTypeSelection');
    console.log(value);
    props.onSelectChange(value);
  };

  const values: string[] = ['moduleonly', 'gradableonly'];
  const valuesDescription = new Map([
    [values[0], 'Module Exemption'],
    [values[1], 'Gradable Exemption'],
  ]);

  const labelText = 'Select the type of exemption you are seeking for this module. ';

  return (
    <Fragment>
      <FormSelect
        name="exemptionType"
        uniqueName={`ex-typ-${props.index}`}
        label={labelText}
        values={values}
        valuesDescription={valuesDescription}
        fullwidth={true}
        onDataChange={onSelectChange}
        selectedValue={props.exemptionDetail?.exemptionType}
      />
    </Fragment>
  );
};

export { ExemptionTypeSelection };
