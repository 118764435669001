import React, { useState, useEffect, Fragment } from 'react';

import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import FormInput from 'components/FormPrimitives/FormInput';
import { IndustryTraining } from 'graphql/globalTypes';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormsDatePickerComponent } from 'components/datepickerComponent';

interface IFormProps {
  index: number;
  job: IndustryTraining;
  filename?: string | null;
  onChange: (index: number, sectionDetail: IndustryTraining) => void;
  onDelete: (index: number, sectionDetail: IndustryTraining) => void;
}

const IndustryTrainingForm: React.FC<IFormProps> = (props) => {
  const [sectionDetail, setSectionDetail] = useState<IndustryTraining>(props.job);

  useEffect(() => {
    console.log('IndustryTrainingForm: useEffect: props.job', props.job);
    setSectionDetail(props.job);
  }, [props.job]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = value;
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  const onTextAreaChange: FormTextAreaChange = (value, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newReason: any = Object.assign({}, sectionDetail);
    newReason[index as string] = value;
    setSectionDetail(newReason);
    props.onChange(props.index, newReason);
  };

  const onDeleteConfirm = (): void => {
    // delete clicked.
    //console.log('delete button clicked.');
    //console.log('following is going to be deleted.');
    //console.log(sectionDetail);
    props.onDelete(props.index, props.job);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal.${props.index}`);

    /* istanbul ignore next */
    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const DateCallback = (name: string, value: Date | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = value;
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  return (
    <Fragment>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={`IndustryTraining -> Item ${props.index + 1}`}
        key={'del-ititem' + props.index}
        index={props.index}
        modalId={`delete_confirm_modal.${props.index}`}
      />
      <form className="experience-container">
        {sectionDetail && (
          <div className="delete-item-button">
            <button
              key={'del' + sectionDetail.id}
              onClick={handleDeleteClick}
              className={'btn-sm btn-delete'}
              type="button"
            >
              Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
            </button>
          </div>
        )}
        <div className="break"></div>
        <FormInput
          name="jobTitle"
          uniqueName={`jobTitle-it-${props.job.id}`}
          label="Job title"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.jobTitle}
        />
        <FormInput
          name="NatureOfBusiness"
          uniqueName={`NatureOfBusiness-it-${props.job.id}`}
          label="Industry sector"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.NatureOfBusiness}
        />
        <FormInput
          name="companyName"
          uniqueName={`companyName-it-${props.job.id}`}
          label="Company name"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.companyName}
        />
        <FormInput
          name="companyAddress"
          uniqueName={`companyAddress-it-${props.job.id}`}
          label="Location"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.companyAddress}
        />
        <FormsDatePickerComponent
          name="startDate"
          uniqueName={`startDate-we-${props.job.id}`}
          label="Start date"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.startDate}
          fullwidth={true}
        />
        <FormsDatePickerComponent
          name="endDate"
          uniqueName={`endDate-we-${props.job.id}`}
          label="End date"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.endDate}
          fullwidth={true}
        />
        <div className="break"></div>
        <FormTextArea
          name="workRelatedTraining"
          uniqueName={`workRelatedTraining-it-${props.job.id}`}
          label="examples of all work related training"
          onDataChange={onTextAreaChange}
          value={sectionDetail.workRelatedTraining}
          tooltip=""
        />
        <FormTextArea
          name="trainingEvents"
          uniqueName={`trainingEvents-it-${props.job.id}`}
          label="Training events including conferences and webinars"
          onDataChange={onTextAreaChange}
          value={sectionDetail.trainingEvents}
          tooltip=""
        />
        <div className="break"></div>
      </form>
    </Fragment>
  );
};

export default IndustryTrainingForm;
