import { ApplicationStatus } from 'graphql/globalTypes';
import { FormInputChangeParam } from './FormPrimitives/FormInput';

export const FilterDropdown = new Map<string, string>([
  [ApplicationStatus.Submitted, 'Submitted for review'],
  [ApplicationStatus.Reviewing, 'Under review'],
  [ApplicationStatus.Moreinfo, 'More information requested'],
  [ApplicationStatus.Recommendaccept, 'Recommended for acceptance'],
  [ApplicationStatus.Accepted, 'Accepted'],
  [ApplicationStatus.Refused, 'Rejected'],
  [ApplicationStatus.Closed, 'Closed'],
  [ApplicationStatus.Registrarapproval, 'Registry approval'],
]);

export const FilterReverseLookup = new Map<string, ApplicationStatus>([
  ['submitted', ApplicationStatus.Submitted],
  ['reviewing', ApplicationStatus.Reviewing],
  ['moreinfo', ApplicationStatus.Moreinfo],
  ['recommendaccept', ApplicationStatus.Recommendaccept],
  ['accepted', ApplicationStatus.Accepted],
  ['refused', ApplicationStatus.Refused],
  ['closed', ApplicationStatus.Closed],
  ['registrarapproval', ApplicationStatus.Registrarapproval],
]);

interface iFilterBarComponentProps {
  currentFilter?: ApplicationStatus;
  onStatusFilterSelect: (applicationStatus?: ApplicationStatus) => void;
}

export const StatusFilterComponent: React.FC<iFilterBarComponentProps> = (props) => {
  const onStatusFilterSelect = (event: FormInputChangeParam): void => {
    const selectedFilterStatus = event.target.value;
    const f = FilterReverseLookup.get(selectedFilterStatus);
    props.onStatusFilterSelect(f);
  };

  return (
    <>
      <div className="filter-dropdown">
        <div className="filter-content">
          <label> STATUS </label>
          <select
            className="select-css"
            aria-labelledby="filter-dropdown"
            id="filterstatus"
            name="filterstatus"
            onChange={onStatusFilterSelect}
            value={props.currentFilter}
          >
            <option value="none">{'None'}</option>
            <option value="submitted">{FilterDropdown.get(ApplicationStatus.Submitted)}</option>
            <option value="reviewing">{FilterDropdown.get(ApplicationStatus.Reviewing)}</option>
            <option value="moreinfo">{FilterDropdown.get(ApplicationStatus.Moreinfo)}</option>
            <option value="recommendaccept">{FilterDropdown.get(ApplicationStatus.Recommendaccept)}</option>
            <option value="accepted">{FilterDropdown.get(ApplicationStatus.Accepted)}</option>
            <option value="refused">{FilterDropdown.get(ApplicationStatus.Refused)}</option>
            <option value="closed">{FilterDropdown.get(ApplicationStatus.Closed)}</option>
            <option value="registrarapproval">{FilterDropdown.get(ApplicationStatus.Registrarapproval)}</option>
          </select>
        </div>
      </div>
    </>
  );
};
