import React, { Fragment } from 'react';
import AboutData from 'data/about-html.json';

type AboutDataType = {
  about_rpl: string;
  rpl_for_entry: string;
  rpl_for_exemptions: string;
  faqs: string;
};

const aboutPayload: AboutDataType = AboutData;

const openTab = (e: React.MouseEvent, tabName = 'about_rpl'): void | undefined => {
  const tablinks = document.getElementsByClassName('tablinks');
  let i;
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(' active', '');
    const tabContent = document.getElementsByClassName('tab-content'); //HTMLCollectionOf<Element> TS seems to force type the returned array items so using 'any' as a workaround

    console.log('tabContent is ', tabContent[i] ?? '');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tabContent[i] as any).style.display = 'none';
  }

  const getTab: HTMLElement | null = document.getElementById(tabName);
  console.log('getting ' + tabName);
  if (getTab !== null) {
    getTab.style.display = 'block';
  }
  e.currentTarget.className += ' active';
};

const ContentAbout: React.FC = () => {
  return (
    <Fragment>
      <section id="content-area">
        <div className="content-area">
          <div className="content-header-subnav">
            <nav className="content-subnav">
              <ul>
                <li>
                  <button
                    className="tablinks active"
                    onClick={(event: React.MouseEvent): void => openTab(event, 'about_rpl')}
                  >
                    About RPL
                  </button>
                </li>
                <li>
                  <button
                    className="tablinks"
                    onClick={(event: React.MouseEvent): void => openTab(event, 'rpl_for_entry')}
                  >
                    RPL for entry
                  </button>
                </li>
                <li>
                  <button
                    className="tablinks"
                    onClick={(event: React.MouseEvent): void => openTab(event, 'rpl_for_exemptions')}
                  >
                    RPL for exemptions
                  </button>
                </li>
                <li>
                  <button className="tablinks" onClick={(event: React.MouseEvent): void => openTab(event, 'faqs')}>
                    FAQs
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="content-body">
            <div
              id="about_rpl"
              className="tab-content show-content"
              dangerouslySetInnerHTML={{ __html: aboutPayload.about_rpl }}
            ></div>
            <div
              id="rpl_for_entry"
              className="tab-content"
              dangerouslySetInnerHTML={{ __html: aboutPayload.rpl_for_entry }}
            ></div>
            <div
              id="rpl_for_exemptions"
              className="tab-content"
              dangerouslySetInnerHTML={{ __html: aboutPayload.rpl_for_exemptions }}
            ></div>
            <div id="faqs" className="tab-content" dangerouslySetInnerHTML={{ __html: aboutPayload.faqs }}></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export { ContentAbout };
