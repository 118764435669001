import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const SUBJECT_FIELDS = gql`
  fragment SubjectFields on Subject {
    attachments {
      ...SolleAttachmentFields
    }
    id
    subject
    grade
    level
    yearExam
  }
  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const SECONDARY_EDUCATION_FIELDS = gql`
  fragment SecondaryEducationFields on SecondaryEducation {
    id
    country
    institute
    subjects {
      ...SubjectFields
    }
  }

  ${SUBJECT_FIELDS}
`;

export const ADD_SECONDARY_EDUCATION = gql`
  mutation AddSecondaryEducation($secondary: SecondaryEducationInput!) {
    addSecondaryEducation(secondary: $secondary) {
      education {
        secondary {
          ...SecondaryEducationFields
        }
      }
    }
  }
  ${SECONDARY_EDUCATION_FIELDS}
`;

export const UPDATE_SECONDARY_EDUCATION = gql`
  mutation UpdateSecondaryEducation($secondary: SecondaryEducationInput!, $secondaryId: String!) {
    updateSecondaryEducation(secondary: $secondary, id: $secondaryId) {
      education {
        secondary {
          ...SecondaryEducationFields
        }
      }
    }
  }
  ${SECONDARY_EDUCATION_FIELDS}
`;

export const REMOVE_SECONDARY_EDUCATION = gql`
  mutation RemoveSecondaryEducation($secondaryId: String!) {
    removeSecondaryEducation(id: $secondaryId) {
      education {
        secondary {
          ...SecondaryEducationFields
        }
      }
    }
  }
  ${SECONDARY_EDUCATION_FIELDS}
`;
