import React from 'react';
import Tooltip from '../Tooltip';
import { Maybe } from 'graphql/globalTypes';

interface IFormInputProps {
  label: string;
  value?: string | undefined | null | number;
  name: string;
  uniqueName?: string;
  type: string;
  fullwidth: boolean;
  checked?: Maybe<boolean>;
  disabled?: boolean;
  tooltipenabled?: boolean;
  tooltip?: string;
  onDataChange: FormInputChange;
  step?: string;
  placeholder?: string;
}

export type FormInputChangeParam = React.ChangeEvent<
  HTMLInputElement & HTMLSelectElement & IFormInputProps & { files: File[] }
>;
export type FormInputChange = (event: FormInputChangeParam) => void;

const FormInput: React.FC<IFormInputProps> = (props) => {
  let content;
  switch (props.type) {
    case 'checkbox': {
      content = (
        <div className="form-group">
          <label htmlFor={props.uniqueName ?? props.name}>
            <input
              name={props.name}
              type={props.type}
              id={props.uniqueName ?? props.name}
              defaultChecked={props.checked ?? undefined}
              onChange={props.onDataChange}
            />
            {props.label}
          </label>
        </div>
      );

      break;
    }

    default: {
      content = (
        <div className="form-group">
          <label htmlFor={props.uniqueName ?? props.name}>
            {props.label}
            {props.tooltipenabled ? <Tooltip tooltip={props.tooltip ?? props.placeholder} /> : ''}
          </label>
          <input
            defaultValue={props.value ?? ''}
            id={props.uniqueName ?? props.name}
            name={props.name}
            className={'application' + (props.fullwidth ? ' full-width-form-field' : ' form-field')}
            type={props.type}
            disabled={props.disabled}
            onChange={props.onDataChange}
            step={props.step}
            placeholder={props.placeholder}
          />
        </div>
      );

      break;
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};

export default FormInput;
