import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const FURTHER_EDUCATION_FIELDS = gql`
  fragment FurtherEducationFields on FurtherEducation {
    id
    courseTitle
    country
    institute
    startDate
    endDate
    qualification
    courseInfo
    fullTime
    grade
    attachments {
      ...SolleAttachmentFields
    }
  }
  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const ADD_FURTHER_EDUCATION = gql`
  mutation AddFurtherEducation($furtherEducation: FurtherEducationInput!) {
    addFurtherEducation(furtherEducation: $furtherEducation) {
      education {
        furtherEducation {
          ...FurtherEducationFields
        }
      }
    }
  }
  ${FURTHER_EDUCATION_FIELDS}
`;

export const UPDATE_FURTHER_EDUCATION = gql`
  mutation UpdateFurtherEducation($furtherEducation: FurtherEducationInput!, $furtherEducationId: String!) {
    updateFurtherEducation(furtherEducation: $furtherEducation, id: $furtherEducationId) {
      education {
        furtherEducation {
          ...FurtherEducationFields
        }
      }
    }
  }
  ${FURTHER_EDUCATION_FIELDS}
`;

export const REMOVE_FURTHER_EDUCATION = gql`
  mutation RemoveFurtherEducation($furtherEducationId: String!) {
    removeFurtherEducation(id: $furtherEducationId) {
      education {
        furtherEducation {
          ...FurtherEducationFields
        }
      }
    }
  }
  ${FURTHER_EDUCATION_FIELDS}
`;
