import React, { useEffect, useState } from 'react';
import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import { AttachmentTypes, ExemptionReason, MutationCopyWeCertsToExemptionReasonArgs } from 'graphql/globalTypes';
import { FormAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { DeleteConfirmModal } from 'pages/Applicant/PersonalDetails/DeleteConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { ExemptionWESelection } from './ExemptionWESelection';
import { IReasonCLSelection } from './OPCLform';
import { useMutation } from '@apollo/client';
import { COPY_WORKEXPERIENCE_CERTIFICATE } from 'graphql/queries/copycertificate';
import { promptToolTip } from 'services/constants';

const PELform: React.FC<IReasonCLSelection> = (props) => {
  const [sectionDetail, setSectionDetail] = useState<ExemptionReason>(props.job);

  const [copyWECert, { error: CopyWECertError }] = useMutation<MutationCopyWeCertsToExemptionReasonArgs>(
    COPY_WORKEXPERIENCE_CERTIFICATE
  );

  useEffect(() => {
    setSectionDetail(props.job);
  }, [props.job, props.filename]);

  const onTextAreaChange: FormTextAreaChange = (value, index) => {
    console.log(index);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newReason: any = Object.assign({}, sectionDetail);
    newReason[index as string] = value;
    setSectionDetail(newReason);
    props.onChange(props.index, newReason);
  };

  const onDeleteConfirm = (): void => {
    console.log('delete button called.');
    props.onDeleteExemptionReason(props.moduleId, props.job.id);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal_pelform.${props.index}.${props.moduleId}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const onWESelect = async (data: string, workExperienceId: string): Promise<void> => {
    const expTextArea = document.getElementById(
      `experience-pel-${props.parentIndex}${props.index}`
    ) as HTMLTextAreaElement;

    try {
      await copyWECert({
        variables: {
          workExperienceId,
          reasonId: sectionDetail.id,
          moduleId: props.moduleId,
        },
      });
    } catch (e) {
      console.log(`Error copying the work experience certificate ${e}`);
    }
    expTextArea.value = data;
    onTextAreaChange(data, 'experience');
  };

  const labelText =
    'Add a description of this Prior Experiential Learning entry. This can be based on any prior professional or other experience. Show how it contributes to your meeting the learning outcomes of the module. Be sure to attach any relevant certification.';

  return (
    <div className="form-learning-type-area">
      {sectionDetail && (
        <div className="delete-item-button">
          <button
            key={'del' + sectionDetail.id}
            onClick={handleDeleteClick}
            className={'btn-sm btn-delete'}
            type="button"
          >
            Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
          </button>
        </div>
      )}
      <div className="break" />
      <h4 className="form-learning-type-head">Prior Experiential Learning</h4>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={'Prior Experiential Learning'}
        index={props.index}
        modalId={`delete_confirm_modal_pelform.${props.index}.${props.moduleId}`}
      />
      <ExemptionWESelection onWESelect={onWESelect} index={props.index} user={props.user} />
      <div className="break" />
      <FormTextArea
        name="experience"
        uniqueName={`experience-pel-${props.parentIndex}${props.index}`}
        label={labelText}
        onDataChange={onTextAreaChange}
        value={props.job.experience}
      />
      <div className="break" />
      <FormAttachmentsSelect
        name="attachment"
        uniqueName={`attachment-pl-${props.index}${props.parentIndex}}`}
        label="Upload Certification"
        attachmentsData={{
          attachmentType: AttachmentTypes.ExemptionReason,
          idOne: props.moduleId,
          idTwo: props.job.id,
        }}
        onAttachmentsSelect={props.onAttachmentSelect}
        tooltip={promptToolTip}
      />
      <div className="filename">{props.filename}</div>
      <div className="break"></div>
      {CopyWECertError && (
        <div className="response error-response">Error copy work experience certificate: {CopyWECertError}</div>
      )}
    </div>
  );
};

export { PELform };
