import React from 'react';
import Data from 'data/site-data.json';
import { DataMarketing } from '../content/Content';

interface IHeroProps {
  isLoggedIn?: boolean;
  isAdmin: boolean;
  target: string;
}

const Hero: React.FC<IHeroProps> = (props) => {
  const payload: DataMarketing = Data.Marketing;
  const pageName = props.target;
  const header: string = payload[pageName].heroHead;
  const subheader: string = payload[pageName].heroSub;

  return (
    <div>
      <section id="jumbo">
        <div
          className={props.isLoggedIn ? 'jumbo-container-subdued jumbo-logged-in' : 'jumbo-container-subdued'}
          style={{ maxHeight: pageName === 'contact' || pageName === 'privacy' || pageName === 'cookies' ? 240 : 400 }}
        >
          <div className={props.isLoggedIn ? 'jumbo-text-logged-in' : 'jumbo-text'}>
            <h1>{header}</h1>
            <p>{subheader}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
