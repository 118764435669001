import React, { useContext, useState } from 'react';
import UpdateApplicationStatus from './UpdateApplicationStatus';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import { Link } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { PDFDocument } from 'pages/Pdf/PDFDocument';
import { CourseCtx, UserCtx } from 'services/getCtx';
import { useMutation } from '@apollo/client';
import { SUBMIT_TO_REGISTRAR_FOR_REVIEW } from 'graphql/queries/submittoregistry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faUpload, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SubmitToRegistrarForReviewMutation, SubmitToRegistrarForReviewMutationVariables } from 'graphql/globalTypes';
import FlashMessageSuccess from 'components/FlashMessageSuccess';
import { constants } from 'services/constants';

const ReviewerControls: React.FC<IUserStateDetail> = (props) => {
  const [user] = useContext(UserCtx);
  const [course] = useContext(CourseCtx);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmittedToRegistrar, setHasSubmittedToRegistrar] = useState(false);

  const [SubmitToRegistry, { error: SubmitToError }] = useMutation<
    SubmitToRegistrarForReviewMutation,
    SubmitToRegistrarForReviewMutationVariables
  >(SUBMIT_TO_REGISTRAR_FOR_REVIEW);
  const submitToRegistry = async (): Promise<void> => {
    setIsLoading(true);
    setHasSubmittedToRegistrar(false);
    try {
      const blobData = await pdf(<PDFDocument user={user} course={course} />).toBlob();
      const output = `${user.personalDetails.firstName}_${user.personalDetails.lastName}.pdf`;
      const pdfCV = new File([blobData], output, { type: blobData.type });

      await SubmitToRegistry({
        variables: {
          applicantEmail: user.email,
          pdfCV,
        },
      });

      setHasSubmittedToRegistrar(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div id="review_status_controls" className="review-status-controls">
      <div className="btn-group">
        {hasSubmittedToRegistrar && (
          <FlashMessageSuccess
            message={'The application has been sent to registrar for review.'}
            timeoutSecond={5000}
          />
        )}
        <span id="pdf_export_button">Export all information</span>
        <Link to="/admin/SavePDF">
          <button
            className="btn btn-square btn-secondary"
            type="button"
            name="pdf-export-button"
            aria-labelledby="pdf_export_button"
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </button>
        </Link>
      </div>
      {!constants.FF_HideSubmitToRegistrar && (
        <div className="btn-group">
          {!isLoading ? (
            <>
              <span id="pdf_save_to_file_button">Submit to Registry for review</span>
              <button
                className="btn btn-square btn-secondary"
                type="button"
                name="pdf-export-button"
                aria-labelledby="pdf_export_button"
                onClick={submitToRegistry}
              >
                <FontAwesomeIcon icon={faUpload} />
              </button>
            </>
          ) : (
            <FontAwesomeIcon icon={faCircleNotch} className="spinner" />
          )}
        </div>
      )}
      <UpdateApplicationStatus {...props} hasSubmittedToRegistrar={hasSubmittedToRegistrar} />

      {SubmitToError && <div className="response error-response">Error submitting to registrar: {SubmitToError}</div>}
    </div>
  );
};

export default ReviewerControls;
