import React, { Fragment, useContext } from 'react';

import { ReviewSegmentExemptions } from 'pages/Review/ReviewSegmentExemptions';
import { UserCtx, CourseCtx } from 'services/getCtx';
import { Course, User } from 'graphql/globalTypes';

export const Exemptions: React.FC = () => {
  const [user] = useContext(UserCtx);
  const [course] = useContext(CourseCtx);

  console.log('Exemption :: User Detail');
  console.log(user);
  return (
    <Fragment>
      <div className="panel panel-form">
        <div className="review-section">{displayExemptions(user, course)}</div>
      </div>
    </Fragment>
  );
};

const displayExemptions = (user: User | null | undefined, course: Course) => {
  if (user?.application && user?.application.courseId === course._id) {
    if (user?.application.exemptions) {
      return (
        <ReviewSegmentExemptions
          user={user}
          data={user?.application.exemptions}
          moduleData={course.modules}
          applicationId={user.application._id.toString()}
          isAdmin={true}
        />
      );
    } else {
      return;
    }
  } else {
    return;
  }
};
