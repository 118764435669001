import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import VideoImage from 'images/video-image.jpg';

import WorkExperienceForm from './Forms/WorkExperienceForm';

import { REMOVE_WORK_EXPERIENCE, REPLACE_ALL_WORK_EXPERIENCE } from 'graphql/queries/workexperience';
import { GET_USER } from 'graphql/queries/users';
import {
  WorkExperience,
  GetUserQuery,
  ReplaceAllWorkExperienceMutation,
  ReplaceAllWorkExperienceMutationVariables,
  WorkExperienceInput,
  RemoveWorkExperienceMutation,
  RemoveWorkExperienceMutationVariables,
} from 'graphql/globalTypes';

import { UpdateAttachments } from 'services/attachments';
import { constants } from 'services/constants';
import { AnalyticsSendEvent } from 'services/googleAnalytics';

import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import FlashMessageError from 'components/FlashMessageError';
import { fileSizeExceeded } from 'services/utils';

let saveTimeoutId: number | undefined;

const WEComponent: React.FC = () => {
  const [fileuploadExceedLimitMessage, setFileuploadExceedLimitMessage] = useState<Error>();
  const {
    loading: GetWEloading,
    error: GetWEError,
    data: WEData,
    refetch: RefetchWEData,
  } = useQuery<GetUserQuery>(GET_USER);
  const [ReplaceAllWorkExperience, { error: PutWEError }] = useMutation<
    ReplaceAllWorkExperienceMutation,
    ReplaceAllWorkExperienceMutationVariables
  >(REPLACE_ALL_WORK_EXPERIENCE);

  const [removeWE, { error: RemoveError }] = useMutation<
    RemoveWorkExperienceMutation,
    RemoveWorkExperienceMutationVariables
  >(REMOVE_WORK_EXPERIENCE);

  const [workExperiences, setWorkExperiences] = useState<WorkExperience[]>([]);

  useEffect(() => {
    if (WEData?.getUser?.workExperience) {
      console.log('useEffect:GetWEData', WEData.getUser.workExperience);

      setWorkExperiences([...WEData.getUser.workExperience]);
    }
  }, [WEData]);

  const onWEChange = async (index: number, sectionDetail: WorkExperience, addNew = false): Promise<void> => {
    console.log('sectionDetail', sectionDetail);
    if (saveTimeoutId) {
      window.clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    let newExperiences: WorkExperience[];

    if (addNew) {
      newExperiences = [...workExperiences, sectionDetail];
    } else {
      newExperiences = [...workExperiences];
      newExperiences[index] = Object.assign({}, sectionDetail);
    }

    setWorkExperiences(newExperiences);

    saveTimeoutId = window.setTimeout(async () => {
      const saveVar: WorkExperienceInput[] = newExperiences.map((weItem) => {
        const { __typename, id, attachments, ...rest } = weItem;

        return rest;
      });

      try {
        await ReplaceAllWorkExperience({ variables: { workExperience: saveVar } });
      } catch (error) {
        console.log('Error Saving Work Experience: ', error);
      } finally {
        await RefetchWEData();
      }
    }, constants.TIME_TO_SAVE);
  };

  const onResetFlashMessage = (): void => {
    setFileuploadExceedLimitMessage(undefined);
  };
  const onAttachmentSelect: funcAttachmentsSelect = async (attachmentData, attachments) => {
    const validateError = fileSizeExceeded(attachments);

    if (validateError) {
      setFileuploadExceedLimitMessage(validateError);
    } else {
      try {
        await UpdateAttachments(attachmentData, attachments);
        await RefetchWEData();
      } catch (e) {
        setFileuploadExceedLimitMessage(e);
      }
    }
  };

  const onPlusBtnClick = (e: React.FormEvent): void => {
    e.preventDefault();

    onWEChange(-1, { id: 'newId' }, true);

    AnalyticsSendEvent({
      category: 'User - Applicant',
      action: 'Add more details',
      label: 'Work experience',
      transport: 'beacon',
    });
  };

  if (GetWEloading) {
    return <div className="response success-response">Loading Data</div>;
  }

  const onDelete = async (index: number, sectionDetail: WorkExperience): Promise<void> => {
    // delete clicked.
    console.log('delete button clicked.');
    console.log('following is going to be deleted.');
    console.log(workExperiences[index]);
    try {
      await removeWE({
        variables: {
          workExperienceId: sectionDetail.id,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      await RefetchWEData();
    }
  };

  return (
    <div>
      <div className="panel panel-form">
        <div className="panel-jumbo">
          <img src={VideoImage} alt="placeholder for jumbo intro video" />
        </div>
        <div className="application-form-area">
          <h2>Work Experience</h2>
          {fileuploadExceedLimitMessage && (
            <FlashMessageError error={fileuploadExceedLimitMessage} onFinish={onResetFlashMessage} />
          )}
          {workExperiences.map((weItem, index) => {
            return (
              <>
                <WorkExperienceForm
                  // add filename to the key as it doesn't update when the rest of the info updates
                  // key={weItem.id + weItem.attachments?.filename}
                  key={`weItem_${index}`}
                  index={index}
                  job={weItem}
                  filename={weItem.attachments?.filename}
                  onChange={onWEChange}
                  onAttachmentSelect={onAttachmentSelect}
                  onDelete={onDelete}
                />
              </>
            );
          })}
          <div className="add-button-container">
            <button type="submit" data-testid="addMore" onClick={onPlusBtnClick}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </div>
          {RemoveError && <div className="response error-response">Error delete Data: {RemoveError.message}</div>}
          {GetWEError && <div className="response error-response">Error getting Data: {GetWEError.message}</div>}
          {PutWEError && <div className="response error-response">Error putting Data: {PutWEError.message}</div>}
        </div>
      </div>
    </div>
  );
};

export default WEComponent;
