interface ICourseSwitcher {
  courseSwitcher: () => void;
}

export const CourseSwitcher: React.FC<ICourseSwitcher> = (props) => {
  return (
    <div className="switch-courses">
      <button
        data-testid="courseswitcher"
        className="btn btn-md btn-secondary"
        type="button"
        onClick={(): void => props.courseSwitcher()}
      >
        <span>Switch Awards</span>
      </button>
    </div>
  );
};
