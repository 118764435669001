import { useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import {
  AdminUser,
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables,
  GetAllAdminUsersQueryVariables,
} from 'graphql/globalTypes';
import { DELETE_ADMIN_USER, GET_ALL_ADMIN_USERS } from 'graphql/queries/adminusers';
import AdminUserListing from '../Home/AdminUserListing';
import { EditAdminUser } from '../Home/EditAdminUser';
import { CreateAdminUser } from '../Home/CreateAdminUser';
import { Route, Routes, useParams } from 'react-router-dom';
import { AdminCreateActionButton } from '../Home/AdminCreateActionButton';
import { DeleteConfirmModal } from 'pages/Applicant/PersonalDetails/DeleteConfirmModal';
import { AdminUserCtx } from 'services/getCtx';

interface IQueryUserList {
  getAllAdminUsers: AdminUser[];
}

const SuperAdminHome: React.FC = () => {
  const [, setAdminUser] = useContext(AdminUserCtx);
  const [deleteAdminUser, setDeleteAdminUser] = useState<AdminUser>();
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>();
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const {
    data: adminUserList,
    error: userListError,
    refetch: adminUserRefetch,
    loading: userListLoading,
  } = useQuery<IQueryUserList, GetAllAdminUsersQueryVariables>(GET_ALL_ADMIN_USERS);

  const [deleAdmin, { error: errorDeletingAdmin }] = useMutation<
    DeleteAdminUserMutation,
    DeleteAdminUserMutationVariables
  >(DELETE_ADMIN_USER);

  useEffect(() => {
    if (adminUserList?.getAllAdminUsers) {
      console.log('useEffect:getAllAdminUsers ---> ', adminUserList.getAllAdminUsers);
      setAdminUsers(adminUserList.getAllAdminUsers);
    }
  }, [adminUserList]);

  if (userListLoading) {
    return <div data-testid={'superadminloading'}>Loading ...</div>;
  }

  const onCreateButtonPressed = () => {
    console.log('i am create selected admin');
    setCreating(true);
  };

  const onEditButtonPressed = (targetAdmin: AdminUser) => {
    console.log('i am update selected admin');
    setUpdating(true);
    setAdminUser(targetAdmin);
  };

  const onCreatingAdminUser = async (success: boolean): Promise<void> => {
    console.log('user has been created successfully, refresh the parent list of admin user');
    console.log(success);
    try {
      await adminUserRefetch();
    } catch (err) {
      console.log('CreateAdminUser.tsx:onCreatingAdminUser:Error: ', err);
    }
    setCreating(false);
  };

  const onUpdatingAdminUser = async (success: boolean): Promise<void> => {
    console.log('user has been update successfully, refresh the parent list of admin user');
    console.log(success);
    try {
      await adminUserRefetch();
    } catch (err) {
      console.log('CreateAdminUser.tsx:onCreatingAdminUser:Error: ', err);
    }

    setUpdating(false);
  };

  const onDeleteAdminConfirm = async (): Promise<void> => {
    console.log('confirm deletion.');
    try {
      await deleAdmin({ variables: { targetEmail: deleteAdminUser?.email ?? '' } });
    } catch (err) {
      console.log('SuperAdminHome.tsx:onDeleteAdminConfirm:Error: ', err);
    } finally {
      await adminUserRefetch();
    }
  };

  const onDeleteAction = (targetAdmin: AdminUser): void => {
    console.log(targetAdmin);
    const confirmSubmission = document.getElementById('delete_confirm_modal_admin');

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }

    console.log('i am delete selected admin');

    setDeleteAdminUser(targetAdmin);
  };

  return (
    <>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteAdminConfirm}
        record={`${deleteAdminUser?.personalDetails?.firstName + ' ' + deleteAdminUser?.personalDetails?.lastName}`}
        index={0}
        modalId={'delete_confirm_modal_admin'}
      />
      {errorDeletingAdmin && (
        <div className="response error-response">Error deleting an admin user: {errorDeletingAdmin.message}</div>
      )}
      {userListError && (
        <div className="response error-response">Error getting admin users: {userListError.message}</div>
      )}

      <div className="dashboard-home-area">
        <div className="grid">
          <div className="col-6" data-testid="stale-applications">
            <div className="panel p-1">
              <div className="applicant-saved-applications">
                {adminUsers && (
                  <AdminUserListing
                    Title={'Manage Users'}
                    admins={adminUsers}
                    onSetAdminUser={onEditButtonPressed}
                    onDeleteAction={onDeleteAction}
                  />
                )}
              </div>
              <div>
                <AdminCreateActionButton onCreateButtonPressed={onCreateButtonPressed} />
              </div>
              <div className="Buffer-Box"></div>
            </div>
          </div>

          <div className="col-6">
            <div className="panel p-1">
              <div className="applicant-saved-applications">
                <Routes>
                  <Route
                    path="editadmin/:adminId"
                    element={<EditAdminUser onUpdatingAdminUser={onUpdatingAdminUser} updating={updating} />}
                  />
                  <Route
                    path="createadminuser/"
                    element={<CreateAdminUser onCreatingAdminUser={onCreatingAdminUser} creating={creating} />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminHome;
