import { ApolloClient } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from '@apollo/client/cache';
import { persistCache } from 'apollo3-cache-persist';

import fragment_matcher from 'graphql/fragment.schema';

import { createBrowserHistory } from 'history';

import { constants } from 'services/constants';
import { getBearerToken } from 'services/validation';
import { setupAttachments } from 'services/attachments';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (operation) {
    console.log(`[Apollo Error]: Operation: ${JSON.stringify(operation, undefined, 2)}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // handle errors differently based on its error code
      console.log('[Apollo Error]: Extensions Code.', extensions);
      switch (message) {
        case 'Access denied! You need to be authorized to perform this action!':
          console.log(`[Apollo Error]: Message: ${message}`);
          createBrowserHistory().push('/');
          break;
        default:
          console.log(`[Apollo Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          break;
      }
    });
  }

  if (networkError) {
    console.log(`[Apollo Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry

    return;
  }
});

const link = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getBearerToken(),
    },
  };
})
  .concat(errorLink)
  .concat(
    createUploadLink({
      uri: () => constants.GRAPHQL_URL,
      // removed as it was not set correctly accoring to keep-alive spec so
      // can't have been doing anything useful???
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Keep-Alive
      // https://datatracker.ietf.org/doc/html/draft-thomson-hybi-http-timeout-03#section-2
      // headers: {
      //   'keep-alive': true,
      // },
    })
  );

export const setupApolloClient = async (): Promise<ApolloClient<unknown>> => {
  const cache = new InMemoryCache(fragment_matcher);

  try {
    await persistCache({ cache, storage: window.localStorage as never });
  } catch (err) {
    console.error('Error setting up Apollo Persist Cache: ', err);
  }

  const client = new ApolloClient({ cache, link });

  setupAttachments(client);

  return client;
};
