import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

interface IDropdownProps {
  dropdownVisible: boolean;
  isAdmin?: boolean;
}

const UserMenu: React.FC<IDropdownProps> = (props) => {
  const urlSwitch = (checker: boolean | undefined): string => {
    return checker ? '/admin' : '/home';
  };

  return (
    <Fragment>
      {props.dropdownVisible && (
        <div className="dropdown-content" data-testid="user_menu">
          <nav>
            <ul>
              {/* <li>Switch RPL application</li>
              <li>Security</li>
              <li>Help</li>
              <li>
                <Link to={urlSwitch(props.isAdmin)}> My Dashboard</Link>
              </li> */}
              <li>
                <Link to={urlSwitch(props.isAdmin)}> My Dashboard</Link>
              </li>
              <li>
                <Link to="/logout">Sign Out</Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </Fragment>
  );
};

export { UserMenu };
