import React from 'react';
import MarketingFooter from './MarketingFooter';
import Hero from 'components/hero/Hero';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';

const Privacy: React.FC<IUserStateDetail> = (props) => {
  return (
    <div>
      <Hero {...props} {...{ target: 'privacy' }} />
      <section id="content-area">
        <div className="content-area">
          <div className="content-header">
            <h2>General Privacy Statement</h2>
          </div>
          <div className="content-body">
            <h3>General statement</h3>
            <p>
              This privacy statement relates to the practices of the website{' '}
              <a href="https://rpl4lifelonglearning.ie/">rpl4lifelonglearning.ie</a>. This statement may be updated
              frequently as our technologies change. The information provided here is constantly being reviewed in line
              with the Data Protection Acts, 1988 and 2003 and with EU General Data Protection Regulation 2018.
            </p>
            <p>This webpage was last updated on 26 July 2021.</p>
            <h3>Who are we?</h3>
            <p>
              This South East Technological University (SETU) website collates information, which is used by SETU to
              assess the applicants’ prior learning for the purposes of entry to an academic award and/or to award
              exemptions for credits within an academic award. Further information about SETU can be located{' '}
              <a href=" https://www.wit.ie/about_wit/documents_and_policies/privacy_statement">at this website</a>.
            </p>
            <h3>What information do we collect? </h3>
            <p>
              This website collects information provided by applicants that is used by SETU to assess the applicants’
              prior learning for the purposes of entry to an academic award and/or to award exemptions for credits
              within an academic award.
            </p>
            <p>
              The following information is collected from applicants:
              <ul>
                <li>personal profile data e.g. name, contact details etc.</li>
                <li>details relating to the applicants’ educational and employment history</li>
                <li>verification documentation (e.g. academic transcripts, award certificates, etc).</li>
              </ul>
            </p>
            <h3>How we use the information that is collected on this website? </h3>
            <p>
              Information provided by applicants is used by SETU to assess the applicants’ prior learning for the
              purposes of entry to an academic award and/or to award exemptions for credits within an academic award.
              The information is shared within SETU with the relevant academic School(s) and the Office of the Registrar
              as part of the assessment process.
            </p>
            <h3>How long will the information be stored?</h3>
            <p>
              All information will be stored in a secure password encrypted facility. Users right to privacy is
              respected and any personal information provided by the applicant will be treated with the highest
              standards of security and confidentiality, strictly in accordance with the Data Protection Act 2018 and
              General Data Protection Regulation (GDPR). All users, both applicants and SETU staff, are required to log
              in with their username and password to access the application part of the website. Further details on SETU
              GDPR policy{' '}
              <a href="https://www.wit.ie/about_wit/documents_and_policies/staff_data_protection">are available here</a>
              .
            </p>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </div>
  );
};

export default Privacy;
