import React, { useState, useEffect } from 'react';
import { AnalyticsSendEvent } from 'services/googleAnalytics';

import axios, { AxiosResponse } from 'axios';
import { ILogin } from './LoginModal';
import { validatePassword, IValidatePassword, confirmMatch } from 'services/validatePassword';

const RegisterForm: React.FC<ILogin> = (props) => {
  const defaultValidPassword: IValidatePassword = {
    validUppercase: true,
    validLowercase: true,
    validNumber: true,
    validLength: true,
    validMatch: true,
  };

  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [registered, setRegistered] = useState(false);
  const [jwtToken, setJwtToken] = useState('');
  const [error, setError] = useState(false);
  const [passwordValid, setPasswordValid] = useState<IValidatePassword>(defaultValidPassword);
  const [passwordValConfirmed, setPasswordValConfirmed] = useState(false);
  const [showPasswordMatchPrompt, setShowPasswordMatchPrompt] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  useEffect(() => {
    if (registered) {
      props.onSuccess(jwtToken);
    }
  }, [registered]);

  //
  const disableSubmitBtn = (): boolean =>
    !loading &&
    !showPasswordMatchPrompt &&
    passwordValid.validLength &&
    passwordValid.validUppercase &&
    passwordValid.validLowercase &&
    passwordValid.validNumber &&
    passwordValConfirmed;

  const handlePasswordInput = (): void => {
    const passwordValue: string | undefined | null = (document.getElementById('user_password') as HTMLInputElement)
      .value;
    const repeatPasswordValue: string | undefined | null = (
      document.getElementById('repeat_password') as HTMLInputElement
    ).value;

    setPassword(passwordValue);
    setPasswordValConfirmed(confirmMatch(passwordValue, repeatPasswordValue, defaultValidPassword));
    setPasswordValid(validatePassword(passwordValue, defaultValidPassword));
    passwordValue !== repeatPasswordValue ? setShowPasswordMatchPrompt(true) : setShowPasswordMatchPrompt(false);
  };

  const handleInputChange = (): void => {
    const passwordValue: string | undefined | null = (document.getElementById('user_password') as HTMLInputElement)
      .value;
    const repeatPasswordValue: string | undefined | null = (
      document.getElementById('repeat_password') as HTMLInputElement
    ).value;

    setPasswordValConfirmed(confirmMatch(passwordValue, repeatPasswordValue, defaultValidPassword));
    passwordValue !== repeatPasswordValue ? setShowPasswordMatchPrompt(true) : setShowPasswordMatchPrompt(false);
  };

  const onRegister = async (event: React.MouseEvent): Promise<void> => {
    setLoading(true);
    event.preventDefault();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await axios.post<unknown, AxiosResponse<any, any>>(
        props.url,
        {
          email: emailAddress,
          password: password,
          personalDetails: {
            firstName: firstName,
            lastName: surname,
          },
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      setJwtToken(data.jwtToken.token);
      setLoading(false);
      setRegistered(true);
      setError(false);

      AnalyticsSendEvent({
        category: 'User - Applicant',
        action: 'Created an Account',
        transport: 'beacon',
      });

      setFeedback('Thank you, you will receive an email shortly with further instructions');
      console.log(feedback);
    } catch (error) {
      setRegistered(false);
      setError(true);
      setFeedback(error.status);
      setLoading(false);
      console.log(error.body);
      setServerErrorMessage(error.response.data.message);
    }
  };

  return (
    <form className="registration-form">
      <label id="firstname" htmlFor="firstname">
        First Name
      </label>
      <input
        type="text"
        value={firstName}
        className="full-width-form-field"
        aria-describedby="firstname"
        pattern="[a-zA-Z]*"
        onChange={(e): void => setFirstName(e.target.value.replace(/[^a-zA-Z]/gi, ''))}
      />
      <label id="surname" htmlFor="surname">
        Surname
      </label>
      <input
        type="text"
        value={surname}
        className="full-width-form-field"
        aria-describedby="surname"
        onChange={(e): void => setSurname(e.target.value.replace(/[^a-zA-Z]/gi, ''))}
      />
      <label id="register_email" htmlFor="email">
        Email Address
      </label>
      <input
        type="email"
        className="full-width-form-field"
        aria-describedby="regsiter_email"
        onChange={(e): void => setEmailAddress(e.target.value)}
      />

      <label id="register_password" htmlFor="user_password">
        Password
      </label>
      <input
        id="user_password"
        type="password"
        name="password"
        className="full-width-form-field"
        aria-describedby="register_email"
        aria-labelledby="register_password"
        onChange={handlePasswordInput}
      />
      <label htmlFor="repeat_password">Repeat password</label>
      <input
        id="repeat_password"
        type="password"
        className="full-width-form-field"
        aria-describedby="register_email"
        aria-labelledby="repeat_password"
        onChange={handleInputChange}
      />
      {showPasswordMatchPrompt && (
        <div className="password-status">
          <p>Passwords do not match</p>
        </div>
      )}
      {!passwordValid.validLength && (
        <div className="password-status">
          <p>Should be between 8 and 64 characters long</p>
        </div>
      )}
      {!passwordValid.validUppercase && (
        <div className="password-status">
          <p>Must contain at least 1 uppercase character</p>
        </div>
      )}
      {!passwordValid.validLowercase && (
        <div className="password-status">
          <p>Must contain at least 1 lowercase character</p>
        </div>
      )}
      {!passwordValid.validNumber && (
        <div className="password-status">
          <p>Must contain at least 1 number</p>
        </div>
      )}
      {error && (
        <div className="response error-response">
          <p>{serverErrorMessage}</p>
        </div>
      )}
      <div className="modal-login-submit">
        <button
          id="signup_submit_btn"
          type="submit"
          className="btn btn-modal btn-secondary btn-full-width"
          onClick={onRegister}
          disabled={!disableSubmitBtn()}
        >
          Sign Up
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
