import { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Course, GetUsersByCourseQueryVariables, User } from 'graphql/globalTypes';
import { GET_USERS_BY_COURSE } from 'graphql/queries/users';
import { ManageCourses } from '../Home/Widget/ManageCourses';
import { StaleApplications } from '../Home/Widget/StaleApplications';
import { ExemptionApplications } from '../Home/Widget/ExemptionApplications';
import { AdvanceEntryApplications } from '../Home/Widget/AdvanceEntryApplications';
import CourseApplicationsOverview from '../Home/CourseApplicationsOverview';
import { useNavigate } from 'react-router-dom';
import { UserCtx } from 'services/getCtx';

interface IQueryUserList {
  getUsersByCourse: User[];
}

const AdminHome: React.FC = () => {
  const [, setUser] = useContext(UserCtx);
  const [currentCourse, setCurrentCourse] = useState<Course | undefined>(undefined);
  const [applicants, setApplicants] = useState<User[]>();

  const [getUsersByCourse, { data: userList, error: userListError, loading: userListLoading }] = useLazyQuery<
    IQueryUserList,
    GetUsersByCourseQueryVariables
  >(GET_USERS_BY_COURSE);

  /*
  //Notifications setup
  // Need to update to admin settings
  const {
    loading: GetSettingsloading,
    error: GetSettingsError,
    data: SettingsData,
    refetch: RefetchSettingsData,
    } = useQuery<GetUserSettingsQuery>(GET_NOTIFICATION_SETTINGS, { displayName: 'AdminEmailSettings' });

  const [setNotificationSettings, { error: PutSettingsError }] = useMutation<
    MutationSetNotificationSettingsArgs,
    SetNotificationSettingsMutationVariables
    >(SET_NOTIFICATION_SETTINGS);

  const [notification, setNotification] = useState<Notification>();

  useEffect(() => {
    if (SettingsData?.getUser?.notification) {
      console.log('useEffect:GetSettingsData', SettingsData.getUser.notification);

      setNotification(SettingsData.getUser.notification);
    }
    }, [SettingsData]);
    */

  useEffect(() => {
    if (userList?.getUsersByCourse) {
      console.log('useEffect:getUsersByCourse ---> ', userList.getUsersByCourse);

      setApplicants(userList.getUsersByCourse);
    }
  }, [userList]);

  const courseSelection = async (targetCourse?: Course): Promise<void> => {
    if (!targetCourse) return;

    setCurrentCourse(targetCourse);

    getUsersByCourse({ variables: { courseId: targetCourse._id.toString() } });
  };

  /*const onEmailSettingsChange = async (sectionDetail: Notification): Promise<void> => {
    console.log('sectionDetail', sectionDetail);

    setNotification(sectionDetail);

    const { __typename, ...rest } = sectionDetail;

    try {
      await setNotificationSettings({
        variables: { newMessage: rest.newMessage ?? false, statusChange: rest.statusChange ?? false },
      });
    } catch (error) {
      console.log('Error Saving Email notification settings: ', error);
    } finally {
      await RefetchSettingsData();
    }
    };*/

  const navigate = useNavigate();
  const onViewApplicationsPressed = (): void => {
    // push view applications navigation here.
    console.log('view application button pressed...');
    navigate('/admin/applications-view/' + currentCourse?._id);
  };

  return (
    <>
      {userListError && (
        <div className="response error-response">Error getting applications: {userListError.message}</div>
      )}

      <div className="dashboard-home-area">
        <div className="grid">
          <div className="col-6">
            <ManageCourses
              key={'-course-selection-admin-'}
              courseSelection={courseSelection}
              currentCourse={currentCourse}
              viewAllApplications={onViewApplicationsPressed}
            />
          </div>
          <div className="col-1">
            <div className="white-space"></div>
          </div>
          <div className="col-4">
            <CourseApplicationsOverview applicants={applicants} />
          </div>
          <div className="col-1">
            <div className="white-space"></div>
          </div>
          {/*
          <div className="col-4">
            <EmailNotificationSettings
              notification={notification}
              onEmailSettingsChange={onEmailSettingsChange}
              GetSettingsloading={GetSettingsloading}
              GetSettingsError={GetSettingsError}
              PutSettingsError={PutSettingsError}
            />
          </div>
            */}

          <StaleApplications onSelected={setUser} applicants={applicants} />
          <ExemptionApplications onSelected={setUser} applicants={applicants} />
          <AdvanceEntryApplications onSelected={setUser} applicants={applicants} />
        </div>
      </div>
    </>
  );
};

export default AdminHome;
