import React from 'react';
import FormInput from 'components/FormPrimitives/FormInput';
import { IModulesSelection } from './ModuleModal';

export interface ISemesterModuleSelection {
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedModules: IModulesSelection[];
  semester: string;
}

const SemesterWideModule: React.FC<ISemesterModuleSelection> = (props) => {
  return (
    <div className="module-schedule">
      <h2>Semester {props.semester}</h2>
      <table>
        <tbody>
          {props.selectedModules
            .filter((item) => {
              return item.module.semester === props.semester;
            })
            .map((item, index) => {
              if (item.module.exemptionAllowed) {
                console.log(`Index = ${item.module.semester}.${index}, hasSelected = ${item.hasSelected}`);
                return (
                  <tr>
                    <td className="module-name">
                      <FormInput
                        name={`${item.module._id}`}
                        key={`mdkey${index}${item.module.semester}-${item.module._id}`}
                        uniqueName={`md${item.module.semester}-${item.module._id}`}
                        label={item.module.title ?? ''}
                        type="checkbox"
                        fullwidth={true}
                        onDataChange={props.onCheckboxChange}
                        value={item.module._id.toString()}
                        checked={item.hasSelected}
                      />
                    </td>
                    <td className="pl-1">{item.module.credits} credits</td>
                  </tr>
                );
              } else {
                return (
                  <>
                    <p>Module details not available.</p>
                  </>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SemesterWideModule;
