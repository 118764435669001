import React, { Fragment, useContext } from 'react';

import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import { SkillsCompetencies, AttachmentTypes } from 'graphql/globalTypes';
import { FormAttachmentsSelect, funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { CourseCtx } from 'services/getCtx';
import { promptToolTip } from 'services/constants';

interface ISkillsAndCompetencies {
  skillsAndCompetencies: SkillsCompetencies[];
  onSCChange: FormTextAreaChange;
  onAttachmentsSelect: funcAttachmentsSelect;
}

const SkillsAndCompetanciesForm: React.FC<ISkillsAndCompetencies> = (props) => {
  const [course] = useContext(CourseCtx);

  return (
    <form className="skills-form">
      {props.skillsAndCompetencies.map((skill, index) => {
        const foundCriteria = course.criteria?.scCriteria?.filter(
          (criteria) => criteria.title?.toLowerCase() === skill.title.toLowerCase()
        )[0];

        return (
          foundCriteria && [
            <Fragment key={skill.title + skill.attachments?.filename}>
              <FormTextArea
                index={index}
                name={skill.title}
                label={skill.title}
                onDataChange={props.onSCChange}
                value={skill.description}
                tooltip={foundCriteria?.helperText ?? ''}
                placeholder={skill.title ? foundCriteria?.helperText ?? '' : ''}
              />

              <FormAttachmentsSelect
                name={`skillCompetencies${skill.title}`}
                label="Upload certification"
                attachmentsData={{ attachmentType: AttachmentTypes.SkillsAndCompetencies, idOne: skill.title }}
                onAttachmentsSelect={props.onAttachmentsSelect}
                tooltip={promptToolTip}
              />
              <div className="filename ml-1 mr-1 mb-1">{skill.attachments?.filename}</div>
              <div className="break"></div>
            </Fragment>,
          ]
        );
      })}
    </form>
  );
};

export default SkillsAndCompetanciesForm;
