import React, { useEffect, useState } from 'react';
import { ApplicationStatus } from 'graphql/globalTypes';
import { constants } from 'services/constants';
import { StatusDescription } from 'pages/Admin/application-review/UpdateApplicationStatus';

export interface IExemptionStatus {
  exemptionStatus?: ApplicationStatus;
}

const ExemptionStatus: React.FC<IExemptionStatus> = (props) => {
  const [exemptionStatus, setExemptionStatus] = useState<ApplicationStatus>(
    props.exemptionStatus ?? ApplicationStatus.Presubmission
  );

  useEffect(() => {
    setExemptionStatus(props.exemptionStatus ?? ApplicationStatus.Presubmission);
  }, [props.exemptionStatus]);

  const flashClass = {
    submitted: 'neutral-response',
    reviewing: 'notice-response',
    moreinfo: 'alert-response',
    accepted: 'success-response',
    refused: 'error-response',
    recommendaccept: 'neutral-response',
    recommendrefuse: 'neutral-response',
    closed: 'neutral-response',
    registrarapproval: 'neutral-response',
  };

  if (!constants.FF_SHOW_EXEMPTION_STATUS || exemptionStatus === ApplicationStatus.Presubmission) {
    return null;
  }

  return (
    <>
      <div className={`response ${flashClass[exemptionStatus]} exemption-status-flash`}>
        <span>Status: {StatusDescription.get(exemptionStatus)}</span>
      </div>
    </>
  );
};

export { ExemptionStatus };
