import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { MarketingView } from '../Marketing/MarketingView';
import { ApplicantView } from '../Applicant/ApplicantView';
import { AdminView } from '../Admin/AdminView';

import { CourseCtx, LoggedInStatusCtx, JwtCtx } from 'services/getCtx';
import { decodeToken, isAdminRole } from 'services/validation';

import { Roles } from 'graphql/globalTypes';
import { VerifyAccount } from 'pages/Navigation/VerifyAccount';
import { constants } from 'services/constants';
import { SuperAdminView } from 'pages/SuperAdmin/SuperAdminView';
import { Logout } from './Logout';

export enum LoggedInStatus {
  LoggedOut,
  LoggedIn,
  LoggingOut,
  LoggingIn,
}

// force rebuild
export const IsLoggedIn: React.FC = () => {
  const [jwtToken] = useContext(JwtCtx);
  const [course] = useContext(CourseCtx);
  const [loggedInStatus] = useContext(LoggedInStatusCtx);
  const location = useLocation();
  const navigate = useNavigate();
  const tokenValue = decodeToken(jwtToken);
  const [navigateTarget, setNavigateTarget] = useState('');

  useEffect(() => {
    if (navigateTarget?.length && !location.pathname.localeCompare(navigateTarget)) {
      navigate(navigateTarget);
    }
  }, [navigateTarget]);

  // const isLoggedIn = tokenValue.isValid;
  const userRole = tokenValue.decodedToken?.role;
  const courseId = course._id;
  const isVerifed = tokenValue.decodedToken?.isVerified;

  console.log('courseId', courseId);
  console.log('decodedToken: ', tokenValue.decodedToken);
  console.log('IsLoggedIn: ', loggedInStatus);
  console.log('userRole: ', userRole);

  if (loggedInStatus === LoggedInStatus.LoggingIn) {
    console.log('Logging In...');
    return <div>Logging In....</div>;
  }

  if (loggedInStatus === LoggedInStatus.LoggingOut) {
    console.log('Logging Out...');
    return <div>Logging Out....</div>;
  }

  if (
    constants.FF_ALLOW_VERIFIED_ONLY &&
    loggedInStatus === LoggedInStatus.LoggedIn &&
    tokenValue.isValid &&
    isVerifed === false
  ) {
    return <VerifyAccount isAdmin={isAdminRole(userRole)} isLoggedIn={true} />;
  }

  if (loggedInStatus === LoggedInStatus.LoggedIn && userRole === Roles.Superadmin) {
    if (!location.pathname.includes('admin')) {
      // setNavigateTarget('/');
    }
    return <SuperAdminView isLoggedIn={true} isAdmin={true} />;
  }

  if (loggedInStatus === LoggedInStatus.LoggedIn && isAdminRole(userRole)) {
    // if not on the correct path reset to root
    // this fixes BUG 310
    if (!location.pathname.includes('admin')) {
      //setNavigateTarget('/');
    }
    return <AdminView isLoggedIn={true} isAdmin={true} />;
  }

  if (courseId && loggedInStatus === LoggedInStatus.LoggedIn && userRole === Roles.Applicant) {
    // if not on the correct path reset to root
    // this fixes BUG 310
    if (!location.pathname.includes('personalinfo') && !location.pathname.localeCompare(navigateTarget)) {
      // setNavigateTarget('/');
      // navigate('/');
    }
    return <ApplicantView isLoggedIn={true} isAdmin={false} />;
  }

  // if (location.pathname.includes('personalinfo') || location.pathname.includes('admin')) {
  //   // setNavigateTarget('/');
  // }

  if (loggedInStatus === LoggedInStatus.LoggedIn && !tokenValue.isValid) {
    if (location.pathname.localeCompare('/logout') !== 0) {
      return <Logout />;
    }
  }

  return <MarketingView courseId={courseId} isLoggedIn={loggedInStatus === LoggedInStatus.LoggedIn} isAdmin={false} />;
};
