import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface IToolbarProps {
  destination: string;
  label?: string;
}

const DashboardTopToolbar: React.FC<IToolbarProps> = (props) => {
  const backButtonContent = (
    <Link to={props.destination}>
      <span className="review-file-icon">
        <FontAwesomeIcon icon={faChevronLeft} />
      </span>
      {props.label}
    </Link>
  );

  const content = <div className="dashboard-top-toolbar">{props.destination && backButtonContent}</div>;

  return <Fragment>{content}</Fragment>;
};

export { DashboardTopToolbar };
