import React from 'react';
import { FormInputChange } from './FormInput';

interface IInputProps {
  label: string;
  name: string;
  uniqueName?: string;
  values: string[];
  valuesDescription: Map<string, string>;
  fullwidth: boolean;
  selectedValue?: string | null;
  onDataChange: FormInputChange;
}

const FormSelect: React.FC<IInputProps> = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.uniqueName ?? props.name}>{props.label}</label>

      <select
        name={props.name}
        id={props.uniqueName ?? props.name}
        onChange={props.onDataChange}
        // value cannot be null
        value={props.selectedValue ?? undefined}
      >
        <option> -- select an option -- </option>
        {props.values.map((value) => (
          <option value={value} key={value}>
            {props.valuesDescription.get(value)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
