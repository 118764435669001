import { CSSProperties, useState, useEffect, useRef, RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ReviewBarMessaging from 'pages/Messaging/ReviewBarMessaging';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import { CSSTransition } from 'react-transition-group';

export interface IReviewBarProps extends IUserStateDetail {
  expanded?: boolean;
  breakPoint?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'; //in case need to support media queries
  parentRef?: RefObject<HTMLDivElement>;
}

const DashboardReviewBar: React.FC<IReviewBarProps> = (props) => {
  //console.assert(props.parentRef?.current !== null);
  const [sidebarState, setSidebarState] = useState(props.expanded);
  const sideBar = useRef<HTMLDivElement>(null);
  const reviewerContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sideBarContent();
  }, [sidebarState]);

  const setupWidth: string = props.expanded ? '25%' : '3rem';

  const sidebarStyles = {
    sizing: {
      width: setupWidth,
    } as CSSProperties,
  };

  const sidebarToggle = (): void => setSidebarState(!sidebarState);

  const handleClick = (): void => {
    resizeSidebar();
    sidebarToggle();
    sideBarContent();
  };

  const resizeSidebar = (): CSSProperties => {
    if (sideBar.current && props.parentRef?.current) {
      if (sidebarState) {
        sideBar.current.style.width = '3rem';
        props.parentRef.current.style.width = '100%';
        props.parentRef.current.style.paddingRight = '8rem';
      } else {
        sideBar.current.style.width = '25%';
        props.parentRef.current.style.width = '75%';
        props.parentRef.current.style.paddingRight = '5rem';
      }
    }
    return sidebarStyles.sizing;
  };

  const buttonIcon = (): JSX.Element => {
    if (sidebarState) {
      return <FontAwesomeIcon icon={faChevronRight} />;
    } else {
      return <FontAwesomeIcon icon={faChevronLeft} />;
    }
  };

  const sideBarContent = (): void => {
    if (reviewerContent && reviewerContent.current) {
      sidebarState
        ? (reviewerContent.current.style.display = 'block')
        : (reviewerContent.current.style.display = 'none');
    }
  };

  return (
    <div ref={sideBar} className="dashboard-sidebar-right" style={sidebarStyles.sizing}>
      <button data-testid="control_btn" type="button" onClick={handleClick} className="reviewbar-btn">
        {buttonIcon()}
      </button>
      <CSSTransition in={sidebarState} timeout={0} classNames="fade">
        <div
          data-testid="reviewer_content"
          id="reviewer_content"
          className="reviewer-sidebar-content"
          ref={reviewerContent}
        >
          <ReviewBarMessaging {...props} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default DashboardReviewBar;
