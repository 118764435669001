import { gql } from '@apollo/client';
import { APPLICATION_FIELDS } from './applications';
import { USER_FIELDS } from './users';

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on Notification {
    newMessage
    statusChange
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query GetUserSettings {
    getUser {
      ...UserFields
      application {
        ...ApplicationFields
      }
      notification {
        ...NotificationFields
      }
    }
  }
  ${USER_FIELDS}
  ${APPLICATION_FIELDS}
  ${NOTIFICATION_FIELDS}
`;

export const SET_NOTIFICATION_SETTINGS = gql`
  mutation SetNotificationSettings($newMessage: Boolean!, $statusChange: Boolean!) {
    setNotificationSettings(notification: { newMessage: $newMessage, statusChange: $statusChange }) {
      _id
      email
      notification {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`;
