//Enable indexing of lookup below by string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasKey<O extends object>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export type exemptionMappingType = {
  moduleonly: string;
  gradableonly: string;
};

export type exemptionBasisType = {
  PCL: string;
  PL: string;
  PWE: string;
};

const exemptionMapping = {
  moduleonly: 'Module exemption',
  gradableonly: 'Gradable exemption',
};

const exemptionBasis = {
  PCL: 'Recognised Prior Certified Learning',
  PL: 'Prior Certified Learning (not recognised as automatically equivalent)',
  PWE: 'Prior experiential learning',
};

const exemptionLookup = (
  exemptionInput: string | null | undefined,
  mapping: exemptionMappingType | exemptionBasisType
): string | null | undefined => {
  const exemption = exemptionInput as string;

  if (hasKey(mapping, exemption)) {
    return mapping[exemption];
  } else {
    return;
  }
};

export { exemptionMapping, exemptionBasis, exemptionLookup };
