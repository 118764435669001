import React, { useState, useRef, useEffect } from 'react';
import { ExemptionTypeSelection } from './ExemptionTypeSelection';
import { ApplicationStatus, Exemption, ExemptionReasonInput, Module, User } from 'graphql/globalTypes';
import { ExemptionReasons } from './ExemptionReasons';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DeleteConfirmModal } from 'pages/Applicant/PersonalDetails/DeleteConfirmModal';
import { ExemptionStatus } from './ExemptionStatus';

export interface IExemptionSelection {
  module: Module;
  onChange: (index: number, reason: Exemption, saveNow: boolean) => void;
  moduleId: string;
  index: number;
  exemption: Exemption;
  onAttachmentSelect: funcAttachmentsSelect;
  onDeleteExemptionReason: (moduleId: string, reasonId: string) => void;
  onDeleteExemption: (moduleId: string) => void;
  user: User;
}

const AccordionItem: React.FC<IExemptionSelection> = (props) => {
  const [exemptionDetail, setExemptionDetail] = useState<Exemption>(props.exemption);
  const [setActive, setActiveState] = useState(false);
  const [setHeight, setHeightState] = useState('0');
  const accordionContent = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setActiveState(!setActive);
    if (null !== accordionContent.current) {
      setHeightState(setActive ? '0' : `${accordionContent.current.scrollHeight}px`);
    }
  };

  useEffect(() => {
    setExemptionDetail(props.exemption);
  }, [props.exemption]);

  const onReasonsChange = (moduleId: string, exemptionReasons: ExemptionReasonInput[], saveNow = false): void => {
    console.log(moduleId);
    console.log(exemptionReasons);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newExempt: any = Object.assign({}, exemptionDetail);
    newExempt['exemptionReasons'] = exemptionReasons;

    setExemptionDetail(newExempt);
    props.onChange(props.index, newExempt, saveNow);
    if (null !== accordionContent.current) {
      setHeightState(`${accordionContent.current.scrollHeight + 650}px`);
    }
  };

  const onSelectExemptionTypeChange = (exemptionType: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newExempt: any = Object.assign({}, exemptionDetail);
    newExempt['exemptionType'] = exemptionType;
    setExemptionDetail(newExempt);
    props.onChange(props.index, newExempt, true);
  };

  const onDeleteConfirm = (): void => {
    console.log('delete button called.');
    props.onDeleteExemption(props.moduleId);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal_aitem.${props.index}.${props.moduleId}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  return (
    <div className="accordion-container" key={`${props.module.name} key`}>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={props.module.title ?? ''}
        index={props.index}
        modalId={`delete_confirm_modal_aitem.${props.index}.${props.moduleId}`}
      />
      <button
        className={`accordion ${setActive && 'active'}`}
        onClick={toggleAccordion}
        key={props.module.title}
        data-testid="accordion-toggle"
      >
        {props.module.title}
        <span className={`accordion-icon ${setActive && 'rotate'}`}>
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </button>
      <div className="accordion-panel" ref={accordionContent} style={{ maxHeight: `${setHeight}` }}>
        {props.children}
        <div className="grid">
          <div className="col-6">
            <ExemptionStatus exemptionStatus={exemptionDetail.exemptionStatus ?? ApplicationStatus.Presubmission} />
          </div>
          <div className="col-6">
            <div className="delete-item-button">
              <button
                key={'del.aitem' + props.moduleId}
                onClick={handleDeleteClick}
                className={'btn-sm btn-delete'}
                type="button"
              >
                Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
              </button>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <p key={`${props.module.title} body`}>{props.module.shortDescription}</p>
            <h4>Learning outcomes</h4>
            <p>
              In order to be approved for exemption you must demonstrate that you meet the following learning outcomes.
            </p>
            {props.module.learningOutcomes && formatLearningOutcomes(props.module.learningOutcomes as string)}
            <div className="secondary-container">
              <ExemptionTypeSelection
                onSelectChange={onSelectExemptionTypeChange}
                exemptionDetail={exemptionDetail}
                key={`er-sl-${props.index}`}
                index={props.index}
              />
              <ExemptionReasons
                key={`er-cp-${props.index}`}
                onReasonsChange={onReasonsChange}
                module={props.module}
                index={props.index}
                exemptionReasons={exemptionDetail.exemptionReasons ?? undefined}
                onAttachmentSelect={props.onAttachmentSelect}
                onDeleteExemptionReason={props.onDeleteExemptionReason}
                user={props.user}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccordionItem };

const formatLearningOutcomes = (learningOutcomes: string) => {
  const stringArray = learningOutcomes.split(/\d+\./);
  return (
    <>
      <p>{stringArray[0]}</p>
      <ol>
        {stringArray.map((str, i) => {
          if (i !== 0) {
            return <li key={`${i}_${str.substring(1, 10)}`}>{str}</li>;
          } else return;
        })}
      </ol>
    </>
  );
};
