import { gql } from '@apollo/client';

export const MESSAGE_FIELDS = gql`
  fragment MessageFields on Message {
    _id
    fromId {
      email
      name
      role
    }
    toId {
      email
      name
      role
    }
    applicationId
    replyToMessage
    date
    read
    section
    subsection
    body
    createdAt
    updatedAt
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($applicationId: String, $orderBy: OrderByInput, $pagination: PaginationInput) {
    getMessages(orderBy: $orderBy, applicationId: $applicationId, pagination: $pagination) {
      messages {
        ...MessageFields
      }
      totalMessages
    }
  }
  ${MESSAGE_FIELDS}
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($message: MessageInput!) {
    sendMessage(message: $message) {
      ...MessageFields
    }
  }

  ${MESSAGE_FIELDS}
`;

export const GET_NOTIFICATIONS_ADMIN = gql`
  query GetNotificationsForAdmin($orderBy: OrderByInput, $pagination: PaginationInput) {
    getNotifications(orderBy: $orderBy, pagination: $pagination) {
      messages {
        ...MessageFields
      }
      totalMessages
    }
  }
  ${MESSAGE_FIELDS}
`;

export const GET_NOTIFICATIONS_APPLICANT = gql`
  query GetNotificationsForApplicant($orderBy: OrderByInput, $pagination: PaginationInput) {
    getNotificationsForApplicant(orderBy: $orderBy, pagination: $pagination) {
      messages {
        ...MessageFields
      }
      totalMessages
    }
  }
  ${MESSAGE_FIELDS}
`;

export const SET_MESSAGE_READ = gql`
  mutation SetMessageRead($messageId: String!) {
    setMessageRead(messageId: $messageId) {
      ...MessageFields
    }
  }
  ${MESSAGE_FIELDS}
`;
