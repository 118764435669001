import React from 'react';
import { SolleAttachment, Maybe } from 'graphql/globalTypes';
import { decodeToken } from 'services/validation';

interface ISolleAttachment {
  attachment?: Maybe<SolleAttachment>;
  displayLink: boolean;
}

const Attachment: React.FC<ISolleAttachment> = (props) => {
  if (props?.attachment?.filepath && props.attachment?.filename && props.displayLink) {
    const { token } = decodeToken();

    const filenameWithToken = `${props.attachment.filepath}?token=${token}`;
    return (
      <div className="filename">
        <a href={filenameWithToken} target="_blank" rel="noopener noreferrer">
          {props.attachment.filename}
        </a>
      </div>
    );
  }

  return <div className="filename">{props?.attachment?.filename}</div>;
};

export default Attachment;
