import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { SolleAttachment, Maybe } from 'graphql/globalTypes';
import { decodeToken } from 'services/validation';

interface IDisplayAttachmentProps {
  label?: string;
  attachment?: Maybe<SolleAttachment>;
}

export const DisplayAttachment: React.FC<IDisplayAttachmentProps> = (props) => {
  const getAttachmentLink = (url?: Maybe<string>): string => `${url}?token=${decodeToken().token}`;

  return (
    <Fragment>
      <div>
        <label>{props.label}</label>
        <div className="review-row">
          <div className="review-file-icon">
            <FontAwesomeIcon icon={faFile} />
          </div>
          <a href={getAttachmentLink(props.attachment?.filepath)} target="_blank" rel="noopener noreferrer">
            {props.attachment?.filename}
          </a>
        </div>
      </div>
    </Fragment>
  );
};
