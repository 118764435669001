import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Course } from 'graphql/globalTypes';

interface ICourseProps {
  course: Course;
  isAdmin?: boolean;
  hideReadMore?: boolean;
  onCourse: (course: Course) => void;
}

const CourseCard: React.FC<ICourseProps> = (props) => {
  const [imgSrc, setImgSrc] = useState(process.env.PUBLIC_URL + props.course.imageUrl);

  return (
    <div className="flex-grid-col">
      <div className="flex-grid-item-wrapper">
        <div className="flex-grid-item-container">
          <div className="course-card">
            <img
              className="card-img-top"
              src={imgSrc}
              onError={(): void => setImgSrc(process.env.PUBLIC_URL + `/img/courses/default.jpg`)}
              alt=" happy student taking the course"
            />
            <div className="card-head">
              <h3>{props.course.title}</h3>
            </div>
            <div className="card-body">
              <p>{props.course.shortDescription}</p>
              <p className="card-read-more">
                {!props.hideReadMore && <Link to={`/courses/${props.course._id}`}>Read more</Link>}
              </p>
              <div className="card-actions">
                <button
                  data-testid="courseactionbutton"
                  id={props.course._id}
                  className="btn btn-sm btn-secondary"
                  type="button"
                  onClick={(): void => props.onCourse(props.course)}
                >
                  {!props.isAdmin && <span>Apply</span>}
                  {props.isAdmin && <span>View applications</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
