import React from 'react';

interface ReviewSubmitButtonProps {
  handleClick: () => void;
  // disabled:() =>  boolean | undefined;
  // applicationUpdated: boolean | undefined;
  statusMessage: () => boolean | undefined;
}

export const ReviewSubmitButton: React.FC<ReviewSubmitButtonProps> = (props) => {
  const buttonText = props.statusMessage() ? 'Re-submit RPL Application' : 'Submit RPL Application';
  const buttonStyle = props.statusMessage() ? 'btn btn-sm' : 'btn btn-sm btn-secondary';

  return (
    <div className="modal-login-submit">
      <button type="submit" className={buttonStyle} onClick={props.handleClick}>
        {buttonText}
      </button>
    </div>
  );
};
