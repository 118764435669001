import { ApplicationStatusLabel } from 'components/ApplicationStatusLabel';
import { ApplicationWithCourse } from 'pages/Applicant/Sidemenu/ManageApplications';
import { DashboardWidgetFooter } from './DashboardWidgetFooter';

interface IActiveApplicationCard {
  application?: ApplicationWithCourse;
}

export const ActiveApplicationCard: React.FC<IActiveApplicationCard> = (props) => {
  if (!props.application) {
    return null;
  }

  return (
    <div className="active-application-card h-auto">
      <img
        src={props.application.course.imageUrl ?? ''}
        alt={props.application.course.imageUrl ?? ''}
        className="card-img-top"
      />
      <div className="m-1">
        <p className="text-bold">{props.application.course.title}</p>
        <p>
          This {props.application.course.credits} credit award runs for {props.application.course.Length} months,
          starting from {props.application.course.activeFrom}.
        </p>
        <div className="application-status-position">
          Status: <ApplicationStatusLabel statusNow={props.application.currentStatus ?? 'not available'} />
        </div>
      </div>
      <DashboardWidgetFooter linkText={'Edit Application'} linkPath={'/personalinfo'} />
    </div>
  );
};
