import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Course } from 'graphql/globalTypes';
//import PickCourse from 'pages/Course/PickCourse';

interface ICourseProps extends RouteComponentProps {
  course: Course;
}

const CourseHero: React.FC<ICourseProps> = ({ course }) => {
  const { _id, title, shortDescription } = course;

  return (
    <div>
      <section id="jumbo">
        <div className="jumbo-container">
          <div className="jumbo-text">
            <h1>{title}</h1>
            {/* <p>{shortDescription}</p> */}
            {/* <PickCourse courseId={_id} /> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CourseHero;
