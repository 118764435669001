import React from 'react';

export enum MenuType {
  PersonalData,
  WorkExperience,
  Education,
  Skills,
  Exemption,
  Review,
}
export interface MenuItem {
  menuType: MenuType;
  title: string;
  comp: React.FC<{ menuItems: MenuItem[] }>;
  icon: string;
  link: string;
  progressValue?: string;
  blurb: string;
}
