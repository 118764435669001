import React, { useState, useEffect, useContext } from 'react';

import { Route, Routes, useNavigate } from 'react-router-dom';

import { PanelItem } from './PanelItem';
import { IUserStateDetail } from './DashboardHeader';
import { MenuItem, MenuType } from './menuItems';

import ApplicationStatusNotice from 'pages/Notifications/ApplicationStatusNotice';
import { ApplicationStatusProgress } from 'pages/Notifications/ApplicationStatusProgress';

import { Course, GetUserQuery, GetUserQueryVariables, User } from 'graphql/globalTypes';

import { CCIsVisible } from 'services/utils';
import { CourseCtx, ShowExemptionsCtx } from 'services/getCtx';
import ReviewerControls from 'pages/Admin/application-review/ReviewerControls';
import { constants } from 'services/constants';
import { useQuery } from '@apollo/client';
import { GET_USER } from 'graphql/queries/users';
import { IsAuthorized } from 'services/PrivateRoute';

interface ApplicantDataNavigateProps extends IUserStateDetail {
  defaultSelection?: number;
  menuItems: MenuItem[];
}

export const ApplicantDataNavigate: React.FC<ApplicantDataNavigateProps> = (props) => {
  const [showExemptions, setShowExemptions] = useContext(ShowExemptionsCtx);
  const [activeMenuItem, setActiveMenuItem] = useState(props.menuItems[props.defaultSelection ?? 0].title);
  const [currentMenu, setCurrentMenu] = useState(props.menuItems);
  const [course] = useContext(CourseCtx);
  const navigate = useNavigate();

  const shouldSkip = (): boolean => {
    if (props.isAdmin) {
      return true;
    }

    if (Object.keys(showExemptions).length === 0 && showExemptions.constructor === Object) {
      return false;
    }

    if (showExemptions === undefined) {
      return false;
    }

    return true;
  };

  console.log('shouldSupportExemption = ');
  console.log(Object.keys(showExemptions).length);
  console.log(showExemptions);
  console.log(shouldSkip());

  const {
    data: applicant,
    loading: applicantLoading,
    error: applicantError,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, { skip: shouldSkip() });

  const { data: applicationStatusWatch } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER);

  useEffect(() => {
    if (applicant?.getUser?.application && !applicantLoading && !applicantError) {
      console.log('IN useEffect::ApplicantDataNav');
      console.log(applicant);
      const current = applicant.getUser as User;
      if (current.application) {
        setShowExemptions(current.application.exemptionApplication ?? false);
      }
    }
    const defaultSelection = props.defaultSelection ?? 0;
    console.log('show exemptions value after if statement: ');
    console.log(showExemptions);
    setCurrentMenu(filterMenuItems(props.menuItems, showExemptions, course));
    navigate(props.menuItems[defaultSelection].link);
    //history.go(1);
    setActiveMenuItem(props.menuItems[defaultSelection].title);
  }, [course, props.menuItems, props.defaultSelection, showExemptions, applicant]);

  const onMenuItemClick = (title: string): void => {
    console.log('menu click: ', title);
    setActiveMenuItem(title);
  };

  if (applicantLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {applicantError && <div className="response error-response">Error getting users: {applicantError.message}</div>}
      <div className="col-4 panel">
        {currentMenu.map((menuitem) => (
          <PanelItem
            key={menuitem.title}
            active={menuitem.title === activeMenuItem ? true : false}
            onMenuItemClick={onMenuItemClick}
            menuitem={menuitem}
          />
        ))}
        {props.isAdmin && <ReviewerControls {...props} />}

        <ApplicationStatusNotice {...props} />
      </div>
      <div className="col-8">
        {!props.isAdmin && (
          <ApplicationStatusProgress
            statusNow={applicationStatusWatch?.getUser?.application?.currentStatus?.toString() ?? undefined}
          />
        )}
        <Routes>
          {currentMenu.map((menuitem) => {
            const Component = menuitem.comp;
            return (
              <Route
                key={menuitem.link}
                path={menuitem.link}
                element={
                  <IsAuthorized {...props}>
                    <Component {...props} />
                  </IsAuthorized>
                }
              />
            );
          })}
        </Routes>
      </div>
    </>
  );
};

const filterMenuItems = (menuItems: MenuItem[], showExemptions: boolean, course?: Course): MenuItem[] => {
  const isMIVisible = (menuItem: MenuItem): boolean => {
    switch (menuItem.menuType) {
      case MenuType.WorkExperience:
        return CCIsVisible(course?.criteria?.workExperienceState);

      case MenuType.Education:
        return CCIsVisible(course?.criteria?.educationState);

      case MenuType.Skills:
        return CCIsVisible(course?.criteria?.scState);

      case MenuType.Exemption:
        console.log(showExemptions);
        return constants.FF_EXEMPTIONS_ENABLED;

      default:
        return true;
    }
  };

  return menuItems.filter((menuItem) => isMIVisible(menuItem));
};
