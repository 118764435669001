import { gql } from '@apollo/client';

export const INDUSTRY_TRAINING_FIELDS = gql`
  fragment IndustryTrainingFields on IndustryTraining {
    id
    jobTitle
    companyName
    NatureOfBusiness
    companyAddress
    startDate
    endDate
    workRelatedTraining
    trainingEvents
  }
`;

export const GET_INDUSTRY_TRAINING = gql`
  query GetIndustryTraining {
    getUser {
      industryTraining {
        ...IndustryTrainingFields
      }
    }
  }

  ${INDUSTRY_TRAINING_FIELDS}
`;

export const REPLACE_ALL_INDUSTRY_TRAINING = gql`
  mutation ReplaceAllIndustryTraining($industryTraining: [IndustryTrainingInput!]!) {
    replaceAllIndustryTraining(industryTraining: $industryTraining) {
      _id
      email
      industryTraining {
        ...IndustryTrainingFields
      }
    }
  }

  ${INDUSTRY_TRAINING_FIELDS}
`;

export const ADD_INDUSTRY_TRAINING = gql`
  mutation AddIndustryTraining($industryTraining: [IndustryTrainingInput!]!) {
    addIndustryTraining(industryTraining: $industryTraining) {
      industryTraining {
        ...IndustryTrainingFields
      }
    }
  }
  ${INDUSTRY_TRAINING_FIELDS}
`;

export const UPDATE_INDUSTRY_TRAINING = gql`
  mutation UpdateIndustryTraining($industryTraining: IndustryTrainingInput!, $industryTrainingId: String!) {
    updateIndustryTraining(industryTraining: $industryTraining, id: $industryTrainingId) {
      industryTraining {
        ...IndustryTrainingFields
      }
    }
  }
  ${INDUSTRY_TRAINING_FIELDS}
`;

export const REMOVE_INDUSTRY_TRAINING = gql`
  mutation removeIndustryTraining($industryTrainingId: String!) {
    removeIndustryTraining(id: $industryTrainingId) {
      industryTraining {
        ...IndustryTrainingFields
      }
    }
  }
  ${INDUSTRY_TRAINING_FIELDS}
`;
