import { ApolloClient } from '@apollo/client';
import {
  MutationUpdateAttachmentsArgs,
  AttachmentInput,
  SolleAttachment,
  UpdateAttachmentsMutation,
} from 'graphql/globalTypes';
import { UPDATE_ATTACHMENTS } from 'graphql/queries/users';
import { AnalyticsSendEvent } from 'services/googleAnalytics';

let apolloClient: ApolloClient<unknown> | undefined;

export type funcFileInput = (attachmentsData: AttachmentInput, attachments: File[]) => void;

export const setupAttachments = (client: ApolloClient<unknown>): void => {
  apolloClient = client;
};

export const UpdateAttachments = async (
  attachmentsData: AttachmentInput,
  attachments: File[]
): Promise<SolleAttachment[] | null | undefined> => {
  if (!apolloClient) {
    throw new Error('UploadAttachments:Error: apolloClient Not Defined');
  }

  const retVal = await apolloClient.mutate<UpdateAttachmentsMutation, MutationUpdateAttachmentsArgs>({
    mutation: UPDATE_ATTACHMENTS,
    variables: { attachmentsData, attachments },
  });

  AnalyticsSendEvent({
    category: 'User - Applicant',
    action: 'File upload',
    label: attachmentsData.attachmentType,
    transport: 'beacon',
  });

  return retVal.data?.updateAttachments;
};
