import React, { Fragment, useContext } from 'react';

import { ReviewSegmentFurtherEducation } from 'pages/Review/ReviewSegmentFurtherEducation';
import { ReviewSegmentSecondLevel } from 'pages/Review/ReviewSegmentSecondLevel';
import { ReviewSegmentIndustryTraining } from 'pages/Review/ReviewSegmentIndustryTraining';
import { UserCtx, CourseCtx } from 'services/getCtx';
import { CCIsVisible } from 'services/utils';

export const Education: React.FC = () => {
  const [user] = useContext(UserCtx);
  const [course] = useContext(CourseCtx);

  return (
    <Fragment>
      <div className="panel panel-form">
        {CCIsVisible(course.criteria?.educationCriteria?.secondary) && (
          <div className="review-section">
            <h3>Education and Training: Second level</h3>
            <div>
              {user?.education?.secondary?.map((educationEntry, index) => (
                <ReviewSegmentSecondLevel secondLevel={educationEntry} count={index + 1} />
              ))}
            </div>
          </div>
        )}
        {CCIsVisible(course.criteria?.educationCriteria?.further) && (
          <div className="review-section">
            <h3>Education and Training: Further Education</h3>
            <div>
              {user?.education?.furtherEducation?.map((educationEntry, index) => (
                <ReviewSegmentFurtherEducation furtherEducation={educationEntry} count={index + 1} />
              ))}
            </div>
          </div>
        )}
        {CCIsVisible(course.criteria?.educationCriteria?.thirdLevel) && (
          <div className="review-section">
            <h3>Education and Training: Third level</h3>
            <div>
              {user?.education?.thirdLevel?.map((educationEntry, index) => (
                <ReviewSegmentFurtherEducation furtherEducation={educationEntry} count={index + 1} />
              ))}
            </div>
          </div>
        )}
        {CCIsVisible(course.criteria?.industryTrainingState) && (
          <div className="review-section">
            <h3>Education and Training: Industry training</h3>
            <div>
              {user?.industryTraining?.map((weItem, index) => (
                <ReviewSegmentIndustryTraining key={`itexpforms${index}`} industryTraining={weItem} count={index + 1} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
