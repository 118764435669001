import DatePicker from 'react-datepicker';
//The react datepicker css needs to be added like this due to jest not transforming it from the node modules. Snapshots were throwing errors because jest was ignoring it within the node modules.
import './react-datepicker.css';

interface iCourseDatepickerComponentProps {
  label: string;
  value?: Date | undefined | null;
  name: string;
  uniqueName?: string;
  type: string;
  fullwidth: boolean;
  disabled?: boolean;
  tooltipenabled?: boolean;
  tooltip?: string;
  onDataChange: (name: string, value: Date | null) => void;
  step?: string;
  placeholder?: string;
}

interface iEducationDatepickerComponentProps {
  label: string;
  value?: Date | string | undefined | null;
  name: string;
  uniqueName?: string;
  type: string;
  fullwidth: boolean;
  disabled?: boolean;
  tooltipenabled?: boolean;
  tooltip?: string;
  onDataChange: (name: string, value: Date | null) => void;
  step?: string;
  placeholder?: string;
}

export const DatePickerComponent: React.FC<iCourseDatepickerComponentProps> = (props) => {
  return (
    <>
      <DatePicker
        title="Date Picker"
        selected={props.value}
        startDate={props.value}
        selectsStart
        onChange={(date) => props.onDataChange(props.name, date as Date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
      />
    </>
  );
};

export const FormsDatePickerComponent: React.FC<iEducationDatepickerComponentProps> = (props) => {
  let newValue: Date | undefined | null;

  if (typeof props.value === 'string') {
    try {
      newValue = new Date(props.value);
    } catch (e) {
      newValue = null;
    }
  }

  if (props.disabled === true) {
    newValue = null;
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor={props.uniqueName ?? props.name}>{props.label}</label>
        <DatePicker
          id={props.uniqueName ?? props.name}
          wrapperClassName="datePicker"
          aria-labelledby="formDatePicker"
          selected={newValue}
          startDate={newValue}
          selectsStart
          disabled={props.disabled}
          onChange={(date) => props.onDataChange(props.name, date as Date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
        />
      </div>
    </>
  );
};
