import React, { useState } from 'react';

const TabContent: React.FC = () => {
  const [isClosed, setIsClosed] = useState(true);

  const openTab = (e: React.MouseEvent, tabName = 'Apply'): void | undefined => {
    const tablinks = document.getElementsByClassName('tablinks');
    let i;
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
      const tabContent = document.getElementsByClassName('tab-content');

      console.log('tabContent is ', tabContent[i] ?? '');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (tabContent[i] as any).style.display = 'none';
    }

    const getTab: HTMLElement | null = document.getElementById(tabName);
    console.log('getting ' + tabName);
    if (getTab !== null) {
      getTab.style.display = 'block';
    }
    e.currentTarget.className += ' active';
  };

  const menuIcon = (checker: boolean): string => (checker ? '&#43;' : '&times;');
  const handleClick = (): void => setIsClosed(!isClosed);

  return (
    <section id="content-area">
      <div className="form-content-area">
        <div className="form-header-subnav">
          <nav className="form-content-subnav">
            <ul>
              <li>
                <button
                  className="tablinks active"
                  onClick={(event: React.MouseEvent): void => openTab(event, 'Apply')}
                >
                  Apply
                </button>
              </li>
              <li>
                <button
                  className="tablinks"
                  onClick={(event: React.MouseEvent): void => openTab(event, 'types_of_exemption')}
                >
                  Types
                </button>
              </li>
              <li>
                <button
                  className="tablinks"
                  onClick={(event: React.MouseEvent): void => openTab(event, 'basis_of_exemption')}
                >
                  Basis
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div className="form-tab-body">
          <div id="Apply" className="tab-content show-content">
            <p>
              It is possible to seek exemption from select modules on the basis that you have already achieved the
              relevant learning outcomes through prior study, training or professional experience.
            </p>
            <p>Use the options below to apply for these exemptions.</p>
          </div>
          <div id="types_of_exemption" className="tab-content">
            <p>The following types of exemption can be sought via the application portal.</p>
            <h4>Module exemption</h4>
            <p>
              Module exemption can be granted on the basis of prior learning, evidence of which can be provided by the
              applicant via the provision of certification and descriptions of how the prior learning contributes to the
              learning outcomes of the module from which exemption is sought.
            </p>
            <h4>Gradable exemption</h4>
            <p>Support for this option is coming soon.</p>
            <p>
              Under Gradable exemption a candidate may be required to submit a portfolio of work or complete an
              assignment to demonstrate that they are capabable of meeting the learning outcomes of the module from
              which exemption is sought.
            </p>
          </div>
          <div id="basis_of_exemption" className="tab-content">
            <p>An application for module exemption can be made on the basis of the following criteria.</p>
            <h4>Recognised Prior Certified Learning</h4>
            <p>
              In the case of Recognised Prior Certified Learning, where an applicant can provide evidence that they have
              been previously awarded a particular certification they will be deemed as automatically qualifying for
              exemption. The relevant certifications can be selected by choosing Recognised Prior Certified Learning as
              the basis for exemption.
            </p>
            <h4>Prior Certified Learning</h4>
            <p>
              In the case of Prior Certified Learning that is not currently recognised as offering automatic exemption,
              a candidate should provide evidence of the certification and describe how the learning contributes toward
              the learning outcomes of the particular module for which exemption is sought.
            </p>
            <h4>Prior Experiential Learning</h4>
            <p>
              In the case of Prior Experiential Learning, a candidate should provide evidence of any associated
              certification or award and describe how the learning contributes toward the learning outcomes of the
              particular module for which exemption is sought.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export { TabContent };
