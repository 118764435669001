import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';
import { APPLICATION_FIELDS } from './applications';
import { PERSONAL_DETAIL_FIELDS } from './personaldetail';
import { WORK_EXPERIENCE_FIELDS } from './workexperience';
import { SECONDARY_EDUCATION_FIELDS } from './secondaryeducation';
import { FURTHER_EDUCATION_FIELDS } from './furthereducation';
import { SKILLS_AND_COMPETENCIES_FIELDS } from './skillsandcompetencies';
import { COURSE_QUERY_FIELDS } from './courses';
import { INDUSTRY_TRAINING_FIELDS } from './industrytraining';
import { NOTIFICATION_FIELDS } from './notification';

export const USER_FIELDS = gql`
  fragment UserFields on User {
    _id
    email
    role
    isVerified
    currentCourse
    personalDetails {
      ...PersonalDetailsFields
    }
    workExperience {
      ...WorkExperienceFields
    }
    industryTraining {
      ...IndustryTrainingFields
    }
    education {
      secondary {
        ...SecondaryEducationFields
      }
      furtherEducation {
        ...FurtherEducationFields
      }
      thirdLevel {
        ...FurtherEducationFields
      }
    }
    skillsCompetencies {
      ...SkillsAndCompetenciesFields
    }
    createdAt
    updatedAt
  }

  ${SOLLE_ATTACHMENT_FIELDS}
  ${PERSONAL_DETAIL_FIELDS}
  ${WORK_EXPERIENCE_FIELDS}
  ${INDUSTRY_TRAINING_FIELDS}
  ${SECONDARY_EDUCATION_FIELDS}
  ${FURTHER_EDUCATION_FIELDS}
  ${SKILLS_AND_COMPETENCIES_FIELDS}
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      ...UserFields
      application {
        ...ApplicationFields
      }
    }
  }
  ${USER_FIELDS}
  ${APPLICATION_FIELDS}
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  query ResendVerificationEmail {
    resendVerificationEmail
  }
`;

export const GET_USER_APPLICATIONS = gql`
  query GetUserApplications {
    getUser {
      ...UserFields
      application {
        ...ApplicationFields
      }
      applications {
        ...ApplicationFields
        course {
          title
          activeFrom
          Length
          credits
          imageUrl
        }
      }
    }
  }

  ${USER_FIELDS}
  ${APPLICATION_FIELDS}
`;

export const GET_USER_COURSEID = gql`
  query GetUserCourseId {
    getUser {
      currentCourse
    }
  }
`;

export const GET_USER_COURSE_APPLICATION = gql`
  query GetUserCourseApplication {
    getUser {
      course {
        ...CourseFields
      }
      application {
        ...ApplicationFields
      }
    }
  }

  ${COURSE_QUERY_FIELDS}
  ${APPLICATION_FIELDS}
`;

export const REVIEW_AND_SUBMIT = gql`
  mutation ReviewAndSubmit {
    reviewAndSubmit {
      ...ApplicationFields
    }
  }

  ${APPLICATION_FIELDS}
`;

export const GET_USERS_BY_COURSE = gql`
  query GetUsersByCourse($courseId: String!) {
    getUsersByCourse(courseId: $courseId) {
      ...UserFields
      application {
        ...ApplicationFields
      }
    }
  }

  ${USER_FIELDS}
  ${APPLICATION_FIELDS}
`;

export const UPDATE_ATTACHMENTS = gql`
  mutation UpdateAttachments($attachmentsData: AttachmentInput!, $attachments: [Upload!]!) {
    updateAttachments(attachmentsData: $attachmentsData, attachments: $attachments) {
      ...SolleAttachmentFields
    }
  }

  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const ADD_UPDATE_CURRENT_APPLICATION = gql`
  mutation AddOrUpdateCurrentApplication($courseId: String!) {
    addOrUpdateCurrentApplication(courseId: $courseId) {
      ...UserFields
      application {
        ...ApplicationFields
      }
    }
  }

  ${USER_FIELDS}
  ${APPLICATION_FIELDS}
`;
