import React, { useState, useEffect } from 'react';

interface ICreditLimitProps {
  totalCredits: number;
  selectedCredits: number;
}

const FlashMessageCreditLimit: React.FC<ICreditLimitProps> = (props) => {
  const [selectedCredits, setSelectedCredits] = useState<number>(props.selectedCredits);

  const fiftyPercentCreditLimit = props.totalCredits / 2;
  useEffect(() => {
    setSelectedCredits(props.selectedCredits);
  }, [props.selectedCredits, props.totalCredits]);

  if (selectedCredits > fiftyPercentCreditLimit) {
    return (
      <div className="response error-response">
        You have exceeded the credit limits of {fiftyPercentCreditLimit}. Please deselect some of the module(s)
      </div>
    );
  }

  return (
    <div className="response success-response">
      You have selected modules worth {selectedCredits} / {props.totalCredits} credits for exemption
    </div>
  );
};

export default FlashMessageCreditLimit;
