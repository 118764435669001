import React, { useContext, Fragment, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import { Course, GetCoursesQuery, GetCoursesQueryVariables } from 'graphql/globalTypes';
import { GET_COURSES } from 'graphql/queries/courses';
import { ADD_UPDATE_CURRENT_APPLICATION } from 'graphql/queries/users';

import CourseCard from './courseCard';
import { CourseCtx, JwtCtx } from 'services/getCtx';
import { decodeToken } from 'services/validation';

export type CourseSelectedCB = (course: Course) => void;

interface IShowCourses {
  isAdmin?: boolean;
  hideReadMore?: boolean;
  onCourseSelected?: CourseSelectedCB;
}

export const ShowCourses: React.FC<IShowCourses> = (props) => {
  const [, setCourse] = useContext(CourseCtx);
  const { data, loading, error } = useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GET_COURSES, {
    fetchPolicy: 'cache-and-network',
  });
  const [jwt] = useContext(JwtCtx);
  const [AddOrUpdateCurrentApplication] = useMutation(ADD_UPDATE_CURRENT_APPLICATION);

  if (loading) {
    return <p>loading....</p>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  const displayLoginDialog = (): void => {
    const login = document.getElementById('login_modal');

    if (login !== null) {
      if (login.classList.contains('show-modal')) {
        login.classList.remove('show-modal');
      } else {
        login.classList.add('show-modal');
      }
    }
  };

  const onCourse = async (course: Course): Promise<void> => {
    setCourse(course);

    if (!props.isAdmin) {
      if (decodeToken(jwt).isValid) {
        try {
          await AddOrUpdateCurrentApplication({ variables: { courseId: course._id } });
          props.onCourseSelected && props.onCourseSelected(course);
        } catch (err) {
          console.log('Error saving course selection');
        }
      } else {
        displayLoginDialog();
      }
    } else {
      //history.push('/admin/applications-view/' + course._id);
      props.onCourseSelected && props.onCourseSelected(course);
    }
  };

  return (
    <Fragment>
      {data?.getCourses.map((course: Course) => (
        <CourseCard
          course={course}
          key={course._id}
          isAdmin={props.isAdmin}
          hideReadMore={props.hideReadMore}
          onCourse={onCourse}
        />
      ))}
    </Fragment>
  );
};
