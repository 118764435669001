import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// import Crest from 'images/logo/wit-crest.png';
import Logo from 'images/logo/setu-white.png';
import ShowCourseInfo from 'pages/Course/ShowCourseInfo';
import Notifications from 'pages/Notifications/notifications';
import { UserAvatarButton } from 'pages/Navigation/UserAvatarButton';
import { MobileMenuHandlerLoggedIn } from './MobileMenuHandlerLoggedIn';
import { CourseSelectedCB } from 'pages/Course/ShowCourses';

export interface IUserStateDetail {
  isLoggedIn: boolean;
  isAdmin: boolean;
  onCourseSelected?: CourseSelectedCB;
}

const DashboardHeader: React.FC<IUserStateDetail> = (props) => {
  const [collapsedTitle, setCollapsedTitle] = useState(false);
  const breakpoint = 810;

  useEffect(() => {
    setCollapsedTitle(window.innerWidth <= breakpoint);
    const handleResize = (): void => {
      setCollapsedTitle(window.innerWidth <= breakpoint);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <div className="dashboard-topbar">
        <div className="dashboard-logo-area">
          {/* <img src={Crest} alt="Waterford IT crest" /> */}
          <img src={Logo} alt="SETU logo" />
          <div className="dashboard-topbar-title">
            <ShowCourseInfo {...props} {...{ collapsed: collapsedTitle }} />
          </div>
        </div>
        {/* <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            data-aria-valuenow={70}
            data-aria-valuemin="0"
            data-aria-valuemax="100"
            style={{ width: '70%' }}
          >
            <span className="sr-only">% Complete</span>
          </div>
        </div> */}
        <div className="dashboard-topbar-tools">
          <div className="topbar-search disabled temporary-hide" role="search">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="loggedin-marketing-buttons">
            <MobileMenuHandlerLoggedIn {...props} />
          </div>
          <Notifications {...props} />
          <div className="topbar-user">
            <UserAvatarButton isAdmin={props.isAdmin} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
