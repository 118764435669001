import { useRef } from 'react';

import { ApplicantDataNavigate } from 'pages/Navigation/ApplicantDataNavigate';
import { ApplicantMenuItems } from 'pages/Navigation/ApplicantDataMenuItems';
import DashboardReviewBar, { IReviewBarProps } from 'pages/Messaging/DashboardReviewBar';

export const PersonalDetails: React.FC<IReviewBarProps> = (props) => {
  const reviewerArea = useRef<HTMLDivElement>(null);

  return (
    <div>
      <section>
        <div id="reviewer_area" className="dashboard-review-area" ref={reviewerArea}>
          <div className="grid-collapse">
            <ApplicantDataNavigate menuItems={ApplicantMenuItems} {...props} />
          </div>
        </div>
      </section>
      <DashboardReviewBar expanded={false} {...props} parentRef={reviewerArea} />
    </div>
  );
};
