import React, { Fragment, useContext } from 'react';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { CourseCtx } from 'services/getCtx';

export interface IExemptionReasonTypeSelection {
  onSelectChange: (reasonType: string) => void;
  moduleId: string;
}

const ExemptionReasonTypeSelection: React.FC<IExemptionReasonTypeSelection> = (props) => {
  const [course] = useContext(CourseCtx);
  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { name, value } = event.currentTarget;

    console.log(name);
    console.log(value);
    props.onSelectChange(value);
  };

  const hasPCL = (): boolean => {
    const foundModuleWithPCL = course.modules?.find((module) => {
      return module._id === props.moduleId && module.pcls && module.pcls.length > 0;
    });

    if (foundModuleWithPCL) {
      return true;
    }

    return false;
  };

  const values: string[] = ['PCL', 'PL', 'PWE'];
  const valuesDescription = new Map([
    [values[0], 'Recognised Prior Certified Learning'],
    [values[1], 'Prior Learning'],
    [values[2], 'Prior Experiential Learning'],
  ]);

  if (!hasPCL()) {
    valuesDescription.delete(values[0]);
    values.splice(0, 1);
  }

  return (
    <Fragment>
      <FormSelect
        name="exemptionReasonType"
        label="Select the basis on which you seek exemption."
        values={values}
        valuesDescription={valuesDescription}
        fullwidth={true}
        onDataChange={onSelectChange}
        selectedValue={undefined}
      />
    </Fragment>
  );
};

export { ExemptionReasonTypeSelection };
