import { FormAttachmentsSelect, funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { AttachmentTypes, ExemptionReason } from 'graphql/globalTypes';
import { DeleteConfirmModal } from 'pages/Applicant/PersonalDetails/DeleteConfirmModal';
import React, { useContext, useEffect, useState } from 'react';
import { CourseCtx } from 'services/getCtx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { promptToolTip } from 'services/constants';

export interface IReasonSelection {
  onChange: (index: number, reason: ExemptionReason) => void;
  moduleId: string;
  index: number;
  parentIndex: number;
  job: ExemptionReason;
  filename?: string | null;
  onAttachmentSelect: funcAttachmentsSelect;
  onDeleteExemptionReason: (moduleId: string, reasonId: string) => void;
}

const PCLform: React.FC<IReasonSelection> = (props) => {
  const [course] = useContext(CourseCtx);
  const [sectionDetail, setSectionDetail] = useState<ExemptionReason>(props.job);

  useEffect(() => {
    setSectionDetail(props.job);
  }, [props.job, props.filename]);

  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { name, value } = event.currentTarget;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newReason: any = Object.assign({}, sectionDetail);
    newReason[name] = findPCLTitle(value);

    setSectionDetail(newReason);
    props.onChange(props.index, newReason);
  };

  const findPCLTitle = (pclId: string): string => {
    let pclTitle = '';
    course.modules?.forEach((module) => {
      if (module._id === props.moduleId) {
        const foundPCL = module.pcls?.filter((pcl) => pcl._id === pclId)[0];

        if (foundPCL) {
          pclTitle = foundPCL.title ?? '';
        }
      }
    });

    return pclTitle;
  };

  const findPCLId = (pclTitle: string | undefined): string | undefined => {
    if (pclTitle == undefined) {
      return undefined;
    }
    let pclId = undefined;
    course.modules?.forEach((module) => {
      if (module._id === props.moduleId) {
        const foundPCL = module.pcls?.filter((pcl) => pcl.title === pclTitle)[0];

        if (foundPCL) {
          pclId = foundPCL._id;
        }
      }
    });

    return pclId;
  };

  const values: string[] = [];
  const valuesDescription = new Map();

  course.modules?.forEach((module) => {
    if (module._id === props.moduleId) {
      module.pcls?.map((pcl) => {
        values.push(pcl._id);
        valuesDescription.set(pcl._id, pcl.title);
      });
    }
  });

  const onDeleteConfirm = (): void => {
    console.log('delete button called.');
    props.onDeleteExemptionReason(props.moduleId, props.job.id);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal_pclform.${props.index}.${props.moduleId}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const labelText =
    'If you have previously earned any of the listed awards, this may grant you automatic exemption from this module.';

  return (
    <div className="form-learning-type-area">
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={'Recognised Prior Certified Learning'}
        index={props.index}
        modalId={`delete_confirm_modal_pclform.${props.index}.${props.moduleId}`}
      />
      <div className="break" />
      {sectionDetail && (
        <div className="delete-item-button">
          <button
            key={'del' + sectionDetail.id}
            onClick={handleDeleteClick}
            className={'btn-sm btn-delete'}
            type="button"
          >
            Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
          </button>
        </div>
      )}
      <div className="break" />
      <h4 className="form-learning-type-head">Recognised Prior Certified Learning</h4>
      <FormSelect
        name="experience"
        uniqueName={`experience-pcl-${props.parentIndex}${props.index}`}
        label={labelText}
        values={values}
        valuesDescription={valuesDescription}
        fullwidth={true}
        onDataChange={onSelectChange}
        selectedValue={findPCLId(props.job.experience ?? undefined)}
      />
      <div className="break" />
      <FormAttachmentsSelect
        name="attachment"
        uniqueName={`attachment-pcl-${props.parentIndex}${props.index}`}
        label="Upload Certification"
        attachmentsData={{
          attachmentType: AttachmentTypes.ExemptionReason,
          idOne: props.moduleId,
          idTwo: props.job.id,
        }}
        tooltip={promptToolTip}
        onAttachmentsSelect={props.onAttachmentSelect}
      />
      <div className="filename">{props.filename}</div>
      <div className="break"></div>
    </div>
  );
};

export { PCLform };
