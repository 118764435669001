import React, { useState, useEffect } from 'react';

interface IMessageProps {
  message?: string;
  timeoutSecond?: number;
  onFadeout?: () => void;
}

const FlashMessageSuccess: React.FC<IMessageProps> = ({ message, timeoutSecond, onFadeout }) => {
  const [fadeout, setFadeout] = useState(false);

  useEffect(() => {
    let isRunning = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;
    if (message && !fadeout) {
      timeout = setTimeout((): void => {
        if (isRunning) {
          setFadeout(true);
          if (onFadeout) {
            onFadeout();
          }
        }
      }, timeoutSecond ?? 2000);
    }
    return (): void => {
      isRunning = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  if (message && !fadeout) {
    return <div className="response success-response">{message}</div>;
  }

  return null;
};

export default FlashMessageSuccess;
