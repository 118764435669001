import { User } from 'graphql/globalTypes';
import ApplicationGrid from '../ApplicationGrid';

interface IExemptionApplicationsProps {
  applicants?: User[];
  onSelected: (targetApplicant: User) => void;
}

export const AdvanceEntryApplications: React.FC<IExemptionApplicationsProps> = (props) => {
  const filterApplications = (): User[] | undefined =>
    props.applicants?.filter((applicant) => applicant.application?.exemptionApplication !== true);

  if (!props.applicants || props.applicants.length === 0) {
    return null;
  }

  const filterApp = filterApplications();

  if (!filterApp || filterApp.length === 0) {
    return null;
  }

  return (
    <div className="col-6" data-testid="advanceentry-applications">
      <div className="panel p-1">
        <div className="applicant-saved-applications">
          <ApplicationGrid
            Title={'Applications for advanced entry'}
            applicants={filterApp}
            onSelected={props.onSelected}
          />
        </div>
      </div>
    </div>
  );
};
