import { NavLink } from 'react-router-dom';

interface IPrimaryNavProps {
  isLoggedIn?: boolean;
}

const PrimaryNav: React.FC<IPrimaryNavProps> = (props) => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink
            to={props.isLoggedIn ? '/home' : '/'}
            className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link') as string}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link') as string}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/courses" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link') as string}>
            Awards
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/news" activeClassName="active" className="disabled">
            News
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link') as string}>
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export { PrimaryNav };
