import { User } from 'graphql/globalTypes';
import { useEffect, useState } from 'react';
import ApplicationGrid from '../ApplicationGrid';

interface IExemptionApplicationsProps {
  applicants?: User[];
  onSelected: (targetApplicant: User) => void;
}

export const ExemptionApplications: React.FC<IExemptionApplicationsProps> = (props) => {
  const filterApplications = (): User[] | undefined =>
    props.applicants?.filter((applicant) => applicant.application?.exemptionApplication);

  const [exemptionApplications, setExemptionApplication] = useState<User[] | undefined>(filterApplications());
  // if ( exemptionApplications.length >= 5) {
  //   exemptionApplications?.splice(0, 5);
  // }
  useEffect(() => {
    if (props.applicants) {
      setExemptionApplication(filterApplications());
    }
  }, [props.applicants]);

  if (!exemptionApplications || exemptionApplications.length === 0) {
    return null;
  }

  return (
    <div className="col-6" data-testid="exemption-applications">
      <div className="panel p-1">
        <div className="applicant-saved-applications">
          <ApplicationGrid
            Title={'Applications for exemption'}
            applicants={exemptionApplications}
            onSelected={props.onSelected}
          />
        </div>
      </div>
    </div>
  );
};
