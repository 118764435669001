import React, { useState, useEffect, useContext } from 'react';
import { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import { useMutation } from '@apollo/client';
import FlashMessageError from 'components/FlashMessageError';
import FlashMessageSuccess from 'components/FlashMessageSuccess';
import { ApplicationStatus, Exemption, User } from 'graphql/globalTypes';
import { SET_EXEMPTION_STATUS } from 'graphql/queries/exemptionstatus';
import { UserCtx } from 'services/getCtx';

export const StatusDescription = new Map<string, string>([
  [ApplicationStatus.Presubmission, 'Presubmission'],
  [ApplicationStatus.Submitted, 'Submitted for review'],
  [ApplicationStatus.Reviewing, 'Under review'],
  [ApplicationStatus.Moreinfo, 'More information requested'],
  [ApplicationStatus.Accepted, 'Accepted'],
  [ApplicationStatus.Refused, 'Rejected'],
]);

export interface IExemptionStatusUpdate {
  exemption: Exemption;
  applicationId: string;
  onStatusUpdateButtonClicked?: () => void;
  user?: User;
}

const UpdateExemptionStatus: React.FC<IExemptionStatusUpdate> = (props) => {
  const [currentStatus, setCurrentStatus] = useState<string | undefined>(
    props.exemption.exemptionStatus ?? ApplicationStatus.Presubmission
  );
  const [stUpdates, setStUpdates] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string>();
  const [setStatus, { error }] = useMutation(SET_EXEMPTION_STATUS);
  const [exemption, setExemption] = useState(props.exemption);
  const [currentUser, setCurrentUser] = useState<User | undefined>(props?.user);
  const [, setUser] = useContext(UserCtx);

  useEffect(() => {
    setExemption(props.exemption);
  }, [props.exemption]);

  useEffect(() => {
    setCurrentUser(props.user);
  }, [props.user]);

  const onStatusSelect = (event: FormInputChangeParam): void => {
    const selectedStatus = event.target.value;
    if (selectedStatus) {
      setCurrentStatus(selectedStatus);
    }
    setSuccessMsg(undefined);
  };

  useEffect(() => {
    let isRunning = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;
    if (currentStatus === ApplicationStatus.Submitted && stUpdates === false) {
      timeout = setTimeout(async (): Promise<void> => {
        if (isRunning) {
          modifyStatus(ApplicationStatus.Reviewing);
          setStUpdates(true);
          setCurrentStatus(ApplicationStatus.Reviewing);
        }
      }, 5000);
    }
    return (): void => {
      isRunning = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentStatus]);

  const onStatusUpdateButtonClicked = async (): Promise<void> => {
    if (props.onStatusUpdateButtonClicked) {
      props.onStatusUpdateButtonClicked();
    }
    if (currentStatus) {
      try {
        await modifyStatus(currentStatus);
        setSuccessMsg('Exemption status has been changed successfully.');
        setStUpdates(true);
      } catch (err) {
        setSuccessMsg(undefined);
        setStUpdates(true);
        console.log('Set Exemption status Mutation Error', err.message);
      }
    }
  };

  const modifyStatus = async (exemptionStatus: string): Promise<void> => {
    try {
      await setStatus({
        variables: {
          applicationId: props.applicationId,
          moduleId: exemption.moduleId,
          exemptionStatus,
        },
      });
      await updateUserContext(exemptionStatus);
    } catch (err) {
      throw err;
    }
  };

  const updateUserContext = async (exemptionStatus: string) => {
    const newUser: User = Object.assign({}, currentUser);

    newUser?.application?.exemptions?.forEach((exempt) => {
      if (exempt.moduleId === exemption.moduleId) {
        exempt.exemptionStatus = exemptionStatus as ApplicationStatus;
      }
    });

    setCurrentUser(newUser);
    if (newUser) {
      setUser(newUser);
    }
  };

  return (
    <div className="exemption-basis">
      <label id="update_exemption_status" htmlFor="status" aria-label="Select exemption status">
        Status of this application for exemption
      </label>
      <select
        aria-labelledby="update_exemption_status"
        id="status"
        name="status"
        onChange={onStatusSelect}
        value={currentStatus}
        className="exemption-status-select"
      >
        {/* <option>Select exemption status</option> */}
        <option value="submitted">{StatusDescription.get(ApplicationStatus.Submitted)}</option>
        <option value="reviewing">{StatusDescription.get(ApplicationStatus.Reviewing)}</option>
        <option value="moreinfo">{StatusDescription.get(ApplicationStatus.Moreinfo)}</option>
        <option value="accepted">{StatusDescription.get(ApplicationStatus.Accepted)}</option>
        <option value="refused">{StatusDescription.get(ApplicationStatus.Refused)}</option>
      </select>
      <button className="btn btn-sm btn-secondary" type="button" onClick={onStatusUpdateButtonClicked}>
        Update
      </button>
      {error && <FlashMessageError error={error} />}
      {successMsg && <FlashMessageSuccess message={successMsg} />}
    </div>
  );
};

export default UpdateExemptionStatus;
