import { ShowCourses, CourseSelectedCB } from 'pages/Course/ShowCourses';

interface ISelectCourse {
  isLoggedIn: boolean;
  isAdmin: boolean;
  hideReadMore?: boolean;
  onCourseSelected?: CourseSelectedCB;
}

export const SelectCourse: React.FC<ISelectCourse> = (props) => {
  return (
    <section id="courses-area">
      <div className="content-area">
        <div className="content-body-cards">
          <div className="flex-grid-row-marketing">
            <ShowCourses {...props} />
          </div>
        </div>
      </div>
    </section>
  );
};
