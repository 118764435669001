import React, { Fragment } from 'react';
import FormSelect from 'components/FormPrimitives/FormSelect';
import { User } from 'graphql/globalTypes';

export interface IExemptionWESelection {
  onWESelect: (we: string, workExperienceId: string) => void;
  index: number;
  user: User;
}

const ExemptionWESelection: React.FC<IExemptionWESelection> = (props) => {
  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { value } = event.currentTarget;
    const weFound = props.user.workExperience?.filter((we) => we.id === value)[0];

    if (weFound) {
      const wedata = `Job Title: ${weFound.jobTitle} \nCompany name: ${weFound.companyName}`;
      props.onWESelect(wedata, value);
    }
  };

  const values: string[] = [];
  const valuesDescription = new Map();

  props.user.workExperience?.map((we) => {
    if (we.jobTitle) {
      values.push(we.id);
      valuesDescription.set(we.id, we.jobTitle);
    }
  });

  const labelText = 'Select work experience for exemption';

  return (
    <Fragment>
      <FormSelect
        name="weData"
        uniqueName={`ex-wedata-${props.index}`}
        label={labelText}
        values={values}
        valuesDescription={valuesDescription}
        fullwidth={true}
        onDataChange={onSelectChange}
      />
    </Fragment>
  );
};

export { ExemptionWESelection };
