import { GreenStyleButtonWithLink } from 'components/ButtonWithLink/GreenStyleButtonWithLink';

interface ICreateAdminUser {
  onCreateButtonPressed: () => void;
}

export const AdminCreateActionButton: React.FC<ICreateAdminUser> = (props) => {
  return (
    <GreenStyleButtonWithLink
      link={`/superadmin/createadminuser/`}
      keyValue={`cradminuserbutton`}
      buttonTitle={`Create Admin User`}
      onClick={props.onCreateButtonPressed}
    />
  );
};
