import React, { useState, useEffect, useRef, Fragment } from 'react';
import { PrimaryNav } from './PrimaryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

interface IMobileMenuHandlerProps {
  onToggleLogin: () => void;
}

const MobileMenuHandler: React.FC<IMobileMenuHandlerProps> = (props) => {
  const [mobileState, setMobileState] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const { pathname } = useLocation();
  const breakpointLg = 992;

  const scrollBarWidth = useRef(15);

  useEffect(() => {
    setisVisible(false);
  }, [pathname]);

  useEffect(() => {
    setMobileState(window.innerWidth <= breakpointLg);
    const handleResize = (): void => {
      setMobileState(window.innerWidth <= breakpointLg);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const div = document.getElementById('root');
    const hamburger = Array.from(document.getElementsByClassName('hamburger'))[0] as HTMLElement;
    const hamburgerOpen = Array.from(document.getElementsByClassName('hamburger-open'))[0] as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    scrollBarWidth.current = window.innerWidth - div!.clientWidth;

    if (isVisible) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollBarWidth.current}px`;
      if (hamburger) hamburger.style.paddingRight = `${scrollBarWidth.current}px`;
      if (hamburgerOpen) hamburgerOpen.style.paddingRight = `${scrollBarWidth.current}px`;
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.paddingRight = '';
      if (hamburger) hamburger.style.paddingRight = '';
      if (hamburgerOpen) hamburgerOpen.style.paddingRight = '';
    }
  });

  const menuIcon = (): IconDefinition => (isVisible ? faTimes : faBars);
  const handleClick = (): void => setisVisible(!isVisible);

  return (
    <Fragment>
      {!mobileState && (
        <div className="topnav">
          <PrimaryNav />
        </div>
      )}

      {mobileState && (
        <div className={isVisible ? 'hamburger-open' : 'hamburger'} onClick={handleClick}>
          <FontAwesomeIcon icon={menuIcon()} transform={isVisible ? 'grow-2' : ''} />
        </div>
      )}

      {mobileState && (
        <div className={isVisible ? 'side-drawer show' : 'side-drawer hide'}>
          <PrimaryNav />
          <a className={'btn-login nav-link'} onClick={props.onToggleLogin}>
            Login
          </a>
        </div>
      )}
    </Fragment>
  );
};

export { MobileMenuHandler };
