import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const WORK_EXPERIENCE_FIELDS = gql`
  fragment WorkExperienceFields on WorkExperience {
    id
    attachments {
      ...SolleAttachmentFields
    }
    jobTitle
    companyName
    NatureOfBusiness
    currentlyWorking
    companyAddress
    startDate
    endDate
    keyResponsibilities
    skillsAndCompetencies
    promoted
    promotionDetails
  }

  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const GET_WORK_EXPERIENCE = gql`
  query GetWorkExperience {
    getUser {
      workExperience {
        ...WorkExperienceFields
      }
    }
  }

  ${WORK_EXPERIENCE_FIELDS}
`;

export const REPLACE_ALL_WORK_EXPERIENCE = gql`
  mutation ReplaceAllWorkExperience($workExperience: [WorkExperienceInput!]!) {
    replaceAllWorkExperience(workExperience: $workExperience) {
      _id
      email
      workExperience {
        ...WorkExperienceFields
      }
    }
  }

  ${WORK_EXPERIENCE_FIELDS}
`;

export const ADD_WORK_EXPERIENCE = gql`
  mutation AddWorkExperience($workExperience: [WorkExperienceInput!]!) {
    addWorkExperience(workExperience: $workExperience) {
      workExperience {
        ...WorkExperienceFields
      }
    }
  }
  ${WORK_EXPERIENCE_FIELDS}
`;

export const UPDATE_WORK_EXPERIENCE = gql`
  mutation UpdateWorkExperience($workExperience: WorkExperienceInput!, $workExperienceId: String!) {
    updateWorkExperience(workExperience: $workExperience, id: $workExperienceId) {
      workExperience {
        ...WorkExperienceFields
      }
    }
  }
  ${WORK_EXPERIENCE_FIELDS}
`;

export const REMOVE_WORK_EXPERIENCE = gql`
  mutation removeWorkExperience($workExperienceId: String!) {
    removeWorkExperience(id: $workExperienceId) {
      workExperience {
        ...WorkExperienceFields
      }
    }
  }
  ${WORK_EXPERIENCE_FIELDS}
`;
