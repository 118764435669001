import { gql } from '@apollo/client';
import { PCL_FIELDS } from './pcl';

export const MODULES_FIELDS = gql`
  fragment ModuleFields on Module {
    _id
    witId
    name
    title
    exemptionAllowed
    semester
    credits
    exam
    lecturers
    remoteUrl
    imageUrl
    assesmentCriteria
    shortDescription
    longDescription
    subjectsCovered
    learningOutcomes
    createdAt
    updatedAt
    pcls {
      ...PCLFields
    }
  }
  ${PCL_FIELDS}
`;
