import { gql } from '@apollo/client';

import { FURTHER_EDUCATION_FIELDS } from './furthereducation';

export const ADD_THIRDLEVEL_EDUCATION = gql`
  mutation AddThirdLevelEducation($thirdLevel: FurtherEducationInput!) {
    addThirdLevelEducation(thirdLevel: $thirdLevel) {
      education {
        thirdLevel {
          ...FurtherEducationFields
        }
      }
    }
    ${FURTHER_EDUCATION_FIELDS}
  }
`;

export const UPDATE_THIRDLEVEL_EDUCATION = gql`
  mutation UpdateThirdLevelEducation($thirdLevel: FurtherEducationInput!, $thirdLevelId: String!) {
    updateThirdLevelEducation(thirdLevel: $thirdLevel, id: $thirdLevelId) {
      education {
        thirdLevel {
          ...FurtherEducationFields
        }
      }
    }
    ${FURTHER_EDUCATION_FIELDS}
  }
`;

export const REMOVE_THIRDLEVEL_EDUCATION = gql`
  mutation RemoveThirdLevelEducation($thirdLevelId: String!) {
    removeThirdLevelEducation(id: $thirdLevelId) {
      education {
        thirdLevel {
          ...FurtherEducationFields
        }
      }
    }
  }
  ${FURTHER_EDUCATION_FIELDS}
`;
