import { FormInputChangeParam } from './FormPrimitives/FormInput';

interface iFilterExeComponentProps {
  currentExeFilter?: boolean;
  onFilterSelectCB: (exemptionApplication?: boolean) => void;
}

export const ExemptionsFilterComponent: React.FC<iFilterExeComponentProps> = (props) => {
  const onFilterSelectCB = (event: FormInputChangeParam): void => {
    switch (event.target.value) {
      case 'show':
        props.onFilterSelectCB(true);
        break;
      case 'hide':
        props.onFilterSelectCB(false);
        break;
      default:
        props.onFilterSelectCB(undefined);
        break;
    }
  };

  return (
    <>
      <div className="filter-dropdown">
        <div className="filter-content">
          <label> EXEMPTIONS </label>
          <select
            className="select-css"
            aria-labelledby="filter-dropdown"
            id="filterstatus"
            name="filterstatus"
            onChange={onFilterSelectCB}
            value={props.currentExeFilter === undefined ? 'none' : props.currentExeFilter ? 'show' : 'hide'}
          >
            <option value="none" selected>
              {'None'}
            </option>
            <option value="show">Show</option>
            <option value="hide">Hide</option>
          </select>
        </div>
      </div>
    </>
  );
};
