import { ApplicationStatus, FieldState, User } from 'graphql/globalTypes';
import { constants } from './constants';

export const fileSizeExceeded = (files: File[]): Error | undefined => {
  if (files && files[0]) {
    const valid = files[0].size > constants.UPLOAD_MAX_FILE_SIZE;
    if (valid) {
      return new Error('The maximum file size allowed is 10MB. Please choose another file and try again.');
    }
  }

  return undefined;
};

export const CCIsVisible = (state?: FieldState | null): boolean => {
  return state === FieldState.Required || state === FieldState.Visible;
};

export const shouldDisableApplicationStatus = (user: User): boolean => {
  const flag = user.application?.exemptions?.some(
    (value) =>
      value.exemptionStatus !== ApplicationStatus.Refused && value.exemptionStatus !== ApplicationStatus.Accepted
  );

  console.log(`shouldEnableApplication Status flag: ${flag}`);

  if (flag === false || flag === undefined) {
    return false;
  }

  return true;
};

export const deepClone = <T>(originalObject: T): T => {
  return JSON.parse(JSON.stringify(originalObject));
};

/// @see https://stackoverflow.com/questions/41582357/local-copy-of-react-prop-is-read-only
/// @see https://stackoverflow.com/questions/184710/what-is-the-difference-between-a-deep-copy-and-a-shallow-copy
export const deepishCopyAssign = <T>(
  originalObject: T,
  index: string | number,
  value: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateFn?: (newObject: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): T => {
  console.assert(originalObject);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newObj: any;
  if (Array.isArray(originalObject)) {
    newObj = Array.from(originalObject);
  } else {
    newObj = Object.assign({}, originalObject);
  }

  newObj[index] = value;

  if (updateFn) updateFn(newObj);

  return newObj;
};

// Create applicant-side display mapping for application status
export const ApplicationStatusNotifier = new Map<string, string>([
  [ApplicationStatus.Presubmission, 'Pre-submission'],
  [ApplicationStatus.Submitted, 'Submitted'],
  [ApplicationStatus.Reviewing, 'In review'],
  [ApplicationStatus.Recommendaccept, 'In review'],
  [ApplicationStatus.Registrarapproval, 'In review'],
  [ApplicationStatus.Moreinfo, 'More information requested'],
  [ApplicationStatus.Accepted, 'Accepted'],
  [ApplicationStatus.Refused, 'Rejected'],
  [ApplicationStatus.Closed, 'Closed'],
]);

/// This will return a valid string whether the date is a valid date, string, null or non string/non string field
export function parseDate(d?: Date): string {
  try {
    if (!d) {
      return '';
    }

    if (typeof d === 'string') {
      return (d as string).substr(0, 10);
    }

    return d.toISOString().substr(0, 10);
  } catch (e) {
    return '';
  }
}
