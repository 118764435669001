import { ApplicationStatus } from 'graphql/globalTypes';
import { ApplicationStatusNotifier } from 'services/utils';

interface IApplicationStatusProgress {
  statusNow?: string;
}

export const ApplicationStatusProgress: React.FC<IApplicationStatusProgress> = (props) => {
  return (
    <div className="application-status">
      <div className="application-status-container">
        {checkIfInitialState(props.statusNow) ? intialStates(props.statusNow) : alertStates(props.statusNow)}
      </div>
    </div>
  );
};

const checkIfInitialState = (inpData: unknown | undefined) => {
  return (
    inpData === ApplicationStatus.Presubmission ||
    inpData === ApplicationStatus.Submitted ||
    inpData === ApplicationStatus.Reviewing ||
    inpData === ApplicationStatus.Recommendaccept ||
    inpData === ApplicationStatus.Registrarapproval
  );
};

const intialStates = (inpData: unknown | undefined) => {
  return (
    <>
      <h3>Application status</h3>
      <div className={`status-chevron ${inpData === ApplicationStatus.Presubmission ? 'active' : ''}`}>
        <div className="chevron-content">
          <p>{ApplicationStatusNotifier.get(ApplicationStatus.Presubmission)}</p>
        </div>
      </div>
      <div className={`status-chevron ${inpData === ApplicationStatus.Submitted ? 'active' : ''}`}>
        <div className="chevron-content">
          <p>{ApplicationStatusNotifier.get(ApplicationStatus.Submitted)}</p>
        </div>
      </div>
      <div
        className={`status-chevron ${
          inpData === ApplicationStatus.Reviewing ||
          inpData === ApplicationStatus.Recommendaccept ||
          inpData === ApplicationStatus.Registrarapproval
            ? 'active'
            : ''
        }`}
      >
        <div className="chevron-content">
          <p>{ApplicationStatusNotifier.get(ApplicationStatus.Reviewing)}</p>
        </div>
      </div>
    </>
  );
};

const alertStates = (inpData: unknown | undefined) => {
  switch (inpData) {
    case ApplicationStatus.Moreinfo:
      return <div className="response alert-response">Further details requested on your application.</div>;
    case ApplicationStatus.Accepted:
      return <div className="response success-response">Your application has been accepted.</div>;
    case ApplicationStatus.Refused:
      return <div className="response error-response">Unfortunately your application has been refused.</div>;
    case ApplicationStatus.Closed:
      return <div className="response notice-response">This application has been marked as closed.</div>;
    default:
      return <div className="response neutral-response">Check back later for updated status information.</div>;
  }
};
