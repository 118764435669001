import React, { Fragment, useEffect, useState } from 'react';
import { Course, Module } from 'graphql/globalTypes';
import { IModulesSelection } from './ModuleModal';
import FlashMessageCreditLimit from './FlashMessageCreditLimit';
import SemesterWideModule from './SemesterWideModule';

export interface IModuleSelection {
  onSelectChange: (modules: IModulesSelection[], creditLimitExceeded: boolean) => void;
  selectedModules: IModulesSelection[];
  course: Course;
  shouldDeselectAll: boolean;
  deselectedAll: () => void;
  alreadySelectedCredits: number;
  totalCredits: number;
}

const ModuleSelection: React.FC<IModuleSelection> = (props) => {
  const [isCourseModule, setIsCourseModule] = useState(false);
  const [selectedModules, setSelectedModules] = useState<IModulesSelection[]>(props.selectedModules);
  const [selectedCredits, setSelectedCredits] = useState<number>(0);
  const [totalCredits, setTotalCredits] = useState<number>(props.totalCredits);

  const getSelectedCredits = (): number => {
    let credits = props.alreadySelectedCredits;
    selectedModules.map((item) => {
      if (item.hasSelected && item.module.credits) {
        credits += item.module.credits ?? 0;
      }
    });

    return credits;
  };

  useEffect(() => {
    console.log('useEffect::ModuleSelection: called');
    if (props.shouldDeselectAll) {
      setIsCourseModule(checkIfAnyAwardYearCourse());
      props.deselectedAll();
    }

    setSelectedCredits(getSelectedCredits());
    setSelectedModules(props.selectedModules);
    setTotalCredits(props.totalCredits);
  }, [props.selectedModules, props.totalCredits, props.alreadySelectedCredits, props.shouldDeselectAll]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target as { name: string; checked: boolean };

    console.log(`Name = ${name}`);
    console.log(`Checked = ${checked}`);
    const oldArray: IModulesSelection[] = [...selectedModules];
    let sCredits = selectedCredits + props.alreadySelectedCredits;
    oldArray.forEach((item) => {
      if (item.module._id === name) {
        item.hasSelected = checked;
        if (item.module.credits) {
          if (checked) {
            sCredits = selectedCredits + item.module.credits;
          } else {
            sCredits = selectedCredits - item.module.credits;
          }
        }
      }
    });

    setIsCourseModule(checkIfAnyAwardYearCourse());

    setSelectedCredits(sCredits);
    setSelectedModules(oldArray);
    props.onSelectChange(oldArray, sCredits > totalCredits / 2);
  };

  const checkIfAnyAwardYearCourse = (): boolean => {
    const awardYearFlag = selectedModules.some((item) => {
      return item.hasSelected && checkCourseYear(props.course, item.module, monthsInYear, semestersInYear);
    });

    return awardYearFlag;
  };

  const uniqueSemesters = [...new Set(selectedModules.map((selectedModule) => selectedModule.module.semester).sort())];
  return (
    <Fragment>
      <FlashMessageCreditLimit selectedCredits={selectedCredits} totalCredits={totalCredits} />
      {uniqueSemesters.map((semester) => {
        return (
          <SemesterWideModule
            semester={semester ?? ''}
            selectedModules={selectedModules}
            onCheckboxChange={onCheckboxChange}
            key={`semester-wise-${semester}`}
          />
        );
      })}

      {isCourseModule && (
        <div className="warning-container">
          <div className="error-response exemption-warning">
            <p className="text-bold">Important notice</p>
            <p>
              This module is in an award year. If you are granted an exemption for this module, you will only be
              entitled to an unclassified Award. By including this module in your application you confirm that you
              understand this notice.
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export { ModuleSelection };

const monthsInYear = 12;
const semestersInYear = 2;

const checkCourseYear = (courseData: Course, moduleData: Module, monthsInYear: number, semestersInYear: number) => {
  if (courseData.Length && moduleData.semester) {
    const courseLengthSemesters = (courseData.Length / monthsInYear) * semestersInYear;
    const moduleSemester = parseInt(moduleData.semester);
    return moduleSemester > courseLengthSemesters / semestersInYear;
  } else {
    return false;
  }
};
