import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModuleSelection } from './ModuleSelection';
import { Course, Module } from 'graphql/globalTypes';

export interface IModulesSelection {
  module: Module;
  hasSelected: boolean;
}

export interface IModuleModal {
  onSelected: (modules: IModulesSelection[]) => void;
  selectedModules: IModulesSelection[];
  course: Course;
  alreadySelectedCredits: number;
  //show: boolean;
  totalCredits: number;
}

export const ModuleModal: React.FC<IModuleModal> = (props) => {
  const [selectedModules, setSelectedModules] = useState<IModulesSelection[]>(props.selectedModules);
  const [hasExceededCreditLimit, setHasExceededCreditLimit] = useState<boolean>(false);
  const [deselectAll, setDeselectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectedModules(props.selectedModules);
  }, [props.selectedModules, deselectAll]);

  const CloseModal = (): void => {
    setDeselectAll(true);

    const confirmSubmission = document.getElementById('confirm_modal');

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      }
    }
  };

  /*
  useEffect(() => {
     props.show && document.body.style.overflow = 'hidden';
     !props.show && document.body.style.overflow = 'unset';
     }, [props.show ]);*/

  const onBackCloseBtnClick = (): void => {
    setDeselectAll(true);
    updateCheckboxSelection(false);
    CloseModal();
  };

  const onSaveBtnClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    CloseModal();
    props.onSelected(selectedModules);
  };

  const onSelectChange = (modules: IModulesSelection[], creditLimit = false): void => {
    setSelectedModules(modules);
    console.log('credit limit exceeded ');
    console.log(creditLimit);
    setHasExceededCreditLimit(creditLimit);
  };

  const onDeselectedAllModules = (e: React.MouseEvent): void => {
    e.preventDefault();
    console.log('onDeselectedAllModules');

    setDeselectAll(true);
    updateCheckboxSelection(false);
  };

  const updateCheckboxSelection = (flag: boolean): void => {
    const newModules: IModulesSelection[] = [...selectedModules];
    console.log('newModules is::::: ', newModules);
    newModules.map((item, index) => {
      console.log('newModules index / item is ' + index + ' ::: ' + ' ');
      console.log(
        'md${index}${item.module.semester}-${item.module._id}',
        `md${item.module.semester}-${item.module._id}`
      );
      const moduleCheckbox = document.getElementById(
        `md${item.module.semester}-${item.module._id}`
      ) as HTMLInputElement;
      item.hasSelected = flag;
      moduleCheckbox.checked = flag;
    });
    setSelectedModules(newModules);
  };

  const deselectedAll = (): void => {
    setDeselectAll(false);
  };

  console.log(`shouldDeselectAll - deselectAll ${deselectAll}`);

  return (
    <div id="confirm_modal" className="modal">
      <div className="modal-popover-confirm">
        <div className="modal-login-form-area">
          <div className="close-modal" onClick={onBackCloseBtnClick}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="modal-content-exemptions">
            <p className="text-bold">Module selection</p>
            <div className="text-centre mb-1">
              <ModuleSelection
                onSelectChange={onSelectChange}
                selectedModules={selectedModules}
                course={props.course}
                shouldDeselectAll={deselectAll}
                deselectedAll={deselectedAll}
                alreadySelectedCredits={props.alreadySelectedCredits}
                totalCredits={props.totalCredits}
              />
            </div>
          </div>
        </div>

        <div className="modal-confirm-button text-right mr-1">
          <button
            data-testid="deselect-all-modules"
            type="submit"
            className="btn btn-sm btn-auto-width mr-1"
            onClick={onDeselectedAllModules}
          >
            Deselect All Modules
          </button>
          <button
            data-testid="back"
            type="submit"
            className="btn btn-sm btn-red btn-auto-width mr-1"
            onClick={onBackCloseBtnClick}
          >
            Back
          </button>
          <button
            data-testid="select-module"
            type="submit"
            className="btn btn-sm btn-auto-width"
            disabled={hasExceededCreditLimit}
            onClick={onSaveBtnClick}
          >
            Confirm Selection
          </button>
        </div>
      </div>
    </div>
  );
};
