import React, { useState, Fragment } from 'react';

import { FurtherEducation, AttachmentTypes, AttachmentInput } from 'graphql/globalTypes';
import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import FormSelect from 'components/FormPrimitives/FormSelect';
import FormInput from 'components/FormPrimitives/FormInput';
import Attachment from 'components/Attachment';
import { funcAttachmentsSelect, FormAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { EducationTypes } from '../Education';
import { educationCountries } from 'data/selector_lists';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormsDatePickerComponent } from 'components/datepickerComponent';
import { promptToolTip } from 'services/constants';

interface IFETLFormProps {
  index: number;
  educationType: EducationTypes;
  job: FurtherEducation;
  onChange: (index: number, sectionDetail: FurtherEducation) => void;
  onAttachmentsSelect: funcAttachmentsSelect;
  onDelete: (sectionDetail: FurtherEducation) => void;
  recordTitle: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createCountryMap = (arr: string[]): any => {
  return arr.map((item: string) => [item, item]);
};

export const CountryDescription = new Map<string, string>(createCountryMap(educationCountries));

export const FETLForm: React.FC<IFETLFormProps> = (props): JSX.Element => {
  const inputData = Object.assign([], props.job);
  const [sectionDetail, setSectionDetail] = useState<FurtherEducation>(inputData);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'grade') {
      setSectionDetail(Object.assign(sectionDetail, { [name]: Number(value) }));
    } else {
      setSectionDetail(Object.assign(sectionDetail, { [name]: value }));
    }

    props.onChange(props.index, sectionDetail);
  };

  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { name, value } = event.currentTarget;
    setSectionDetail(Object.assign(sectionDetail, { [name]: value }));

    props.onChange(props.index, sectionDetail);
  };

  const onTextAreaChange: FormTextAreaChange = (value, index) => {
    setSectionDetail(Object.assign(sectionDetail, { [index as string]: value }));

    props.onChange(props.index, sectionDetail);
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target as { name: string; checked: boolean };
    const newSD = Object.assign(sectionDetail, { [name]: checked });
    setSectionDetail(newSD);

    props.onChange(props.index, newSD);
  };

  const uniqueName = (base: string): string => `${base}-${props.educationType.toString()}-${props.index}`;

  const getAttachmentData = (): AttachmentInput => {
    let attachmentType = AttachmentTypes.ThirdLevel;

    switch (props.educationType) {
      case EducationTypes.ThirdLevel:
        attachmentType = AttachmentTypes.ThirdLevel;
        break;

      case EducationTypes.FurtherEducation:
        attachmentType = AttachmentTypes.FurtherEducation;
        break;
    }

    return {
      attachmentType,
      idOne: sectionDetail.id,
    };
  };

  const onDeleteConfirm = (): void => {
    props.onDelete(props.job);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal.${props.educationType}.${props.index}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const DateCallback = (name: string, value: Date | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = value?.toISOString().substr(0, 10);
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  return (
    <Fragment>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={props.recordTitle}
        index={props.index}
        key={'del-FETL' + props.index}
        modalId={`delete_confirm_modal.${props.educationType}.${props.index}`}
      />

      {sectionDetail && (
        <div className="delete-item-button mb-0">
          <button
            key={'del' + sectionDetail.id}
            onClick={handleDeleteClick}
            className={'btn-sm btn-delete'}
            type="button"
          >
            Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
          </button>
        </div>
      )}
      <form className="secondary-container">
        <FormSelect
          name="country"
          uniqueName={uniqueName('country')}
          label={`Choose Country of ${
            props.educationType === EducationTypes.FurtherEducation ? 'College' : 'University'
          }/Institute`}
          values={educationCountries}
          valuesDescription={CountryDescription}
          fullwidth={true}
          onDataChange={onSelectChange}
          selectedValue={sectionDetail.country ?? undefined}
        />
        {props.educationType === EducationTypes.ThirdLevel && (
          <div>
            <label className="naric-label">
              If your qualification was received outside of Ireland, confirm that it corresponds, to a major award type
              and level on the Irish National Framework of Qualifications (NFQ) by consulting the{' '}
              <a
                href="https://qsearch.qqi.ie/WebPart/Search?searchtype=recognitions"
                target="new"
                data-testid="naric-link"
              >
                NARIC database
              </a>{' '}
            </label>
          </div>
        )}
        <FormInput
          value={sectionDetail.institute}
          name="institute"
          uniqueName={uniqueName('institute')}
          label={`Name of ${
            props.educationType === EducationTypes.FurtherEducation ? 'College' : 'University'
          }/Institute`}
          type="text"
          fullwidth={true}
          disabled={false}
          onDataChange={onChange}
        />
        <FormInput
          value={sectionDetail.qualification}
          name="qualification"
          uniqueName={uniqueName('qualification')}
          label="Title of course/qualification/award"
          type="text"
          fullwidth={true}
          disabled={false}
          onDataChange={onChange}
          tooltipenabled={true}
          tooltip="Specify the qualification you received on graduation."
        />
        <FormInput
          value={sectionDetail.grade}
          name="grade"
          uniqueName={uniqueName('grade')}
          label="Grade of qualification/award"
          type="number"
          fullwidth={true}
          disabled={false}
          onDataChange={onChange}
          tooltipenabled={true}
          tooltip="Provide the grade you received in your award."
          step="0.01"
        />
        <FormsDatePickerComponent
          name="startDate"
          uniqueName={uniqueName('startDate')}
          label="Start date"
          placeholder="Start date"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.startDate}
          fullwidth={true}
        />
        <FormsDatePickerComponent
          name="endDate"
          uniqueName={uniqueName('endDate')}
          label="End date"
          aria-labelledby="FormEndDate"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.endDate}
          fullwidth={true}
        />
        <div className="break"></div>
        <FormAttachmentsSelect
          key={'AttachmentSelect' + sectionDetail.id + sectionDetail.attachments?.filename ?? 'nofile'}
          name="attachment"
          uniqueName={uniqueName('attachment')}
          label="Upload certification"
          attachmentsData={getAttachmentData()}
          onAttachmentsSelect={props.onAttachmentsSelect}
          tooltip={promptToolTip}
        />
        <Attachment
          key={'Attachment' + sectionDetail.id + sectionDetail.attachments?.filename ?? 'nofile'}
          attachment={sectionDetail.attachments}
          displayLink={false}
        />
        <div className="break"></div>
        <FormInput
          name="fullTime"
          uniqueName={uniqueName('fullTime-tl')}
          label="Full time award"
          type="checkbox"
          fullwidth={true}
          onDataChange={onCheckboxChange}
          value="fulltime"
          checked={sectionDetail.fullTime ?? false}
        />
        <div className="break"></div>
        <FormTextArea
          name="courseInfo"
          uniqueName={uniqueName('courseInfo')}
          label="Award modules/skills Covered"
          onDataChange={onTextAreaChange}
          value={sectionDetail.courseInfo}
          tooltip="Provide details of the modules covered in the award and identify the skills and competencies you have developed as an outcome of your study."
          placeholder="Provide details of modules ....."
        />
      </form>
    </Fragment>
  );
};
