import { NavLink } from 'react-router-dom';

import HomeIcon from 'images/icons/home.svg';
import PersonalIcon from 'images/icons/user.svg';
// import InboxIcon from 'images/icons/inbox.svg';
// import ChevronIcon from 'images/icons/chevron.svg';
import DashboardHeader, { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import DashboardFooter from 'pages/Navigation/DashboardFooter';
import { ApplicantRoutes } from './ApplicantRoutes';
import { constants } from 'services/constants';

export const ApplicantView: React.FC<IUserStateDetail> = (props) => {
  let leftHomeMenuIcon;
  if (constants.FF_SHOW_HIDE_HOME) {
    leftHomeMenuIcon = (
      <li>
        <NavLink to="/home" className={({ isActive }) => (isActive ? 'active' : undefined) as string}>
          <img src={HomeIcon} alt="click for home" />
        </NavLink>
      </li>
    );
  }
  return (
    <div className="dashboard-view">
      <DashboardHeader isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} />
      <div className="dashboard-sidebar-left">
        <ul className="Private">
          {leftHomeMenuIcon}
          <li>
            <NavLink to="/personalinfo" className={({ isActive }) => (isActive ? 'active' : undefined) as string}>
              <img src={PersonalIcon} alt="User icon, linking to User Profile" />
            </NavLink>
          </li>
          {/*
          <li>
            <Link to="/mail">
              <img src={InboxIcon} alt="click for Inbox" />
            </Link>
          </li>
        </ul>
        <ul className="Private dashboard-settings">
          <li className="settings-btn">
            <img src={ChevronIcon} alt="click to expand menu bar" />
          </li>*/}
        </ul>
      </div>
      <ApplicantRoutes {...props} />
      <DashboardFooter />
    </div>
  );
};
