import { AdminUser } from 'graphql/globalTypes';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RedButton } from 'components/Buttons/RedButton';

interface IDeleteAction {
  setAdmin: (targetAdmin: AdminUser) => void;
  targetAdmin: AdminUser;
}

export const AdminDeleteActionButton: React.FC<IDeleteAction> = (props) => {
  return (
    <RedButton keyValue={`del${props.targetAdmin._id}`} onClick={(): void => props.setAdmin(props.targetAdmin)}>
      Delete
      <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
    </RedButton>
  );
};
