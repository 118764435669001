import { Route, Navigate, Routes } from 'react-router-dom';

import { IUserStateDetail } from '../Navigation/DashboardHeader';

import { ApplicationsView } from 'pages/Admin/ApplicationsView';
import { ApplicationReview } from 'pages/Admin/ApplicationReview';
import { SavePDF } from '../Pdf/SavePDF';
import AdminHome from './Sidemenu/AdminHome';
import { AdminCourseSelection } from './AdminCourseSelection';
import { CommonRoutes } from 'pages/Navigation/CommonRoutes';
import { IsAuthorized } from 'services/PrivateRoute';

export const AdminRoutes: React.FC<IUserStateDetail> = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace={true} to="/admin" />} />
        <Route
          path="/admin"
          element={
            <IsAuthorized {...props}>
              <AdminHome />
            </IsAuthorized>
          }
        />
        <Route
          path="/admin/select-course"
          element={
            <IsAuthorized {...props}>
              <AdminCourseSelection {...props} />
            </IsAuthorized>
          }
        />
        <Route
          path="/admin/applications-view/:courseId"
          element={
            <IsAuthorized {...props}>
              <ApplicationsView />
            </IsAuthorized>
          }
        />
        <Route
          path="/admin/application-review/*"
          element={
            <IsAuthorized {...props}>
              <ApplicationReview {...props} />
            </IsAuthorized>
          }
        />
        <Route
          path="/admin/SavePDF"
          element={
            <IsAuthorized {...props}>
              <SavePDF />
            </IsAuthorized>
          }
        />
        {CommonRoutes(props)}
      </Routes>
    </>
  );
};
