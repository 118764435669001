import { ApplicationStatus, User } from 'graphql/globalTypes';
import SubmittedForReviewApplications from './CourseApplicationsOverview/SubmittedForReviewApplications';
import InReviewApplications from './CourseApplicationsOverview/InReviewApplications';
import InformationRequestedApplications from './CourseApplicationsOverview/InformationRequestedApplications';
import AcceptedApplications from './CourseApplicationsOverview/AcceptedApplications';
import RefusedApplications from './CourseApplicationsOverview/RefusedApplications';
import ClosedApplications from './CourseApplicationsOverview/ClosedApplications';

interface IApplicationsOverview {
  applicants?: User[];
}
const CourseApplicationsOverview: React.FC<IApplicationsOverview> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) =>
      app.application?.currentStatus === ApplicationStatus.Reviewing ||
      app.application?.currentStatus === ApplicationStatus.Recommendaccept ||
      app.application?.currentStatus === ApplicationStatus.Recommendrefuse ||
      app.application?.currentStatus === ApplicationStatus.Registrarapproval ||
      app.application?.currentStatus === ApplicationStatus.Accepted ||
      app.application?.currentStatus === ApplicationStatus.Closed ||
      app.application?.currentStatus === ApplicationStatus.Moreinfo ||
      app.application?.currentStatus === ApplicationStatus.Refused ||
      app.application?.currentStatus === ApplicationStatus.Submitted
  ).length;
  return (
    <div className="col-4">
      <div className="panel-admin-dashboard p-1">
        <p className="mb-0 text-bold">Application Status Summary</p>
        <div className="active-applications-card">
          <h5 className="application-counter" data-testid="applicationscounter">
            {applicationCount}
          </h5>
          <h6 className="applications-title">Applications</h6>
          <div className="application-container">
            <SubmittedForReviewApplications applicants={props.applicants} />
            <InReviewApplications applicants={props.applicants} />
            <InformationRequestedApplications applicants={props.applicants} />
            <AcceptedApplications applicants={props.applicants} />
            <RefusedApplications applicants={props.applicants} />
            <ClosedApplications applicants={props.applicants} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseApplicationsOverview;
