import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { MobileMenuHandler } from 'pages/Navigation/MobileMenuHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UserAvatarButton } from 'pages/Navigation/UserAvatarButton';
// import Logo from 'images/logo/wit-white.png';
import Logo from 'images/logo/setu-white.png';

import LoginModal from 'pages/Login/LoginModal';
import { MarketingRoutes } from './MarketingRoutes';

interface IMarketingViewProps {
  isLoggedIn: boolean;
  courseId?: string;
  isAdmin: boolean;
}

export const MarketingView: React.FC<IMarketingViewProps> = (props) => {
  const onToggleLogin = (): void => {
    const login = document.getElementById('login_modal');

    if (login !== null) {
      if (login.classList.contains('show-modal')) {
        login.classList.remove('show-modal');
      } else {
        login.classList.add('show-modal');
      }
    }
  };

  return (
    <Fragment>
      <LoginModal courseId={props.courseId} />
      <header>
        <div className="topbar">
          <div className="topbar-logo">
            <a className="" href="/">
              {/* <img src={Logo} alt="Waterford Institute of Technology logo" /> */}
              <img src={Logo} alt="SETU logo" />
            </a>
          </div>
          <div className="topbar-tools">
            <MobileMenuHandler onToggleLogin={onToggleLogin} />
            <div className="topbar-search disabled temporary-hide" role="search">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className="topbar-user-marketing">
              {props.isLoggedIn ? (
                <Fragment>
                  <UserAvatarButton isAdmin={props.isAdmin} />
                  {/* <Link to="/home"> My Dashboard</Link>
                  <Link to="/logout"> Logout</Link> */}
                </Fragment>
              ) : (
                // <button
                //   id="login_btn"
                //   className="topbar-login btn-login nav-link"
                //   type="button"
                //   onClick={onToggleLogin}
                // >
                //   Login
                // </button>
                <a className={'btn-login nav-link'} onClick={onToggleLogin}>
                  Login
                </a>
              )}
            </div>
          </div>
          {/* <button className="hamburger">
            <i className="fas fa-bars"></i>
          </button>
          <button className="hamburger-loggedin">
            <i className="fas fa-bars"></i>
          </button> */}
        </div>
      </header>
      <MarketingRoutes {...props} />
    </Fragment>
  );
};
