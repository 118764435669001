import { NavLink } from 'react-router-dom';

import HomeIcon from 'images/icons/home.svg';
// import ApplicantsIcon from 'images/icons/applicants.svg';
// import InboxIcon from 'images/icons/inbox.svg';
// import NotesIcon from 'images/icons/notes.svg';
// import SettingsIcon from 'images/icons/settings.svg';
// import ChevronIcon from 'images/icons/chevron.svg';

import DashboardHeader, { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import DashboardFooter from 'pages/Navigation/DashboardFooter';

import { AdminRoutes } from 'pages/Admin/AdminRoute';

export const AdminView: React.FC<IUserStateDetail> = (props) => {
  return (
    <div className="dashboard-view">
      <DashboardHeader {...props} />
      <div className="dashboard-sidebar-left">
        <ul className="Private">
          <li>
            <NavLink to="/admin" className={({ isActive }) => (isActive ? 'active' : undefined) as string}>
              <img src={HomeIcon} alt="click for home" />
            </NavLink>
          </li>
          {/* <li className="disabled">
            <Link to="#">
              <img src={ApplicantsIcon} alt="click for application control sheet" />
            </Link>
          </li>
          <li className="disabled">
            <Link to="#">
              <img src={InboxIcon} alt="click for Inbox" />
            </Link>
          </li>
          <li className="disabled">
            <Link to="#">
              <img src={NotesIcon} alt="click for Notes" />
            </Link>
          </li> */}
        </ul>
        {/* <ul className="Private dashboard-settings">
          <li className="disabled">
            <Link to="#">
              <img src={SettingsIcon} alt="click for home" />
            </Link>
          </li>
          <li className="settings-btn disabled">
            <Link to="#">
              <img src={ChevronIcon} alt="click for application control sheet" />
            </Link>
          </li>
        </ul> */}
      </div>
      <AdminRoutes {...props} />
      <DashboardFooter />
    </div>
  );
};
