import React, { useState, useEffect } from 'react';

interface IMessageProps {
  error?: Error;
  onFinish?: () => void;
}

const FlashMessageError: React.FC<IMessageProps> = ({ error, onFinish }) => {
  const [fadeout, setFadeout] = useState(false);
  useEffect(() => {
    let isRunning = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;
    if (error && !fadeout) {
      timeout = setTimeout((): void => {
        if (isRunning) {
          setFadeout(true);
          if (onFinish) {
            onFinish();
          }
        }
      }, 5000);
    }
    return (): void => {
      isRunning = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  if (error && !fadeout) {
    return <div className="response error-response">{error.message}</div>;
  }

  return null;
};

export default FlashMessageError;
