import { ApplicationStatus, User } from 'graphql/globalTypes';
import UnderReview from 'components/ApplicationStatuses/UnderReview';

interface IApplications {
  applicants?: User[];
}
const InReviewApplications: React.FC<IApplications> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) =>
      app.application?.currentStatus === ApplicationStatus.Reviewing ||
      app.application?.currentStatus === ApplicationStatus.Recommendaccept ||
      app.application?.currentStatus === ApplicationStatus.Recommendrefuse ||
      app.application?.currentStatus === ApplicationStatus.Registrarapproval
  ).length;

  return (
    <div>
      <div className="application-statistics" data-testid="ircounter">
        {applicationCount}
      </div>
      <UnderReview />
    </div>
  );
};

export default InReviewApplications;
