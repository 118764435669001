import { useState } from 'react';
import { ApplicationGridParser } from 'components/ApplicationGridParser';

interface IGridComponent {
  Title?: string;
  Data: unknown[];
  ColumnNames: string[];
  ColumnFields: string[];
  ReturnFields: string[];
  CurrentIndex: number;
  onSelected: (index: number) => void;
  deleteIcon: ActionButton;
  switchIcon: ActionButton;
}

type ActionButton = { icon: JSX.Element; btnClass: string; handler: (inp: string) => void };

export const GridComponent: React.FC<IGridComponent> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(props.CurrentIndex);

  return (
    <>
      {props.Title?.length ? <p className="text-bold mb-0">{props.Title}</p> : null}
      <table>
        <thead>
          <tr>
            {props.ColumnNames.map((item) => (
              <th key={item}>{item}</th>
            ))}
            <th>Make active</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.Data?.map((itemData, index) => (
            <tr
              onClick={() => {
                setSelectedIndex(index);
                props.onSelected(index);
              }}
              key={'application_item_' + index}
              className={selectedIndex === index ? 'active' : ''}
            >
              {props.ColumnFields.map((item, index2) => (
                <td
                  className={props.CurrentIndex === index && !index2 ? 'activated-item' : ''}
                  key={'application_detail_' + index + '_' + index2}
                >
                  <ApplicationGridParser itemData={(itemData as never)[item]} />
                </td>
              ))}
              <td>{actionButton(props.switchIcon, (itemData as never)[props.ReturnFields[1]])}</td>
              <td>{actionButton(props.deleteIcon, (itemData as never)[props.ReturnFields[0]])}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const actionButton = (actionButton: ActionButton, returnField: string): JSX.Element => {
  return (
    <button
      className={actionButton.btnClass}
      onClick={() => {
        console.log('activateApplication clicked 2', returnField);
        actionButton.handler(returnField);
      }}
    >
      {actionButton.icon}
    </button>
  );
};
