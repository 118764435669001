import { ApplicationStatus, User } from 'graphql/globalTypes';
import SubmittedForReview from 'components/ApplicationStatuses/SubmittedForReview';

interface IApplications {
  applicants?: User[];
}
const SubmittedForReviewApplications: React.FC<IApplications> = (props) => {
  const applicationCount = props.applicants?.filter(
    (app) => app.application?.currentStatus === ApplicationStatus.Submitted
  ).length;

  return (
    <div data-testid="submittedforreviewapplications">
      <div className="application-statistics" data-testid="sfrcounter">
        {applicationCount}
      </div>
      <SubmittedForReview />
    </div>
  );
};

export default SubmittedForReviewApplications;
