import React, { useEffect, useState, Fragment } from 'react';

import { useLazyQuery } from '@apollo/client';

// import Logo from 'images/logo/wit-white.png';
import Logo from 'images/logo/setu-white.png';

import { ResendVerificationEmailQuery, ResendVerificationEmailQueryVariables } from 'graphql/globalTypes';
import { RESEND_VERIFICATION_EMAIL } from 'graphql/queries/users';

import { UserAvatarButton } from './UserAvatarButton';
import { Route, Routes } from 'react-router-dom';
import { Logout } from 'pages/Login/Logout';
import MarketingFooter from 'pages/Marketing/MarketingFooter';
import { Link } from 'react-router-dom';

enum statusMsg {
  Null = '',
  Waiting = 'Awaiting verification...',
  ErrorResendingEmail = 'Error resend email, Please contact the administrator',
  SendingEmail = 'Sending email verification request',
  Success = 'Verification email sent successfully',
  Failure = 'Account is already verified, Please Log in again!',
}

interface IVerifyAccountProps {
  isAdmin: boolean;
  isLoggedIn: boolean;
}

export const VerifyAccount: React.FC<IVerifyAccountProps> = (props) => {
  const [status, setStatus] = useState(statusMsg.Null);
  const [resendVerificationEmail, { error, called, loading, data: success }] = useLazyQuery<
    ResendVerificationEmailQuery,
    ResendVerificationEmailQueryVariables
  >(RESEND_VERIFICATION_EMAIL);

  useEffect(() => {
    if (error) {
      setStatus(statusMsg.ErrorResendingEmail);
    } else if (loading) {
      setStatus(statusMsg.SendingEmail);
    } else if (called && success) {
      if (success.resendVerificationEmail) {
        setStatus(statusMsg.Success);
      } else {
        setStatus(statusMsg.Failure);
      }
    } else {
      setStatus(statusMsg.Waiting);
    }
  }, [error, loading, success, called]);

  return (
    <Fragment>
      {/* {ROUTER CHECK HERE} */}
      <Routes>
        <Route path="/logout" element={<Logout />} />
      </Routes>
      <header>
        <div className="topbar">
          <div className="topbar-logo">
            <a className="" href="/">
              {/* <img src={Logo} alt="Waterford Institute of Technology logo" /> */}
              <img src={Logo} alt="SETU logo" />
            </a>
          </div>
          <div className="topbar-tools">
            <div className="topbar-user-marketing">
              <>
                <UserAvatarButton isAdmin={props.isAdmin} />
              </>
            </div>
          </div>
          <button className="hamburger">
            <i className="fas fa-bars"></i>
          </button>
        </div>
      </header>
      <section className="content-area">
        <div className="content-body">
          <div className="verification-notice">
            <h2 className="mb-1">Verify your account</h2>
            <h3 className="mb-1">A verification link has been sent to your email account.</h3>
            <p>
              Please use the link that has just been sent to your email address to verify your account. You will then
              need to sign out of this portal and sign in again using your email and password.
            </p>
            <button
              type="submit"
              className="btn btn-lg btn-secondary mt-1 mb-1"
              disabled={loading}
              onClick={() => resendVerificationEmail()}
            >
              Resend verification email
            </button>
            <div>{status}</div>
            <div>{status === statusMsg.Success && 'Please check your email'} </div>
            <Link to="/logout">
              <button className="btn  btn-secondary mt-1 mb-1 pl-2 pr-2">Sign Out</button>
            </Link>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </Fragment>
  );
};
