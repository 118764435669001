import React, { useContext } from 'react';

import { useApolloClient } from '@apollo/client';

import { CourseCtx, LoggedInStatusCtx, JwtCtx } from 'services/getCtx';
import { Course } from 'graphql/globalTypes';
import { LoggedInStatus } from './IsLoggedIn';
import { Navigate } from 'react-router-dom';

const clearApollo = async (setLoggedInStatus: (val: LoggedInStatus) => void): Promise<void> => {
  const client = useApolloClient();

  await client.clearStore();
  //await client.cache.reset();

  console.log('All Caches Cleared');

  setLoggedInStatus(LoggedInStatus.LoggedOut);
};

export const Logout: React.FC = () => {
  const [, setLoggedInStatus] = useContext(LoggedInStatusCtx);
  setLoggedInStatus(LoggedInStatus.LoggingOut);
  const [, setJwt] = useContext(JwtCtx);
  setJwt('');
  const [, setCourse] = useContext(CourseCtx);
  setCourse({} as Course);

  console.log('logout called');

  clearApollo(setLoggedInStatus);

  return <Navigate to={'/'} replace={true} />;
};
