import React, { Fragment } from 'react';

import { DateFormatter } from 'components/helper_date';
import { IndustryTraining } from 'graphql/globalTypes';
import { ShowFieldString } from './ReviewShowPrimitives';

interface IReviewSegmentIndustryTraining {
  industryTraining: IndustryTraining;
  count: number;
}

export const ReviewSegmentIndustryTraining: React.FC<IReviewSegmentIndustryTraining> = (props) => {
  const jobStatus = (
    <span>
      <DateFormatter date={props.industryTraining.endDate} />
    </span>
  );

  return (
    <Fragment>
      <div>
        <div className="review-section-block">
          <div className="grid-collapse">
            <div className="col-1">
              <div className="review-section-number">{props.count}</div>
            </div>
            <div className="col-11">
              {props.industryTraining.startDate && (
                <p>
                  From <DateFormatter date={props.industryTraining.startDate} /> to {jobStatus}
                </p>
              )}
              {ShowFieldString('Job title', props.industryTraining.jobTitle)}
              {ShowFieldString('Company name', props.industryTraining.companyName)}
              {ShowFieldString('Industry sector', props.industryTraining.NatureOfBusiness)}
              {ShowFieldString('Company address', props.industryTraining.companyAddress)}
              {ShowFieldString('examples of all work related training', props.industryTraining.workRelatedTraining)}
              {ShowFieldString(
                'Training events including conferences and webinars',
                props.industryTraining.trainingEvents
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
