import React, { Fragment, ReactElement, useContext, useState, useEffect } from 'react';
import { FormTextAreaChange } from './MessageInputArea';
import {
  Message,
  MessageInput,
  UserId,
  Roles,
  MutationSendMessageArgs,
  GetUserQuery,
  GetMessagesQuery,
  User,
  SendMessageMutation,
  MutationSetMessageReadArgs,
  GetUserQueryVariables,
} from 'graphql/globalTypes';
import { UserCtx } from 'services/getCtx';
import { GET_MESSAGES, SEND_MESSAGE, SET_MESSAGE_READ } from 'graphql/queries/messages';
import { useQuery, useMutation } from '@apollo/client';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';
import { GET_USER } from 'graphql/queries/users';
import SendMessageArea from './SendMessageArea';
import { DateFormatter } from 'components/helper_date';

const defaultSectionName = 'personal-info';

const ReviewBarMessaging: React.FC<IUserStateDetail> = (props) => {
  const [user] = useContext(UserCtx);
  console.log(props);
  console.log('review message user ...');
  console.log(user);

  const [currentApplicant, setCurrentApplicant] = useState<User>(user);
  const [currentApplication, setCurrentApplication] = useState(user.application);
  const {
    data: applicant,
    loading: applicantLoading,
    error: applicantError,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, { skip: props.isAdmin });
  const [setMessageRead] = useMutation<MutationSetMessageReadArgs>(SET_MESSAGE_READ);

  const section = (): string => {
    const currentURL = window.location.href;
    let sectionName = currentURL.substr(currentURL.lastIndexOf('/') + 1);
    if (!sectionName || sectionName.length === 0) {
      sectionName = defaultSectionName;
    }
    return sectionName;
  };

  const checkSection = (messageObj: Message): boolean => {
    let currentSection = messageObj.section;

    if (!currentSection || currentSection.length === 0) {
      currentSection = defaultSectionName;
    }

    return section().indexOf(currentSection) !== -1;
  };

  const [messages, setMessages] = useState<Message[]>();
  const [messageBody, setMessageBody] = useState<string>();

  const { data, loading, error } = useQuery<GetMessagesQuery>(GET_MESSAGES, {
    fetchPolicy: 'network-only', // set this to ensure local cache isn't used and new messages are read
    variables: {
      orderBy: { fieldName: 'createdAt', value: 'ASC' },
      applicationId: currentApplication?._id,
      pagination: { skip: 0, take: 1000 },
    },
  });

  const [sendMessage, { error: SendError }] = useMutation<SendMessageMutation, MutationSendMessageArgs>(SEND_MESSAGE, {
    onCompleted(data) {
      let oldMsgs: Message[] = [];

      if (messages) {
        oldMsgs = [...messages];
      }
      const newMsg = data.sendMessage;
      if (newMsg) {
        oldMsgs.push(newMsg);
      }

      setMessages(oldMsgs);
    },
  });

  const updateMessageReadStatus = async (messageItem: Message): Promise<void> => {
    try {
      await setMessageRead({
        variables: {
          messageId: messageItem._id,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const UpdateReadStatusIfNeeded = (messagesArray?: Message[]): void => {
    messagesArray?.filter(checkSection).map(async (messageItem) => {
      let shouldUpdate = true;

      if (
        (props.isAdmin && messageItem.toId?.role == Roles.Applicant) ||
        (!props.isAdmin && messageItem.toId?.role == Roles.Admin)
      ) {
        shouldUpdate = false;
      }

      if (messageItem.read !== true && shouldUpdate) {
        try {
          await updateMessageReadStatus(messageItem);

          const newMessages = messages?.map((message) => {
            return { ...message };
          });

          const updatedMessage = newMessages?.filter((messageInner) => {
            return messageInner._id === messageItem._id;
          });

          if (updatedMessage) {
            updatedMessage[0].read = true;
            setMessages(newMessages);
          }
        } catch (e) {
          throw e;
        }
      }
    });
  };

  useEffect(() => {
    if (applicant?.getUser?.application && !applicantLoading && !applicantError) {
      const current = applicant.getUser as User;
      setCurrentApplicant(current);
      setCurrentApplication(current.application);
    }

    console.log('useEffect:messageData:', data?.getMessages);
    if (data?.getMessages && !messages) {
      setMessages(data.getMessages.messages);
      UpdateReadStatusIfNeeded(data.getMessages.messages);
    }
  }, [applicant, applicantLoading, applicantError, messages, data]);

  if (loading) {
    return <div>Loading messages...</div>;
  }

  const onSendBtnClick = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const replyToMessage = messages && messages.length > 0 ? messages[0]._id : undefined;
    const toId = !props.isAdmin ? undefined : findToId();
    const messageInput: MessageInput = {
      body: messageBody,
      section: section(),
      applicationId: currentApplication?._id,
      replyToMessage: replyToMessage,
      date: new Date(),
    };

    if (toId) {
      Object.assign(messageInput, { toId });
    }

    try {
      await sendMessage({ variables: { message: messageInput } });
      setMessageBody('');
    } catch (err) {
      console.log('sendMessage Mutation Error', err.message);
    }
  };

  const findToId = (): UserId => {
    const toEmail = currentApplicant?.email;
    return {
      email: toEmail,
      role: props.isAdmin ? Roles.Applicant : Roles.Admin,
    };
  };

  //Enable indexing of lookup below by string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function hasKey<O extends object>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
  }

  const roleLookup = (role: string | null | undefined) => {
    const roleParsed = role as string;
    const roleMapping = {
      SUPERADMIN: 'Sys-admin',
      ADMIN: 'Administrator',
      DEPARTMENTHEAD: 'Head of Dept',
      COURSELEADER: 'Course leader',
      ASSISTANT: 'Course assistant',
      APPLICANT: 'Applicant',
    };

    if (hasKey(roleMapping, roleParsed)) {
      return roleMapping[roleParsed];
    } else {
      return;
    }
  };

  if (messages) {
    UpdateReadStatusIfNeeded(messages);
  }

  const messageThread = (): ReactElement => {
    return (
      <div className="message-thread">
        {messages?.filter(checkSection).map((messageItem) => {
          const fromId = messageItem.fromId;
          const sender = props.isAdmin ? Roles.Admin : Roles.Applicant;
          if (fromId && sender === fromId.role) {
            return (
              <div className="message-item message-outbound" key={messageItem._id}>
                <div className="message-item-content">
                  <p className="user-type">{fromId.name}</p>
                  <p>{messageItem.body}</p>
                  <div className="message-date">
                    <DateFormatter date={messageItem.createdAt} />
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="message-item message-inbound" key={messageItem._id}>
                <div className="message-item-content">
                  <p className="user-type text-red">
                    {roleLookup(fromId?.role)}: {fromId?.name}
                  </p>
                  <p>{messageItem.body}</p>
                  <p className="user-email">{fromId?.email}</p>
                  <div className="message-date">
                    <DateFormatter date={messageItem.createdAt} />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleMessageBodyInput: FormTextAreaChange = (value): void => {
    const newValue = value && value.length !== 0 ? value : undefined;
    setMessageBody(newValue);
  };

  return (
    <Fragment>
      <h4 className="mb-1">Comments</h4>
      {SendError && <div className="response error-response">Error sending message: {SendError.message}</div>}
      {error && <div className="response error-response">Error getting messages: {error.message}</div>}
      <div className="message-scroll-area">
        {messages && messageThread()}
        <div className="fadeout"></div>
      </div>
      <SendMessageArea
        section={section()}
        handleMessageBodyInput={handleMessageBodyInput}
        messageBody={messageBody}
        onSendBtnClick={onSendBtnClick}
        application={currentApplication}
      />
    </Fragment>
  );
};

export default ReviewBarMessaging;
