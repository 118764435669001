import React, { useState, useEffect, Fragment } from 'react';
import { PrimaryNav } from './PrimaryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';

const MobileMenuHandlerLoggedIn: React.FC<IUserStateDetail> = (props) => {
  const [mobileState, setMobileState] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const { pathname } = useLocation();
  const breakpointLg = 1520;

  useEffect(() => {
    document.body.style.overflow = 'visible';
    setisVisible(false);
  }, [pathname]);

  useEffect(() => {
    setMobileState(window.innerWidth <= breakpointLg);
    const handleResize = (): void => {
      setMobileState(window.innerWidth <= breakpointLg);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuIcon = (): IconDefinition => (isVisible ? faTimes : faBars);
  const handleClick = (): void => setisVisible(!isVisible);

  return (
    <Fragment>
      {!mobileState && (
        <div className={props.isLoggedIn ? 'topnav-wide' : 'topnav'}>
          <PrimaryNav />
        </div>
      )}

      {mobileState && (
        <div className={isVisible ? 'hamburger-open-loggedin' : 'hamburger-loggedin'} onClick={handleClick}>
          <FontAwesomeIcon icon={menuIcon()} transform={isVisible ? 'grow-2' : ''} />
        </div>
      )}

      {mobileState && (
        <div className={isVisible ? 'side-drawer-loggedin show' : 'side-drawer-loggedin hide'}>
          <PrimaryNav isLoggedIn={true} />
          <Link className={'btn-login nav-link'} to="/logout">
            Sign Out
          </Link>
        </div>
      )}
    </Fragment>
  );
};

export { MobileMenuHandlerLoggedIn };
