import { GreenStyleButtonWithLink } from 'components/ButtonWithLink/GreenStyleButtonWithLink';
import { AdminUser } from 'graphql/globalTypes';

interface IViewApplications {
  setAdmin: (targetAdmin: AdminUser) => void;
  targetAdmin: AdminUser;
}

export const AdminEditActionButton: React.FC<IViewApplications> = (props) => {
  return (
    <GreenStyleButtonWithLink
      link={`editadmin/${props.targetAdmin._id}`}
      keyValue={props.targetAdmin._id}
      buttonTitle={`Edit`}
      onClick={() => props.setAdmin(props.targetAdmin)}
    />
  );
};
