import { gql } from '@apollo/client';
import { MODULES_FIELDS } from './modules';

export const COURSE_WE_CRITERIA = gql`
  fragment WECriteriaQuery on WECriteria {
    jobTitle
    companyName
    NatureOfBusiness
    companyAddress
    startDate
    endDate
    keyResponsibilities
    skillsAndCompetencies
    promoted
    promotionDetails
  }
`;

export const COURSE_IT_CRITERIA = gql`
  fragment ITCriteriaQuery on ITCriteria {
    jobTitle
    companyName
    NatureOfBusiness
    companyAddress
    startDate
    endDate
    workRelatedTraining
    trainingEvents
  }
`;

export const COURSE_EDUCATION_CRITERIA = gql`
  fragment EducationCriteriaQuery on EducationCriteria {
    secondary
    further
    thirdLevel
  }
`;

export const COURSE_CRITERIA = gql`
  fragment CriteriaQuery on CourseCriteria {
    workExperienceCriteria {
      ...WECriteriaQuery
    }
    workExperienceState
    industryTrainingCriteria {
      ...ITCriteriaQuery
    }
    industryTrainingState
    educationCriteria {
      ...EducationCriteriaQuery
    }
    educationState
    scCriteria {
      title
      helperText
    }
    scState
  }

  ${COURSE_WE_CRITERIA}
  ${COURSE_IT_CRITERIA}
  ${COURSE_EDUCATION_CRITERIA}
`;

export const COURSE_QUERY_FIELDS = gql`
  fragment CourseFields on Course {
    _id
    witId
    supportExemptions
    courseLeaders
    departmentHead
    title
    shortDescription
    longDescription
    imageUrl
    remoteUrl
    campus
    entryRequirements
    Length
    credits
    entryLevel
    delivery
    moduleOverview
    activeFrom
    activeTo
    createdAt
    updatedAt
    criteria {
      ...CriteriaQuery
    }
    modules {
      ...ModuleFields
    }
  }

  ${COURSE_CRITERIA}
  ${MODULES_FIELDS}
`;

export const GET_COURSE = gql`
  query GetCourse($courseId: String!) {
    getCourse(courseId: $courseId) {
      ...CourseFields
    }
  }

  ${COURSE_QUERY_FIELDS}
`;

export const GET_COURSES = gql`
  query GetCourses {
    getCourses {
      ...CourseFields
    }
  }

  ${COURSE_QUERY_FIELDS}
`;
