interface IApplicationGridParser {
  itemData: never;
}

export const ApplicationGridParser: React.FC<IApplicationGridParser> = (props) => {
  return <>{dataTreatment(props.itemData)}</>;
};

const dataTreatment = (dataInp: never) => {
  switch (dataInp) {
    case 'presubmission':
      return <div className="status-label neutral-response">Pre-submission</div>;
    case 'submitted':
      return <div className="status-label notice-response">Submitted for review</div>;
    case 'reviewing':
      return <div className="status-label neutral-response">In review</div>;
    case 'moreinfo':
      return <div className="status-label alert-response">Information requested</div>;
    case 'accepted':
      return <div className="status-label success-response">Approved</div>;
    case 'refused':
      return <div className="status-label error-response">Refused</div>;
    case 'closed':
      return <div className="status-label notice-response">Closed</div>;
    case 'recommendaccept':
      return <div className="status-label notice-response">In review</div>;
    case 'registrarapproval':
      return <div className="status-label notice-response">In review</div>;
    case 'recommendrefuse':
      return <div className="status-label notice-response">In review</div>;
    default:
      return dataInp;
  }
};
