import { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import { Application, ApplicationStatus } from 'graphql/globalTypes';
import React from 'react';
import MessageInputArea from './MessageInputArea';

interface ISendMessageArea {
  messageBody: string | undefined;
  handleMessageBodyInput: FormTextAreaChange;
  onSendBtnClick: (e: React.FormEvent) => Promise<void>;
  application?: Application | null;
  section: string;
}

const SendMessageArea: React.FC<ISendMessageArea> = (props) => {
  if (props.application && props.application.currentStatus == ApplicationStatus.Presubmission) {
    return null;
  }

  if (props.section === 'review') {
    return null;
  }

  return (
    <div className="message-composer">
      <MessageInputArea
        key="Input"
        index={0}
        name="body"
        label="Add a comment"
        onDataChange={props.handleMessageBodyInput}
        value={props.messageBody}
        placeholder="Add message"
      />
      <div className="text-right">
        <button
          className="btn btn-sm btn-secondary"
          type="button"
          onClick={props.onSendBtnClick}
          disabled={!props.messageBody ? true : false}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default SendMessageArea;
