import React, { useState, Fragment, useEffect } from 'react';
import { Subject, AttachmentInput } from 'graphql/globalTypes';
import FormInput from 'components/FormPrimitives/FormInput';
import Attachment from 'components/Attachment';
import { FormAttachmentsSelect, funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { deepishCopyAssign } from 'services/utils';
import { promptToolTip } from 'services/constants';

interface ISubjectProps {
  index: number;
  subject: Subject;
  attachmentsData: AttachmentInput;
  onChange: (index: number, subjectDetail: Subject) => void;
  onAttachmentsSelect: funcAttachmentsSelect;
}

export const SubjectForm: React.FC<ISubjectProps> = (props): JSX.Element => {
  const [subject, setSubject] = useState(props.subject);

  useEffect(() => {
    setSubject(props.subject);
  }, [props.subject]);

  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    const newSubject = deepishCopyAssign(subject, name, name === 'grade' ? Number(value) : value, setSubject);

    props.onChange(props.index, newSubject);
  };

  const label = `Level of ${subject.subject} in Secondary school/Institute`;
  const certLabel = `${subject.subject} certification`;

  return (
    <Fragment>
      <FormInput
        value={subject.level}
        name="level"
        uniqueName={`level-sl-s-${props.index}`}
        label={label}
        type="text"
        fullwidth={true}
        disabled={false}
        onDataChange={onSubjectChange}
        tooltip="Provide the level at which you sat your exam - please note this is not the grade received."
        tooltipenabled={true}
      />
      <FormInput
        value={subject.grade}
        name="grade"
        uniqueName={`grade-sl-s-${props.index}`}
        label="Grade of award"
        type="number"
        fullwidth={true}
        disabled={false}
        onDataChange={onSubjectChange}
        tooltip="Provide the grade you received in your examination."
        tooltipenabled={true}
        step="0.01"
      />
      <FormInput
        value={subject.yearExam}
        name="yearExam"
        uniqueName={`yearExam-sl-s-${props.index}`}
        label="Year of exam"
        type="number"
        fullwidth={false}
        disabled={false}
        onDataChange={onSubjectChange}
      />
      <div className="break"></div>
      <FormAttachmentsSelect
        name="attachment"
        uniqueName={`attachment-sl-s-${props.index}`}
        label={certLabel}
        attachmentsData={props.attachmentsData}
        onAttachmentsSelect={props.onAttachmentsSelect}
        tooltip={promptToolTip}
      />
      <Attachment key={subject.attachments?.filepath} attachment={subject.attachments} displayLink={false} />
      <div className="break"></div>
    </Fragment>
  );
};
