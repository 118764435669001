import React, { Fragment, useContext } from 'react';

import { ReviewSegmentSkills } from 'pages/Review/ReviewSegmentSkills';
import { UserCtx, CourseCtx } from 'services/getCtx';

export const SkillsAndCompetencies: React.FC = () => {
  const [user] = useContext(UserCtx);
  const [course] = useContext(CourseCtx);

  return (
    <Fragment>
      <div className="panel panel-form">
        <div className="review-section">
          <h3>Skills &amp; Competencies</h3>
          <div>
            {user?.skillsCompetencies
              ?.filter((skillEntry) =>
                course?.criteria?.scCriteria?.find(
                  (item) => item.title?.toLowerCase() === skillEntry.title.toLowerCase()
                )
              )
              .map((skillEntry, index) => (
                <ReviewSegmentSkills skills={skillEntry} count={index + 1} />
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
