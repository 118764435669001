import { ApplicationStatus, User } from 'graphql/globalTypes';
import InformationRequested from 'components/ApplicationStatuses/InformationRequested';

interface IApplications {
  applicants?: User[];
}
const InformationRequestedApplications: React.FC<IApplications> = (props) => {
  console.log(props.applicants);

  const applicationCount = props.applicants?.filter(
    (app) => app.application?.currentStatus === ApplicationStatus.Moreinfo
  ).length;

  return (
    <div>
      <div className="application-statistics" data-testid="ifrcounter">
        {applicationCount}
      </div>
      <InformationRequested />
    </div>
  );
};

export default InformationRequestedApplications;
