import React from 'react';
import { Exemption, User } from 'graphql/globalTypes';
import UpdateExemptionStatus from './UpdateExemptionStatus';
import { constants } from 'services/constants';

export interface IExemptionStatusUpdate {
  exemption: Exemption;
  applicationId: string;
  isAdmin: boolean;
  user?: User;
}

const ShouldShowExemptionStatus: React.FC<IExemptionStatusUpdate> = (props) => {
  if (constants.FF_SHOW_EXEMPTION_STATUS && props.isAdmin) {
    return <UpdateExemptionStatus exemption={props.exemption} applicationId={props.applicationId} user={props.user} />;
  }

  return null;
};

export default ShouldShowExemptionStatus;
