import React, { useState, useEffect } from 'react';

import { Subject, SecondaryEducation, AttachmentTypes } from 'graphql/globalTypes';
import { SubjectForm } from './SubjectForm';
import Tooltip from 'components/Tooltip';
import FormSelect from 'components/FormPrimitives/FormSelect';
import FormInput from 'components/FormPrimitives/FormInput';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { funcEducationChange } from '../Education';
import { CountryDescription } from './FETLForm';
import { educationCountries } from 'data/selector_lists';
import { deepishCopyAssign } from 'services/utils';

interface SecondaryFormProps {
  secondary: SecondaryEducation[];
  onChange: funcEducationChange;
  onAttachmentsSelect: funcAttachmentsSelect;
}

export const SecondaryForm: React.FC<SecondaryFormProps> = (props) => {
  const [secondary, setSecondary] = useState(props.secondary[0]);

  useEffect(() => {
    setSecondary(props.secondary[0]);
  }, [props.secondary]);

  const onSubjectChange = (index: number, subject: Subject): void => {
    const newSubs = deepishCopyAssign(secondary.subjects, index, subject);
    const newSecondary = deepishCopyAssign(secondary, 'subjects', newSubs, setSecondary);

    props.onChange({ SL: [newSecondary] });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    const newSecondary = deepishCopyAssign(secondary, name, value, setSecondary);

    props.onChange({ SL: [newSecondary] });
  };

  const onSelectChange = (event: React.FormEvent<HTMLSelectElement>): void => {
    const { name, value } = event.currentTarget;

    const newSecondary = deepishCopyAssign(secondary, name, value, setSecondary);

    props.onChange({ SL: [newSecondary] });
  };

  return (
    <div className="education-form-container">
      <h3>
        Second Level Education
        <Tooltip tooltip="Provide details of all relevant institutes at which you have previously studied." />
      </h3>
      <form className="secondary-container">
        <FormSelect
          name="country"
          uniqueName={`country-sl-0`}
          label="Choose country of Secondary school/Institute"
          values={educationCountries}
          valuesDescription={CountryDescription}
          fullwidth={true}
          onDataChange={onSelectChange}
          selectedValue={secondary?.country ?? undefined}
        />
        <FormInput
          value={secondary?.institute}
          name="institute"
          uniqueName={`institute-sl-0`}
          label="Name of Secondary school/Institute"
          type="text"
          fullwidth={true}
          disabled={false}
          onDataChange={onChange}
        />

        {secondary?.subjects &&
          secondary.subjects.map((item, index) => {
            return (
              <SubjectForm
                key={(item.subject ?? 'subject' + index) + item.attachments?.filename}
                index={index}
                subject={item}
                attachmentsData={{
                  attachmentType: AttachmentTypes.Subject,
                  idOne: props.secondary?.[0]?.id,
                  idTwo: item.id,
                }}
                onChange={onSubjectChange}
                onAttachmentsSelect={props.onAttachmentsSelect}
              />
            );
          })}
      </form>
    </div>
  );
};
