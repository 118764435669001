import React from 'react';
import MarketingFooter from './MarketingFooter';
import Hero from 'components/hero/Hero';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';

const Privacy: React.FC<IUserStateDetail> = (props) => {
  return (
    <div>
      <Hero {...props} {...{ target: 'cookies' }} />
      <section id="content-area">
        <div className="content-area">
          <div className="content-header">
            <h2>Cookies</h2>
          </div>
          <div className="content-body">
            <h3>Cookie policy</h3>
            <p>
              rpl4lifelonglearning.ie and associated websites use cookies. Cookies are small text files that are placed
              on your computer by websites that you visit. They are widely used to make websites work, or work more
              efficiently, as well as to provide information to the owners of the site.
            </p>
            <p>
              There are two main kinds of cookies: session cookies and persistent cookies. Session cookies are deleted
              from your computer when you close your browser, whereas persistent cookies remain stored on your computer
              until deleted, or until they reach their expiry date.
            </p>
            <p>
              When you first visit the Recognition of Prior Learning website, you will be given an option to accept or
              change your cookie preference. Once you accept or amend, you won’t be shown this message again from that
              browser. However, you can amend your preferences at any time.
            </p>
            <h3>Managing cookies</h3>
            <p>
              Your browser settings will automatically allow cookies to be accepted. Should you wish to switch off this
              feature, you can do so by visiting the “options” or “preferences” setting on your browser. You learn how
              to access these settings directly through the following links:
              <li>
                <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">
                  Google Chrome
                </a>
              </li>
              <li>
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                  Mozilla Firefox
                </a>
              </li>
              <li>
                <li>
                  <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft Edge</a>
                </li>
                <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                  Microsoft Internet Explorer
                </a>
              </li>
              <li>
                <a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a>
              </li>
              <li>
                <a href="https://support.apple.com/kb/ph21411?locale=en_US">Apple Safari</a>
              </li>
            </p>
            <p>
              Please note that declining our cookies or asking for notification each time a cookie is being sent, may
              affect your ease of use of our website and the Services. For more information about cookies, please see:{' '}
              <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a> and{' '}
              <a href="http://youronlinechoices.eu/">youronlinechoices.eu</a>.
            </p>
            <h3>Google Analytics</h3>
            <p>rpl4lifelonglearning.ie does not capture personal data through Google Analytics cookies.</p>
            <p>
              _ga : This cookie is used to monitor site usage for the site’s analytics report. The cookie stores
              information anonymously and assigns a randomly generated number to distinguish unique visitors.
            </p>
            <p>_gat : This cookie is used to throttle the request rate to limit the collection of data.</p>
            <p>
              _gid : This cookie is used to store information of how visitors use a website and helps in creating an
              analytics report of how well the website is operating. The data collected includes the number visitors,
              how they arrived at the site, and the pages they visited. The data collected maintains user anonymity.
            </p>

            <p>This webpage was last updated on 10 May 2021.</p>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </div>
  );
};

export default Privacy;
