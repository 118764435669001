import { Course } from 'graphql/globalTypes';

interface IViewApplications {
  viewAllApplications: () => void;
  currentCourse?: Course;
}

export const ViewAllApplications: React.FC<IViewApplications> = (props) => {
  console.log('current course under Course Selection component.');
  console.log(props.currentCourse);
  if (!props.currentCourse) {
    return null;
  }

  return (
    <div className="view-all-applications">
      <button
        data-testid="vaabutton"
        className="btn-md btn-secondary"
        type="button"
        onClick={(): void => props.viewAllApplications()}
      >
        <span>View All Applications</span>
      </button>
    </div>
  );
};
