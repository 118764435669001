import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const PERSONAL_DETAIL_FIELDS = gql`
  fragment PersonalDetailsFields on PersonalDetails {
    firstName
    lastName
    ppsNo
    dateOfBirth
    firstLanguage
    gender
    phone
    profilePicture {
      ...SolleAttachmentFields
    }
    englishLanguageCert {
      ...SolleAttachmentFields
    }
    cv {
      ...SolleAttachmentFields
    }
  }
  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const GET_PERSONAL_DETAILS = gql`
  query getPersonalDetails {
    getUser {
      email
      personalDetails {
        ...PersonalDetailsFields
      }
    }
  }

  ${PERSONAL_DETAIL_FIELDS}
`;

export const UPDATE_PERSONAL_DETAIL = gql`
  mutation UpdatePersonalDetail($personalDetails: PersonalDetailsInput!) {
    updatePersonalDetails(personalDetails: $personalDetails) {
      personalDetails {
        ...PersonalDetailsFields
      }
    }
  }
  ${PERSONAL_DETAIL_FIELDS}
`;
