import { useMutation } from '@apollo/client';
import { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import {
  AdminPersonalDetails,
  AdminUser,
  MutationUpdateAdminUserArgs,
  Roles,
  UpdateAdminUserMutation,
} from 'graphql/globalTypes';
import { UPDATE_ADMIN_USER } from 'graphql/queries/adminusers';
import { useContext, useEffect, useState } from 'react';
import CreateAdminUserForm from './Forms/CreateAdminUserForm';
import { GreenButton } from 'components/Buttons/GreenButton';
import { AdminUserCtx } from 'services/getCtx';
import { useParams } from 'react-router-dom';

interface IEditAdminUser {
  // adminId?: string;
  onUpdatingAdminUser?: (success: boolean) => void;
  updating: boolean;
}

const initialAdmin = {
  email: '',
  role: Roles.Admin,
  _id: '',
  personalDetails: {
    title: undefined,
    gender: undefined,
    firstName: undefined,
    lastName: undefined,
    department: undefined,
    staffId: undefined,
  },
  isVerified: false,
  createdAt: '',
  updatedAt: '',
};

export const EditAdminUser: React.FC<IEditAdminUser> = (props) => {
  const padminId = useParams().adminId;
  const [admin, setAdmin] = useState<AdminUser>(initialAdmin);
  const [updated, setUpdated] = useState<boolean>(!props.updating);
  const [adminUser] = useContext(AdminUserCtx);
  const [adminId, setAdminId] = useState<string>();

  const [updateAdmin, { error }] = useMutation<UpdateAdminUserMutation, MutationUpdateAdminUserArgs>(UPDATE_ADMIN_USER);

  useEffect(() => {
    if (props.updating) {
      setUpdated(!props.updating);
      setAdmin(adminUser);
    }
  }, [props.updating]);

  useEffect(() => {
    if (adminId !== padminId && padminId) {
      setAdminId(padminId);
      setAdmin(adminUser);
      setUpdated(false);
    }
  }, [padminId]);

  const onChangeAdmin = (event: FormInputChangeParam): void => {
    let adminData: AdminUser = { ...admin, [event.target.name]: event.target.value };

    if (event.target.name === 'role' && event.target.value === '-- select an option --') {
      adminData = { ...adminData, [event.target.name]: Roles.Admin };
    }

    console.log(adminData);
    setAdmin(adminData);
  };

  const onChangePD = (event: FormInputChangeParam): void => {
    let newPersonal: AdminPersonalDetails = {
      ...admin.personalDetails,
      [event.target.name]: event.target.value,
    };

    if (
      (event.target.name === 'gender' || event.target.name === 'title') &&
      event.target.value === '-- select an option --'
    ) {
      newPersonal = { ...newPersonal, [event.target.name]: null };
    }

    const newData = { ...admin, ['personalDetails']: newPersonal };
    setAdmin(newData);
  };

  const updateButton = async (): Promise<void> => {
    const { _id, __typename, createdAt, updatedAt, ...basicAdmin } = admin;

    if (basicAdmin.personalDetails) {
      delete basicAdmin.personalDetails.__typename;
    } else {
      basicAdmin.personalDetails = { firstName: '' };
    }

    try {
      await updateAdmin({ variables: { adminUser: basicAdmin } });

      if (props.onUpdatingAdminUser) {
        props.onUpdatingAdminUser(true);
      }

      setUpdated(true);
    } catch (err) {
      console.log('EditAdminUser.tsx:updateAdmin:Error: ', err);
    }
  };

  return (
    <div data-testid={'editadminid'} key={admin?._id ?? 'updatecomponent'}>
      {updated && <div className="response success-response">The admin user has been updated successfully</div>}
      {error && <div className="response error-response">Error updating admin users: {error.message}</div>}

      {!updated && (
        <div className="application-form-area">
          <h2 data-testid="updateadminsection">Update Admin User</h2>
          <CreateAdminUserForm
            onChangeAdmin={onChangeAdmin}
            onChangePD={onChangePD}
            isEditing={true}
            email={admin?.email}
            role={admin?.role}
            personalDetails={admin?.personalDetails}
          />
          <div>
            <GreenButton onClick={updateButton} keyValue={'updatingadmin'}>
              Update
            </GreenButton>
          </div>
        </div>
      )}
    </div>
  );
};
