import React from 'react';
import Hero from 'components/hero/Hero';
import MarketingFooter from './MarketingFooter';
import map from 'images/map/map-marked-setu.jpg';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

const ContactUs: React.FC<IUserStateDetail> = (props) => {
  return (
    <div>
      <Hero {...props} {...{ target: 'contact' }} />
      <section className="contact-map-area">
        <div className="grid-collapse">
          <div className="col-6">
            <div className="contact-card">
              <div className="underline-container">
                <h1 className="underline-blue">Contact</h1>
              </div>
              <div className="mt-1">
                <h4>For general enquiries on RPL</h4>
                <address>
                  Email:{' '}
                  <a className="link-text" href="mailto:rpl4lifelonglearning@wit.ie">
                    rpl4lifelonglearning@wit.ie
                  </a>
                </address>
                <h4 className="mt-1">For specific RPL queries</h4>
                <a className="link-text text-bolder " href="http://www.wit.ie/ictskills">
                  <p className="mt-1 mb-0">Higher Diploma in Computer Science</p>
                </a>
                <address>
                  Eamonn de Leastar (Course leader):{' '}
                  <a className="link-text" href="mailto:edeleastar@setu.ie">
                    edeleastar@setu.ie
                  </a>
                </address>
                <address>
                  Colm Dunphy (Course leader):{' '}
                  <a className="link-text" href="mailto:cdunphy@setu.ie">
                    cdunphy@setu.ie
                  </a>
                </address>
                <address>
                  Martina Mullally (Course co-ordinator):{' '}
                  <a className="link-text" href="mailto:mmmullally@setu.ie">
                    mmmullally@setu.ie
                  </a>
                </address>
                <a
                  className="link-text text-bolder"
                  href="https://www.wit.ie/courses/bachelor_of_arts_in_adult_education"
                >
                  <p className="mt-1 mb-0">Bachelor of Arts in Adult and Further Education</p>
                </a>
                <address>
                  Anne Marie Cullen (Course leader):{' '}
                  <a className="link-text" href="mailto:acullen@setu.ie">
                    acullen@setu.ie
                  </a>
                </address>
                <address>
                  Janet Syms (Course Administrator):{' '}
                  <a className="link-text" href="mailto:jsyms@setu.ie">
                    jsyms@setu.ie
                  </a>
                </address>
                {/* <address>
                  Address: Main Campus Cork Road
                  <br />
                  Waterford City
                  <br />
                  Co.Waterford
                  <br />
                  Ireland
                </address> */}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="setu-map">
              <img src={map} alt="Map showing South East Technological University campus location." />
            </div>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </div>
  );
};

export default ContactUs;
