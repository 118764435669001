import React, { Fragment, useContext } from 'react';

import { ReviewSegmentWorkExperience } from 'pages/Review/ReviewSegmentWork';
import { UserCtx } from 'services/getCtx';

export const WorkExperience: React.FC = () => {
  const [user] = useContext(UserCtx);

  return (
    <Fragment>
      <div className="panel panel-form">
        <div className="review-section">
          <h3>Work experience</h3>
          <div>
            {user?.workExperience?.map((workEntry, index) => {
              return <ReviewSegmentWorkExperience workExperience={workEntry} count={index + 1} key={workEntry.id} />;
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
