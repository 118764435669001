export interface IValidatePassword {
  validUppercase: boolean;
  validLowercase: boolean;
  validNumber: boolean;
  validLength: boolean;
  validMatch: boolean;
}

const passwordLength = {
  min: 8,
  max: 64,
};

export const validatePassword = (checkPassword: string, truthMatrix: IValidatePassword): IValidatePassword => {
  truthMatrix.validLength = checkPassword.length >= passwordLength.min && checkPassword.length <= passwordLength.max;
  truthMatrix.validUppercase = hasUpperCase(checkPassword);
  truthMatrix.validLowercase = hasLowerCase(checkPassword);
  truthMatrix.validNumber = hasNumber(checkPassword);

  return truthMatrix;
};

export const confirmMatch = (
  checkPassword: string,
  checkRepeatPassword: string,
  truthMatrix: IValidatePassword
): boolean => (truthMatrix.validMatch = checkPassword === checkRepeatPassword);

const hasUpperCase = (pwd: string): boolean => {
  // return pwd.toLowerCase() !== pwd;
  return /[A-Z]/.test(pwd);
};

const hasLowerCase = (pwd: string): boolean => {
  // return pwd.toUpperCase() !== pwd;
  return /[a-z]/.test(pwd);
};

const hasNumber = (pwd: string): boolean => {
  return /\d/.test(pwd);
};
