import { User } from 'graphql/globalTypes';
import ApplicationGrid from '../ApplicationGrid';

interface IStaleApplicationsProps {
  applicants?: User[];
  onSelected: (targetApplicant: User) => void;
}

export const StaleApplications: React.FC<IStaleApplicationsProps> = (props) => {
  const filterApplications = (): User[] | [] => {
    const currentDate = new Date();
    const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
    const last30DaysDateTime = last30DaysDate.getTime();

    const staleApplications = props.applicants?.filter((applicant) => {
      const appDatetime = new Date(applicant.application?.updatedAt).getTime();
      console.log(applicant.application?.updatedAt);
      if (appDatetime < last30DaysDateTime) {
        return true;
      }
      return false;
    });

    if (!staleApplications) {
      return [];
    }

    return staleApplications;
  };

  if (!props.applicants || props.applicants.length === 0) {
    return null;
  }

  const filterApp = filterApplications();
  // filterApp.length = 5;

  if (filterApp.length === 0) {
    return null;
  }

  return (
    <div className="col-6" data-testid="stale-applications">
      <div className="panel p-1">
        <div className="applicant-saved-applications">
          <ApplicationGrid
            Title={'Applications Not updated 30 days'}
            applicants={filterApp}
            onSelected={props.onSelected}
          />
        </div>
      </div>
    </div>
  );
};
