import PersonalIcon from 'images/icons/personal.svg';
import WorkIcon from 'images/icons/work.svg';
import EducationIcon from 'images/icons/education.svg';
import SkillsIcon from 'images/icons/skills.svg';

import { PersonalInfo } from 'pages/Admin/application-review/ReviewPersonalInfo';
import { WorkExperience } from 'pages/Admin/application-review/ReviewWorkExperience';
import { Education } from 'pages/Admin/application-review/ReviewEducation';
import { SkillsAndCompetencies } from 'pages/Admin/application-review/ReviewSkillsAndCompetencies';
import { Exemptions } from 'pages/Admin/application-review/ReviewExemptions';
import { MenuItem, MenuType } from 'pages/Navigation/menuItems';

export const ApplicantReviewMenuItems: MenuItem[] = [
  {
    menuType: MenuType.PersonalData,
    title: 'Personal Information',
    comp: PersonalInfo,
    icon: PersonalIcon,
    link: 'personal-info',
    blurb: "Applicant's personal information.",
  },
  {
    menuType: MenuType.WorkExperience,
    title: 'Work Experience',
    comp: WorkExperience,
    icon: WorkIcon,
    link: 'work-experience',
    blurb: "Details of applicant's past work experience.",
  },
  {
    menuType: MenuType.Education,
    title: 'Education and Training',
    comp: Education,
    icon: EducationIcon,
    link: 'education',
    blurb: "Details of applicant's previous, certified education and training.",
  },
  {
    menuType: MenuType.Skills,
    title: 'Skills and Competencies',
    comp: SkillsAndCompetencies,
    icon: SkillsIcon,
    link: 'skills',
    blurb: "Details of applicant's skills and competencies.",
  },
  {
    menuType: MenuType.Exemption,
    title: 'Exemptions',
    comp: Exemptions,
    icon: SkillsIcon,
    link: 'exemptions',
    blurb: "Details of applicant's requests for exemption.",
  },
];
