import React, { Fragment } from 'react';

import { FurtherEducation } from 'graphql/globalTypes';
import { ShowFieldAttachment, ShowFieldString } from './ReviewShowPrimitives';
import { DateFormatter } from 'components/helper_date';

interface IReviewSegmentFurtherEducation {
  furtherEducation: FurtherEducation;
  count: number;
}

export const ReviewSegmentFurtherEducation: React.FC<IReviewSegmentFurtherEducation> = (props) => {
  const enrolledStatus = !props.furtherEducation.endDate ? (
    'present'
  ) : (
    <span>
      <DateFormatter date={props.furtherEducation.endDate} monthYearFormat={true} />
    </span>
  );

  return (
    <Fragment>
      <div className="review-section-block">
        <div className="grid-collapse">
          <div className="col-1">
            <div className="review-section-number">{props.count}</div>
          </div>
          <div className="col-11">
            {props.furtherEducation.startDate && (
              <p>
                From <DateFormatter date={props.furtherEducation.startDate} monthYearFormat={true} /> to{' '}
                {enrolledStatus}
              </p>
            )}
            {props.furtherEducation.courseTitle && ShowFieldString('Course title', props.furtherEducation.courseTitle)}
            {ShowFieldString('Qualification', props.furtherEducation.qualification)}
            {ShowFieldString('Grade', props.furtherEducation.grade?.toString())}
            {ShowFieldString('Institute', props.furtherEducation.institute)}
            {ShowFieldString('Country', props.furtherEducation.country)}
            <div className="review-group">
              <label>Award details</label>
              <p>{props.furtherEducation.courseInfo}</p>
              {props.furtherEducation.fullTime && <p>This was a fulltime award.</p>}
            </div>
            {props?.furtherEducation.attachments?.filename &&
              ShowFieldAttachment('Transcript', props.furtherEducation.attachments)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
