import { Navigate, Route, Routes } from 'react-router-dom';

import { useGoogleAnalytics } from 'services/googleAnalytics';

import { MarketingHome } from 'pages/Marketing/MarketingHome';
import { CommonRoutes } from 'pages/Navigation/CommonRoutes';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

export const MarketingRoutes: React.FC<IUserStateDetail> = (props) => {
  useGoogleAnalytics();

  return (
    <>
      <Routes>
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route path="/" element={<MarketingHome {...props} />} />
        <Route path="/forgot/:token" element={<MarketingHome {...props} />} />
        {/* <PrivateRoute path="/dashboard" component={Courses} {...props} /> */}
        {CommonRoutes(props)}
      </Routes>
    </>
  );
};
