import { Navigate, Route, Routes } from 'react-router-dom';

import { IsAuthorized } from 'services/PrivateRoute';

import { IUserStateDetail } from '../Navigation/DashboardHeader';

import { PersonalDetails } from 'pages/Applicant/Sidemenu/PersonalDetails';
import { useGoogleAnalytics } from 'services/googleAnalytics';

import Home from 'pages/Applicant/Sidemenu/Home';
// import Mail from 'pages/Applicant/Sidemenu/Mail';
import { constants } from 'services/constants';
import { CommonRoutes } from 'pages/Navigation/CommonRoutes';

export const ApplicantRoutes: React.FC<IUserStateDetail> = (props) => {
  useGoogleAnalytics();

  return (
    <Routes>
      <Route path="/" element={<Navigate to={constants.FF_SHOW_HIDE_HOME ? '/home' : '/personalinfo'} />} />
      {/* <Route path="/home" element={<Home {...props} />} /> */}
      <Route
        path="/home"
        element={
          <IsAuthorized {...props}>
            <Home {...props} />
          </IsAuthorized>
        }
      />
      <Route
        path="/personalinfo/*"
        element={
          <IsAuthorized {...props}>
            <PersonalDetails {...props} />
          </IsAuthorized>
        }
      />
      {/* <PrivateRoute path="/mail" component={Mail} {...props} /> */}
      {CommonRoutes(props)}
    </Routes>
  );
};
