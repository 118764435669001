import { useMutation } from '@apollo/client';
import { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import {
  AdminPersonalDetailsInput,
  AdminUserInput,
  CreateAdminUserMutation,
  MutationCreateAdminUserArgs,
} from 'graphql/globalTypes';
import { CREATE_ADMIN_USER } from 'graphql/queries/adminusers';
import { Fragment, useEffect, useState } from 'react';
import CreateAdminUserForm from './Forms/CreateAdminUserForm';
import { GreenButton } from 'components/Buttons/GreenButton';

interface ICreateAdminUser {
  onCreatingAdminUser?: (success: boolean) => void;
  creating: boolean;
}

export const CreateAdminUser: React.FC<ICreateAdminUser> = (props) => {
  const [created, setCreated] = useState<boolean>(!props.creating);
  const [adminPassword, setAdminPassword] = useState<string>();
  const [admin, setAdmin] = useState<AdminUserInput>({ personalDetails: {} });

  const [createAdmin, { error }] = useMutation<CreateAdminUserMutation, MutationCreateAdminUserArgs>(
    CREATE_ADMIN_USER,
    {
      onCompleted: (data) => {
        console.log(data);
        setAdminPassword(data.createAdminUser.password);
        setCreated(true);
      },
    }
  );

  useEffect(() => {
    if (props.creating) {
      setCreated(!props.creating);
      setAdmin({
        personalDetails: {
          // firstName: '',
        },
      });
    }
  }, [props.creating]);

  const onChangeAdmin = (event: FormInputChangeParam): void => {
    let adminData: AdminUserInput = { ...admin, [event.target.name]: event.target.value };

    if (event.target.name === 'role' && event.target.value === '-- select an option --') {
      adminData = { ...adminData, [event.target.name]: null };
    }

    console.log(adminData);

    // if (adminData.personalDetails?.firstName === undefined) {
    //   adminData = { ...adminData, personalDetails: { firstName: '' } };
    // }

    setAdmin(adminData);
  };

  const onChangePD = (event: FormInputChangeParam): void => {
    let newPersonal: AdminPersonalDetailsInput = {
      ...admin.personalDetails,
      [event.target.name]: event.target.value,
    };

    if (
      (event.target.name === 'gender' || event.target.name === 'title') &&
      event.target.value === '-- select an option --'
    ) {
      newPersonal = { ...newPersonal, [event.target.name]: null };
    }

    const newData = { ...admin, ['personalDetails']: newPersonal };
    console.log(newData);
    setAdmin(newData);
  };

  const createButton = async (): Promise<void> => {
    console.log(admin);
    try {
      await createAdmin({ variables: { adminUser: admin } });

      if (props.onCreatingAdminUser) {
        props.onCreatingAdminUser(true);
      }
    } catch (err) {
      console.log('CreateAdminUser.tsx:onCreateAdmin:Error: ', err);
    }
  };

  return (
    <Fragment>
      {created && (
        <div className="response success-response">
          The new admin user has been created successfully. Here is the auto generated password for this user:{' '}
          {adminPassword}
        </div>
      )}
      {error && <div className="response error-response">Error create admin users: {error.message}</div>}

      {!created && (
        <div className="application-form-area">
          <h2 data-testid="createadminsection">Create Admin User</h2>
          <CreateAdminUserForm
            onChangeAdmin={onChangeAdmin}
            onChangePD={onChangePD}
            email={admin?.email ?? undefined}
            role={admin?.role ?? undefined}
            personalDetails={admin.personalDetails}
          />
          <div>
            <GreenButton onClick={createButton} keyValue={'creatingadmin'}>
              Create Admin User
            </GreenButton>
          </div>
        </div>
      )}
    </Fragment>
  );
};
