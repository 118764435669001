import React, { useContext, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Datasort from 'react-data-sort';

import Avatar from 'images/samples/avatar.png';

import { DateFormatter } from 'components/helper_date';
import { ApplicationStatus, User } from 'graphql/globalTypes';
import { GET_USERS_BY_COURSE } from 'graphql/queries/users';

import { UserCtx } from 'services/getCtx';
import { DashboardTopToolbar } from 'components/DashboardTopToolbar';
import { decodeToken } from 'services/validation';
import { StatusFilterComponent } from 'components/StatusFilterComponent';
import { ExemptionsFilterComponent } from 'components/ExemptionsFilterComponent';
import { FilterBarComponent } from 'components/FilterBarComponent';
import { DateChangedFilterComponent } from 'components/DateChangedFilterComponent';
import { StatusDescription } from './application-review/UpdateApplicationStatus';
import { ApplicationLink } from './Home/ApplicationLink';

interface IQueryUserList {
  getUsersByCourse: User[];
}

export const ApplicationsView: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus>();
  const [exemptionFilter, setExemptionFilter] = useState<boolean>();
  const [startDateFilter, setStartDate] = useState<Date | null>();
  const [endDateFilter, setEndDate] = useState<Date | null>();
  const { courseId } = useParams();
  const [, setUser] = useContext(UserCtx);
  const { data: userList } = useQuery<IQueryUserList>(GET_USERS_BY_COURSE, {
    variables: { courseId },
  });

  const StatusFilterCallback = (status?: ApplicationStatus) => {
    setStatusFilter(status);
  };

  const ExemptionFilterCallback = (status?: boolean) => {
    setExemptionFilter(status);
  };

  const StartDateFilterCallback = (startDate?: unknown) => {
    setStartDate(startDate as Date);
  };

  const EndDateFilterCallback = (endDate?: unknown) => {
    setEndDate(endDate as Date);
  };

  return (
    <section>
      <div className="dashboard-main-area">
        <div className="applicant-listing">
          <DashboardTopToolbar label="Select award" destination="/admin" />
          <FilterBarComponent>
            <DateChangedFilterComponent
              startDate={startDateFilter}
              onSetStartDate={StartDateFilterCallback}
              endDate={endDateFilter}
              onSetEndDate={EndDateFilterCallback}
            />
            <ExemptionsFilterComponent currentExeFilter={exemptionFilter} onFilterSelectCB={ExemptionFilterCallback} />
            <StatusFilterComponent currentFilter={statusFilter} onStatusFilterSelect={StatusFilterCallback} />
          </FilterBarComponent>
          {userList &&
            ShowDataSorter(
              userList.getUsersByCourse,
              setUser,
              statusFilter,
              exemptionFilter,
              startDateFilter,
              endDateFilter
            )}
        </div>
      </div>
    </section>
  );
};

const ShowList = (tableData: ITableData[], setState: (value: User) => void): JSX.Element[] => {
  console.log('tables data ... ');
  console.log(tableData);
  return tableData.map((data) => (
    <tr key={data.user.email}>
      <td>
        <img
          className="avatar-sm"
          src={
            data.user?.personalDetails?.profilePicture?.filepath
              ? data.user?.personalDetails?.profilePicture?.filepath + '?token=' + decodeToken().token
              : Avatar
          }
          alt="Applicant avatar"
        />
      </td>

      <td>
        <span className="text-bold">{`${data.user.personalDetails.firstName} ${data.user.personalDetails.lastName}`}</span>
        <br />
        <span className="text-red">PPS:</span> {data.user.personalDetails.ppsNo ?? 'Not submitted'}
      </td>
      <td>
        <DateFormatter date={data.user.personalDetails.dateOfBirth} />
      </td>
      <td>{data.phone}</td>
      <td>
        <DateFormatter date={data.user.application?.updatedAt ?? ''} />
      </td>
      <td>{data.status}</td>

      <td>
        <ApplicationLink targetApplicant={data.user} setApplicant={setState} />
      </td>
    </tr>
  ));
};

interface ITableData {
  name: string;
  dateOfBirth: number;
  phone: string;
  updatedAt: number;
  status: string | undefined;
  user: User;
}

const ShowDataSorter = (
  userList: User[],
  setState: (value: User) => void,
  statusFilter?: ApplicationStatus,
  exemptionFilter?: boolean,
  startDateFilter?: Date | null,
  endDateFilter?: Date | null
) => {
  const userData = userList.filter((user) => {
    if (user.application?.currentStatus === ApplicationStatus.Presubmission) {
      return false;
    }

    if (statusFilter && user.application?.currentStatus !== statusFilter) {
      return false;
    }

    if (exemptionFilter === true && (user.application?.exemptions?.length ?? 0) <= 0) {
      return false;
    }

    if (exemptionFilter === false && (user.application?.exemptions?.length ?? 0) > 0) {
      return false;
    }

    const date = new Date(user.application?.updatedAt as Date);

    // The time for these dates are not calculated in UTC format like updatedAt and createdAt are stored as.
    if (startDateFilter) {
      const localFilter = new Date(startDateFilter);
      localFilter.setSeconds(localFilter.getSeconds() - 1);

      if (date < localFilter) {
        return false;
      }
    }

    if (endDateFilter) {
      const localFilter = new Date(endDateFilter);
      localFilter.setDate(localFilter.getDate() + 1);

      if (date > localFilter) {
        return false;
      }
    }

    return true;
  });

  if (!userData || userData.length === 0) {
    return null;
  }

  const tableData: ITableData[] = [];

  userData.forEach((user) => {
    tableData.push({
      name: `${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`.toUpperCase(),
      dateOfBirth: new Date(user.personalDetails.dateOfBirth).getTime(),
      phone: user.personalDetails.phone ?? '',
      updatedAt: new Date(user.application?.updatedAt).getTime(),
      status: user.application?.currentStatus ? StatusDescription.get(user.application.currentStatus) : '',
      user: user,
    });
  });

  return (
    <Datasort
      data={tableData}
      defaultSortBy={'name'}
      defaultDirection={'asc'}
      render={({ data, setSortBy, sortBy, direction, toggleDirection }) => {
        return (
          <table>
            {TableHead(setSortBy, sortBy, direction, toggleDirection)}
            <tbody>{ShowList(data, setState)}</tbody>
          </table>
        );
      }}
    />
  );
};

const TableHead = (
  setSortBy: (sortBy: string) => void,
  sortBy: string,
  direction: string,
  toggleDirection: () => void
) => {
  const columns = [
    { key: 'name', title: 'Name / PPS' },
    { key: 'dateOfBirth', title: 'Date of birth' },
    { key: 'phone', title: 'Mobile No.' },
    { key: 'updatedAt', title: 'Updated' },
    { key: 'status', title: 'Status' },
  ];
  const items = columns.map(({ key, title }) => {
    const active = key === sortBy;
    return (
      <th
        onClick={() => {
          if (active) {
            toggleDirection();
          }
          setSortBy(key);
        }}
        style={{ fontWeight: active ? 'bold' : 'normal', cursor: 'pointer' }}
        key={key}
      >
        {title} {active ? (direction === 'asc' ? '▲' : '▼') : null}
      </th>
    );
  });
  return (
    <thead>
      <tr>
        <th></th>
        {items}
        <th></th>
      </tr>
    </thead>
  );
};
