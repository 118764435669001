/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext } from 'react';
import VideoImage from 'images/video-image.jpg';
import ExemptionForm from './Forms/ExemptionsForm';
import { ExemptionAltForm } from './Forms/ExemptionsAltForm';
import { CourseCtx } from 'services/getCtx';

const Exemptions: React.FC = () => {
  const [course] = useContext(CourseCtx);

  return (
    <div>
      <div className="panel panel-form">
        <div className="panel-jumbo">
          <img src={VideoImage} alt="placeholder for jumbo intro" />
        </div>
        <div className="application-form-area">
          <h2>Exemptions</h2>
          {course.supportExemptions ? <ExemptionForm /> : <ExemptionAltForm />}
        </div>
      </div>
    </div>
  );
};

export default Exemptions;
