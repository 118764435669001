import { gql } from '@apollo/client';

import { APPLICATION_FIELDS } from './applications';

export const SET_EXEMPTION_STATUS = gql`
  mutation SetExemptionStatus($exemptionStatus: String!, $applicationId: String!, $moduleId: String!) {
    setExemptionStatus(applicationId: $applicationId, moduleId: $moduleId, exemptionStatus: $exemptionStatus) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;
