import React, { useState, Fragment, useContext, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccordionItem } from 'components/exemptions/AccordionItem';
import { TabContent } from 'components/exemptions/TabContent';
import { IModulesSelection, ModuleModal } from 'components/exemptions/ModuleModal';

import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';

import FlashMessageError from 'components/FlashMessageError';

import {
  AddOrUpdateAllExemptionsMutation,
  AddOrUpdateAllExemptionsMutationVariables,
  Exemption,
  GetUserQuery,
  GetUserQueryVariables,
  RemoveExemptionMutation,
  RemoveExemptionMutationVariables,
  RemoveExemptionReasonMutation,
  RemoveExemptionReasonMutationVariables,
  User,
} from 'graphql/globalTypes';

import { ADD_OR_UPDATE_ALL_EXEMPTIONS, REMOVE_EXEMPTION, REMOVE_EXEMPTION_REASON } from 'graphql/queries/exemptions';
import { GET_USER } from 'graphql/queries/users';

import { CourseCtx } from 'services/getCtx';
import { fileSizeExceeded } from 'services/utils';
import { UpdateAttachments } from 'services/attachments';
import { constants } from 'services/constants';
import FlashMessageCreditLimit from 'components/exemptions/FlashMessageCreditLimit';

let saveTimeoutId: number | undefined;

const ExemptionForm: React.FC = () => {
  const [course] = useContext(CourseCtx);

  const initialModulesSelectedState = (sExmptions: Exemption[]): IModulesSelection[] => {
    const sModules: IModulesSelection[] = [];

    course.modules?.forEach((item) => {
      const foundModule = sExmptions.find((exemption) => exemption.moduleId === item._id.toString());

      if (!foundModule) {
        sModules.push({ module: item, hasSelected: false });
      }
    });

    return sModules;
  };

  const getTotalCredits = (): number => {
    let credits = 0;
    course.modules?.forEach((current) => {
      if (current && current.credits) {
        credits += current.credits ?? 0;
      }
    });

    return credits;
  };
  const {
    loading: GetWEloading,
    error: GetWEError,
    data: userData,
    refetch: RefetchWEData,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER);

  const [fileuploadExceedLimitMessage, setFileuploadExceedLimitMessage] = useState<Error>();
  const [user, setUser] = useState<User>();
  const [alreadySelectedCredits, setAlreadySelectedCredits] = useState<number>(0);
  const [totalCredits, setTotalCredits] = useState<number>(getTotalCredits());
  const [unSelectedModules, setUnselectedModules] = useState<IModulesSelection[]>(initialModulesSelectedState([]));
  const [exemptions, setExemptions] = useState<Exemption[]>([]);

  const [AddOrUpdateAllExemptions, { error: PutWEError }] = useMutation<
    AddOrUpdateAllExemptionsMutation,
    AddOrUpdateAllExemptionsMutationVariables
  >(ADD_OR_UPDATE_ALL_EXEMPTIONS);

  const [removeER, { error: RemoveErrorER }] = useMutation<
    RemoveExemptionReasonMutation,
    RemoveExemptionReasonMutationVariables
  >(REMOVE_EXEMPTION_REASON);
  const [removeE, { error: RemoveErrorE }] = useMutation<RemoveExemptionMutation, RemoveExemptionMutationVariables>(
    REMOVE_EXEMPTION
  );

  const getSelectedCredits = (exempts: Exemption[]): number => {
    let credits = 0;
    exempts.map((item) => {
      const foundModule = course.modules?.find((mItem) => mItem._id === item.moduleId);
      if (foundModule && foundModule.credits) {
        credits += foundModule.credits ?? 0;
      }
    });

    return credits;
  };

  useEffect(() => {
    console.log('useEffect::ExemptionForm: called');
    if (userData?.getUser?.application?.exemptions) {
      setUser(userData.getUser);
      const sExemptions = userData.getUser.application.exemptions;
      console.log(sExemptions);
      setExemptions([...userData.getUser.application.exemptions]);
      _unselectedmodules(sExemptions);
      setAlreadySelectedCredits(getSelectedCredits(sExemptions));
      setTotalCredits(getTotalCredits());
    }
  }, [userData]);

  const _unselectedmodules = (sExemptions: Exemption[]): void => {
    console.log('all unselected module has been called.');
    const modules = initialModulesSelectedState(sExemptions);

    setUnselectedModules(modules);
  };

  const onModulesSelected = async (modules: IModulesSelection[]): Promise<void> => {
    const newExemptions: Exemption[] = [...exemptions];
    modules.forEach(async (item) => {
      if (item.hasSelected) {
        const newSectionEntry: Exemption = { moduleId: item.module._id.toString() };

        newExemptions.push(newSectionEntry);
      }
    });
    await saveAllChange(newExemptions, true);
  };

  const showModuleModal = (): void => {
    const confirmSubmission = document.getElementById('confirm_modal');

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const onChange = async (index: number, exemptionItem: Exemption, saveNow = false): Promise<void> => {
    console.log('exemptionData', exemptionItem);

    const newExemptions = [...exemptions];
    newExemptions[index] = Object.assign({}, exemptionItem);
    await saveAllChange(newExemptions, saveNow);
    setUnselectedModules([]);
  };

  const saveAllChange = async (newExemptions: Exemption[], saveNow = false): Promise<void> => {
    if (saveTimeoutId) {
      window.clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    setExemptions(newExemptions);

    saveTimeoutId = window.setTimeout(
      async () => {
        try {
          const exemptionsToSend = newExemptions.map((exemption) => {
            const { __typename, ...rest } = exemption;
            const eReasons = rest.exemptionReasons?.map((reason) => {
              const { id, __typename, attachments, ...rest } = reason;
              return rest;
            });

            return { ...rest, exemptionReasons: eReasons };
          });

          await AddOrUpdateAllExemptions({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            variables: { applicationId: userData!.getUser.application!._id, exemptions: exemptionsToSend },
          });
        } catch (error) {
          console.log('Error Saving Exemptions for application: ', error);
        } finally {
          await RefetchWEData();
        }
      },
      saveNow ? 0 : constants.TIME_TO_SAVE
    );
  };

  const onResetFlashMessage = (): void => {
    setFileuploadExceedLimitMessage(undefined);
  };

  const onAttachmentSelect: funcAttachmentsSelect = async (attachmentData, attachments) => {
    const validateError = fileSizeExceeded(attachments);

    if (validateError) {
      setFileuploadExceedLimitMessage(validateError);
    } else {
      try {
        await UpdateAttachments(attachmentData, attachments);
      } catch (e) {
        setFileuploadExceedLimitMessage(e);
      } finally {
        await RefetchWEData();
      }
    }
  };

  const onDeleteExemptionReason = async (moduleId: string, reasonId: string): Promise<void> => {
    // delete clicked.
    console.log('delete button clicked.');
    console.log('following is going to be deleted.');
    console.log(moduleId);
    console.log(reasonId);
    try {
      await removeER({
        variables: {
          moduleId: moduleId,
          reasonId: reasonId,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      await RefetchWEData();
    }
  };

  const onDeleteExemption = async (moduleId: string): Promise<void> => {
    // delete clicked.
    console.log('delete button clicked.');
    console.log('following is going to be deleted.');
    console.log(moduleId);
    try {
      await removeE({
        variables: {
          moduleId,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      await RefetchWEData();
    }
  };

  if (GetWEloading) {
    console.log('Waiting to load Exemptions');
    return <div className="response success-response">Loading Data</div>;
  }

  const creditLimits = alreadySelectedCredits * 2 === totalCredits;

  console.log('Unselect Modules are ');
  console.log(unSelectedModules);

  return (
    <Fragment>
      {fileuploadExceedLimitMessage && (
        <FlashMessageError error={fileuploadExceedLimitMessage} onFinish={onResetFlashMessage} />
      )}
      <TabContent />
      <div className="education-form-container pb-5">
        <ModuleModal
          onSelected={onModulesSelected}
          selectedModules={unSelectedModules}
          course={course}
          alreadySelectedCredits={alreadySelectedCredits}
          totalCredits={totalCredits}
        />
        <p>
          Complete the information for each module on which you wish to apply for exemption following the steps below.
        </p>
        <ol>
          <li>Select an eligible module.</li>
          <li>Specify the type of exemption for which you wish to apply.</li>
          <li>Select the criteria under which you can fulfil the module's learning outcomes.</li>
        </ol>
        <FlashMessageCreditLimit selectedCredits={alreadySelectedCredits} totalCredits={totalCredits} />
        <div className="form-group mb-1">
          <div className="add-list-item-container">
            <button type="submit" onClick={showModuleModal} disabled={creditLimits}>
              <FontAwesomeIcon icon={faPlusCircle} />
              <div>Select module for exemption</div>
            </button>
          </div>
        </div>
        {exemptions.map((item, index) => {
          const foundModule = course.modules?.find((mItem) => mItem._id === item.moduleId);

          if (foundModule && user) {
            return (
              <AccordionItem
                key={`exemption-forms-${index}`}
                index={index}
                exemption={item}
                moduleId={foundModule._id}
                module={foundModule}
                onChange={onChange}
                onAttachmentSelect={onAttachmentSelect}
                onDeleteExemptionReason={onDeleteExemptionReason}
                onDeleteExemption={onDeleteExemption}
                user={user}
              />
            );
          }
          return null;
        })}
        {GetWEError && <div className="response error-response">Error getting Data: {GetWEError}</div>}
        {RemoveErrorER && (
          <div className="response error-response">Error deleting exemption reason Data: {RemoveErrorER}</div>
        )}
        {RemoveErrorE && <div className="response error-response">Error deleting exemption Data: {RemoveErrorE}</div>}
        {PutWEError && <div className="response error-response">Error putting Data: {PutWEError}</div>}
      </div>
    </Fragment>
  );
};

export default ExemptionForm;
