/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import VideoImage from 'images/video-image.jpg';

import SkillsAndCompetenciesForm from './Forms/SkillsAndCompetenciesForm';

import { GET_SKILLS_AND_COMPETENCIES, ADD_OR_UPDATE_SCS } from 'graphql/queries/skillsandcompetencies';
import { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import { constants } from 'services/constants';
import {
  User,
  SkillsCompetencies,
  GetUserQuery,
  MutationAddOrUpdateSCsArgs,
  GetUserQueryVariables,
} from 'graphql/globalTypes';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { UpdateAttachments } from 'services/attachments';
import { fileSizeExceeded } from 'services/utils';
import FlashMessageError from 'components/FlashMessageError';

let saveTimeOut: number | undefined;

const SkillsAndCompetencies: React.FC = () => {
  const [fileuploadExceedLimitMessage, setFileuploadExceedLimitMessage] = useState<Error>();
  const {
    loading: GetSCLoading,
    error: GetSCError,
    data: GetSCData,
    refetch: RefetchSCData,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_SKILLS_AND_COMPETENCIES);
  const [skills, setSkills] = useState<SkillsCompetencies[]>();
  const [replaceAllSC, { error: SaveError }] = useMutation<User, MutationAddOrUpdateSCsArgs>(ADD_OR_UPDATE_SCS);

  useEffect(() => {
    if (GetSCData?.getUser?.skillsCompetencies) {
      console.log('get user confirm ... ');
      console.log(GetSCData.getUser);
      const newSkills = cloneSkills(GetSCData.getUser.skillsCompetencies); // [...GetSCData.getUser.skillsCompetencies];
      setSkills(newSkills);
    }
  }, [GetSCData]);

  const onChange: FormTextAreaChange = (value, index) => {
    const newSkills = cloneSkills(skills); //: SkillsCompetencies[] = JSON.parse(JSON.stringify(skills)); // [...(skills ?? [])];

    newSkills[index as number].description = value;
    setSkills(newSkills);

    if (saveTimeOut) {
      window.clearTimeout(saveTimeOut);
      saveTimeOut = undefined;
    }

    saveTimeOut = window.setTimeout(async () => {
      // remove typename, and attachments
      const inputSkills = newSkills.map((skill) => {
        const { __typename, attachments, ...rest } = skill;

        return rest;
      });

      try {
        await replaceAllSC({ variables: { skillsCompetencies: inputSkills } });
      } catch (err) {
        // need to handle the error cause Apollo is stupid
        // see https://stackoverflow.com/a/59472340/3343178
        console.log('SkillsAndCompetencies Mutation Error', err.message);
      } finally {
        // some tests are failing as the test data isn't setup properly
        // this causes the test to throw an error with calling the following
        // function. The try catch protects against this
        try {
          await RefetchSCData();
        } catch (err) {
          console.log(err);
        }
      }
    }, constants.TIME_TO_SAVE);
  };
  const onResetFlashMessage = (): void => {
    setFileuploadExceedLimitMessage(undefined);
  };

  const onAttachmentsSelect: funcAttachmentsSelect = async (attachmentsData, attachments) => {
    const validateError = fileSizeExceeded(attachments);

    if (validateError) {
      setFileuploadExceedLimitMessage(validateError);
    } else {
      try {
        const retVal = await UpdateAttachments(attachmentsData, attachments);
        await RefetchSCData();

        if (!retVal) {
          return;
        }

        // update the local data with the new attachment data
        setSkills((skills) => {
          const val = skills?.find((skill) => skill.title === attachmentsData.idOne);

          if (val) {
            val.attachments = retVal[0];
          }

          return [...(skills ?? [])];
        });
      } catch (e) {
        setFileuploadExceedLimitMessage(e);
      }
    }
  };

  console.log('run through tests');
  console.log(skills);
  return (
    <div>
      <div className="panel panel-form">
        <div className="panel-jumbo">
          <img src={VideoImage} alt="placeholder for jumbo intro" />
        </div>
        <div className="application-form-area">
          <h2>Skills and Competencies</h2>
          {fileuploadExceedLimitMessage && (
            <FlashMessageError error={fileuploadExceedLimitMessage} onFinish={onResetFlashMessage} />
          )}
          {skills && (
            <SkillsAndCompetenciesForm
              skillsAndCompetencies={skills}
              onSCChange={onChange}
              onAttachmentsSelect={onAttachmentsSelect}
            />
          )}

          {GetSCError && <div className="response error-response">Error getting Data: {GetSCError.message}</div>}
          {SaveError && <div className="response error-response">Error putting Data: {SaveError.message}</div>}
          {GetSCLoading && <div className="response success-response">Loading Data</div>}
          {/* {PutScData && <div className="response success-response">Skills and Competencies have saved successfully.</div>} */}
        </div>
      </div>
    </div>
  );
};

const cloneSkills = (skills?: SkillsCompetencies[]): SkillsCompetencies[] => {
  return JSON.parse(JSON.stringify(skills));
};

export default SkillsAndCompetencies;
