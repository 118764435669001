import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ExemptionReasonTypeSelection } from './ExemptionReasonTypeSelection';

interface IReasonModal {
  onSelected: (type: string) => void;
  index: number;
  moduleId: string;
}

export const ReasonModal: React.FC<IReasonModal> = (props) => {
  const [selectedType, setSelectedType] = useState<string>();
  const CloseModal = (): void => {
    const confirmSubmission = document.getElementById('reason_modal' + props.index);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      }
    }
  };

  const onSaveBtnClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    CloseModal();
    if (selectedType) {
      props.onSelected(selectedType);
    }
  };

  const onSelectChange = (type: string): void => {
    setSelectedType(type);
  };

  return (
    <div id={`reason_modal${props.index}`} className="modal">
      <div className="modal-popover-confirm">
        <div className="modal-login-form-area">
          <div className="close-modal" onClick={CloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="modal-content">
            <p className="text-bold">Select the basis on which you seek exemption</p>
            <ExemptionReasonTypeSelection onSelectChange={onSelectChange} moduleId={props.moduleId} />
          </div>
        </div>

        <div className="modal-confirm-button text-right">
          <button type="submit" className="btn btn-sm btn-red btn-auto-width mr-1" onClick={CloseModal}>
            Back
          </button>
          <button
            data-testid="reasonTypeSubmit"
            type="submit"
            className="btn btn-sm btn-auto-width mr-5"
            onClick={onSaveBtnClick}
          >
            Confirm Selection
          </button>
        </div>
      </div>
    </div>
  );
};
