import jwt from 'jwt-decode';

import { Roles } from 'graphql/globalTypes';

import { constants } from 'services/constants';

export interface ITokenData {
  email: string;
  isVerified: boolean;
  role: Roles;
  iat: number;
  exp: number;
  iss: string;
  sub: string;
}

export interface IDecodedToken {
  isValid: boolean;
  token?: string;
  decodedToken?: ITokenData;
}

export function getBearerToken(): string | null {
  const { isValid, token } = decodeToken();

  return isValid ? `Bearer ${token}` : null;
}

export function decodeToken(jwtToken?: string): IDecodedToken {
  let token = jwtToken;

  if (!token) {
    try {
      token = JSON.parse(localStorage.getItem(constants.JWT_STORAGE_KEY) ?? '');
    } catch (err) {
      // JSON.parse failed
      // do nothing here as an invalid token is still a valid return type
    }
  }

  if (!token || token.length === 0) {
    return { isValid: false };
  }

  try {
    const decodedToken: ITokenData = jwt(token) as ITokenData;
    const nowDate = Date.now() / 1000;

    if (decodedToken.exp < nowDate) {
      return { isValid: false };
    }

    return { isValid: true, token, decodedToken };
  } catch (err) {
    return { isValid: false };
  }
}

export const isAdminRole = (role?: Roles): boolean =>
  role !== Roles.Applicant && role !== Roles.Superadmin && role !== undefined;
