import { useEffect, useState } from 'react';

import { ModalDialog } from 'components/ModalDialog';
import { SelectCourse } from 'pages/Course/SelectCourse';
import { CourseSelectedCB } from 'pages/Course/ShowCourses';
import { Course } from 'graphql/globalTypes';
import { CourseSelection } from './CourseSelection';

interface IManageCourseProps {
  currentCourse?: Course;
  courseSelection: (targetCourse?: Course) => void;
  viewAllApplications: () => void;
}

export const ManageCourses: React.FC<IManageCourseProps> = (props) => {
  const firstTime = props.currentCourse === undefined ? true : false;
  const [showCoursesSelection, setShowCourseSelection] = useState(firstTime);
  const onCancelSelectCourse = () => setShowCourseSelection(false);
  const [currentCourse, setCurrentCourse] = useState<Course>();

  useEffect(() => {
    if (props.currentCourse) {
      setCurrentCourse(props.currentCourse);
      setShowCourseSelection(false);
    }
  }, [props.currentCourse]);

  const onCourseSelected: CourseSelectedCB = (targetCourse: Course) => {
    setShowCourseSelection(false);
    setCurrentCourse(targetCourse);
    props.courseSelection(targetCourse);
  };

  const switchHandler = () => setShowCourseSelection(true);

  return (
    <>
      <ModalDialog isOpen={showCoursesSelection} title="Select Award" onCancel={onCancelSelectCourse}>
        <SelectCourse isAdmin={true} isLoggedIn={true} hideReadMore={true} onCourseSelected={onCourseSelected} />
      </ModalDialog>
      <CourseSelection
        currentCourse={currentCourse}
        courseSwitcher={switchHandler}
        viewAllApplications={props.viewAllApplications}
      />
    </>
  );
};
