import React, { Fragment } from 'react';

import { DateFormatter } from 'components/helper_date';
import { WorkExperience } from 'graphql/globalTypes';
import { ShowFieldString, ShowFieldAttachment } from './ReviewShowPrimitives';

interface IReviewSegmentWorkExperience {
  workExperience: WorkExperience;
  count: number;
}

export const ReviewSegmentWorkExperience: React.FC<IReviewSegmentWorkExperience> = (props) => {
  const jobStatus = props.workExperience.currentlyWorking ? (
    'present'
  ) : (
    <span>
      <DateFormatter date={props.workExperience.endDate} monthYearFormat={true} />
    </span>
  );

  return (
    <Fragment>
      <div>
        <div className="review-section-block">
          <div className="grid-collapse">
            <div className="col-1">
              <div className="review-section-number">{props.count}</div>
            </div>
            <div className="col-11">
              {props.workExperience.startDate && (
                <p>
                  From <DateFormatter date={props.workExperience.startDate} monthYearFormat={true} /> to {jobStatus}
                </p>
              )}
              {ShowFieldString('Job title', props.workExperience.jobTitle)}
              {ShowFieldString('Company name', props.workExperience.companyName)}
              {ShowFieldString('Industry sector', props.workExperience.NatureOfBusiness)}
              {ShowFieldString('Company address', props.workExperience.companyAddress)}
              {ShowFieldString('Key responsibilities in this role', props.workExperience.keyResponsibilities)}
              {ShowFieldString(
                'Skills and Competencies used in carrying out the duties',
                props.workExperience.skillsAndCompetencies
              )}
              {props.workExperience.promoted &&
                props.workExperience.promotionDetails &&
                ShowFieldString('Promotion details', props.workExperience.promotionDetails)}
              {props?.workExperience.attachments?.filename &&
                ShowFieldAttachment('Additional supporting documentation', props.workExperience.attachments)}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
