import React, { useState, useEffect, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import VideoImage from 'images/video-image.jpg';

import {
  SecondaryEducation,
  FurtherEducation,
  AttachmentTypes,
  ReplaceAllEducationMutation,
  MutationReplaceAllEducationArgs,
  GetUserQuery,
  Education,
  FieldState,
  Maybe,
  EducationInput,
  RemoveFurtherEducationMutation,
  RemoveFurtherEducationMutationVariables,
  RemoveThirdLevelEducationMutation,
  RemoveThirdLevelEducationMutationVariables,
} from 'graphql/globalTypes';

import { SecondaryForm } from './Forms/SecondaryForm';
import { FETL } from './FETL';

import { GET_USER } from 'graphql/queries/users';
import { REPLACE_ALL_EDUCATION } from 'graphql/queries/education';

import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';

import { UpdateAttachments } from 'services/attachments';
import { constants } from 'services/constants';
import { CourseCtx } from 'services/getCtx';
import { deepClone, deepishCopyAssign, fileSizeExceeded } from 'services/utils';
import FlashMessageError from 'components/FlashMessageError';
import { REMOVE_FURTHER_EDUCATION } from 'graphql/queries/furthereducation';
import { REMOVE_THIRDLEVEL_EDUCATION } from 'graphql/queries/thirdleveleducation';
import ITComponent from './IndustryTraining';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let saveTimeoutId: any; //NodeJS.Timeout | undefined;

export interface IFuncEducationChange {
  SL?: SecondaryEducation[];
  FE?: FurtherEducation[];
  TL?: FurtherEducation[];
}

export type funcEducationChange = (data: IFuncEducationChange, InstantSave?: boolean) => Promise<void>;

export enum EducationTypes {
  SecondLevel,
  FurtherEducation,
  ThirdLevel,
}

export const EducationComponent: React.FC = () => {
  const [course] = useContext(CourseCtx);

  const {
    loading: GetEloading,
    error: GetEError,
    data: GetEData,
    refetch: RefetchEData,
  } = useQuery<GetUserQuery>(GET_USER);
  const [secondary, setSecondary] = useState<SecondaryEducation[]>([]);
  const [furtherEducation, setFurtherEducation] = useState<FurtherEducation[]>([]);
  const [thirdLevel, setThirdLevel] = useState<FurtherEducation[]>([]);
  const [fileuploadExceedLimitMessage, setFileuploadExceedLimitMessage] = useState<Error>();
  const [ReplaceAllEducation, { error: PutEError }] = useMutation<
    ReplaceAllEducationMutation,
    MutationReplaceAllEducationArgs
  >(REPLACE_ALL_EDUCATION);

  const [removeFE, { error: RemoveFEError }] = useMutation<
    RemoveFurtherEducationMutation,
    RemoveFurtherEducationMutationVariables
  >(REMOVE_FURTHER_EDUCATION);
  const [removeTE, { error: RemoveTEError }] = useMutation<
    RemoveThirdLevelEducationMutation,
    RemoveThirdLevelEducationMutationVariables
  >(REMOVE_THIRDLEVEL_EDUCATION);

  useEffect(() => {
    if (GetEData && !GetEError && !GetEloading) {
      setSecondary([...(GetEData.getUser.education?.secondary ?? [])]);
      setFurtherEducation([...(GetEData.getUser.education?.furtherEducation ?? [])]);
      setThirdLevel([...(GetEData.getUser.education?.thirdLevel ?? [])]);
    }
  }, [GetEData, GetEError, GetEloading]);

  /**
   * @description Only one data value will be set in data on each call
   */
  const onEducationChange: funcEducationChange = async (data, InstantSave) => {
    console.log('onEducationChange: ', data.SL, data.FE, data.TL);
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    const edu: Education = {
      secondary: data.SL ?? secondary,
      furtherEducation: data.FE ?? furtherEducation,
      thirdLevel: data.TL ?? thirdLevel,
    };

    // update the local data as there will be a delay when saving and reloading data from server

    if (data.SL) {
      setSecondary(data.SL);
    }

    if (data.FE) {
      setFurtherEducation(data.FE);
    }

    if (data.TL) {
      setThirdLevel(data.TL);
    }

    saveTimeoutId = setTimeout(
      () => {
        ReplaceAllEducation({ variables: { education: GetSaveableData(edu) } });
      },
      InstantSave ? 0 : constants.TIME_TO_SAVE
    );
  };

  const onResetFlashMessage = (): void => {
    setFileuploadExceedLimitMessage(undefined);
  };

  const onAttachmentsSelect: funcAttachmentsSelect = async (attachmentData, attachments): Promise<void> => {
    const validateError = fileSizeExceeded(attachments);

    if (validateError) {
      setFileuploadExceedLimitMessage(validateError);
    } else {
      try {
        const retVal = await UpdateAttachments(attachmentData, attachments);

        if (!retVal) {
          return;
        }

        RefetchEData(); // run in background

        switch (attachmentData.attachmentType) {
          case AttachmentTypes.Subject: {
            setSecondary((SL) => {
              const newSl = deepClone(SL);

              const val = newSl
                ?.find((sl) => sl.id === attachmentData.idOne)
                ?.subjects?.find((subject) => subject.id === attachmentData.idTwo);

              val && (val.attachments = retVal[0]);

              return newSl;
            });

            break;
          }

          case AttachmentTypes.ThirdLevel: {
            setThirdLevel((TL) => {
              const newTl = deepClone(TL);

              const val = newTl?.find((tl) => tl.id === attachmentData.idOne);

              val && (val.attachments = retVal[0]);

              return newTl;
            });

            break;
          }

          case AttachmentTypes.FurtherEducation: {
            setFurtherEducation((nFE) => {
              const newFe = deepClone(nFE);

              const val = newFe?.find((fe) => fe.id === attachmentData.idOne);

              val && (val.attachments = retVal[0]);

              return newFe;
            });

            break;
          }
        }
      } catch (e) {
        setFileuploadExceedLimitMessage(e);
      }
    }
  };

  const showState = (state?: Maybe<FieldState>): boolean => {
    return !!state && state !== FieldState.Hidden;
  };

  const onDelete = async (id: string, type: EducationTypes): Promise<void> => {
    try {
      switch (type) {
        case EducationTypes.ThirdLevel: {
          await removeTE({
            variables: {
              thirdLevelId: id,
            },
          });
          break;
        }

        case EducationTypes.FurtherEducation: {
          await removeFE({
            variables: {
              furtherEducationId: id,
            },
          });
          break;
        }

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    } finally {
      await RefetchEData();
    }
  };

  return (
    <div>
      <div className="panel panel-form">
        <div className="panel-jumbo">
          <img src={VideoImage} alt="placeholder for jumbo intro video" />
        </div>
        <div className="application-form-area">
          <h2>Education and Training</h2>
          {fileuploadExceedLimitMessage && (
            <FlashMessageError error={fileuploadExceedLimitMessage} onFinish={onResetFlashMessage} />
          )}

          {secondary && showState(course.criteria?.educationCriteria?.secondary) && (
            <SecondaryForm
              // key={secondary.toString()}
              onChange={onEducationChange}
              secondary={secondary}
              onAttachmentsSelect={onAttachmentsSelect}
            />
          )}
          {thirdLevel && showState(course.criteria?.educationCriteria?.thirdLevel) && (
            <FETL
              educationType={EducationTypes.ThirdLevel}
              onChange={onEducationChange}
              FETL={thirdLevel}
              onAttachmentsSelect={onAttachmentsSelect}
              onDelete={onDelete}
            />
          )}
          {furtherEducation && showState(course.criteria?.educationCriteria?.further) && (
            <FETL
              educationType={EducationTypes.FurtherEducation}
              onChange={onEducationChange}
              FETL={furtherEducation}
              onAttachmentsSelect={onAttachmentsSelect}
              onDelete={onDelete}
            />
          )}
          {showState(course.criteria?.industryTrainingState) && <ITComponent />}
          {GetEloading && <div className="response success-response">Loading Education Data</div>}
          {GetEError && <div className="response error-response">Error fetching data: {GetEError}</div>}
          {RemoveFEError && (
            <div className="response error-response">Error deleting further education: {RemoveFEError}</div>
          )}
          {RemoveTEError && (
            <div className="response error-response">Error deleting third education: {RemoveTEError}</div>
          )}
          {/* {PutEData && <div className="response success-response">Education has saved successfully.</div>} */}
          {PutEError && <div className="response error-response">Error putting Data: {PutEError}</div>}
        </div>
      </div>
    </div>
  );
};

/**
 * @description
 * This function will take in the education structure clean it up and return
 * the data that can be saved. It will remove blank data so stubs aren't saved.
 *
 * @param edu
 */
const GetSaveableData = (edu: Education): EducationInput => {
  const secondarySave = edu.secondary?.map((item) => {
    const { id, __typename, ...rest } = item;

    const subjectSave = item.subjects?.map((innerItem) => {
      const { id, __typename, attachments, ...rest } = innerItem;
      return rest;
    });

    return { ...rest, subjects: subjectSave };
  });

  const furtherEducationSave = edu.furtherEducation?.map((feItem) => {
    const { id, __typename, attachments, ...rest } = feItem;
    return rest;
  });

  const thirdLevelSave = edu.thirdLevel?.map((teItem) => {
    const { id, __typename, attachments, ...rest } = teItem;
    return rest;
  });

  if (saveTimeoutId) {
    window.clearTimeout(saveTimeoutId);
    saveTimeoutId = undefined;
  }

  // Don't save blank data
  const eduSave: EducationInput = {};

  if (secondarySave) {
    eduSave.secondary = secondarySave;
  }

  if (furtherEducationSave) {
    eduSave.furtherEducation = furtherEducationSave;
  }

  if (thirdLevelSave) {
    eduSave.thirdLevel = thirdLevelSave;
  }

  return eduSave;
};
