import { Course } from 'graphql/globalTypes';
import { ShowCourses, CourseSelectedCB } from 'pages/Course/ShowCourses';
import { useNavigate } from 'react-router-dom';

interface ISelectCourse {
  isLoggedIn: boolean;
  isAdmin: boolean;
  hideReadMore?: boolean;
  onCourseSelected?: CourseSelectedCB;
}

export const AdminCourseSelection: React.FC<ISelectCourse> = (props) => {
  const navigate = useNavigate();
  const onCourseSelected: CourseSelectedCB = (course: Course) => {
    navigate('/admin/applications-view/' + course._id);
  };

  return (
    <div className="flex-grid-row">
      <ShowCourses isAdmin={props.isAdmin} onCourseSelected={onCourseSelected} />
    </div>
  );
};
