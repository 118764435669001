import PersonalIcon from 'images/icons/personal.svg';
import WorkIcon from 'images/icons/work.svg';
import EducationIcon from 'images/icons/education.svg';
import ReviewIcon from 'images/icons/review.svg';
import SkillsIcon from 'images/icons/skills.svg';

import { MenuType, MenuItem } from 'pages/Navigation/menuItems';

import PersonalInfo from 'pages/Applicant/PersonalDetails/PersonalInfo';
import WorkExperience from 'pages/Applicant/PersonalDetails/WorkExperience';
import { EducationComponent } from 'pages/Applicant/PersonalDetails/Education';
import SkillsAndCompetencies from 'pages/Applicant/PersonalDetails/SkillsAndCompetencies';
import Review from 'pages/Applicant/PersonalDetails/Review';
import Exemptions from 'pages/Applicant/PersonalDetails/Exemptions';

export const ApplicantMenuItems: MenuItem[] = [
  {
    menuType: MenuType.PersonalData,
    title: 'Personal Information',
    comp: PersonalInfo,
    icon: PersonalIcon,
    link: 'personal-info',
    progressValue: '100',
    blurb:
      'Enter your personal details. All fields are optional. Empty fields will not be included in the submitted application.',
  },
  {
    menuType: MenuType.WorkExperience,
    title: 'Work Experience',
    comp: WorkExperience,
    icon: WorkIcon,
    link: 'work-experience',
    progressValue: '50',
    blurb:
      'Enter details of your past work experience. Include information on the length of time working within each role, the industry sector and your own professional responsibilities.',
  },
  {
    menuType: MenuType.Education,
    title: 'Education and Training',
    comp: EducationComponent,
    icon: EducationIcon,
    link: 'education',
    progressValue: '60',
    blurb:
      'Enter details of your previous, certified education and training. Include, wherever possible, supporting documentation in the form of a transcript, certificate or similar.',
  },
  {
    menuType: MenuType.Skills,
    title: 'Skills and Competencies',
    comp: SkillsAndCompetencies,
    icon: SkillsIcon,
    link: 'skills',
    progressValue: '10',
    blurb:
      'Enter details of your skills and competencies. Be sure to include all skills or competencies that may be relevant to the themes of this award, give an indication of your experience in applying them and your level of ability.',
  },
  {
    menuType: MenuType.Exemption,
    title: 'Exemptions',
    comp: Exemptions,
    icon: SkillsIcon,
    link: 'exemptions',
    progressValue: '10',
    blurb: 'Apply for exemption from eligible modules within this award.',
  },
  {
    menuType: MenuType.Review,
    title: 'Review and Submit',
    comp: Review,
    icon: ReviewIcon,
    link: 'review',
    progressValue: '10',
    blurb: 'Review the details of your application before submitting it for assessment.',
  },
];
