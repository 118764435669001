import { Course } from 'graphql/globalTypes';
import { ActiveCourseCard } from './ActiveCourseCard';
import { CourseSwitcher } from './CourseSwitcher';
import { ViewAllApplications } from './ViewAllApplications';

interface ICourseSelection {
  currentCourse?: Course;
  courseSwitcher: () => void;
  viewAllApplications: () => void;
}

export const CourseSelection: React.FC<ICourseSelection> = (props) => {
  return (
    <div className="col-4">
      <div className="panel-admin-dashboard p-1">
        <p className="mb-0 text-bold">View application for selected award</p>
        <div className="active-application-card">
          <ActiveCourseCard course={props.currentCourse} />
        </div>
        <div className="view-all-buttons">
          <div className="button-group">
            <ViewAllApplications viewAllApplications={props.viewAllApplications} currentCourse={props.currentCourse} />
            <CourseSwitcher courseSwitcher={props.courseSwitcher} />
          </div>
        </div>
      </div>
    </div>
  );
};
