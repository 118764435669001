import { gql } from '@apollo/client';
import { APPLICATION_FIELDS } from './applications';

export const COPY_THIRDLEVEL_CERTIFICATE = gql`
  mutation CopyThirdLevelCertsToExemptionReason($thirdLevelId: String!, $reasonId: String!, $moduleId: String!) {
    copyThirdLevelCertsToExemptionReason(thirdLevelId: $thirdLevelId, reasonId: $reasonId, moduleId: $moduleId) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;

export const COPY_FURTHER_CERTIFICATE = gql`
  mutation CopyFurtherCertsToExemptionReason($furtherEducationId: String!, $reasonId: String!, $moduleId: String!) {
    copyFurtherCertsToExemptionReason(
      furtherEducationId: $furtherEducationId
      reasonId: $reasonId
      moduleId: $moduleId
    ) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;

export const COPY_WORKEXPERIENCE_CERTIFICATE = gql`
  mutation CopyWECertsToExemptionReason($workExperienceId: String!, $reasonId: String!, $moduleId: String!) {
    copyWECertsToExemptionReason(workExperienceId: $workExperienceId, reasonId: $reasonId, moduleId: $moduleId) {
      ...ApplicationFields
    }
  }
  ${APPLICATION_FIELDS}
`;
