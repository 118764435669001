import { Navigate } from 'react-router-dom';

import { useGoogleAnalytics } from 'services/googleAnalytics';

import { Course } from 'graphql/globalTypes';

interface IPrivateRouteProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
  courseId?: string;
  currentRole?: string;
  allowedRoles?: string[];
  courseDetail?: Course;
}

export const IsAuthorized: React.FC<IPrivateRouteProps> = (props) => {
  const isAllowed = props.isLoggedIn && (props?.allowedRoles?.includes(props?.currentRole ?? '') ?? true);

  useGoogleAnalytics();

  return <>{isAllowed && props.children ? props.children : <Navigate to="/logout" />}</>;
};
