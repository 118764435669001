import React from 'react';

interface ITooltipProps {
  tooltip: string | undefined;
}

const Tooltip: React.FC<ITooltipProps> = ({ tooltip }) => {
  const handleTooltipClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    const elem = e.target as HTMLSpanElement;
    if (elem.nextElementSibling?.classList.contains('show-tip')) {
      elem.nextElementSibling?.classList.remove('show-tip');
    } else {
      elem.nextElementSibling?.classList.add('show-tip');
    }
  };

  const handleClose = (e: React.FormEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    const tip = e.target as HTMLButtonElement;
    //get the main container 2 levels up
    const parent = tip.parentNode?.parentNode as HTMLDivElement;
    parent.classList.remove('show-tip');
  };

  return (
    <span className="tooltip-container">
      <span className="tooltip-button" onClick={handleTooltipClick}>
        ?
      </span>
      <div className="tooltip-content">
        <h3>Information</h3>
        <p>{tooltip}</p>
        <div className="button-container">
          <button className="btn btn-sm btn-blue" type="button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </span>
  );
};

export default Tooltip;
