import React, { useState, useEffect, Fragment } from 'react';

import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import FormInput from 'components/FormPrimitives/FormInput';
import { WorkExperience, AttachmentTypes } from 'graphql/globalTypes';
import { FormAttachmentsSelect, funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormsDatePickerComponent } from 'components/datepickerComponent';
import { promptToolTip } from 'services/constants';

interface IFormProps {
  index: number;
  job: WorkExperience;
  filename?: string | null;

  onChange: (index: number, sectionDetail: WorkExperience) => void;
  onDelete: (index: number, sectionDetail: WorkExperience) => void;
  onAttachmentSelect: funcAttachmentsSelect;
}

const WorkExperienceForm: React.FC<IFormProps> = (props) => {
  const [sectionDetail, setSectionDetail] = useState<WorkExperience>(props.job);

  useEffect(() => {
    console.log('WorkExperienceForm: useEffect: props.job', props.job);
    setSectionDetail(props.job);
  }, [props.job]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = value;
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  const onTextAreaChange: FormTextAreaChange = (value, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newReason: any = Object.assign({}, sectionDetail);
    newReason[index as string] = value;
    setSectionDetail(newReason);
    props.onChange(props.index, newReason);
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = checked;
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  const onDeleteConfirm = (): void => {
    // delete clicked.
    //console.log('delete button clicked.');
    //console.log('following is going to be deleted.');
    //console.log(sectionDetail);
    props.onDelete(props.index, props.job);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal.${props.index}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const currentTitle = props.index === 0 ? 'Current/Most Recent' : 'Previous';

  const DateCallback = (name: string, value: Date | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSD: any = Object.assign({}, sectionDetail);
    newSD[name] = value;
    setSectionDetail(newSD);
    props.onChange(props.index, newSD);
  };

  return (
    <Fragment>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={'WorkExperience -> ' + currentTitle}
        index={props.index}
        modalId={`delete_confirm_modal.${props.index}`}
      />
      <form className="experience-container">
        <h3>{currentTitle}</h3>
        {sectionDetail && (
          <div className="delete-item-button">
            <button
              key={'del' + sectionDetail.id}
              onClick={handleDeleteClick}
              className={'btn-sm btn-delete'}
              type="button"
            >
              Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
            </button>
          </div>
        )}
        <div className="break"></div>
        <FormInput
          name="jobTitle"
          uniqueName={`jobTitle-we-${props.job.id}`}
          label="Job title"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.jobTitle}
        />
        <FormInput
          name="NatureOfBusiness"
          uniqueName={`NatureOfBusiness-we-${props.job.id}`}
          label="Industry sector"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.NatureOfBusiness}
        />
        <FormInput
          name="companyName"
          uniqueName={`companyName-we-${props.job.id}`}
          label="Company name"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.companyName}
        />
        <FormInput
          name="companyAddress"
          uniqueName={`companyAddress-we-${props.job.id}`}
          label="Location"
          type="text"
          fullwidth={true}
          onDataChange={onChange}
          value={sectionDetail.companyAddress}
        />
        <FormsDatePickerComponent
          name="startDate"
          uniqueName={`startDate-we-${props.job.id}`}
          label="Start date"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.startDate}
          fullwidth={true}
        />
        <FormsDatePickerComponent
          name="endDate"
          uniqueName={`endDate-we-${props.job.id}`}
          label="End date"
          type="monthYearPicker"
          onDataChange={DateCallback}
          value={sectionDetail.endDate}
          disabled={sectionDetail.currentlyWorking ?? false}
          fullwidth={true}
        />
        {props.index === 0 && (
          <FormInput
            name="currentlyWorking"
            uniqueName={`currentlyWorking-we-${props.job.id}`}
            label="Current employment"
            type="checkbox"
            fullwidth={true}
            onDataChange={onCheckboxChange}
            checked={sectionDetail.currentlyWorking}
          />
        )}
        <div className="break"></div>
        <FormTextArea
          name="keyResponsibilities"
          uniqueName={`keyResponsibilities-we-${props.job.id}`}
          label="List of key responsibilities"
          onDataChange={onTextAreaChange}
          value={sectionDetail.keyResponsibilities}
          tooltip="Provide details of your key areas of responsibility in this role. Focus in on areas most relevant to this application."
        />
        <FormTextArea
          name="skillsAndCompetencies"
          uniqueName={`skillsAndCompetencies-we-${props.job.id}`}
          label="Skills and competencies used in carrying out the duties"
          onDataChange={onTextAreaChange}
          value={sectionDetail.skillsAndCompetencies}
          tooltip="Provide details of the skills and competencies you developed in this role and provide examples of how you have applied them."
        />
        <div className="break"></div>
        <FormInput
          name="promoted"
          uniqueName={`promoted-we-${props.job.id}`}
          label="Have you been promoted?"
          type="checkbox"
          fullwidth={true}
          onDataChange={onCheckboxChange}
          checked={sectionDetail.promoted}
        />
        {sectionDetail.promoted && (
          <FormInput
            name="promotionDetails"
            uniqueName={`promotionDetails-we-${props.job.id}`}
            label="Promotion details"
            type="text"
            fullwidth={true}
            onDataChange={onChange}
            value={sectionDetail.promotionDetails}
          />
        )}
        <div className="break" />
        <FormAttachmentsSelect
          name="attachment"
          uniqueName={`attachment-we-${props.job.id}`}
          label="Additional supporting documentation"
          attachmentsData={{ attachmentType: AttachmentTypes.WorkExperience, idOne: sectionDetail.id }}
          onAttachmentsSelect={props.onAttachmentSelect}
          tooltip={promptToolTip}
        />
        <div className="filename">{props.filename}</div>
        <div className="break"></div>
      </form>
    </Fragment>
  );
};

export default WorkExperienceForm;
