import { gql } from '@apollo/client';

export const PERSONAL_DETAIL_FIELDS = gql`
  fragment AdminPersonalDetailsFields on AdminPersonalDetails {
    title
    firstName
    lastName
    gender
    staffId
    department
  }
`;

export const ADMIN_USER_FIELDS = gql`
  fragment AdminUserFields on AdminUser {
    _id
    email
    role
    isVerified
    personalDetails {
      ...AdminPersonalDetailsFields
    }
    createdAt
    updatedAt
  }

  ${PERSONAL_DETAIL_FIELDS}
`;

export const GET_ALL_ADMIN_USERS = gql`
  query GetAllAdminUsers {
    getAllAdminUsers {
      ...AdminUserFields
    }
  }
  ${ADMIN_USER_FIELDS}
`;

export const GET_ADMIN_USER = gql`
  query GetAdminUser {
    getAdminUser {
      ...AdminUserFields
    }
  }
  ${ADMIN_USER_FIELDS}
`;

export const UPDATE_ADMIN_USER = gql`
  mutation UpdateAdminUser($adminUser: AdminUserInput!) {
    updateAdminUser(adminUser: $adminUser) {
      ...AdminUserFields
    }
  }
  ${ADMIN_USER_FIELDS}
`;

export const DELETE_ADMIN_USER = gql`
  mutation DeleteAdminUser($targetEmail: String!) {
    deleteAdminUser(targetEmail: $targetEmail)
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation CreateAdminUser($adminUser: AdminUserInput!, $password: String) {
    createAdminUser(adminUser: $adminUser, password: $password)
  }
`;
