import React, { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, ApolloProvider } from '@apollo/client';

import 'style/app.scss';

import { IsLoggedIn } from 'pages/Login/IsLoggedIn';

import ScrollToTop from 'components/ScrollToTop';

import { SetupContextProviders } from 'services/getCtx';
import { setupApolloClient } from 'services/apollo';
import { constants } from 'buffer';
import CookieConsent from 'react-cookie-consent';
import { InitConstants } from 'services/constants';
import {
  useGoogleAnalytics,
  AnalyticsTiming,
  AnalyticsInit,
  handleAcceptCookie,
  handleDeclineCookie,
} from 'services/googleAnalytics';
const currentTime = new Date().getMilliseconds();

const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClient<unknown>>();
  const [envLoaded, setEnvLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      await InitConstants();
      AnalyticsInit();
      console.log(constants);
      const cl = await setupApolloClient();
      setEnvLoaded(true);
      setClient(cl);
    })();
  }, []);

  useGoogleAnalytics();

  if (!client || !envLoaded) {
    return <div>Loading...</div>;
  }

  if (process.env.NODE_ENV !== 'test') {
    // uses the performance Navigation Timing API if supported
    if (window.performance) {
      const performanceValues = {
        timeSincePageLoad: window.performance.now(),
        firstRequest: window.performance.timing.requestStart,
        firstResponse: window.performance.timing.responseStart,
        lastResponse: window.performance.timing.responseEnd,
      };
      AnalyticsTiming('Timing', 'Server Latency', Math.round(performanceValues.timeSincePageLoad), 'Main app');
      AnalyticsTiming(
        'Timing',
        'Time to First Byte',
        Math.round(performanceValues.firstResponse - performanceValues.firstRequest),
        'Main app'
      );
      AnalyticsTiming(
        'Timing',
        'Time to Last Byte',
        Math.round(performanceValues.lastResponse - performanceValues.firstRequest),
        'Main app'
      );
    }
  }

  AnalyticsTiming('Timing', 'App first render millisec', new Date().getMilliseconds() - currentTime);

  return (
    <ApolloProvider client={client}>
      <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
        This website uses Google Analytics Cookies for the site’s analytics report. The cookie stores your information
        anonymously.
      </CookieConsent>
      <BrowserRouter>
        <ScrollToTop />
        <SetupContextProviders>
          <IsLoggedIn />
        </SetupContextProviders>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
