import React, { useState, useContext, useEffect } from 'react';
import { FormInputChangeParam } from 'components/FormPrimitives/FormInput';
import { UserCtx } from 'services/getCtx';
import { SET_APPLICATION_STATUS } from 'graphql/queries/applicationstatus';
import { useMutation } from '@apollo/client';
import FlashMessageError from 'components/FlashMessageError';
import FlashMessageSuccess from 'components/FlashMessageSuccess';
import { ApplicationStatus } from 'graphql/globalTypes';
import { shouldDisableApplicationStatus } from 'services/utils';

export const StatusDescription = new Map<string, string>([
  [ApplicationStatus.Presubmission, 'Presubmission'],
  [ApplicationStatus.Submitted, 'Submitted for review'],
  [ApplicationStatus.Reviewing, 'Under review'],
  [ApplicationStatus.Moreinfo, 'More information requested'],
  [ApplicationStatus.Recommendaccept, 'Recommended for acceptance'],
  [ApplicationStatus.Accepted, 'Accepted'],
  [ApplicationStatus.Refused, 'Rejected'],
  [ApplicationStatus.Closed, 'Closed'],
  [ApplicationStatus.Registrarapproval, 'Registry approval'],
]);

interface IUpdateStatusUpdate {
  hasSubmittedToRegistrar?: boolean;
}

const UpdateApplicationStatus: React.FC<IUpdateStatusUpdate> = (props) => {
  const [user] = useContext(UserCtx);
  let cStatus = user.application?.currentStatus ?? undefined;
  cStatus = props.hasSubmittedToRegistrar === true ? ApplicationStatus.Registrarapproval : cStatus;

  console.log(`cStatus = ${cStatus}`);
  const [currentStatus, setCurrentStatus] = useState<string | undefined>(cStatus);
  const [stUpdates, setStUpdates] = useState(props.hasSubmittedToRegistrar ?? false);
  const [successMsg, setSuccessMsg] = useState<string>();
  const [setStatus, { error }] = useMutation(SET_APPLICATION_STATUS);
  const shouldDisableStatus = shouldDisableApplicationStatus(user);

  console.log('user exemption status detail in user application');
  console.log(user);

  const onStatusSelect = (event: FormInputChangeParam): void => {
    const selectedStatus = event.target.value;
    if (selectedStatus) {
      setCurrentStatus(selectedStatus);
    }
    setSuccessMsg(undefined);
  };

  useEffect(() => {
    if (props.hasSubmittedToRegistrar && currentStatus !== ApplicationStatus.Registrarapproval) {
      setCurrentStatus(ApplicationStatus.Registrarapproval);
    }
  }, [props.hasSubmittedToRegistrar]);

  useEffect(() => {
    let isRunning = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;
    if (currentStatus === ApplicationStatus.Submitted && stUpdates === false) {
      timeout = setTimeout(async (): Promise<void> => {
        if (isRunning) {
          modifyStatus(ApplicationStatus.Reviewing);
          setStUpdates(true);
          setCurrentStatus(ApplicationStatus.Reviewing);
        }
      }, 5000);
    }
    return (): void => {
      isRunning = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentStatus]);

  const onStatusUpdateButtonClicked = async (): Promise<void> => {
    if (currentStatus) {
      try {
        await modifyStatus(currentStatus);
        setSuccessMsg('Application status has been changed successfully.');
        setStUpdates(true);
      } catch (err) {
        setSuccessMsg(undefined);
        setStUpdates(true);
        console.log('SetApplicationStatus Mutation Error', err.message);
      }
    }
  };

  const modifyStatus = async (status: string): Promise<void> => {
    try {
      await setStatus({
        variables: {
          applicationId: user.application?._id,
          status: status,
        },
      });
    } catch (err) {
      throw err;
    }
  };

  console.log(' current status');
  console.log(currentStatus);
  console.log('hasSubmittedToRegistrar');
  console.log(props.hasSubmittedToRegistrar);

  return (
    <>
      <div className="update-status-controls">
        <p id="update_application_status" className="mt-1">
          Update application status
        </p>
        <select
          aria-labelledby="update_application_status"
          id="status"
          name="status"
          onChange={onStatusSelect}
          value={currentStatus}
          disabled={shouldDisableStatus}
        >
          <option disabled>Select application status</option>
          <option value="submitted">{StatusDescription.get(ApplicationStatus.Submitted)}</option>
          <option value="reviewing">{StatusDescription.get(ApplicationStatus.Reviewing)}</option>
          <option value="moreinfo">{StatusDescription.get(ApplicationStatus.Moreinfo)}</option>
          <option value="recommendaccept">{StatusDescription.get(ApplicationStatus.Recommendaccept)}</option>
          <option value="accepted">{StatusDescription.get(ApplicationStatus.Accepted)}</option>
          <option value="refused">{StatusDescription.get(ApplicationStatus.Refused)}</option>
          <option value="closed">{StatusDescription.get(ApplicationStatus.Closed)}</option>
          <option value="registrarapproval">{StatusDescription.get(ApplicationStatus.Registrarapproval)}</option>
        </select>
        <button
          className="btn btn-sm btn-secondary"
          type="button"
          onClick={onStatusUpdateButtonClicked}
          disabled={shouldDisableStatus}
        >
          Update
        </button>
        {shouldDisableStatus && (
          <p className="update-status-message">All exemptions must be resolved to enable status updates</p>
        )}
      </div>
      {error && <FlashMessageError error={error} />}
      {successMsg && <FlashMessageSuccess message={successMsg} />}
    </>
  );
};

export default UpdateApplicationStatus;
