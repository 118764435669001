import { useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import {
  Application,
  GetUserApplicationsQuery,
  GetUserApplicationsQueryVariables,
  RemoveApplicationMutation,
  RemoveApplicationMutationVariables,
  SetExemptionSupportMutation,
  SetExemptionSupportMutationVariables,
  User,
  GetCoursesQuery,
  GetCoursesQueryVariables,
  Course,
  GetUserSettingsQuery,
  MutationSetNotificationSettingsArgs,
  Notification,
  SetNotificationSettingsMutationVariables,
} from 'graphql/globalTypes';
import { GET_COURSES } from 'graphql/queries/courses';
import { SET_EXEMPTION_SUPPORT } from 'graphql/queries/exemptions';
import { ADD_UPDATE_CURRENT_APPLICATION, GET_USER_APPLICATIONS } from 'graphql/queries/users';
import { constants } from 'services/constants';
import { CourseCtx, ShowExemptionsCtx } from 'services/getCtx';
import { ApplicationWithCourse, ManageApplications } from './ManageApplications';
import { REMOVE_APPLICATION } from 'graphql/queries/applications';
import { PersonalDetailsWidget } from 'components/PersonalDetailsWidget';
import { ModalDialog } from 'components/ModalDialog';
import { DashboardWidget } from 'components/DashboardWidget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { GET_NOTIFICATION_SETTINGS, SET_NOTIFICATION_SETTINGS } from 'graphql/queries/notification';
import AESettingsForm from 'components/ApplicantEmailSettings/AESettingsForm';

let saveTimeoutId: number | undefined;

const Home: React.FC = () => {
  const [course, setCourse] = useContext(CourseCtx);
  const [, setShowExemptions] = useContext(ShowExemptionsCtx);
  const [currentApplication, setCurrentApplication] = useState<Application>();
  const [shouldOpen, setShouldOpen] = useState(false);
  const {
    data: applicant,
    loading: applicantLoading,
    error: applicantError,
    refetch: RefetchData,
  } = useQuery<GetUserApplicationsQuery, GetUserApplicationsQueryVariables>(GET_USER_APPLICATIONS, {
    fetchPolicy: 'cache-and-network',
  });
  const [setSupportExemption, { error: SaveError }] = useMutation<
    SetExemptionSupportMutation,
    SetExemptionSupportMutationVariables
  >(SET_EXEMPTION_SUPPORT);
  const [removeApplication] = useMutation<RemoveApplicationMutation, RemoveApplicationMutationVariables>(
    REMOVE_APPLICATION
  );

  const [AddOrUpdateCurrentApplication] = useMutation(ADD_UPDATE_CURRENT_APPLICATION);
  const { data: courseData } = useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GET_COURSES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (applicant?.getUser?.application && !applicantLoading && !applicantError) {
      const current = applicant.getUser as User;
      if (current.application) {
        setCurrentApplication(current.application);
        setShowExemptions(current.application.exemptionApplication ?? false);
      }
    }
  }, [applicant, applicantLoading, applicantError, currentApplication]);

  const onConfirmExemptionSupport = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();

    const applicationId = currentApplication?._id.toString();
    const shouldSupportExemption = currentApplication?.exemptionApplication ?? false;
    if (!applicationId) {
      return;
    }
    try {
      await setSupportExemption({
        variables: {
          applicationId: applicationId ?? '',
          shouldSupportExemption: !shouldSupportExemption,
        },
      });

      setShowExemptions(!shouldSupportExemption);
    } catch (error) {
      console.log('Error Setting Support Exemptions flag for application: ', error);
    } finally {
      await RefetchData();
    }
  };

  const removeApp = async (applicationId?: string) => {
    if (applicationId) {
      await removeApplication({ variables: { applicationId } });
      await RefetchData();
    }
  };

  const activateCourse = async (targetCourseId?: string): Promise<void> => {
    if (targetCourseId) {
      const course = courseData?.getCourses.find((course: Course) => course._id === targetCourseId);
      course && setCourse(course);
      try {
        await AddOrUpdateCurrentApplication({ variables: { courseId: targetCourseId } });
        await RefetchData();
      } catch (err) {
        console.log('Error saving course selection');
      }
    }
  };

  const onCancel = () => {
    setShouldOpen(!shouldOpen);
  };

  //Notifications setup
  const {
    loading: GetSettingsloading,
    error: GetSettingsError,
    data: SettingsData,
    refetch: RefetchSettingsData,
  } = useQuery<GetUserSettingsQuery>(GET_NOTIFICATION_SETTINGS);
  const [setNotificationSettings, { error: PutSettingsError }] = useMutation<
    MutationSetNotificationSettingsArgs,
    SetNotificationSettingsMutationVariables
  >(SET_NOTIFICATION_SETTINGS);

  const [notification, setNotification] = useState<Notification>();

  useEffect(() => {
    if (SettingsData?.getUser?.notification) {
      console.log('useEffect:GetSettingsData', SettingsData.getUser.notification);

      setNotification(SettingsData.getUser.notification);
    }
  }, [SettingsData]);

  const onChange = async (sectionDetail: Notification): Promise<void> => {
    console.log('sectionDetail', sectionDetail);
    if (saveTimeoutId) {
      window.clearTimeout(saveTimeoutId);
      saveTimeoutId = undefined;
    }

    setNotification(sectionDetail);

    saveTimeoutId = window.setTimeout(async () => {
      const { __typename, ...rest } = sectionDetail;

      try {
        await setNotificationSettings({
          variables: { newMessage: rest.newMessage ?? false, statusChange: rest.statusChange ?? false },
        });
      } catch (error) {
        console.log('Error Saving Email notification settings: ', error);
      } finally {
        await RefetchSettingsData();
      }
    }, constants.TIME_TO_SAVE);
  };

  if (GetSettingsloading) {
    return <div className="response success-response">Loading Data</div>;
  }

  console.log('notifiations');
  console.log(notification);

  return (
    <>
      {applicantLoading && <div>Loading...</div>}
      {SaveError && (
        <div className="response error-response">Error saving exemption support flag: {SaveError.message}</div>
      )}
      {applicantError && <div className="response error-response">Error getting user: {applicantError.message}</div>}
      <ModalDialog isOpen={shouldOpen} title={'Email notification settings'} onCancel={onCancel}>
        <div className="modal-panel">
          <AESettingsForm
            key={'-emailsettings-'}
            job={notification ?? { newMessage: false, statusChange: false }}
            onChange={onChange}
            onCancel={onCancel}
          />
        </div>
      </ModalDialog>
      <div className="dashboard-home-area">
        <div className="grid">
          <div className="col-4">
            {applicant?.getUser && <PersonalDetailsWidget personalInfo={applicant.getUser} />}
          </div>
          <div className="col-8">
            <DashboardWidget>
              <div className="mt-1 ml-1">
                <p className="mb-0 text-bold">Email notification settings</p>
                <p className="mb-0">
                  {notification?.newMessage ? (
                    <FontAwesomeIcon icon={faToggleOn} className="text-red" />
                  ) : (
                    <FontAwesomeIcon icon={faToggleOff} className="text-grey" />
                  )}{' '}
                  Receive emails when new messages appear on your application
                </p>
                <p className="mb-0">
                  {notification?.statusChange ? (
                    <FontAwesomeIcon icon={faToggleOn} className="text-red" />
                  ) : (
                    <FontAwesomeIcon icon={faToggleOff} className="text-grey" />
                  )}{' '}
                  Receive emails when your application status changes
                </p>
              </div>
              <div className="dashboard-widget-footer" onClick={() => setShouldOpen(true)}>
                <span>
                  Edit settings <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </div>
            </DashboardWidget>
          </div>
        </div>
        <ManageApplications
          key={applicant?.getUser.currentCourse}
          currentCourse={applicant?.getUser.currentCourse ?? undefined}
          applications={(applicant?.getUser.applications as ApplicationWithCourse[]) ?? undefined}
          courseSelected={async () => await RefetchData()}
          removeApplication={removeApp}
          activateCourse={activateCourse}
        />
        {constants.FF_EXEMPTIONS_ENABLED && (course?.supportExemptions ?? true) && (
          <p>
            <button
              data-testid="shouldSupportExemption"
              type="submit"
              className="btn btn-sm btn-auto-width mr-5"
              onClick={onConfirmExemptionSupport}
            >
              {currentApplication?.exemptionApplication ? 'Disable Exemption Support' : 'Enable Exemption Support'}
            </button>
          </p>
        )}
      </div>
    </>
  );
};

export default Home;
