interface IRedButton {
  keyValue: string;
  dataTestId?: string;
  onClick: () => void;
}

export const RedButton: React.FC<IRedButton> = (props) => {
  return (
    <button
      key={props.keyValue}
      data-testid={props.dataTestId ?? props.keyValue}
      className="btn-sm btn-delete"
      type="button"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
