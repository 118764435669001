import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface IModalDialogProps {
  title: string;
  isOpen: boolean;
  onCancel: () => void;
}

export const ModalDialog: React.FC<IModalDialogProps> = (props) => {
  if (!props.isOpen) return null;

  return (
    <div className="modal show-modal">
      <div className="modal-generic-header">
        <h2 className="m-0">{props.title}</h2>
        <button className="btn" onClick={props.onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="modal-generic-content">{props.children}</div>
    </div>
  );
};
