import React, { Fragment } from 'react';
import { Exemption, ExemptionReason, Module, User } from 'graphql/globalTypes';
import { ShowFieldAttachment } from './ReviewShowPrimitives';
import { exemptionBasis, exemptionLookup } from 'components/Helper_ExemptionMapper';
import ShouldShowExemptionStatus from 'pages/Admin/application-review/ShouldShowExemptionStatus';

interface IReviewSegmentExemptionProps {
  data: Exemption[];
  moduleData: Module[] | null | undefined;
  applicationId: string;
  isAdmin: boolean;
  user?: User;
}

export const ReviewSegmentExemptions: React.FC<IReviewSegmentExemptionProps> = (props) => {
  return (
    <Fragment>
      <div className="review-section">
        <div>
          <h3>Exemptions</h3>
          <div>
            {props.data.map((exemption, index) => (
              <Fragment key={`${exemption.exemptionType}_${index}`}>
                <div className="exemption-item">
                  {
                    <div className="grid-collapse">
                      <div className="col-1">
                        <div className="review-section-number">{index + 1}</div>
                      </div>
                      <div className="col-11">
                        <h4 className="mb-1">{getModuleName(exemption.moduleId, props.moduleData)}</h4>
                        {displayExemptionReasons(exemption.exemptionReasons)}
                      </div>
                    </div>
                  }
                  <div className="grid-collapse">
                    <div className="col-1"></div>
                    <div className="col-11">
                      <ShouldShowExemptionStatus
                        user={props.user}
                        exemption={exemption}
                        applicationId={props.applicationId}
                        isAdmin={props.isAdmin}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const displayExemptionReasons = (inputData: ExemptionReason[] | null | undefined) => {
  if (inputData) {
    return inputData.map((item, index) => {
      return (
        <div className="exemption-basis" key={`${item.id}_${index}`}>
          <label htmlFor={`${item.id}_${index}`}>Basis for exemption</label>
          <p id={`${item.id}_${index}`}>{exemptionLookup(item.type, exemptionBasis)}</p>
          {item.experience && (
            <Fragment>
              <label htmlFor={`${item.id}_${index}_experience`}>Experience</label>
              <p id={`${item.id}_${index}_experience`}>{item.experience}</p>
            </Fragment>
          )}
          {item.attachments?.filename && ShowFieldAttachment('Certification', item.attachments)}
        </div>
      );
    });
  } else {
    return;
  }
};

const getModuleName = (moduleId: string, courseModules?: Module[] | null) => {
  const targetModule = courseModules?.filter((item) => {
    return item._id === moduleId;
  });
  return targetModule && targetModule[0] ? targetModule[0].title : 'Selected module';
};
