import { gql } from '@apollo/client';

import { SECONDARY_EDUCATION_FIELDS } from './secondaryeducation';
import { FURTHER_EDUCATION_FIELDS } from './furthereducation';

export const EDUCATION_FIELDS = gql`
  fragment EducationFields on Education {
    secondary {
      ...SecondaryEducationFields
    }
    furtherEducation {
      ...FurtherEducationFields
    }
    thirdLevel {
      ...FurtherEducationFields
    }
  }

  ${SECONDARY_EDUCATION_FIELDS}
  ${FURTHER_EDUCATION_FIELDS}
`;

export const GET_EDUCATION = gql`
  query GetEducation {
    getUser {
      _id
      email
      education {
        ...EducationFields
      }
    }
  }

  ${EDUCATION_FIELDS}
`;

export const REPLACE_ALL_EDUCATION = gql`
  mutation ReplaceAllEducation($education: EducationInput!) {
    replaceAllEducation(education: $education) {
      _id
      email
      education {
        ...EducationFields
      }
    }
  }

  ${EDUCATION_FIELDS}
`;
