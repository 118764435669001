import { ApplicationStatus, User } from 'graphql/globalTypes';
import { decodeToken } from 'services/validation';
import Avatar from 'images/samples/avatar.png';
import { ApplicationLink } from './ApplicationLink';
import { DateFormatter } from 'components/helper_date';

interface IApplicationGrid {
  Title?: string;
  applicants: User[];
  onSelected: (targetApplicant: User) => void;
}

const ApplicationGrid: React.FC<IApplicationGrid> = (props) => {
  return (
    <>
      {props.Title?.length ? <p className="text-bold mb-0">{props.Title}</p> : null}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Applicant</th>
            <th>Last Updated</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.applicants
            ?.filter((user) => user.application?.currentStatus !== ApplicationStatus.Presubmission)
            .map((user) => (
              <tr key={`app.${user._id}`}>
                <td>
                  <img
                    className="avatar-sm"
                    src={
                      user?.personalDetails?.profilePicture?.filepath
                        ? user?.personalDetails?.profilePicture?.filepath + '?token=' + decodeToken().token
                        : Avatar
                    }
                    alt="Applicant avatar"
                  />
                </td>
                <td>
                  <span className="text-bold">{`${user.personalDetails.firstName} ${user.personalDetails.lastName}`}</span>
                </td>
                <td>
                  <DateFormatter date={user.application?.updatedAt} />
                </td>
                <td>{user.application?.currentStatus}</td>
                <td>
                  <ApplicationLink setApplicant={props.onSelected} targetApplicant={user} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ApplicationGrid;
