import React, { useEffect, useState } from 'react';
import FormTextArea, { FormTextAreaChange } from 'components/FormPrimitives/FormTextArea';
import {
  AttachmentTypes,
  ExemptionReason,
  MutationCopyFurtherCertsToExemptionReasonArgs,
  MutationCopyThirdLevelCertsToExemptionReasonArgs,
  User,
} from 'graphql/globalTypes';
import { FormAttachmentsSelect, funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';
import { DeleteConfirmModal } from 'pages/Applicant/PersonalDetails/DeleteConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { ExemptionEduSelection } from './ExemptionEduSelection';
import { EducationTypes } from 'pages/Applicant/PersonalDetails/Education';
import { useMutation } from '@apollo/client';
import { COPY_FURTHER_CERTIFICATE, COPY_THIRDLEVEL_CERTIFICATE } from 'graphql/queries/copycertificate';
import { promptToolTip } from 'services/constants';

export interface IReasonCLSelection {
  onChange: (index: number, reason: ExemptionReason) => void;
  moduleId: string;
  index: number;
  parentIndex: number;
  job: ExemptionReason;
  filename?: string | null;
  onAttachmentSelect: funcAttachmentsSelect;
  onDeleteExemptionReason: (moduleId: string, reasonId: string) => void;
  user: User;
}

const OPCLform: React.FC<IReasonCLSelection> = (props) => {
  const [sectionDetail, setSectionDetail] = useState<ExemptionReason>(props.job);

  const [copyFECert, { error: CopyFECertError }] =
    useMutation<MutationCopyFurtherCertsToExemptionReasonArgs>(COPY_FURTHER_CERTIFICATE);

  const [copyTLCert, { error: CopyTLCertError }] =
    useMutation<MutationCopyThirdLevelCertsToExemptionReasonArgs>(COPY_THIRDLEVEL_CERTIFICATE);

  useEffect(() => {
    setSectionDetail(props.job);
  }, [props.job, props.filename]);

  const onTextAreaChange: FormTextAreaChange = (value, index) => {
    console.log('PL text area changes');
    console.log(value);
    console.log(index);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newReason: any = Object.assign({}, sectionDetail);
    newReason[index as string] = value;
    setSectionDetail(newReason);
    props.onChange(props.index, newReason);
  };

  const onDeleteConfirm = (): void => {
    console.log('delete button called.');
    props.onDeleteExemptionReason(props.moduleId, props.job.id);
  };

  const handleDeleteClick = (): void => {
    const confirmSubmission = document.getElementById(`delete_confirm_modal_plform.${props.index}.${props.moduleId}`);

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      } else {
        confirmSubmission.classList.add('show-modal');
      }
    }
  };

  const onEduSelect = async (data: string, eduId: string, type: EducationTypes): Promise<void> => {
    const expTextArea = document.getElementById(
      `experience-pl-${props.parentIndex}${props.index}`
    ) as HTMLTextAreaElement;

    try {
      if (type === EducationTypes.ThirdLevel) {
        await copyTLCert({ variables: { thirdLevelId: eduId, reasonId: sectionDetail.id, moduleId: props.moduleId } });
      } else {
        await copyFECert({
          variables: { furtherEducationId: eduId, reasonId: sectionDetail.id, moduleId: props.moduleId },
        });
      }
    } catch (e) {
      console.log(`Error saving the files: ${e}`);
    }
    expTextArea.value = data;
    onTextAreaChange(data, 'experience');
  };

  const labelText =
    'Add an award description for this Prior Learning entry. Show how it contributes to your meeting the learning outcomes of the module. Be sure to attach appropriate certification.';

  return (
    <div className="form-learning-type-area">
      {sectionDetail && (
        <div className="delete-item-button">
          <button
            key={'del' + sectionDetail.id}
            onClick={handleDeleteClick}
            className={'btn-sm btn-delete'}
            type="button"
          >
            Delete entry <FontAwesomeIcon className={'delete-item-icon'} icon={faMinusCircle} />
          </button>
        </div>
      )}
      <div className="break" />
      <h4 className="form-learning-type-head">Prior Learning</h4>
      <DeleteConfirmModal
        onDeleteConfirm={onDeleteConfirm}
        record={'Prior Learning'}
        index={props.index}
        modalId={`delete_confirm_modal_plform.${props.index}.${props.moduleId}`}
      />
      <div className="break" />

      <ExemptionEduSelection onEduSelect={onEduSelect} index={props.index} user={props.user} />
      <div className="break" />
      <FormTextArea
        name="experience"
        uniqueName={`experience-pl-${props.parentIndex}${props.index}`}
        label={labelText}
        onDataChange={onTextAreaChange}
        value={props.job.experience}
      />
      <div className="break" />
      <FormAttachmentsSelect
        name="attachment"
        uniqueName={`attachment-pl-${props.parentIndex}${props.index}`}
        label="Upload Certification"
        attachmentsData={{
          attachmentType: AttachmentTypes.ExemptionReason,
          idOne: props.moduleId,
          idTwo: props.job.id,
        }}
        tooltip={promptToolTip}
        onAttachmentsSelect={props.onAttachmentSelect}
      />
      <div className="filename">{props.filename}</div>
      <div className="break"></div>

      {CopyTLCertError && (
        <div className="response error-response">Error copy third level certificate: {CopyTLCertError}</div>
      )}
      {CopyFECertError && (
        <div className="response error-response">Error copy further education certificate: {CopyFECertError}</div>
      )}
    </div>
  );
};

export { OPCLform };
