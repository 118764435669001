import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { FETLForm } from './Forms/FETLForm';
import { FurtherEducation } from 'graphql/globalTypes';
import { funcAttachmentsSelect } from 'components/FormPrimitives/FormAttachmentsSelect';

import { AnalyticsSendEvent } from 'services/googleAnalytics';
import { funcEducationChange, EducationTypes, IFuncEducationChange } from './Education';
import Tooltip from 'components/Tooltip';

interface IFETLProps {
  educationType: EducationTypes;
  FETL: FurtherEducation[];
  onChange: funcEducationChange;
  onAttachmentsSelect: funcAttachmentsSelect;
  onDelete: (id: string, educationType: EducationTypes) => void;
}

export const FETL: React.FC<IFETLProps> = (props) => {
  const onFETLChange = (index: number, sectionDetail: FurtherEducation): void => {
    const newSections = [...props.FETL];
    newSections[index] = sectionDetail;

    props.onChange(prepareReturnProps(newSections, props.educationType));
  };

  const onPlusBtnClick = (e: React.FormEvent): void => {
    e.preventDefault();

    const newSections = [...props.FETL, { id: 'idNew' } as FurtherEducation];

    props.onChange(prepareReturnProps(newSections, props.educationType), true);

    AnalyticsSendEvent({
      category: 'User - Applicant',
      action: 'Add more details',
      label: props.educationType === EducationTypes.ThirdLevel ? 'Third Level' : 'Further Education',
      transport: 'beacon',
    });
  };

  const onDelete = async (sectionDetail: FurtherEducation): Promise<void> => {
    props.onDelete(sectionDetail.id, props.educationType);
  };

  const currentTitle =
    props.educationType === EducationTypes.ThirdLevel ? 'Third Level Education' : 'Further Education';
  return (
    <div className="education-form-container">
      <h3 className="mt-4">
        {currentTitle}
        <Tooltip tooltip="Provide details of all relevant institutes at which you have previously studied." />
      </h3>

      {props.FETL.map((item, index) => {
        return (
          <div key={`${index}_${item.id}`}>
            <div className="break mt-1 mb-1"></div>
            <div className="review-section-number">{index + 1}</div>
            <FETLForm
              key={`FETL-${index}-${item.id}-${item.attachments?.filename}`}
              educationType={props.educationType}
              index={index}
              job={item}
              onChange={onFETLChange}
              onAttachmentsSelect={props.onAttachmentsSelect}
              onDelete={onDelete}
              recordTitle={`${currentTitle} -> Item ${index + 1}.`}
            />
          </div>
        );
      })}
      <div className="add-button-container">
        <button type="submit" onClick={onPlusBtnClick}>
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
      </div>
    </div>
  );
};

/**
 * @description Use this to format the return data so that ONLY the required data is sent back
 *
 * @param newSection Data to send back
 */
const prepareReturnProps = (newSection: FurtherEducation[], educationType: EducationTypes): IFuncEducationChange => {
  const retVal: IFuncEducationChange = {};

  switch (educationType) {
    case EducationTypes.FurtherEducation:
      retVal.FE = newSection;
      break;

    case EducationTypes.ThirdLevel:
      retVal.TL = newSection;
      break;
  }

  return retVal;
};
