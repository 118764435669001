import { gql } from '@apollo/client';

import { SOLLE_ATTACHMENT_FIELDS } from './solleattachment';

export const SKILLS_AND_COMPETENCIES_FIELDS = gql`
  fragment SkillsAndCompetenciesFields on SkillsCompetencies {
    title
    description
    attachments {
      ...SolleAttachmentFields
    }
  }

  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const GET_SKILLS_AND_COMPETENCIES = gql`
  query GetUserForSC {
    getUser {
      _id
      email
      skillsCompetencies {
        title
        description
        attachments {
          ...SolleAttachmentFields
        }
      }
    }
  }

  ${SOLLE_ATTACHMENT_FIELDS}
`;

export const ADD_OR_UPDATE_SCS = gql`
  mutation AddOrUpdateSCs($skillsCompetencies: [SkillsCompetenciesInput!]!) {
    addOrUpdateSCs(skillsCompetencies: $skillsCompetencies) {
      skillsCompetencies {
        ...SkillsAndCompetenciesFields
      }
    }
  }

  ${SKILLS_AND_COMPETENCIES_FIELDS}
`;

export const REMOVE_SKILLS_AND_COMPETENCIES = gql`
  mutation RemoveSkillAndCompetency($title: String!) {
    removeSkillAndCompetency(title: $title) {
      skillsCompetencies {
        ...SkillsAndCompetenciesFields
      }
    }
  }
  ${SKILLS_AND_COMPETENCIES_FIELDS}
`;
