import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface IReviewConfirmModal {
  onReview: () => void;
}

export const ReviewConfirmModal: React.FC<IReviewConfirmModal> = (props) => {
  const CloseModal = (): void => {
    const confirmSubmission = document.getElementById('confirm_modal');

    if (confirmSubmission !== null) {
      if (confirmSubmission.classList.contains('show-modal')) {
        confirmSubmission.classList.remove('show-modal');
      }
    }
  };

  const onSaveBtnClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    //ReviewAndSubmit();
    CloseModal();
    props.onReview();
  };

  return (
    <div id="confirm_modal" className="modal">
      <div className="modal-popover-confirm">
        <div className="modal-login-form-area">
          <div className="close-modal" onClick={CloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="modal-content-exemptions">
            <p className="text-bold">Submit application</p>
            <p>
              By submitting your application in respect of this award, you are declaring that the information contained
              in this application and all supporting documentation is a true and accurate reflection of your prior
              learning. You accept that any advice or instruction given to you by the Institute or its staff in the
              preparation of the application does not confer any entitlement to admission or advanced entry. Your
              application will be sent to the HEA team for assessment and you will be updated on its progress.
            </p>
          </div>
        </div>

        <div className="modal-confirm-button text-right">
          <button type="submit" className="btn btn-sm btn-secondary btn-auto-width mr-1" onClick={CloseModal}>
            Back
          </button>
          <button
            data-testid="Submit RPL Application"
            type="submit"
            className="btn btn-sm btn-auto-width mr-5"
            onClick={onSaveBtnClick}
          >
            Submit RPL Application
          </button>
        </div>
      </div>
    </div>
  );
};
