import React, { useState, useEffect, Fragment } from 'react';
import { GET_USER } from 'graphql/queries/users';
import { useQuery } from '@apollo/client';
import { GetUserQuery } from 'graphql/globalTypes';
import { IUserStateDetail } from 'pages/Navigation/DashboardHeader';

const ApplicationStatusNotice: React.FC<IUserStateDetail> = (props) => {
  const { data: userData } = useQuery<GetUserQuery>(GET_USER, { skip: props.isAdmin });

  const [moreInfoRequired, setMoreInfoRequired] = useState(false);

  useEffect(() => {
    setMoreInfoRequired(userData?.getUser.application?.currentStatus === 'moreinfo');
  }, [userData]);

  return (
    <Fragment>
      {moreInfoRequired && (
        <div className="response alert-response m-1">
          Further information has been requested on your application. Please address the requested items and resubmit.
        </div>
      )}
    </Fragment>
  );
};

export default ApplicationStatusNotice;
