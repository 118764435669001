import React, { useEffect, useState } from 'react';

import { ShowCourses } from 'pages/Course/ShowCourses';
import MarketingFooter from 'pages/Marketing/MarketingFooter';

import Hero from 'components/hero/Hero';
import { ContentHome } from 'components/content/ContentHome';
import { AnalyticsTiming } from 'services/googleAnalytics';
import VerifyResetPassword from 'pages/Login/VerifyResetPassword';
import { useParams } from 'react-router-dom';
import { IUserStateDetail } from '../Navigation/DashboardHeader';

const currentTime = +new Date().getMilliseconds();

export const MarketingHome: React.FC<IUserStateDetail> = (props) => {
  const { token } = useParams();
  AnalyticsTiming('Timing', 'Marketing render millisec', new Date().getMilliseconds() - currentTime);

  if (process.env.NODE_ENV !== 'test') {
    // uses the performance Navigation Timing API if supported
    if (window.performance) {
      const performanceValues = {
        timeSincePageLoad: window.performance.now(),
        firstRequest: window.performance.timing.requestStart,
        firstResponse: window.performance.timing.responseStart,
        lastResponse: window.performance.timing.responseEnd,
      };
      AnalyticsTiming('Timing', 'Server Latency', Math.round(performanceValues.timeSincePageLoad), 'Marketing page');
      AnalyticsTiming(
        'Timing',
        'Time to First Byte',
        Math.round(performanceValues.firstResponse - performanceValues.firstRequest),
        'Marketing page'
      );
      AnalyticsTiming(
        'Timing',
        'Time to Last Byte',
        Math.round(performanceValues.lastResponse - performanceValues.firstRequest),
        'Marketing page'
      );
    }
  }

  const [isRPModalShowing, setIsRPModalShowing] = useState(false);

  useEffect(() => {
    if (token && !isRPModalShowing) {
      showVerifyRPModal();
      setIsRPModalShowing(true);
    }
  }, [token, isRPModalShowing]);

  const showVerifyRPModal = (): void => {
    const fp = document.getElementById('fp_modal');

    if (fp !== null) {
      if (fp.classList.contains('show-modal')) {
        fp.classList.remove('show-modal');
      } else {
        fp.classList.add('show-modal');
      }
    }
  };

  return (
    <>
      <Hero {...props} {...{ target: 'home' }} />
      {token && <VerifyResetPassword />}
      <ContentHome target="home" />
      <section id="courses-area">
        <div className="content-area">
          <div className="content-header">
            <h2>Awards for RPL Applicants</h2>
          </div>
          <div className="content-body-cards">
            <div className="flex-grid-row-marketing">
              <ShowCourses />
            </div>
          </div>
        </div>
      </section>
      <MarketingFooter />
    </>
  );
};
