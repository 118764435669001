import React, { useState, useEffect } from 'react';

import { AnalyticsSendEvent } from 'services/googleAnalytics';

import axios, { AxiosResponse } from 'axios';

export interface IResetForm {
  url: string;
  onResetSubmitted: (message: string) => void;
  backToMain: () => void;
}

const ResetPasswordForm: React.FC<IResetForm> = (props) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [rpSubmit, setRPSubmit] = useState(false);
  const [fpMessage, setRPMessage] = useState('');
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (rpSubmit) {
      props.onResetSubmitted(fpMessage);
    }
  }, [rpSubmit]);

  const onReset = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await axios.post<unknown, AxiosResponse<any, any>>(props.url, {
        email: emailAddress,
        headers: {
          'content-type': 'application/json',
        },
      });

      setRPMessage(data.message);
      setRPSubmit(true);

      AnalyticsSendEvent({
        category: 'User - Generic',
        action: 'Forgot Password',
        transport: 'beacon',
      });
    } catch (error) {
      setRPSubmit(false);
      setError(error);
      console.log(error);
    }
  };

  const updateEmailAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailAddress(event.target.value);
  };

  return (
    <form>
      <div className="form-group">
        <label id="user_email" htmlFor="email">
          Your email address
        </label>
        <input
          type="email"
          className="full-width-form-field"
          aria-describedby="user_email"
          onChange={updateEmailAddress}
        />
      </div>
      {error && (
        <div className="response error-response">
          <p>{error.message}</p>
        </div>
      )}
      <div className="modal-login-submit">
        <button
          type="submit"
          className="btn btn-modal btn-secondary btn-full-width"
          disabled={emailAddress === '"'}
          onClick={onReset}
        >
          Forgot Password
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
